import { makeStyles, useTheme, Box, Stepper, Step, StepLabel } from '@material-ui/core'

import Layout from 'components/ui/Layout'

interface Props {
  activeStep: number
}

const useStyles = makeStyles(theme => ({
  stepperDesktop: {
    height: 114,
  },
}))

const RenewalStepper = ({ activeStep }: Props) => {
  const classes = useStyles()
  const theme = useTheme()

  const steps = ['Policy and Drivers', 'Your car', 'Quote', 'Payment', 'Renewal confirmation']

  return (
    <>
      <div style={{ position: 'relative', zIndex: 10 }}>
        <div style={{ position: 'fixed', left: 0, right: 0, top: 90 }}>
          <Layout background={theme.palette.primary.main}>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <Stepper activeStep={activeStep} alternativeLabel className={classes.stepperDesktop}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Layout>
        </div>
      </div>

      <Box display={{ xs: 'block', sm: 'none' }}>
        <div style={{ marginTop: 90 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </Box>
    </>
  )
}

export default RenewalStepper
