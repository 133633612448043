export function getContentByErrorCode(errorId: string | null) {
  switch (errorId) {
    case '626':
    case '627':
    case '628':
    case '629':
      return {
        title: '',
        text: `__Sorry, we're unable to process your policy change online.__\n\nPlease contact our Customer Service team on the details provided below. \n\nWe're sorry for any inconvenience caused.`,
      }
    case 'purchase_failed':
      return {
        title: 'Purchase failed',
        text: `We were unable to complete this transaction. No payment has been taken. Please try again later or call us on 0333 103 7791. We apologise for any inconverience caused.`,
      }
    default:
      return {
        title: '',
        text: `__Technical error__\n\nWe are experiencing some technical problems. Please try again later or contact us on Live Chat.`,
      }
  }
}
