import { handleActions } from 'redux-actions'

import {
  showLoginModalAction,
  closeLoginModalAction,
  getAddressByPostalCodeAction,
  getAddressByIdAction,
  getAccountInformationAction,
  updateCorrespondenceAddressAction,
} from './actions'

const defaultState = {
  showLoginModal: false,
  contactNumbersMessage: '',
  emailOrPasswordMessage: '',
  lookupAddresses: [],
  selectedAddress: undefined,
  correspondenceAddress: { addressLine1: '' },
  redriect: undefined,
  marketingPreference: '',
}

export default handleActions(
  {
    [showLoginModalAction.success]: (
      state,
      {
        payload,
      }: {
        payload: {
          showLoginModal: boolean
          contactNumbersMessage?: string
          emailOrPasswordMessage: string
          redirect: string
        }
      },
    ) => {
      return {
        ...state,
        showLoginModal: payload.showLoginModal,
        contactNumbersMessage: payload.contactNumbersMessage ? payload.contactNumbersMessage : '',
        emailOrPasswordMessage: payload.emailOrPasswordMessage,
        redirect: payload.redirect,
      }
    },
    [closeLoginModalAction.success]: () => {
      return defaultState
    },
    [getAddressByPostalCodeAction.success]: (state, { payload }: { payload: any }) => {
      const { lookupAddresses } = payload
      if (!lookupAddresses) {
        return state
      }
      return {
        ...state,
        lookupAddresses,
      }
    },
    [getAccountInformationAction.success]: (state, { payload }: { payload: any }) => {
      const { correspondenceAddress, documentationPreference, marketingPrefOptOut } = payload

      return {
        ...state,
        ...(correspondenceAddress && {
          correspondenceAddress,
        }),
        ...(documentationPreference && {
          documentationPreference,
        }),
        ...(marketingPrefOptOut && {
          marketingPreference: marketingPrefOptOut,
        }),
      }
    },
    [getAddressByIdAction.success]: (state, { payload }: { payload: any }) => {
      const { selectedAddress } = payload
      if (!selectedAddress) {
        return state
      }
      return {
        ...state,
        selectedAddress,
      }
    },
    [updateCorrespondenceAddressAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        lookupAddresses: [],
        selectedAddress: undefined,
      }
    },
  },
  defaultState,
)
