import { connect } from 'react-redux'
import { renewalBindPaymentAction } from 'redux/payment/actions'

import { IReduxState } from 'types/stateTypes'
import RenewalMonthlyMandatePage from './RenewalMonthlyMandatePage'

const mapStateToProps = (state: IReduxState) => ({
  policyNumber: state.policy.policyIds[state.policy.currentPolicyIndex],
  quote: state.renewal.quote,
})

const mapDispatchToProps = {
  renewalBindPaymentAction: renewalBindPaymentAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalMonthlyMandatePage)
