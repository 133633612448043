import React from 'react'
import SVG from 'react-inlinesvg'
import ReactMarkdown from 'react-markdown'
import { v4 as uniqeID } from 'uuid'

import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import { Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography, makeStyles } from '@material-ui/core'

import SpeechBubble from 'components/ui/SpeechBubble'

import content from './content.json'
import { BreakdownHeader } from './BreakdownHeader'
import { IncludedAddOn } from 'guidewire/policy/types/includedAddon'
import { getBreakdownName } from 'utils/content/options'
import { dateObjectToMoment } from 'guidewire/formatTools'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    gap: '0px 10px',
  },
  productIcon: {
    height: 85,
    width: 85,
    marginBottom: 10,
  },
  productIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#cecfd0',
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    transition: '0.5s',

    '& p': {
      marginTop: 0,
    },
  },
  tickCross: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#cecfd0',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    justifyContent: 'center',
    transition: '0.5s',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#cecfd0',
    padding: 10,
    paddingBottom: 20,
    textAlign: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    fontSize: 14,
    transition: '0.5s',
  },
  selected: {
    transform: 'scale(1.05)',
    backgroundColor: '#202020',
    color: 'white',
  },
  // Mobile breakdown styles
  mobileTitle: {
    textAlign: 'center',
    '& p': {
      marginTop: 0,
    },
  },
  mobileCard: {
    backgroundColor: '#cecfd0',
    marginBottom: 20,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobileCardSelected: {
    backgroundColor: '#202020',
    color: 'white',
  },
  // screens
  desktop: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      display: 'none',
    },
  },
}))

export interface Props {
  selectedLevel: string
  onAddon: (id: string, name: string, code: string) => void
  onRemove: (id: string) => void
  breakdownCosts: IncludedAddOn[]
  periodStartDate: any
}

const BreakdownCover = ({ selectedLevel, breakdownCosts, periodStartDate, onAddon, onRemove }: Props) => {
  const classes = useStyles()

  const { breakDownIcons, breakDownInfo, breakDownLinks, linksText, title, titleIcon, subtitle, speechBubble } = content

  // The levels are not presented in order (ie 1, 2, 3) but are ordered by GW. This matches that order
  const levels = ['Level2', 'Level1', 'Level3']
  // if selectedLevel is "none", index is -1
  const selectedIndex = levels.indexOf(selectedLevel)

  const getCost = (id: string) => {
    if (id.includes('Level1')) {
      return breakdownCosts.find(item => item.codeIdentifier === 'Level1')?.costs?.costPerYear
    }
    if (id.includes('Level2')) {
      return breakdownCosts.find(item => item.codeIdentifier === 'Level2')?.costs?.costPerYear
    }
    if (id.includes('Level3')) {
      return breakdownCosts.find(item => item.codeIdentifier === 'Level3')?.costs?.costPerYear
    }

    return 'PH '
  }

  const pcpdOld =
  'https://assets.ctfassets.net/numinvtctmda/6AJsK5H1untCWODvKvKdtX/16b94daaf95f2c38df76c930ed4b6633/TYA_PCPD_v5_Key_Provider_Change.pdf'
  const pcpdNew =
  'https://assets.ctfassets.net/numinvtctmda/WmiPaKx9Irk4N7LKXPqJp/3a75d372e1bd06b5c26a67f8b8cbd417/TYA_PCPD_v6_Key_Provider_Change.pdf'

  const now = dateObjectToMoment(periodStartDate)

  const showNew = moment('20250101', 'YYYYMMDD')

  const correctPCPDdoc = now.isAfter(showNew) ? pcpdNew : pcpdOld

  return (
    <>
      <Grid container spacing={4}>
        <BreakdownHeader title={title} titleIcon={titleIcon} subtitle={subtitle} />

        {/* Desktop */}
        <Grid item xs={12} className={`${classes.container} ${classes.desktop}`}>
          <SpeechBubble body={speechBubble || ''} />

          {breakDownIcons.map((item, index) => (
            <div
              key={item.id}
              className={`${classes.productIconContainer} ${index === selectedIndex ? classes.selected : ''}`}
            >
              <SVG className={classes.productIcon} src={item.iconImage} />
              <ReactMarkdown>{`${item.body} \n\n £${getCost(item.id)} per year` || ''}</ReactMarkdown>
            </div>
          ))}

          {/* Note, these content values use the order Level2, Level1, Level3 */}
          {breakDownInfo.map(item => (
            <React.Fragment key={item.id}>
              <div style={{ fontSize: 14 }}>
                <ReactMarkdown>{item.text || ''}</ReactMarkdown>
                <div style={{ borderBottom: '1px solid #efefef' }} />
              </div>
              {item.included.map((included, index) => (
                <div
                  key={uniqeID()}
                  className={`${classes.tickCross} ${index === selectedIndex ? classes.selected : ''}`}
                >
                  {included ? <CheckIcon /> : <CloseIcon />}
                </div>
              ))}
            </React.Fragment>
          ))}

          <div style={{ fontSize: 14 }}>
            <ReactMarkdown>{linksText || ''}</ReactMarkdown>
          </div>
          {breakDownLinks.map((item, index) => (
            <div key={uniqeID()} className={`${classes.links} ${index === selectedIndex ? classes.selected : ''}`}>
              <a href={item} target="__blank">
                View insurance product information document
              </a>
            </div>
          ))}

          <div />
          {levels.map((item, index) => {
            return index === selectedIndex ? (
              <Button
                style={{ marginTop: 16 }}
                key={uniqeID()}
                variant="outlined"
                color="primary"
                onClick={() => onRemove('TMBreakdownCov')}
              >
                Remove cover
              </Button>
            ) : (
              <Button
                style={{ marginTop: 16 }}
                key={uniqeID()}
                variant="outlined"
                color="secondary"
                onClick={() => onAddon('TMBreakdownCov', getBreakdownName(item), item)}
              >
                Add cover
              </Button>
            )
          })}
        </Grid>

        {/* Mobile */}
        <Grid item xs={12} className={classes.mobile}>
          {breakDownIcons.map((item, index) => (
            <Paper
              key={uniqeID()}
              elevation={0}
              className={`${classes.mobileCard} ${index === selectedIndex ? classes.mobileCardSelected : ''}`}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <SVG className={classes.productIcon} src={item.iconImage} />
                <ReactMarkdown className={classes.mobileTitle}>{item.body || ''}</ReactMarkdown>

                {item.cardIncluded.map(included => (
                  <div key={uniqeID()} style={{ width: '100%' }}>
                    <ReactMarkdown>{included}</ReactMarkdown>
                    <div style={{ borderBottom: `1px solid ${index === selectedIndex ? 'white' : '#202020'} ` }} />
                  </div>
                ))}

                <Button
                  style={{ marginTop: 16 }}
                  variant="outlined"
                  color={index === selectedIndex ? 'primary' : 'secondary'}
                  onClick={
                    index === selectedIndex
                      ? () => onRemove('TMBreakdownCov')
                      : () => onAddon('TMBreakdownCov', getBreakdownName(levels[index]), levels[index])
                  }
                >
                  {index === selectedIndex ? 'Remove cover' : 'Add cover'}
                </Button>
              </div>
            </Paper>
          ))}
        </Grid>

        <Grid item xs={12}>
          <RadioGroup style={{ border: '1px solid', borderRadius: 4, padding: 10 }} value={selectedIndex}>
            <FormControlLabel
              value={undefined}
              control={<Radio />}
              label="I do not need Breakdown Cover"
              checked={selectedLevel === 'none'}
              onChange={() => onRemove('TMBreakdownCov')}
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <ReactMarkdown linkTarget="__blank">
            {`You are covered for a maximum of 6 assistances in a policy year. You cannot claim within the first 48 hours
            of your policy. You can access the [Policy Document](${correctPCPDdoc}) for full terms and conditions`}
          </ReactMarkdown>
        </Grid>
      </Grid>
    </>
  )
}

export default BreakdownCover
