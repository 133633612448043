import { ButtonClassKey } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import { brandPalette } from '../brandPalette'

export const buttonStyles: Partial<StyleRules<ButtonClassKey, any>> = {
  root: {
    height: 50,
    minWidth: 120,
    borderRadius: 25,
    textTransform: 'none',
  },
  outlined: {
    color: brandPalette.button.default.color,
    backgroundColor: brandPalette.button.default.background,
    border: '1px solid',
    borderColor: brandPalette.button.default.borderColor,
    '&:hover': {
      backgroundColor: brandPalette.button.default.background,
      border: '1px solid',
      borderColor: brandPalette.button.default.borderColor,
    },
    '&:disabled': {
      opacity: 0.5,
      color: brandPalette.button.default.color,
      border: '1px solid',
      borderColor: brandPalette.button.default.borderColor,
    },
  },
  outlinedPrimary: {
    color: brandPalette.button.primary.color,
    backgroundColor: brandPalette.button.primary.background,
    border: '1px solid',
    borderColor: brandPalette.button.primary.borderColor,
    '&:hover': {
      backgroundColor: brandPalette.button.primary.background,
      border: '1px solid',
      borderColor: brandPalette.button.primary.borderColor,
    },
    '&:disabled': {
      opacity: 0.5,
      color: brandPalette.button.primary.color,
      border: '1px solid',
      borderColor: brandPalette.button.primary.borderColor,
    },
  },
  outlinedSecondary: {
    color: brandPalette.button.secondary.color,
    backgroundColor: brandPalette.button.secondary.background,
    border: '1px solid',
    borderColor: brandPalette.button.secondary.borderColor,
    '&:hover': {
      backgroundColor: brandPalette.button.secondary.background,
      border: '1px solid',
      borderColor: brandPalette.button.secondary.borderColor,
    },
    '&:disabled': {
      opacity: 0.5,
      color: brandPalette.button.secondary.color,
      border: '1px solid',
      borderColor: brandPalette.button.secondary.borderColor,
    },
  },
}
