import React from 'react'

import {
  Accordion,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
  AccordionDetails,
  Divider,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { LabelValuePair } from 'types/generalTypes'
import { DriverDisplay } from 'types/policy'

const useStyles = makeStyles(theme => ({
  root: {
    border: '0',
    boxShadow: 'none',
    borderBottom: (props: any) => (props.useBorder ? `1px solid ${theme.palette.divider}` : 0),
    '&:last-child': {
      borderRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  label: {
    fontWeight: 'normal',
  },
  container: {
    width: '100%',
  },
}))

export interface Props {
  useBorder?: boolean
  driverDetails: LabelValuePair[]
  driverOtherDetails: LabelValuePair[]
  additionalDrivers: DriverDisplay[]
}

const BasicAccordion = ({ useBorder = true, driverDetails, driverOtherDetails, additionalDrivers }: Props) => {
  const props = { useBorder }
  const classes = useStyles(props)

  return (
    <>
      <Grid container item xs={11}>
        {driverDetails.map(item => (
          <Grid item xs={4} key={item.label}>
            <Typography color="textSecondary" className={classes.label}>
              {item.label}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {additionalDrivers.map((driver, index) => (
        <React.Fragment key={driver.displayName}>
          <Box pb={1} />

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* Border only needs to show for first driver or it looks false */}
              {index < 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}

              <Accordion className={classes.root}>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
                  <Grid container spacing={1}>
                    {driverDetails.map(item => (
                      <Grid item xs={4} key={`${item.value} ${driver.displayName}`}>
                        <Typography className={classes.label}>{driver[item.value]}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {driverOtherDetails.map(item => (
                              <TableRow key={`${item.value} ${driver.displayName}`}>
                                <TableCell>{item.label}</TableCell>
                                <TableCell align="right">{driver[item.value]}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </>
  )
}

export default BasicAccordion
