import { createPhasedAction } from 'redux/actionCreators'

export const { getLoginPageContentAction } = createPhasedAction('GET_LOGIN_PAGE_CONTENT_ACTION')

export const { loginAction } = createPhasedAction('LOGIN_ACTION')

export const { isLoggedInAction } = createPhasedAction('IS_LOGGED_IN_ACTION')

export const { forgotPasswordAction } = createPhasedAction('FORGOT_PASSWORD_ACTION')

export const { refreshedAccessTokenAction } = createPhasedAction('REFRESHED_ACCESS_TOKEN_ACTION')

export const { resetPasswordAction } = createPhasedAction('RESET_PASSWORD_ACTION')

export const { logoutAction } = createPhasedAction('LOGOUT_ACTION')

export const { pageRefreshAction } = createPhasedAction('PAGE_REFRESH_ACTION')
