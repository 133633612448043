import { ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'

import { Grid, Tooltip, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import CaptionText from 'components/ui/CaptionText'

import InfoIcon from '@material-ui/icons/Info'

export interface Props {
  question: string
  hint?: string
  toolTip?: string
  children: ReactNode
}

const useStyles = makeStyles(
  theme => ({
    inputArea: {
      justifyContent: 'space-between',
    },
    question: {
      [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
        paddingRight: 0,
      },
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        paddingRight: theme.spacing(10),
      },
      // First paragraph, remove margin so its level with other elements
      '& :nth-child(1)': {
        marginTop: 0,
      },
    },
    toolTip: {
      marginLeft: 5,
    },
  }),
  { name: 'Question' },
)

const Question = ({ question, hint, children, toolTip }: Props) => {
  const classes = useStyles()

  return (
    <Grid item container xs={12} justify="space-between" alignItems="flex-start">
      <Grid item className={classes.question} xs={12} sm={6}>
        <Typography component="div" style={{ display: 'flex' }}>
          <ReactMarkdown linkTarget="_blank">{question}</ReactMarkdown>

          {toolTip && (
            <Tooltip title={toolTip} className={classes.toolTip}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          )}
        </Typography>
        {hint && <CaptionText hint={hint} />}
      </Grid>

      <Grid item className={classes.inputArea} xs={12} sm={6}>
        {children}
      </Grid>
    </Grid>
  )
}

export default Question
