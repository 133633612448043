import { all, put, call, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux/actionCreators/types'
import { FormikState } from 'formik'

import { isSnackbarOpenAction } from 'redux/snackbarMessage/actions'
import { setIsLoadingAction } from 'redux/loading/actions'
import {
  closeLoginModalAction,
  showLoginModalAction,
  updateContactDetailsAction,
  sendChangePasswordAction,
  getAddressByPostalCodeAction,
  getAddressByIdAction,
  updateCorrespondenceAddressAction,
  getAccountInformationAction,
  updateMarketingPreferencesAction,
  updateCommunicationPreferenceAction,
  updateRenewalPreferenceAction,
} from './actions'

import {
  updateContactNumbers,
  updateEmailAddress,
  sendChangePassword,
  getAddressByPostalCode,
  getAddressById,
  updateCorrespondenceAddress,
  getUserinformation,
  getAccountInfos,
  updateMarketingPreferences,
  updateCommunicationPreference,
  updateMyRenewalPreference,
} from './client'

import { isTokenValid } from 'redux/login/client'

import { ContactDetailsUpdateRequest, ContactDetailsUpdateRequired } from 'types/requests'
import { SearchAddressByPostcodeType } from 'types/responses'
import { onLogout } from 'redux/login/saga'
import { EndPoint } from 'types/endpoint'
import { Redirect } from 'react-router-dom'

export function* onUpdateContactDetailsAction(action: {
  payload: {
    access_token: string
    contactValues: ContactDetailsUpdateRequest
    updateRequired: ContactDetailsUpdateRequired
    close: () => void
    resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void
    refreshPolicy: () => void
  }
}) {
  const {
    payload: { contactValues, resetForm, refreshPolicy, close, updateRequired },
  } = action

  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    yield put(setIsLoadingAction.success({ isLoading: true }))

    // Note: If the user is making a combination of phone / email changes, these require seperate apis
    // if for any reason the phone changes fail the email api should not be triggered
    // email changes will only trigger if phoneChangesSuccess remains true
    let phoneChangesSuccess = true

    if (updateRequired.contactPhoneChange === true) {
      const requestData = {
        homePhone: contactValues.homePhoneConfirm,
        mobilePhone: contactValues.cellNumberConfirm,
      }

      const {
        response: { status: contactNumbersStatus },
      } = yield call(() => updateContactNumbers(access_token, requestData))

      if (contactNumbersStatus === 200) {
        // If email has also changed the policy can be refreshed upon new login
        if (updateRequired.contactEmailChange === false) yield call(() => refreshPolicy())

        yield call(() => close())
        // Note: If an email change has been requested, the successful phone number changes message can be included in the re-login modal
        // If not just the success snackBar is sufficiant
        if (updateRequired.contactEmailChange === false) {
          yield put(
            isSnackbarOpenAction.success({
              isOpen: true,
              message: 'Your changes were successful',
              error: false,
            }),
          )
          // If email is has changed, the resetting of the form can be handled at the page / modal level
          resetForm()
        }
      }

      if (contactNumbersStatus !== 200 && contactNumbersStatus !== 401) {
        phoneChangesSuccess = false

        yield put(
          isSnackbarOpenAction.success({
            isOpen: true,
            message: 'Your changes were not successful. Please check your details and try again',
            error: true,
          }),
        )
      }
    }

    if (updateRequired.contactEmailChange === true && phoneChangesSuccess === true) {
      const emailRequestData = {
        brand: 'tya',
        email: contactValues.emailAddress1Confirm,
      }

      const {
        response: { status: emailAddressStatus },
      } = yield call(() => updateEmailAddress(access_token, emailRequestData))

      if (emailAddressStatus === 200) {
        yield call(() => close())
        // TODO: Remove this after the new login has been impleFmented
        // Only using now to see the change has been made
        yield call(() => refreshPolicy())

        yield put(setIsLoadingAction.success({ isLoading: false }))

        yield put(
          showLoginModalAction.success({
            showLoginModal: true,
            contactNumbersMessage:
              updateRequired.contactPhoneChange === true ? 'Your contact number(s) have been updated successfully' : '',
            emailOrPasswordMessage: 'Your email change was suuccessful. Please login using your new email',
            redirect: EndPoint.MY_ACCOUNT,
          }),
        )
      }

      if (emailAddressStatus !== 200 && emailAddressStatus !== 401) {
        yield put(
          isSnackbarOpenAction.success({
            isOpen: true,
            message: 'Your changes were not successful. Please check your details and try again',
            error: true,
          }),
        )
      }
    }

    yield put(setIsLoadingAction.success({ isLoading: false }))
  } else {
    yield call(() => onLogout())
  }
}

export function* onCloseLoginModalAction() {
  yield put(closeLoginModalAction.success())
}

export function* onSendChangePassword(
  action: Action<{
    password: string
    oldPassword: string
    resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void
  }>,
) {
  const {
    payload: { password, oldPassword, resetForm },
  } = action
  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    try {
      //fetch user information to get userId
      const { data: userInformation } = yield call(() => getUserinformation(access_token))
      const { status } = yield call(() =>
        sendChangePassword(password, oldPassword, access_token, userInformation.user_id),
      )

      if (status == 200) {
        resetForm()

        yield put(
          showLoginModalAction.success({
            showLoginModal: true,
            emailOrPasswordMessage: 'Your password change was successful. Please login using your new password',
            redirect: EndPoint.MY_ACCOUNT,
          }),
        )
      } else {
        yield put(
          isSnackbarOpenAction.success({
            isOpen: true,
            message: 'Something went wrong.',
            error: true,
          }),
        )
      }
    } catch (error) {
      yield put(
        isSnackbarOpenAction.success({
          isOpen: true,
          message: 'Something went wrong.',
          error: true,
        }),
      )
    }
  } else {
    yield call(() => onLogout())
  }

  yield put(setIsLoadingAction.success({ isLoading: false }))
}

export function* onGetAddressByPostalCode(action: Action<string>) {
  try {
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string
      const { data } = yield call(() => getAddressByPostalCode(action.payload, access_token))
      const lookupAddresses = data.map((item: SearchAddressByPostcodeType) => item.address)
      yield put(getAddressByPostalCodeAction.success({ lookupAddresses }))
      // If no results are found the status is still 200 but the array is empty
      // Technically this is not an error but still, the user needs to be informed as if it was
    }
  } catch (error) {
    // If the user enters an invalid postcode
    yield put(getAddressByPostalCodeAction.success({ lookupAddresses: [] }))
  }
}

export function* onGetAddressById(action: Action<string>) {
  try {
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string
      const { data: selectedAddress } = yield call(() => getAddressById(action.payload, access_token))
      yield put(getAddressByIdAction.success({ selectedAddress }))
    }
  } catch (error) {
    yield put(getAddressByPostalCodeAction.success({ selectedAddress: undefined }))
    yield put(getAddressByPostalCodeAction.error({ selectedAddress: undefined }))
  }
}

export function* onUpdateCorrespondenceAddress(action: Action<{ selectedAddress: any; refreshPolicy: () => void }>) {
  try {
    const {
      payload: { selectedAddress, refreshPolicy },
    } = action
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string

      const { status } = yield call(() => updateCorrespondenceAddress(access_token, selectedAddress))

      if (status === 200) {
        yield call(() => close())
        yield call(() => refreshPolicy())

        yield put(setIsLoadingAction.success({ isLoading: false }))

        yield put(
          isSnackbarOpenAction.success({
            isOpen: true,
            message: 'Your address change was successful.',
            error: false,
          }),
        )

        //reset lookup addresses
        yield put(updateCorrespondenceAddressAction.success({}))
      }
    } else {
      yield call(() => onLogout())
    }
  } catch (error) {
    yield put(updateCorrespondenceAddressAction.error())
  }
}

export function* onUpdateMarketingPreferences(
  action: Action<{
    marketingPrefEmail: boolean
    marketingPrefPost: boolean
    marketingPrefMobileApp: boolean
    marketingPrefLandline: boolean
    marketingPrefVoiceMob: boolean
    marketingPrefSMS: boolean
    marketingOptOut: boolean
  }>,
) {
  try {
    const {
      payload: {
        marketingPrefEmail,
        marketingPrefPost,
        marketingPrefMobileApp,
        marketingPrefLandline,
        marketingPrefVoiceMob,
        marketingPrefSMS,
        marketingOptOut,
      },
    } = action
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string

      const { status } = yield call(() =>
        updateMarketingPreferences(
          marketingPrefEmail ? 'true' : 'false',
          marketingPrefPost ? 'true' : 'false',
          marketingPrefMobileApp ? 'true' : 'false',
          marketingPrefLandline ? 'true' : 'false',
          marketingPrefVoiceMob ? 'true' : 'false',
          marketingPrefSMS ? 'true' : 'false',
          marketingOptOut ? 'true' : 'false',
          access_token,
        ),
      )

      if (status === 200) {
        yield call(() => close())
        
        yield put(getAccountInformationAction.start())

        yield put(setIsLoadingAction.success({ isLoading: false }))

        yield put(
          isSnackbarOpenAction.success({
            isOpen: true,
            message: 'Your change was successful.',
            error: false,
          }),
        )
      }
    } else {
      yield call(() => onLogout())
    }
  } catch (error) {
    yield put(updateMarketingPreferencesAction.error())
  }
}

export function* onGetAccountInformation() {
  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    const { data } = yield call(() => getAccountInfos(access_token))
    yield put(getAccountInformationAction.success(data))

    yield put(setIsLoadingAction.success({ isLoading: false }))
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onUpdateCommunicationPreferenceAction(
  action: Action<{ documentationPreference: any; refreshPolicy: () => void }>,
) {
  try {
    const {
      payload: { documentationPreference, refreshPolicy },
    } = action
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string

      yield put(setIsLoadingAction.success({ isLoading: true }))

      const { status } = yield call(() => updateCommunicationPreference(access_token, documentationPreference))

      if (status === 200) {
        yield put(setIsLoadingAction.success({ isLoading: false }))

        yield put(getAccountInformationAction.start())

        yield put(
          isSnackbarOpenAction.success({
            isOpen: true,
            message: 'Your communication preference change was successful.',
            error: false,
          }),
        )
      }
    } else {
      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield call(() => onLogout())
    }
  } catch (error) {
    yield put(updateCommunicationPreferenceAction.error())
  }
}

export function* onUpdateRenewalPreferenceActione(action: any) {
  try {
    const {
      payload: { policyId, value, refreshPolicy },
    } = action

    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      yield put(setIsLoadingAction.success({ isLoading: true }))

      const access_token = sessionStorage.getItem('access_token') as string
      const { status } = yield call(() => updateMyRenewalPreference(access_token, value, policyId))

      if (status === 200) {
        yield put(setIsLoadingAction.success({ isLoading: false }))
        // refresh is important. Afrer the change is successfull, the refresh retrieves the latest policy info from GW and updates the local global state with it
        yield call(() => refreshPolicy())
      } else {
        // This is a measure so something shows in the case of error. no definition of what should happen has ever been presented
        window.alert('There was an error while making this change. Please try again later')
        yield put(setIsLoadingAction.success({ isLoading: false }))
      }
    } else {
      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield call(() => onLogout())
    }
  } catch (error) {
    yield put(setIsLoadingAction.success({ isLoading: false }))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(sendChangePasswordAction.start, onSendChangePassword),
    takeLatest(updateContactDetailsAction.start, onUpdateContactDetailsAction),
    takeLatest(closeLoginModalAction.start, onCloseLoginModalAction),
    takeLatest(getAddressByPostalCodeAction.start, onGetAddressByPostalCode),
    takeLatest(getAddressByIdAction.start, onGetAddressById),
    takeLatest(updateCorrespondenceAddressAction.start, onUpdateCorrespondenceAddress),
    takeLatest(getAccountInformationAction.start, onGetAccountInformation),
    takeLatest(updateMarketingPreferencesAction.start, onUpdateMarketingPreferences),
    takeLatest(updateCommunicationPreferenceAction.start, onUpdateCommunicationPreferenceAction),
    takeLatest(updateRenewalPreferenceAction.start, onUpdateRenewalPreferenceActione),
  ])
}
