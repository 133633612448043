import { makeStyles, Link } from '@material-ui/core'

import Layout from 'components/ui/Layout'

import { history } from 'utils'

import { EndPoint } from 'types/endpoint'

const useStyles = makeStyles(theme => ({
  tab: {
    color: theme.palette.primary.main,
    opacity: 1,
    textTransform: 'initial',
  },
  position: {
    position: 'fixed',
    zIndex: 10,
    left: 0,
    right: 0,
    marginTop: 90,
    boxSizing: 'border-box',
    maxWidth: 1440,
    margin: '0 auto',
    height: 60,
    background: 'white',
  },
  link: {
    padding: '15px 15px 0px 15px',
    cursor: 'pointer',
  },
  container: {
    height: 60,
    display: 'flex',
  },
  activeUnterline: {
    background: theme.palette.secondary.main,
    borderRadius: '10px 10px 0px 0px',
    display: 'flex',
    height: 8,
    width: 50,
    marginTop: 14,
    margin: 'auto',
  },
  navBorder: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
}))

export interface Props {
  url: string
  showMyRenewal: boolean
}

const PolicyNavigationBar = ({ url, showMyRenewal = false }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.position}>
      <Layout background={'white'}>
        <div className={classes.container}>
          <div className={classes.link} onClick={() => history.push(EndPoint.MY_POLICY)}>
            My policy
            {url === EndPoint.MY_POLICY && <div className={classes.activeUnterline} />}
          </div>
          <div className={classes.link} onClick={() => history.push(EndPoint.MY_DOCUMENTS)}>
            My documents
            {url === EndPoint.MY_DOCUMENTS && <div className={classes.activeUnterline} />}
          </div>
          {showMyRenewal && (
            <div className={classes.link} onClick={() => history.push(EndPoint.MY_RENEWAL)}>
              My renewal
              {url === EndPoint.MY_RENEWAL && <div className={classes.activeUnterline} />}
            </div>
          )}
        </div>
      </Layout>

      <div className={classes.navBorder} />
    </div>
  )
}

export default PolicyNavigationBar
