import { Dispatch, SetStateAction } from 'react'

import { Tabs } from 'components/ui/Tab'
import { TabDetails } from 'components/ui/Tab/Tabs'

interface DocumentsTabProps {
  tabs: TabDetails[]
  onTabSelect: Dispatch<SetStateAction<TabDetails<any>>>
}

const DocumentsTab = ({ tabs, onTabSelect }: DocumentsTabProps) => {
  return <Tabs tabs={tabs} onTabSelect={onTabSelect} />
}

export default DocumentsTab
