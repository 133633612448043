import { connect } from 'react-redux'
import { IReduxState } from 'types/stateTypes'

import {
  amendRenewalAction,
  quoteRenewalDirectBuyAction,
  quoteAmendedRenewalDirectBuyAction,
} from 'redux/renewal/actions'

import MyRenewalPage from './MyRenewalPage'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  renewed: state.policy.renewed,
  alternateRenewed: state.policy.alternateRenewed,
})

const mapDispatchToProps = {
  amendRenewalAction: amendRenewalAction.start,
  quoteRenewalDirectBuyAction: quoteRenewalDirectBuyAction.start,
  quoteAmendedRenewalDirectBuyAction: quoteAmendedRenewalDirectBuyAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRenewalPage)
