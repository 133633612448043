import Layout from 'components/ui/Layout'
import { Box, Grid, makeStyles } from '@material-ui/core'
import Section from 'components/ui/Section'
import ContactDetailsSection from 'components/account/ContactDetailsSection'
import PasswordSection from 'components/account/PasswordSection'
import CorrespondenceSection from 'components/account/CorrespondenceSection'
import CookieSection from 'components/account/CookieSection'
import CommunicationSection from 'components/account/CommunicationSection'
import MarketingPreferencesSection from 'components/account/MarketingPreferencesSection'
import PolicyHolderTable from 'components/ui/PolicyHolderTable'
import { PrimaryAddress, SelectedPolicy } from 'types/policy'
import { portalTools } from 'guidewire/portalTools'
import { ContactDetails } from 'types/generalTypes'
import { Formik, FormikHelpers, FormikProps, Form } from 'formik'
import {
  initialValuesMyContactDetails,
  initialValuesPassword,
  passwordValidationSchema,
  contactDetailsValidationSchema,
  initialValuesCommPreference,
  commPreferenceValidationSchema,
  initialValuesMarketingPreference,
  marketingPreferenceValidationSchema,
} from './validationSchema'
import {
  ContactDetailsUpdateRequest,
  ContactDetailsUpdateRequired,
  PasswordChangeRequest,
  PolicySelectRequest,
} from 'types/requests'
import React from 'react'
import { LookupAddressType, AddressById } from 'types/responses'

export interface Props {
  current: SelectedPolicy
  currentPolicyIndex: number
  correspondenceAddress: PrimaryAddress
  lookupAddresses: LookupAddressType[]
  selectedAddress: AddressById
  communicationPreference: string
  marketingPreference: string
  updateContactDetailsAction: (action: any) => void
  policySelectAction: (action: PolicySelectRequest) => void
  sendChangePassword: (action: any) => void
  updateCorrespondenceAddress: (action: any) => void
  getAccountInformationAction: VoidFunction
  getAddressByPostalCodeAction: (postalCode: string) => void
  getAddressByIdAction: (value: string) => void
  editMarketingPreferences: (action: any) => void
  updateCommunicationPreferenceAction: (action: any) => void
}

const useStyles = makeStyles(theme => ({
  screenPadding: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}))

const MyAccountPage = ({
  current,
  currentPolicyIndex,
  correspondenceAddress,
  lookupAddresses,
  selectedAddress,
  communicationPreference,
  marketingPreference,
  updateContactDetailsAction,
  policySelectAction,
  sendChangePassword,
  updateCorrespondenceAddress,
  getAccountInformationAction,
  getAddressByPostalCodeAction,
  getAddressByIdAction,
  editMarketingPreferences,
  updateCommunicationPreferenceAction,
}: Props) => {
  const classes = useStyles()

  const [contactDetailsEdit, setContactDetailsEdit] = React.useState(false)
  const [passwordEdit, setPasswordEdit] = React.useState(false)
  const formRef = React.useRef<FormikProps<any>>(null)
  const formRefMarketingPreference = React.useRef<FormikProps<any>>(null)

  const currentPolicy = portalTools(current)

  const policyHolderContact: any = currentPolicy
    .policyHolderInfo()
    .getValues('emailAddress1', 'cellNumber', 'homePhone')
  // Ideally this should come from the policy holder object rather than drivers, however it is currently missing firstName as a value
  const policyHolderDriver = currentPolicy.allDrivers().policyHolder('display')

  const handleContactDetailsSubmit = (values: ContactDetailsUpdateRequest, formikHelpers: FormikHelpers<any>) => {
    // Note: The APIS are seperate for phone and email
    // phone number changes require both numbers, even if there is only one change. The API needs both numbers and resets the other number if not sent
    // To avoid errors, if there is no change => use current number, else => use changed value
    const contactValues = {
      emailAddress1Confirm: values.emailAddress1Confirm,
      cellNumberConfirm: values.cellNumberConfirm !== '' ? values.cellNumberConfirm : policyHolderContact.cellNumber,
      homePhoneConfirm: values.homePhoneConfirm !== '' ? values.homePhoneConfirm : policyHolderContact.homePhone,
    }

    // Informs saga which apis to call
    // Useful for logic in the saga, not required in the request
    const updateRequired: ContactDetailsUpdateRequired = {
      contactPhoneChange: values.cellNumberConfirm !== '' || values.homePhoneConfirm !== '' ? true : false,
      contactEmailChange: values.emailAddress1Confirm === '' ? false : true,
    }

    updateContactDetailsAction({
      contactValues,
      updateRequired,
      // Call backs if conditions are met in the saga
      // closes the section / resets the form values
      close: () => setContactDetailsEdit(false),
      resetForm: () => formikHelpers.resetForm(),
      // Recalls the policy with updated details. The update apis themselves dont send back an updated policy for the FE to use
      refreshPolicy: () =>
        policySelectAction({
          index: currentPolicyIndex,
          policyId: currentPolicy.policyId().replace('/', '%252F'),
        }),
    })
  }

  const handlePasswordChange = (values: PasswordChangeRequest, formikHelpers: FormikHelpers<any>) => {
    sendChangePassword({
      password: values.passwordNew,
      oldPassword: values.password,
      resetForm: () => formikHelpers.resetForm(),
    })
  }

  const handleCommPreferenceChange = (values: string, formikHelpers: FormikHelpers<any>) => {
    updateCommunicationPreferenceAction({
      documentationPreference: values,
      resetForm: () => formikHelpers.resetForm(),
    })
  }
  const handleMarketingPreferenceChange = (values: any, formikHelpers: FormikHelpers<any>) => {
    if(values.marketingPreference == 'Yes, I Agree'){
      editMarketingPreferences({
        marketingPrefEmail: true,
        marketingPrefPost: true,
        marketingPrefMobileApp: true,
        marketingPrefLandline: true,
        marketingPrefVoiceMob: true,
        marketingPrefSMS: true,
        marketingOptOut: false
    })
  } else {
      editMarketingPreferences({
        marketingPrefEmail: false,
        marketingPrefPost: false,
        marketingPrefMobileApp: false,
        marketingPrefLandline: false,
        marketingPrefVoiceMob: false,
        marketingPrefSMS: false,
        marketingOptOut: true
    })
  }
  getAccountInformationAction()
}

  React.useEffect(() => {
    if (communicationPreference !== undefined && marketingPreference !== undefined) {
      formRef?.current?.setFieldValue('communicationPreference', communicationPreference)
      formRefMarketingPreference?.current?.setFieldValue('marketingPreference', marketingPreference == 'false'? 'Yes, I Agree': 'No, I do not Agree')
    } else {
      getAccountInformationAction()
    }
  });

  return (
    <>
      {communicationPreference && (
        <Layout>
          <Box pt={8}>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Section title="My details">
                  <Box pt={2.5} className={classes.screenPadding}>
                    <PolicyHolderTable
                      policyHolderDetails={policyHolderDriver}
                      headers={[
                        { label: 'First name', value: 'firstName' },
                        { label: 'Surname', value: 'lastName' },
                        { label: 'Date of birth', value: 'dateOfBirth' },
                      ]}
                    />
                  </Box>
                </Section>
              </Grid>

              {/* My Contact Details Form */}
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValuesMyContactDetails}
                  validationSchema={contactDetailsValidationSchema()}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
                    handleContactDetailsSubmit(values, formikHelpers)
                    formikHelpers.setSubmitting(false)
                  }}
                >
                  {(formikPropsMyContactDetails: FormikProps<any>) => (
                    <Form noValidate autoComplete="off">
                      <Section title="My contact details">
                        <Box pt={2.5} className={classes.screenPadding}>
                          <ContactDetailsSection
                            edit={contactDetailsEdit}
                            setEdit={setContactDetailsEdit}
                            policyHolderContact={policyHolderContact as ContactDetails}
                            onSaveChanges={() => {
                              formikPropsMyContactDetails.submitForm()
                            }}
                            onCancelChanges={() => {
                              formikPropsMyContactDetails.resetForm()
                            }}
                          />
                        </Box>
                      </Section>
                    </Form>
                  )}
                </Formik>
              </Grid>

              <Formik
                initialValues={initialValuesPassword}
                validationSchema={passwordValidationSchema()}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
                  handlePasswordChange(values, formikHelpers)
                  formikHelpers.setSubmitting(false)
                  setPasswordEdit(false)
                }}
              >
                {(formikPropsPassword: FormikProps<any>) => (
                  <Grid item xs={12}>
                    <Section title="My password">
                      <Box pt={2.5} className={classes.screenPadding}>
                        <PasswordSection
                          edit={passwordEdit}
                          setEdit={setPasswordEdit}
                          onSaveChanges={() => {
                            formikPropsPassword.submitForm()
                          }}
                          onCancelChanges={() => {
                            formikPropsPassword.resetForm()
                          }}
                        />
                      </Box>
                    </Section>
                  </Grid>
                )}
              </Formik>

              <Grid item xs={12}>
                <Section title="My correspondence address">
                  <CorrespondenceSection
                    screenPadding={classes.screenPadding}
                    correspondenceAddress={correspondenceAddress}
                    lookupAddresses={lookupAddresses}
                    selectedAddress={selectedAddress}
                    getAddressByPostalCodeAction={getAddressByPostalCodeAction}
                    getAddressByIdAction={getAddressByIdAction}
                    submitCorrespondenceAddress={selectedAddress => {
                      updateCorrespondenceAddress({
                        selectedAddress,
                        refreshPolicy: () => {
                          policySelectAction({
                            index: currentPolicyIndex,
                            policyId: currentPolicy.policyId().replace('/', '%252F'),
                          })
                          getAccountInformationAction()
                        },
                      })
                    }}
                  />
                </Section>
              </Grid>

              <Formik
                innerRef={formRef}
                initialValues={initialValuesCommPreference}
                validationSchema={commPreferenceValidationSchema()}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
                  handleCommPreferenceChange(values, formikHelpers)
                  formikHelpers.setSubmitting(false)
                }}
              >
                {(formikPropsMyCommsPreference: FormikProps<any>) => (
                  <Grid item xs={12}>
                    <Section title="My communication preferences">
                      <Box pt={2.5} pr={2.5} pl={2.5} pb={2.5}>
                        <CommunicationSection
                          onSaveChanges={() => {
                            formikPropsMyCommsPreference.submitForm()
                          }}
                        />
                      </Box>
                    </Section>
                  </Grid>
                )}
              </Formik>

              <Formik
                innerRef={formRefMarketingPreference}
                initialValues={initialValuesMarketingPreference}
                validationSchema={marketingPreferenceValidationSchema()}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
                  handleMarketingPreferenceChange(values, formikHelpers)
                  formikHelpers.setSubmitting(false)
                }}
              >
                {(formikPropsMarketingPreference: FormikProps<any>) => (
                  <Grid item xs={12}>
                    <Section title="My marketing preferences">
                      <Box pt={2.5} pr={2.5} pl={2.5} pb={2.5}>
                        <MarketingPreferencesSection
                          onSaveChanges={() => {
                            formikPropsMarketingPreference.submitForm()
                          }}
                          // editMarketingPreferences={editMarketingPreferences}
                        />
                      </Box>
                    </Section>
                  </Grid>
                )}
              </Formik>

              {/* 
           <Grid item xs={12}>
            <Section title="My marketing preferences">
              <Box pt={2.5} pb={2.5} className={classes.screenPadding}>
                <MarketingPreferencesSection editMarketingPreferences={editMarketingPreferences} />
              </Box>
            </Section>
          </Grid> */}

              <Grid item xs={12}>
                <Section title="My cookies preferences">
                  <Box pt={2.5} pb={2.5} className={classes.screenPadding}>
                    <CookieSection />
                  </Box>
                </Section>
              </Grid>

            </Grid>
          </Box>
        </Layout>
      )}
    </>
  )
}

export default MyAccountPage
