//import { isNullOrUndefined } from 'util'
import * as yup from 'yup'
import { isNullOrUndefined } from 'guidewire/formatTools'

// Password change section
export const passwordValidationSchema = () =>
  yup.object().shape({
    password: yup.string().required('Current password is required'),

    passwordNew: yup.string().when('password', {
      is: (password: string) => password !== null && password !== undefined && password.trim().length > 0,
      // isNullOrUndefined(password),
      then: yup
        .string()
        .min(8, 'Please enter at least 8 characters')
        .matches(/^(?=.*[a-z])/, 'Must Contain at least One Lowercase Character')
        .matches(/^(?=.*[A-Z])/, 'Must Contain at least One Uppercase Character')
        .matches(/^(?=.*[0-9])/, 'Must Contain at least 1 Digit')
        .required('Please complete the field')
        .notOneOf([yup.ref('password'), null], 'New password cannot be the same as the current password'),
    }),

    passwordConfirm: yup.string().when('passwordNew', {
      is: (passwordNew: string) => passwordNew !== null && passwordNew !== undefined && passwordNew.trim().length > 0,
      // isNullOrUndefined(password),
      then: yup.string().oneOf([yup.ref('passwordNew'), null], 'Passwords must match'),
    }),
  })

// Validation of contact details (email, mobile no., telephone number) section
export const contactDetailsValidationSchema = () =>
  // Email field validation
  yup.object().shape({
    emailAddress1New: yup
      .string()
      .notRequired()
      .max(60, 'Email cannot be more than 60 chars')
      .matches(
        /^(([a-zA-Z0-9])|([a-zA-Z0-9\%_\+-]{1,}[a-zA-Z0-9\%._\+-][a-z0-9\%_\+-]{1,}))+@(([a-zA-Z0-9\%_\+-])|([a-zA-Z0-9\%_\+-]{1,}[a-zA-Z0-9\%._\+-]{1,}[a-z0-9\%_\+-]{1,}))+\.[a-z]{2,3}$/,
        'Please enter a valid email address',
      ),
    //emailAddress1Confirm: yup.string().oneOf([yup.ref('emailAddress1New'), null], 'Emails must match'),

    emailAddress1Confirm: yup.string().when('emailAddress1New', {
      is: (emailAddress1New: string) =>
        emailAddress1New !== null && emailAddress1New !== undefined && emailAddress1New.trim().length > 0,
      // isNullOrUndefined(emailAddress1New),
      then: yup
        .string()
        .required('Confirm Email address is required')
        .oneOf([yup.ref('emailAddress1New'), null], 'Emails must match'),
    }),

    cellNumberNew: yup
      .string()
      .notRequired()
      .test('value', 'This number contains invalid characters. Please enter numbers only', value => {
        if (value !== undefined && value !== null && value.trim().length > 0) {
          if (/^[0-9]+$/.test(value)) {
            return true
          } else {
            return false
          }
        }

        return true

        //   return value !== undefined && value !== null && value.trim().length > 0 && /^[0-9]+$/.test(value)
      })
      .test('value', 'Please enter a valid mobile number', value => {
        // return value !== undefined && value.startsWith('0')

        if (value !== undefined && value !== null && value.trim().length > 0) {
          if (value.startsWith('0')) {
            return true
          } else {
            return false
          }
        }

        return true
      })
      .min(11, 'Please enter a valid mobile number')
      .max(11, 'Please enter a valid mobile number'),

    cellNumberConfirm: yup.string().when('cellNumberNew', {
      is: (cellNumberNew: string) => !isNullOrUndefined(cellNumberNew),
      then: yup
        .string()
        .required('Confirm Mobile Number is required')
        .oneOf([yup.ref('cellNumberNew'), null], 'Mobile Numbers must match'),
    }),

    homePhoneNew: yup
      .string()
      .notRequired()
      .test('value', 'This number contains invalid characters. Please enter numbers only', value => {
        //return value !== undefined && value !== null && value.trim().length > 0 && /^[0-9]+$/.test(value)
        if (value !== undefined && value !== null && value.trim().length > 0) {
          if (/^[0-9]+$/.test(value)) {
            return true
          } else {
            return false
          }
        }

        return true
      })
      .test('value', 'Please enter a valid landline number', value => {
        if (value !== undefined && value !== null && value.trim().length > 0) {
          if (value.startsWith('0')) {
            return true
          } else {
            return false
          }
        }

        return true
      })
      .min(11, 'Please enter a valid landline number')
      .max(11, 'Please enter a valid landline number'),

    homePhoneConfirm: yup.string().when('homePhoneNew', {
      is: (homePhoneNew: string) => !isNullOrUndefined(homePhoneNew),
      then: yup
        .string()
        .required('Confirm landline number is required')
        .oneOf([yup.ref('homePhoneNew'), null], 'landline numbers must match'),
    }),
  })

// Password change section
export const commPreferenceValidationSchema = () =>
  yup.object().shape({
    //  communicationPreference: yup.string().notRequired(),
    communicationPreference: yup.string().required('Please select an appropriate option'),
  })

export const marketingPreferenceValidationSchema = () =>
  yup.object().shape({
    marketingPreference: yup.string().required('Please select an appropriate option'),
  })

export const initialValuesPassword = {
  password: '',
  passwordNew: '',
  passwordConfirm: '',
}

// My Contact Details section
export const initialValuesMyContactDetails = {
  emailAddress1New: '',
  emailAddress1Confirm: '',
  cellNumberNew: '',
  cellNumberConfirm: '',
  homePhoneNew: '',
  homePhoneConfirm: '',
}

export const initialValuesCommPreference = {
  communicationPreference: '',
}

export const initialValuesMarketingPreference = {
  marketingPreference: '',
}