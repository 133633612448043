import moment from 'moment'

import { SelectedVehicle } from 'types/policy'
import { capitalizeAllFirstLetters } from './formatTools'

const removeUnwantedValues = (values: any) => {
  const obj = Object.fromEntries(
    Object.keys(values).map(key => {
      // IMPORTANT: securityDevices key can have the valid value "None" with the uppercase first letter
      // lowercase "none" is used for a placeholder / default value in dropdowns
      if (values[key] === 'none' || values[key] === '' || values[key] === undefined || values[key] === null) {
        return []
      }

      return [key, values[key]]
    }),
  )

  let purchased = {}
  if (obj['carPurchaseDate']) {
    purchased = {
      purchasedMonth: moment(obj['carPurchaseDate']).format('MMMM'),
      purchasedYear: moment(obj['carPurchaseDate']).year(),
    }
  }

  let residencySince = {}
  if (obj['ukResidentSinceDate']) {
    residencySince = {
      ukResidencyYear: moment(obj['ukResidentSinceDate']).year(),
      ukResidencyMonth: moment(obj['ukResidentSinceDate']).format('MMMM'),
    }
  }

  let passedTest = {}
  if (obj['whenPassTest']) {
    passedTest = {
      passedTestYear: moment(obj['whenPassTest']).year(),
      passedTestMonth: moment(obj['whenPassTest']).format('MMMM'),
    }
  }

  let provisional = {}
  if (obj['firstDroveOnProvisionalLicence']) {
    provisional = {
      provisionalLicenseYear: moment(obj['firstDroveOnProvisionalLicence']).year(),
      provisionalLicenseMonth: moment(obj['firstDroveOnProvisionalLicence']).format('MMMM'),
    }
  }

  let modification = {}
  if (obj['hasBeenModified']) {
    if (obj['hasBeenModified'] === 'noModifications') {
      modification = {
        hasBeenModified: false,
      }
    } else {
      modification = {
        hasBeenModified: true,
        modification: obj['modification'],
      }
    }
  }

  delete obj['undefined']
  delete obj['carPurchaseDate']
  delete obj['ukResidentSinceDate']
  delete obj['hasBeenModified']
  delete obj['whenPassTest']
  delete obj['firstDroveOnProvisionalLicence']

  return { ...obj, ...purchased, ...residencySince, ...modification, ...passedTest, ...provisional }
}

export const convertFormValues = (values: any) => {
  const obj = Object.fromEntries(
    Object.keys(values).map((key: any) => {
      if (values[key] !== null) {
        // Convert "yes / no" radio select values to boolean
        // IMPORTANT: medicalConditions and drivingQualifications keys can have the valid value "No" with the uppercase first letter
        if ((typeof values[key] === 'string' && values[key] === 'yes') || values[key] === 'no') {
          return [key, values[key] === 'yes' ? true : false]
        }
        // Date of birth
        if (key === 'dateOfBirth' || key === 'periodStartDate') {
          return [
            key,
            {
              day: moment(values[key]).date(),
              month: moment(values[key]).month(),
              year: moment(values[key]).year(),
            },
          ]
        }

        if (key === 'annualMileage' && typeof values[key] === 'string') return [key, parseInt(values[key])]

        if (key === 'ncdYears' && typeof values[key] === 'string') return [key, parseInt(values[key])]

        if (key === 'employersBusiness' || key == 'occupation') return [key, values[key].value]

        if (key === 'firstName' || key == 'lastName') return [key, capitalizeAllFirstLetters(values[key] as string)]

        if (key === 'marketValue') {
          if (typeof values[key] === 'string') return [key, { amount: parseInt(values[key]) }]
          else return [key, { amount: values[key] }]
        }
      }

      return [key, values[key]]
    }),
  )

  return removeUnwantedValues(obj)
}

export const createAccountHolderObject = (formValues: any) => {
  return {
    ukResident5Years: formValues['ukResident5Years'],
    ...(formValues['ukResidencyYear'] && { ukResidencyYear: formValues['ukResidencyYear'] }),
    ...(formValues['ukResidencyMonth'] && { ukResidencyMonth: formValues['ukResidencyMonth'] }),
    homeOwner: formValues['homeOwner'],
    cellNumber: formValues['cellNumber'],
    //...(formValues['homeNumber'] && { homeNumber: formValues['homeNumber'] }),
    communicationPreference: formValues['communicationPreference'],
    firstName: formValues['firstName'],
    lastName: formValues['lastName'],
    maritalStatus: formValues['maritalStatus'],
    dateOfBirth: formValues['dateOfBirth'],
    emailAddress1: formValues['emailAddress1'],
    gender: formValues['gender'],
    occupation: formValues['occupation'],
    title: formValues['title'],
    accountHolder: true,
    tempId: 1,
    brand: 'tya',
  }
}

export const createVehicleObject = (vehicle: SelectedVehicle, formValues: any) => {
  const obj = {
    ...vehicle,
    ncdYears: formValues['ncdYears'],
    imported: formValues['imported'],
    annualMileage: formValues['annualMileage'],
    classOfUse: formValues['classOfUse'],
    dayTimeParking: formValues['dayTimeParking'],
    marketValue: formValues['marketValue'],
    nightTimeParking: formValues['nightTimeParking'],
    purchasedMonth: formValues['purchasedMonth'],
    purchasedYear: formValues['purchasedYear'],
    securityDevices: formValues['securityDevices'],
    hasBeenModified: formValues['hasBeenModified'],
    ...(formValues['modification'] && { modification: formValues['modification'] }),
    lessThanEightSeats: formValues['lessThanEightSeats'],
    registeredKeeper: formValues['registeredKeeper'],
    rightHandDrive: formValues['rightHandDrive'],
    securityTracker: formValues['securityTracker'],
    regNumUnknown: formValues['regNumUnknown'],
    ...(formValues['regNumUnknown'] === false && { registrationNumber: formValues['registrationNumber'].trim() }),
    ...(formValues['regNumUnknown'] === true && { registrationNumber: '' }),
    voluntaryExcess: 0.0,
  }

  // breaks the first api call
  delete obj['enhancedVehicleDescription']

  return obj
}
