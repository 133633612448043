import React from 'react'
import moment from 'moment'
import { Box, Button, Divider, Grid, Grow, makeStyles, Paper, Typography } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import { Field, Form, Formik, FormikProps } from 'formik'
import validationSchema, { initialValues } from './validationSchema'

import DateAndTimePicker from 'components/ui/DateAndTimePicker'
import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns'
import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSectionHardCoded'
import ReadOnlyField from 'components/ui/ReadOnlyField'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'
import QuestionWithSelector from 'components/ui/QuestionWithSelector'

import { portalTools } from 'guidewire/portalTools'
import { addressSuggestionsOptions } from 'guidewire/formatTools'

import { LabelValuePair } from 'types/generalTypes'
import { SelectedPolicy } from 'types/policy'
import { AddressById, LookupAddressType } from 'types/responses'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  bottomText: {
    marginLeft: 24,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
  questionBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const readOnlyFields: LabelValuePair[] = [
  { label: 'Address line 1', value: 'addressLine1' },
  { label: 'Town / City', value: 'city' },
  { label: 'Postcode', value: 'postalCode' },
]

const daytimeParkingOptions: LabelValuePair[] = [
  {
    value: 'GaragedAtHome',
    label: 'Garaged At Home',
  },
  {
    value: 'OnDriveAtHome',
    label: 'On Drive At Home',
  },
  {
    value: 'PublicRoadAtHome',
    label: 'Public Road At Home',
  },
  {
    value: 'SecureCarPark',
    label: 'Secure Car Park',
  },
  {
    value: 'StationCarPark',
    label: 'Station Car Park',
  },
  {
    value: 'PublicRoadAwayFromHome',
    label: 'Public Road Away From Home',
  },
  {
    value: 'OtherUnsecuredCarPark',
    label: 'Other Unsecured Car Park',
  },
  {
    value: 'UnsecuredOffRoadParking',
    label: 'Unsecured Off Road Parking',
  },
]

const nightParkingOptions: LabelValuePair[] = [
  {
    value: 'GaragedOvernight',
    label: 'Garaged Overnight',
  },
  {
    value: 'OnDrive',
    label: 'On Drive',
  },
  {
    value: 'PublicRoad',
    label: 'Public Road',
  },
  {
    value: 'SecureCarPark',
    label: 'Secure Car Park',
  },
  {
    value: 'UnsecuredOffRoadParking',
    label: 'Unsecured Off Road Parking',
  },
]

const yesNoOptions: LabelValuePair[] = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

export interface Props {
  current: SelectedPolicy
  lookupAddresses: LookupAddressType[]
  selectedAddress: AddressById
  startDate: string
  jobId: string
  getAddressByPostalCodeAction: (postalCode: string) => void
  getAddressByIdAction: (value: string) => void
  continueAddressChangeAction: (value: any) => void
  cancelMTAChangesAction: VoidFunction
}

const ChangeOvernightAddressPage = ({
  current,
  lookupAddresses,
  selectedAddress,
  startDate,
  jobId,
  getAddressByPostalCodeAction,
  getAddressByIdAction,
  continueAddressChangeAction,
  cancelMTAChangesAction,
}: Props) => {
  const classes = useStyles()
  const formRef = React.useRef<FormikProps<any>>(null)

  const currentPolicy = portalTools(current)
  const currentAddress = currentPolicy.overnightParking().details() as any

  const addressSuggestions = addressSuggestionsOptions(lookupAddresses)
  const [mulitChangeSubmit, setMultiChangeSubmit] = React.useState(false)
  const [showEditAddress, setShowEditAddress] = React.useState(false)

  const handleSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      //combine date and time
      const startDateValue = moment(values.startDate).utc(true)
      const startTimeValue = moment(values.startTime).utc(true)

      startDateValue.set('hours', startTimeValue.hours())
      startDateValue.set('minutes', startTimeValue.minutes())

      continueAddressChangeAction({
        startDate: startDate.length > 0 ? startDate : startDateValue.toISOString(),
        addressSelect: selectedAddress,
        furtherChanges: mulitChangeSubmit,
        homeOwner: values.homeOwner,
        dayTimeParking: values.dayTimeParking,
        nightTimeParking: values.nightTimeParking,
        current: current,
        jobId: jobId,
      })
    }
  }

  const selectAddress = (value: any) => {
    getAddressByIdAction(value)
  }

  const findAddress = () => {
    const postalCode = formRef.current?.values['postCodeLookup']

    getAddressByPostalCodeAction(postalCode)
  }

  const editInitialValues = initialValues
  editInitialValues.startDate = startDate
  editInitialValues.startTime = startDate

  // Clears the address list if back button is pressed and on start
  React.useEffect(() => {
    getAddressByPostalCodeAction('')
    window.onpopstate = () => {
      getAddressByPostalCodeAction('')
    }
  }, [])

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={startDate.length > 0 ? editInitialValues : initialValues}
        onSubmit={async values => handleSubmit(values)}
        validationSchema={validationSchema()}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Layout>
              <Box pt={5} pb={5}>
                <DateAndTimePicker disabled={startDate.length > 0} formRef={formRef} minimumDate={current.effective} />
              </Box>
              <Box pt={5}>
                <Grid item xs={12}>
                  <Section title="Current Address" accordian>
                    <Box pt={2.5} pr={2.5} pl={2.5}>
                      <Grid container spacing={4}>
                        {readOnlyFields.map(item => (
                          <Grid item xs={12} sm={4} key={item.label}>
                            <Typography className={classes.readOnlyLabel}>{item.label}</Typography>
                            <ReadOnlyField value={currentAddress[item.value]} />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Section>
                </Grid>
                <Grid item xs={12}>
                  <Section title="New address">
                    <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithTextField}
                            name="postCodeLookup"
                            question={''}
                            inputStyle="uppercase"
                            onChange={() => formRef.current?.setFieldValue('startedLookup', false)}
                            lookupError={''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Button variant="outlined" color="primary" onClick={findAddress}>
                            Find my address
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {formRef.current?.values['postCodeLookup'] !== '' && addressSuggestions.length > 0 && (
                            <Field
                              component={QuestionWithSelector}
                              name="addressSelect"
                              options={addressSuggestions}
                              placeholderText={`${addressSuggestions.length} address(s) found`}
                              onSelect={(value: string) => {
                                selectAddress(value)
                                setShowEditAddress(true)
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>

                    <Grow in={showEditAddress} timeout={300} unmountOnExit>
                      <>
                        <Typography>
                          <b>{'Selected Address'}</b>
                        </Typography>
                        <Box pt={2.5} pr={2.5} pl={2.5}>
                          <Grid container spacing={4}>
                            {readOnlyFields.map(item => (
                              <Grid item xs={12} sm={4} key={item.label}>
                                <Typography className={classes.readOnlyLabel}>{item.label}</Typography>
                                <ReadOnlyField value={selectedAddress ? (selectedAddress as any)[item.value] : ''} />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                        <Box pt={2.5} pr={2.5} pl={2.5}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4} className={classes.questionBox}>
                              <Typography className={classes.readOnlyLabel}>{'Do you own your own home?'}</Typography>
                              <Field
                                component={QuestionWithRadioBtns}
                                row
                                name="homeOwner"
                                question={''}
                                hint={''}
                                toolTip={''}
                                options={yesNoOptions}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.questionBox}>
                              <Typography className={classes.readOnlyLabel}>
                                {'Where is your car parked during the day?'}
                              </Typography>
                              <Field
                                component={QuestionWithSelector}
                                name="dayTimeParking"
                                question={''}
                                hint={''}
                                toolTip={''}
                                options={daytimeParkingOptions}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.questionBox}>
                              <Typography className={classes.readOnlyLabel}>
                                {'Where is your car parked overnight?'}
                              </Typography>
                              <Field
                                component={QuestionWithSelector}
                                name="nightTimeParking"
                                question={''}
                                hint={''}
                                toolTip={''}
                                options={nightParkingOptions}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    </Grow>
                    <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              // Note: This is done to clear the address select dropdown and is done this way due to time contraints
                              // Clears values by making a bad address lookup api call.
                              // It really needs a redux action to clear the value without the api
                              getAddressByPostalCodeAction('')
                              cancelMTAChangesAction()
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              // Note: This is done to clear the address select dropdown and is done this way due to time contraints
                              // Clears values by making a bad address lookup api call.
                              // It really needs a redux action to clear the value without the api
                              getAddressByPostalCodeAction('')
                              setMultiChangeSubmit(true)
                              const submit = async () => await props.submitForm()
                              submit()
                            }}
                          >
                            Make further changes
                          </Button>
                        </Grid>
                        {startDate.length == 0 && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ margin: 'auto' }}
                              onClick={() => {
                                // Note: This is done to clear the address select dropdown and is done this way due to time contraints
                                // Clears values by making a bad address lookup api call.
                                // It really needs a redux action to clear the value without the api
                                //getAddressByPostalCodeAction('')
                                setMultiChangeSubmit(false)
                                const submit = async () => await props.submitForm()
                                submit()
                              }}
                            >
                              Continue
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Section>
                </Grid>
              </Box>

              <Box pt={5}>
                <Grid item xs={12}>
                  <Section title="Helpful information">
                    <HelpSection showTitle={false} />
                  </Section>
                </Grid>
              </Box>
            </Layout>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangeOvernightAddressPage
