import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core'

import Layout from 'components/ui/Layout'

import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSectionHardCoded'
import ReactMarkdown from 'react-markdown'
import { AddressById } from 'types/responses'
import { SelectedPolicy } from 'types/policy'
import { portalTools } from 'guidewire/portalTools'
import React from 'react'
import { BillingId } from 'guidewire/policy/enums/billingId'
import { getDateXdaysBefore } from 'guidewire/formatTools'
import moment from 'moment'
import { history } from 'utils'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  bottomText: {
    marginLeft: 24,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
}))

export interface Props {
  current: SelectedPolicy
  mtaResponse: any
  correspondenceAddress: any
  autoRenew: boolean
  showExecuteMTAModalAction: VoidFunction
  getAccountInformationAction: VoidFunction
  executeMTAChangesAction: (x: any) => void
}

const MTAConfirmPage = ({
  current,
  mtaResponse,
  correspondenceAddress,
  autoRenew,
  showExecuteMTAModalAction,
  getAccountInformationAction,
  executeMTAChangesAction,
}: Props) => {
  const classes = useStyles()

  const { drivers, overnightParkingAddress, vehicles } = mtaResponse

  const currentPolicy = portalTools(current)
  const currentOvernightParking = currentPolicy.overnightParking().details()

  const newCar = vehicles.filter((x: { hasChanged: boolean }) => x.hasChanged == true)
  const newDrivers = drivers.filter((x: { newlyAdded: boolean }) => x.newlyAdded == true)

  //is installemnt
  const isInstallment = mtaResponse?.paymentPlanId === 'bc:6'
  //is renewing
  const todaysDate = moment(new Date())
    .utc(false)
    .toISOString()
  const renewDate = getDateXdaysBefore(21, current?.expiration)
  const isRenewing = moment(todaysDate).isAfter(renewDate) && moment(todaysDate).isBefore(current?.expiration)

  //6 day payment cycle before my 2nd from last payment
  const is6DaysBefore2ndFromLastPayment =
    current?.installmentDetails?.remainingInstallments &&
    current?.installmentDetails?.nextPaymentDueDate &&
    current?.installmentDetails?.remainingInstallments === 2 &&
    moment(current?.installmentDetails?.nextPaymentDueDate)
      .subtract(6, 'd')
      .isAfter(moment())

  const showContactCallCenter = () => {
    return (
      isInstallment && is6DaysBefore2ndFromLastPayment && !isRenewing && !autoRenew && mtaResponse?.totalCost.amount < 0 //AC04 AC08 AC12 of TOA-2851
    )
  }

  React.useEffect(() => {
    getAccountInformationAction()
  }, [])

  const submit = () => {
    if (
      (mtaResponse?.totalCost.amount < 0 && !showContactCallCenter()) ||
      (isInstallment &&
        !isRenewing &&
        !is6DaysBefore2ndFromLastPayment &&
        current?.installmentDetails?.remainingInstallments >= 2)
    ) {
      executeMTAChangesAction({
        current: current,
        mtaResponse: mtaResponse,
        existingCard: true,
      })
    } else {
      showExecuteMTAModalAction()
    }
  }

  return (
    <>
      <Layout>
        <Box pt={5}>
          <Grid item xs={12}>
            {overnightParkingAddress.publicID !== currentOvernightParking.publicID && (
              <Section title="Change of address">
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                  <Typography>
                    <ReactMarkdown linkTarget="__blank">
                      {correspondenceAddress.addressLine1 !== ''
                        ? `__Has your correspondence address changed?__\n\nOur records show that you have a correspondence address registered which is different to your overnight parking address.\n\nMaking this change to your overnight parking address does not update your correspondence address, it only updates your overnight parking address.\n\nIf your correspondence address has also changed, please update this in the ‘My Account’ section of Your Portal.\n\n__Has your home phone number changed?__\n\nIf your contact numbers have changed, please go to ‘My Account’ Account Settings section of Your Portal and update your new number. Keeping your contact details up to date is important so that we can contact you about your policy`
                        : `__Has your home phone number changed?__\n\nIf your contact numbers have changed, please go to ‘My Account’ Account Settings section of Your Portal and update your new number. Keeping your contact details up to date is important so that we can contact you about your policy.`}
                    </ReactMarkdown>
                  </Typography>
                </Box>
              </Section>
            )}
            {newDrivers.length > 0 && (
              <Section title="Change of Driver">
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                  <Typography>
                    <ReactMarkdown linkTarget="__blank">
                      {`Your policy may allow you to drive other cars that don’t belong to you. This only covers liability to other people. Check your Policy Schedule and Certificate of Motor Insurance to see if this cover is included. This cover only applies to the policyholder and not any named drivers. `}
                    </ReactMarkdown>
                  </Typography>
                </Box>
              </Section>
            )}
            {newCar.length > 0 && (
              <Section title="New car">
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                  <Typography>
                    <ReactMarkdown linkTarget="__blank">{`__About the black box__\n\nWhen you change your car, you need to have a new black box fitted. We will remotely deactivate the black box in your old car.\n\n__Fitting the black box__\n\nOur specialist team will contact you within 5 working days of this policy change to arrange for the black box to be fitted.\n\nYour new car is insured from the moment this policy change is finalised but please note that if we are not able to fit the black box within 14 days of your policy start date, we reserve the right to cancel your policy. We will give you 7 days' notice in writing as described in your Private Car Policy Document. `}</ReactMarkdown>
                  </Typography>
                </Box>
              </Section>
            )}
            <Section>
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <Button variant="outlined" color="primary" style={{ margin: 'auto' }} onClick={() => submit()}>
                  Continue
                </Button>
              </Box>
            </Section>
          </Grid>
        </Box>

        <Box pt={5}>
          <Grid item xs={12}>
            <Section title="Helpful information">
              <HelpSection showTitle={false} />
            </Section>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default MTAConfirmPage
