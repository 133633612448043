import { Vehicle } from '../types/vehicle'

import { costObjectToString } from 'guidewire/format/costObjectToString'
import { LabelValuePair } from 'types'
import { vehicleToDisplay } from 'guidewire/format/vehicleToDisplay'

export const vehicleUtils = (vehicle: Vehicle) => {
  const { ncdYears, voluntaryExcessOptions, voluntaryExcess, ncdProtection, ncdCosts, ncdProtectionAllowed } = vehicle

  const details = () => vehicle

  const display = () => {
    return vehicleToDisplay(vehicle)
  }

  const getNcdProtection = () => ncdProtection
  const getNcdProtectionAllowed = () => ncdProtectionAllowed

  const getNcdYears = (withYears?: 'withYears') => {
    if (withYears) {
      return `${ncdYears} year${ncdYears === 1 ? '' : 's'}`
    }

    return ncdYears
  }

  const getNcdCosts = () => ncdCosts

  const getNcdCostsAsAddon = () => {
    if (ncdCosts) {
      return {
        costPerYear: ncdCosts.amount.toFixed(2),
        costPerMonth: (ncdCosts.amount / 11).toFixed(2),
      }
    }

    return { costPerYear: '', costPerMonth: '' }
  }

  const valuesAsString = (values: Array<keyof Vehicle>) => {
    const vehilcleInfo = values.flatMap(item => {
      if (item === 'marketValue' || item === 'ncdCosts') return costObjectToString(vehicle[item])
      if (item === 'registrationNumber') return vehicle[item].toLocaleUpperCase()
      return vehicle[item]
    })

    return vehilcleInfo.join(' ')
  }

  const getVoluntaryExcess = () => voluntaryExcess

  const getVoluntaryExcessOptions = (asLabelValuePair?: 'asLabelValuePair') => {
    if (asLabelValuePair) {
      const values: LabelValuePair[] = voluntaryExcessOptions.map(item => {
        return {
          value: item.toString(),
          label: `£${item}`,
        }
      })

      return values
    }

    return voluntaryExcessOptions
  }

  return {
    details,
    display,
    getNcdProtection,
    getNcdYears,
    getNcdProtectionAllowed,
    getNcdCosts,
    getNcdCostsAsAddon,
    valuesAsString,
    getVoluntaryExcessOptions,
    getVoluntaryExcess,
  }
}
