import { connect } from 'react-redux'

import { IReduxState } from 'types/stateTypes'
import RenewalMonthlyPayment from './RenewalMonthlyPayment'

import { renewalValidateDirectDebitAction } from 'redux/payment/actions'

const mapStateToProps = (state: IReduxState) => ({
  quote: state.renewal.quote,
  policyNumber: state.policy.policyIds[state.policy.currentPolicyIndex],
})

const mapDispatchToProps = {
  renewalValidateDDIAction: renewalValidateDirectDebitAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalMonthlyPayment)
