import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    columnGap: 16,
    rowGap: 16,
    gridTemplateColumns: '1fr',
    alignItems: 'start',
    marginBottom: 16,

    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      rowGap: 32,
      marginBottom: 32,
    },
  },
}))

const ThreeColumnContainer = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()

  return <div className={classes.container}>{children}</div>
}

export default ThreeColumnContainer
