import { isTokenValid } from 'redux/login/client'
import { getDocumentByIdFromPolicy } from 'redux/policy/client'
import { PolicyDocument } from 'types/policy'
import { history } from 'utils'

export const openDocumentForPolicy = async (policyDocument: PolicyDocument) => {
  const { docUID, sessionID, name, mimeType, policyNumber } = policyDocument
  const urlDocID = docUID.replace('==', '')

  let isValid = undefined
  await isTokenValid().then(({ response }) => (isValid = response))

  if (isValid === true) {
    const access_token = sessionStorage.getItem('access_token') as string

    getDocumentByIdFromPolicy(urlDocID, sessionID, name, mimeType, policyNumber, access_token).then(result => {
      const { status, payload } = result
      if (status === 200 && payload) {
        const url = (window as any).URL.createObjectURL(new Blob([payload], { type: mimeType }))
        window.open(url, '_blank')
      } else {
        console.log(`Error fetching document of ${urlDocID} | status code ${status}`)
      }
    })
  }

  if (isValid === false) history.push('/')
}

export const getDocumentURL = async (policyDocument: PolicyDocument) => {
  const { docUID, sessionID, name, mimeType, policyNumber } = policyDocument
  const urlDocID = docUID.replace('==', '')

  let isValid = undefined
  await isTokenValid().then(({ response }) => (isValid = response))

  if (isValid === true) {
    const access_token = sessionStorage.getItem('access_token') as string

    return getDocumentByIdFromPolicy(urlDocID, sessionID, name, mimeType, policyNumber, access_token).then(result => {
      const { status, payload } = result
      if (status === 200 && payload) {
        return (window as any).URL.createObjectURL(new Blob([payload], { type: mimeType }))
      } else {
        console.log(`Error fetching document of ${urlDocID} | status code ${status}`)
        return window.alert('There was an error fetching this document')
      }
    })
  }

  if (isValid === false) history.push('/')
}
