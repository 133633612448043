import { connect } from 'react-redux'
import { policySelectAction } from 'redux/policy/actions'

import { IReduxState } from 'types/stateTypes'
import RenewalPaymentCompletePage from './RenewalPaymentCompletePage'

const mapStateToProps = (state: IReduxState) => ({
  policyNumber: state.policy.policyIds[state.policy.currentPolicyIndex],
  index: state.policy.currentPolicyIndex,
})

const mapDispatchToProps = {
  policySelectAction: policySelectAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalPaymentCompletePage)
