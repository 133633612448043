import { driverToDisplay } from 'guidewire/format/driverToDisplay'
import { Driver } from '../types/driver'

export const driversUtils = (drivers: Driver[]) => {
  const details = () => drivers

  const display = (driver: Driver) => driverToDisplay(driver)

  const getMain = () => drivers.find(item => item.mainDriver)

  const getPolicyHolder = () => drivers.find(item => item.policyHolder)

  const getTotalDrivers = (withDriversString?: 'withDriversString') => {
    const total = drivers.length

    if (withDriversString) {
      return `${total} driver${total > 1 ? 's' : ''}`
    }

    return total
  }

  const getTotalOtherDrivers = (withDriversString?: 'withDriversString') => {
    const total = drivers.length - 1

    if (withDriversString) {
      if (total === 0) {
        return 'None'
      } else {
        return `${total} driver${total > 1 ? 's' : ''}`
      }
    }

    return total
  }

  return {
    details,
    display,
    getMain,
    getPolicyHolder,
    getTotalDrivers,
    getTotalOtherDrivers,
  }
}
