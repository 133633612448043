import { Button, IconButton, makeStyles } from '@material-ui/core'
import Question from 'components/ui/Question'

const useStyles = makeStyles(theme => ({
  buttonMobileWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
  },
}))

export interface Props {
  btnText: string
  variant?: 'text' | 'outlined' | 'contained' | undefined
  color?: 'inherit' | 'secondary' | 'primary' | 'default' | undefined
  disabled?: boolean
  onClick: () => void
}

const QuestionRowButton = ({ btnText, variant = 'outlined', color = 'primary', disabled = false, onClick }: Props) => {
  const classes = useStyles()
  return (
    <Question question="">
      <div className={classes.buttonMobileWrapper}>
        <Button variant={variant} color={color} disabled={disabled} onClick={() => onClick()}>
          {btnText}
        </Button>
      </div>
    </Question>
  )
}

export default QuestionRowButton
