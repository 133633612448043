import { brandPalette } from 'theme/brandPalette'

export const stepperStyles = {
  MuiStepper: {
    root: {
      backgroundColor: brandPalette.brand.primary.main,
    },
  },
  MuiStepIcon: {
    root: {
      fill: brandPalette.stepper,
      '& $text': {
        fill: brandPalette.bodyText,
      },
      '& $active': {
        fill: brandPalette.stepper,
      },
    },
  },
  MuiStepLabel: {
    // Mobile verical stepper only
    root: {
      '& .MuiStepLabel-label.MuiStepLabel-active': {
        opacity: 1,
        fontWeight: 600,
        color: 'white',
      },
      '& .MuiStepLabel-label.MuiStepLabel-completed': {
        opacity: 0.5,
        fontWeight: 600,
        color: 'white',
      },
    },
    // desktop
    alternativeLabel: {
      '& $label': {
        opacity: 0.5,
        color: 'white',
      },
      '& $active': {
        opacity: 1,
        fontWeight: 600,
      },
    },
  },
}
