import {
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  FormControl,
  Typography,
} from '@material-ui/core'
import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns/QuestionWithRadioBtns'
import { Field } from 'formik'
import { Address } from 'types/generalTypes'

import CommsPreferenceRadioBtns from './CommsPreferenceRadioBtns'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: '#6a6b70',
    paddingTop: 20,
    paddingBottom: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  gridContainer: {
    display: 'grid',
  },
  radioButton: {
    border: `${theme.palette.text.primary}80 1px solid`,
    borderRadius: 4,
    paddingLeft: 15,
    marginLeft: 15,
    marginRight: 20,
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: '19px',
    marginTop: '14px',
  },
}))

export interface Props {
  onSaveChanges: () => void
}

const CommunicationSection = ({ onSaveChanges }: Props) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            {
              'We aim to protect the environment and encourage our customers to do the same. All electronic correspondence will be via Your Portal or email, including your future renewal invitations. If you have requested paper communications, we will issue your policy documentats to you in this format. We may still email you on other matters related to your policy, your driving performance and general driver safety issues so it is important that we have your correct email address. '
            }
          </Typography>
        </Grid>

        <Grid container spacing={4}>
          <Field row component={CommsPreferenceRadioBtns} name="communicationPreference" />
          <Grid container justify="flex-end">
            <Grid item xs={12} sm={6}>
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={() => {
                  onSaveChanges()
                }}
              >
                {'Make changes'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CommunicationSection
