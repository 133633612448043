import React from 'react'

import { Button, Grid, makeStyles } from '@material-ui/core'

import Modal from '../Modal'

const useStyles = makeStyles(theme => ({
  cookieButton: {
    margin: 5,
  },
  settingDiv: {
    textAlign: 'left',
    display: 'flex',
  },
  settingText: {
    textAlign: 'left',
  },
  settingAction: {
    marginLeft: 'auto',
  },
  disabledCheck: {
    color: '#ff7e8f !important',
  },

  formLabel: {
    color: '#6a6b70',
    paddingTop: 20,
    paddingBottom: 20,
  },
  grayButton: {
    backgroundColor: theme.palette.background.default,
    color: `${theme.palette.text.primary}80 !important`,
    marginLeft: 'auto',
  },
  flexContainer: {
    display: 'flex',
  },
  gridContainer: {
    display: 'grid',
  },
  firstButton: {
    marginLeft: 'auto',
    marginRight: 10,
  },
}))

export interface Props {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  logout: VoidFunction
  continueLoggedIn: VoidFunction
}

const LogoutWarningModal = ({ modalOpen, setModalOpen, logout, continueLoggedIn }: Props) => {
  const classes = useStyles()

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(!modalOpen)}>
      <h1>{`Do you want to stay logged in?`}</h1>
      <p>
        {`Due to inactivity you will soon be logged out for security. If you wish to remain logged in, click continue.`}
      </p>
      <Grid item xs={12} className={classes.flexContainer}>
        <Button onClick={() => logout()} className={classes.firstButton} variant="outlined" color="secondary">
          {'Log me out'}
        </Button>
        <Button onClick={() => continueLoggedIn()} variant="outlined" color="primary">
          {'Continue'}
        </Button>
      </Grid>
    </Modal>
  )
}

export default LogoutWarningModal
