import { connect } from 'react-redux'
import { getMyPolicyPageContentAction } from 'redux/policy/actions'

import { IReduxState } from 'types/stateTypes'
import MyDocumentsPage from './MyDocumentsPage'

// import { policies } from './mock.json' // used when mocking

const mapStateToProps = (state: IReduxState) => ({
  content: state.policy.content.find((item: any) => item.id === 'policyHelpSection'),
  policyDocs: state.policyDocuments.period[state.policy.currentPolicyIndex],
})

const mapDispatchToProps = {
  getMyPolicyPageContentAction: getMyPolicyPageContentAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDocumentsPage)
