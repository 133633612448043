import { Field } from 'formik'

import { Box, Grid, makeStyles } from '@material-ui/core'

import QuestionWithDatePicker from '../QuestionWithDatePicker'
import QuestionWithTimePicker from '../QuestionWithTimePicker'
import Section from '../Section'
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles(theme => ({
  mobileWidth: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

export interface Props {
  disabled: boolean | undefined
  formRef?: any
  minimumDate?: Date | undefined
}

const DateAndTimePicker = ({ disabled, formRef, minimumDate }: Props) => {
  const classes = useStyles()
  const [minDate, setMinDate] = React.useState<any>(undefined)

  React.useEffect(() => {
    if (minimumDate) {
      const now = moment()
      const policyStartDate = moment(minimumDate)

      if (policyStartDate.isAfter(now)) {
        setMinDate(policyStartDate.toISOString())
      }
    }
  }, [])

  return (
    <div>
      <Section>
        <Box pl={2.5} pr={2.5} pb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Field
                required
                component={QuestionWithDatePicker}
                disablePast
                sevenDaysRange
                name="startDate"
                question={'Please select'}
                disabled={disabled}
                minDate={minDate}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <div className={classes.mobileWidth}>
                <Field
                  required
                  component={QuestionWithTimePicker}
                  name="startTime"
                  question={'Please select'}
                  disabled={disabled}
                  onDateChanged={(name: string, date: string) => {
                    //handle validation manually because all other fields should only validate on submit
                    if (moment(date).isValid()) {
                      const { startDate } = formRef?.current?.values
                      const pStartDate = moment(startDate).utc(true)
                      const pStartTime = moment(date).utc(true)

                      pStartDate.set('hours', pStartTime.hours())
                      pStartDate.set('minutes', pStartTime.minutes())

                      //check with now and add 30 mins
                      const now = moment().utc(true)
                      now.add(30, 'minute')

                      if (now.isAfter(pStartDate)) {
                        formRef?.current?.setFieldError(
                          'startTime',
                          'Please choose a time that is 30 minutes or more in the future',
                        )
                      } else {
                        formRef?.current?.setFieldError('startTime')
                      }
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Section>
    </div>
  )
}

export default DateAndTimePicker
