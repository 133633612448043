import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { brandPalette } from '../brandPalette'
import { breakpoints } from '../breakPoints'

interface Props {
  '#topBar': CSSProperties
  '#topBarBackground': CSSProperties
  '#brandLogo': CSSProperties
  '#liveChatWrapper': CSSProperties
  '#liveChatBackground': CSSProperties
  '#liveChatIcon': CSSProperties
  '#topBarButton': CSSProperties,
  '#talkdesk-chat-widget-trigger': CSSProperties,
  '.talkdeskLoggedIn': CSSProperties,
  '.talkdeskNotLoggedIn': CSSProperties
}

export const topBarStyles: Partial<Props> = {
  '#topBar': {
    height: '90px',
    [breakpoints.up(breakpoints.values.sm)]: {
      height: '90px',
    },
  },
  '#topBarBackground': {
    backgroundColor: brandPalette.brand.primary.main,
  },
  '#brandLogo': {
    cursor: 'pointer',
    [breakpoints.between(0, breakpoints.values.sm)]: {
      height: '35px',
    },
    [breakpoints.up(breakpoints.values.sm)]: {
      height: '50px',
    },
  },
  '#topBarButton': {
    [breakpoints.between(0, breakpoints.values.sm)]: {
      height: '35px',
      marginLeft: '0px',
      fontSize: '12px',
    },
    [breakpoints.up(breakpoints.values.sm)]: {
      marginLeft: '0px',
    },
  },
  '#talkdesk-chat-widget-trigger': {
    [breakpoints.between(0, breakpoints.values.md)]: {
      marginLeft: '-60px'
    },
    position: 'relative',
    marginTop: '-10px;',
    marginLeft: '-20px',
  },
  '.talkdeskLoggedIn': {
    position: 'fixed',
    bottom: '43px',
    right: '40px',
  },
  '#liveChatWrapper': {
    marginRight: '15px',
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    [breakpoints.up(breakpoints.values.sm)]: {
      width: '50px',
      height: '50px',
    },
  },
  '#liveChatBackground': {
    position: 'absolute',
    [breakpoints.between(0, breakpoints.values.sm)]: {
      height: '35px',
      width: '35px',
    },
    [breakpoints.up(breakpoints.values.sm)]: {
      height: '50px',
      width: '50px',
    },
  },
  '#liveChatIcon': {
    position: 'absolute',
    fill: brandPalette.bodyText,
    [breakpoints.between(0, breakpoints.values.sm)]: {
      height: '15px',
      width: '15px',
    },
    [breakpoints.up(breakpoints.values.sm)]: {
      height: '25px',
      width: '25px',
    },
  },
}
