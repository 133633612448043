import { LabelValuePair } from 'types/contentTypes'
import { Policies } from 'types/policies'

import moment from 'moment'
import { CurrencyType, DateObject } from 'types/policy'
import { LookupAddressType } from 'types/responses'
import { translateTypekey } from './formatToDisplay'

export interface IStringArrayToOptions {
  values: string[]
  labelStart?: string
  labelEnd?: string
}

export interface INumberArrayToOptions {
  values: number[]
  labelStart?: string
  labelEnd?: string
}

interface Placeholders {
  [key: string]: any
  MONTHLY_INSTALLMENT?: string | number
  MONTHLY_DOWNPAYMENT?: string | number
  MONTHLY_TOTAL?: string | number
  MONTHLY_NUMBER_OF_INSTALLMENTS?: number
}

export const getPolicyIds = (policyList: Policies[]) => {
  const policyIds: LabelValuePair[] = []
  policyList.map((policy: Policies) => {
    // Note: There is always at least one object in the periods array, this contains the policy id
    // during a renewal period there is more than one object and they will always have the same id.
    // It is sufficient to only take the id from one object
    const period = policy.periods[0]

    return policyIds.push({ label: period.policyId, value: period.policyId.replace('/', '%252F') })
  })

  return policyIds
}

// Method Added for ticket TOA-2675
export const isPolicyCancelled = (policyList: Policies[]) => {
  let isPolicyCancelled = true
  const todayDt = new Date()

  todayDt.setHours(0o0, 0o0, 0o1)

  policyList.map((policy: Policies) =>
    policy.periods.map(item => {
      //  Checking if any policy is not cancelled or not expired
      if (
        isPolicyCancelled &&
        (item.status === 'In Force' ||
          !(item.cancelled === true || item.status === 'Canceled' || item.expiration < todayDt))
      ) {
        isPolicyCancelled = false
      }
    }),
  )

  return isPolicyCancelled
}

export const placeholderReplace = (value: string, placeholders: Placeholders) => {
  const regexString = Object.keys(placeholders).join('|')
  const regex = new RegExp(regexString, 'gi')

  let changedValue = ''

  if (value !== undefined) {
    changedValue = value.replace(regex, matched => {
      return placeholders[matched]
    })
  }

  return changedValue
}

export const capitalizeAllFirstLetters = (string: string) => {
  return string.replace(/(?:^|\s)\S/g, fistLetter => {
    return fistLetter.toUpperCase()
  })
}

export const booleanToYesOrNo = (value: boolean) => {
  return value === true ? 'Yes' : 'No'
}

export const intToYearsString = (years: number) => {
  return `${years} year${years === 1 ? '' : 's'}`
}

export const stringArrayToOptions = ({
  values,
  labelStart = '',
  labelEnd = '',
}: IStringArrayToOptions): LabelValuePair[] => {
  return values.map(option => {
    return {
      label: `${labelStart}${option}${labelEnd}`,
      value: option,
    }
  })
}

export const formToClaims = (formClaims: any[]) => {
  return formClaims.map((formClaim: any) => ({
    claimType: formClaim.claimType,
    claimDate: formatDate(formClaim.claimDate),
  }))
}

export const formToConvictions = (formConvisions: any[]) => {
  return formConvisions.map((formConvision: any) => ({
    codeID: formConvision.codeID,
    convictionDate: formatDate(formConvision.convictionDate),
    convictionPoints: formConvision.convictionPoints,
    disqualificationMonths: formConvision.disqualificationMonths,
  }))
}

export const formatDateObject = (date: DateObject, format?: string) => {
  return moment(date).format(format)
}

export const formatMonthStringAndYearInt = (month: string, year: number) => {
  return moment()
    .month(month)
    .year(year)
    .utc()
    .format('MM / YYYY')
}

export const formatCurrencyObject = (value: CurrencyType) => {
  const { currency, amount } = value

  const currencyCode: { [x: string]: any } = {
    gbp: '£',
  }

  return `${currencyCode[currency as string]}${amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}

export const formatNumberToPercent = (value: number) => {
  return `${value.toFixed(2)}%`
}

export const monthAndYearToIso = (month: string, year: number) => {
  return moment({
    month: parseInt(
      moment()
        .month(month)
        .format('M'),
    ),
    year: year,
  })
    .utc(true)
    .format()
}

export const dateStringToReadable = (date: string) => {
  return moment(date).format('DD / MM / YYYY')
}

export const getDateXdaysBefore = (days: number, date: Date) => {
  return moment(date)
    .utc(false)
    .subtract(days, 'days')
    .toISOString()
}

export const isEmpty = (val: any) => val == null || !(Object.keys(val) || val).length

export const addressSuggestionsOptions = (lookupAddresses: LookupAddressType[]): LabelValuePair[] => {
  return lookupAddresses.map(item => ({
    label: item.description_itb
      .split(' ')
      .slice(0, -2)
      .join(' ')
      .replace(',', ''),
    value: item.id_itb,
  }))
}

export const isNullOrUndefined = (val: any) => val === null || val === undefined || val.trim().length < 1

export const numberArrayToOptions = ({
  values,
  labelStart = '',
  labelEnd = '',
}: INumberArrayToOptions): LabelValuePair[] => {
  return values.map(option => {
    return {
      label: `${labelStart}${option}${labelEnd}`,
      value: option,
    }
  })
}

export const formatDate = (date: string) => {
  const mDate = moment(date)
  return {
    year: mDate.year(),
    month: mDate.month(),
    day: mDate.date(),
  }
}

export const driversToSelectDisplay = (drivers: any[]) => {
  return drivers
    .filter(function(driver) {
      return driver.updateOrRemove != 'remove'
    })
    .map(item => {
      return {
        label: item.firstName + ' ' + item.lastName,
        value: item['publicID'] ? item.publicID : item.tempID,
      }
    })
}

export const dateObjectToMoment = (date: DateObject) => {
  return moment()
    .month(date.month)
    .year(date.year)
    .date(date.day)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}

export const formatYourCoverSummaryToDisplay = (details: {
  periodStartDate: DateObject
  periodEndDate: moment.Moment
  productName: string
  classOfUse: string
}) => {
  return {
    periodStartDate: moment(details.periodStartDate).format('DD / MM / YYYY'),
    periodEndDate: moment(details.periodEndDate).format('DD / MM / YYYY'),
    productName: details.productName,
    classOfUse: translateTypekey(details.classOfUse, 'typekey.VehicleClassOfUseType_itb'),
  }
}

export const driverValues = (driver: any) => {
  return {
    accessToOtherVehicles: booleanToYesOrNo(driver.accessToOtherVehicles).toLowerCase(),
    accountHolder: booleanToYesOrNo(driver.accountHolder).toLowerCase(),
    cancelledPreviousInsurance: booleanToYesOrNo(driver.cancelledPreviousInsurance).toLowerCase(),
    dateOfBirth: moment(driver.dateOfBirth).format('DD / MM / YYYY'),
    drivingQualifications: driver.drivingQualifications,
    employersBusiness: {
      label: translateTypekey(driver.employersBusiness as string, 'typekey.EmployersBusinessType_itb'),
      value: driver.occupation,
    },
    firstName: driver.firstName,
    fixedId: driver.fixedId,
    gender: driver.gender,
    hasMotorClaims: booleanToYesOrNo(driver.hasMotorClaims).toLowerCase(),
    hasMotorConvictions: booleanToYesOrNo(driver.hasMotorConvictions).toLowerCase(),
    homeOwner: booleanToYesOrNo(driver.homeOwner).toLowerCase(),
    lastName: driver.lastName,
    licenseType: driver.licenseType,
    mainDriver: booleanToYesOrNo(driver.mainDriver).toLowerCase(),
    maritalStatus: driver.maritalStatus,
    medicalConditions: driver.medicalConditions,
    nonMotoringConvictions: booleanToYesOrNo(driver.nonMotoringConvictions).toLowerCase(),
    occupation: {
      label: translateTypekey(driver.occupation, 'typekey.OccupationType_itb'),
      value: driver.occupation,
    },
    occupationStatus: driver.occupationStatus,
    phRelationship: driver.phRelationship,
    pmClaims: driver.pmClaims,
    pmMotorConvictions: driver.pmMotorConvictions,
    policyHolder: booleanToYesOrNo(driver.policyHolder).toLowerCase(),
    publicID: driver.publicID,
    testPassed10Years: booleanToYesOrNo(driver.testPassed10Years).toLowerCase(),
    ...(driver['testPassed10Years'] === false && {
      whenPassTest: monthAndYearToIso(driver.passedTestMonth, driver.passedTestYear),
    }),
    title: driver.title,
    ukResident5Years: booleanToYesOrNo(driver.ukResident5Years).toLowerCase(),
    ...(driver['ukResident5Years'] === false && {
      ukResidentSinceDate: monthAndYearToIso(driver.ukResidencyMonth, driver.ukResidencyYear),
    }),
    ...(driver['isNew'] && { isNew: driver.isNew }),
    ...(driver['updateOrRemove'] && { isNew: driver.updateOrRemove }),
  }
}
