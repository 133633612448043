import { createActions } from 'redux-actions'
import { ISuccessMeta, IErrorMeta, PhaseType } from './types'

const defaultPayloadCreator = (payload: any) => payload
const defaultMetaCreator = (meta: any) => meta

const successMetaCreator = (meta: ISuccessMeta) => {
  const { successMessage } = meta
  return {
    ...(successMessage ? { successMessage } : {}),
  }
}

const errorMetaCreator = (meta: IErrorMeta) => {
  const { errorCode, errorMessage } = meta
  return {
    ...(errorCode ? { errorCode } : {}),
    ...(errorMessage ? { errorMessage } : {}),
  }
}

interface ParamsCreatorArgsProps {
  phase: PhaseType
  payloadCreator: (payload: any) => any
  metaCreator: (meta: any) => any
  type: string
}

const paramsCreator = ({ phase, payloadCreator, metaCreator, type }: ParamsCreatorArgsProps): any => [
  payloadCreator,
  (payload = {}) => {
    const outputMeta = { ...metaCreator(payload), phase, actionName: type }
    return outputMeta
  },
]

export const createPhasedAction = (
  type: string,
  payloadCreator?: (payload: any) => any,
  metaCreator?: (meta: any) => any,
): {
  [actionName: string]: any
} =>
  createActions({
    [type]: {
      [PhaseType.START]: paramsCreator({
        phase: PhaseType.START,
        payloadCreator: payloadCreator || defaultPayloadCreator,
        metaCreator: metaCreator || defaultMetaCreator,
        type,
      }),
      [PhaseType.SUCCESS]: paramsCreator({
        phase: PhaseType.SUCCESS,
        payloadCreator: payloadCreator || defaultPayloadCreator,
        metaCreator: metaCreator || successMetaCreator,
        type,
      }),
      [PhaseType.ERROR]: paramsCreator({
        phase: PhaseType.ERROR,
        payloadCreator: payloadCreator || defaultPayloadCreator,
        metaCreator: errorMetaCreator,
        type,
      }),
    },
  })

interface DefaultMessageType {
  defaultSuccessMessage?: string
  defaultErrorMessage?: string
}

export const createNotifiedPhasedAction = (actionName: string, defaultMessages: DefaultMessageType) => {
  const { defaultSuccessMessage, defaultErrorMessage } = defaultMessages
  const successMessage = defaultSuccessMessage || `${actionName} was executed successfully.`
  const errorMessage = defaultErrorMessage || `${actionName} failed.`

  return createPhasedAction(
    actionName,
    payload => payload,
    payload => ({
      successMessage: payload.successMessage || successMessage,
      errorMessage: payload.errorMessage || errorMessage,
    }),
  )
}
