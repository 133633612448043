// TODO Replace with keys from the API call
import keys from 'mock/guidewire/typekeys.json'
import { LabelValuePair } from 'types/contentTypes'

export interface keyValuePair {
  key: string
  value: string
}

export const typeKeyTools = () => {
  const getDropDownOptions = (name: string) => {
    const key = keys.find(({ typeName }) => typeName === `typekey.${name}`)

    if (key === undefined) return [{ label: '', value: '' }]

    return key.keyValuePairs.map(
      (item: keyValuePair): LabelValuePair => {
        return {
          label: item.value,
          value: item.key.substring(item.key.lastIndexOf('.') + 1),
        }
      },
    )
  }

  return {
    getDropDownOptions,
  }
}
