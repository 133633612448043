import { Button, Grid, makeStyles, Typography } from '@material-ui/core'

import Modal from 'components/ui/Modal'

import { history } from 'utils'

export interface Props {
  showMTAModal: boolean
  redirect: string
  closeMTAModalAction: () => void
}

const useStyles = makeStyles(theme => ({
  firstButton: {
    marginLeft: 'auto',
    marginRight: 10,
  },
  modalText: {
    paddingTop: 0,
    marginTop: 0,
  },
}))

const MTAModal = ({ showMTAModal, redirect, closeMTAModalAction }: Props) => {
  const classes = useStyles()

  return (
    <Modal open={showMTAModal} onClose={closeMTAModalAction}>
      <Grid item xs={12}>
        <h1>{`Please note`}</h1>
        <p className={classes.modalText}>
          {'This policy change will incur a fee and could result in an increase in your premium. Please refer to our '}
          <a href={window.location.origin + '/TYA_Important_Information_v3.pdf'} target="_blank" rel="noreferrer">
            {'Important Information document'}
          </a>
          {' for a summary of our charges.'}
        </p>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.firstButton}
          onClick={() => {
            closeMTAModalAction()
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            closeMTAModalAction()
            history.push(redirect)
          }}
        >
          Continue
        </Button>
      </Grid>
    </Modal>
  )
}

export default MTAModal
