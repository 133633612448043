import React from 'react'
import { Button, makeStyles, Grid } from '@material-ui/core'
import { ReactComponent as ErrorLogo } from 'assets/warning_error.svg'
const useStyles = makeStyles(theme => ({
  formLabel: {
    color: '#6a6b70',
    paddingTop: 20,
    paddingBottom: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  gridContainer: {
    display: 'grid',
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: '19px',
    marginTop: '14px',
  },
}))
export interface Props {
  title: string
  description: string
  cancelButtonText?: string
  submitButtonText: string
  cancelAction?: () => void
  submitAction: () => void
}

const AlertMessageInfo = ({
  title,
  description,
  cancelButtonText,
  submitButtonText,
  cancelAction,
  submitAction,
}: Props) => {
  const classes = useStyles()
  return (
    <div style={{ borderTop: '10px solid red' }}>
      <div style={{ padding: '1rem', margin: '1rem' }}>
        <div
          style={{ margin: '1rem', display: 'flex', justifyContent: 'flex-start', fontSize: 30, fontWeight: 'bold' }}
        >
          <span style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50px',
            width: '50px',
            borderRadius:'50%',
            padding: '15px 0 0 8px',
            background: '#ff000040',
            marginRight: '5px'
          }}>
            <ErrorLogo />
          </span>
          {title}
        </div>

        <div style={{ margin: '1rem', display: 'flex', justifyContent: 'flex-start', fontSize: '1rem' }}>
          {description}
        </div>
        <Grid container justify='flex-end'>
          <Grid item xs={12} sm={6}>
            <Button className={classes.button} color='primary' variant='outlined' onClick={() => submitAction()}>
              {submitButtonText}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AlertMessageInfo
