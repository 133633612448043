import { Divider } from '@material-ui/core'

import BasicAccordion from 'components/ui/BasicAccordion'

export interface Props {
  title: string
  children: any
}

export const HelpAccordian = ({ title, children }: Props) => {
  return (
    <BasicAccordion title={title}>
      <Divider style={{ marginBottom: 20 }} />

      {children}
    </BasicAccordion>
  )
}
