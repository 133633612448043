import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import Layout from 'components/ui/Layout'
import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns'
import Title from 'components/ui/Title'
import { Field } from 'formik'
import { LabelValuePair } from 'types/contentTypes'
import { Quote } from 'types/policyTypes'

export interface CardRegisteredToYouContent {
  id: string
  title: string
  body: string
  isCardRegisteredToYou: string
  isCardRegisteredToYouOptions: LabelValuePair[]
}

export interface Props {
  content: CardRegisteredToYouContent
  onSelect: (value: string) => void
}

const useStyles = makeStyles(theme => ({
  text: {
    '& strong': {
      color: theme.palette.secondary.main,
      fontWeight: 'normal',
    },
  },
}))

const CardRegisteredToYou = ({ content, onSelect }: Props) => {
  const classes = useStyles()
  const { title, body, isCardRegisteredToYou, isCardRegisteredToYouOptions } = content

  return (
    <Layout>
      {title && <Title title={title} />}

      <Box pt={5} pb={5} pl={2} pr={2}>
        <Grid container direction="column" spacing={4}>
          <Field
            required
            row
            component={QuestionWithRadioBtns}
            name="isCardRegisteredToYou"
            question={isCardRegisteredToYou}
            options={isCardRegisteredToYouOptions}
            onSelect={(value: string) => onSelect(value)}
          />

          {body && (
            <Grid item>
              <Typography className={classes.text} component="div">
                <p>
                  {`If you are not the cardholder it is your responsibility to ensure that you have the cardholder's authority to make this payment. You must also advise the cardholder of any changes to the premium.`}
                </p>
                <p>
                  <strong>{`The payment reference shown on your bank statement will be PC/AIOI INSURANCE.`}</strong>
                </p>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Layout>
  )
}

export default CardRegisteredToYou
