import { TableContainer, Table, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { v4 as uniqeID } from 'uuid'

import { LabelValuePair } from 'types/generalTypes'

export interface Props {
  list: LabelValuePair[]
  values: any
  type: string
}

const CoverList = ({ list, values, type }: Props) => {
  const testConst = true
  return (
    <TableContainer>
      <Table>
        <TableBody>
        {type === 'renewalMyCover' ?
        <>
        {list.map(item => (
        <>
        {values[item.value] === '' ? <></> :
        <TableRow key={uniqeID()}>
          <TableCell>
          <div style={{ display: 'flex' }}>
          {item.label}
          {item.infoIcon && (
          <Tooltip title={item.infoIcon} placement="top">
            <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
          </Tooltip>
          )}
          </div>
          </TableCell>
          <TableCell align="right" style={{ width: '50%' }}>
            {values[item.value]}
          </TableCell>
          </TableRow>}

        </>

        ))}</> :
         <>
        {list.map(item => (
         <TableRow key={uniqeID()}>
         <TableCell>
               <div style={{ display: 'flex' }}>
               {item.label}
               {item.infoIcon && (
               <Tooltip title={item.infoIcon} placement="top">
               <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                </Tooltip>
                )}
                </div>
         </TableCell>
         <TableCell align="right" style={{ width: '50%' }}>
           {values[item.value]}
         </TableCell>
         </TableRow>
           ))}</>
        }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CoverList
