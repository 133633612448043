import Iframe from 'react-iframe'

import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  closeLiveChat: VoidFunction
}

const useStyles = makeStyles(theme => ({
  open: {
    zIndex: 11, //needs to be above QuoteAndBuyStepper
    display: 'block',
    position: 'fixed',
    borderRadius: '15px',
    boxShadow: '1px 1px 6px 1px rgb(0 0 0 / 16%)',
    overflow: 'hidden',
    maxWidth: '1000px',
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      height: '90vh',
      left: '20%',
      right: '10%',
      top: '100px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      height: '80vh',
      width: '60%',
      top: '100px',
      right: '0',
      left: '10%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  close_button: {
    color: 'white',
    right: 0,
    position: 'absolute',
  },
  chat_iframe: {
    border: 'none',
    borderRadius: 0,
  },
}))

const LiveChat = ({ closeLiveChat }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.open}>
      <IconButton onClick={closeLiveChat} className={classes.close_button}>
        <CloseIcon />
      </IconButton>

      <Iframe
        className={classes.chat_iframe}
        width={'100%'}
        height={'100%'}
        url={
          'https://apps.commbox.io//chat/p4welY5ilpK3ErcZgRfdXA%3d%3d/?trackerStreamId=isk0iIgqDx0aBC5T_fihKVA%3d%3d&is_CB_frame=1'
        }
      />
    </div>
  )
}

export default LiveChat
