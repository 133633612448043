import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import ReactMarkdown from 'react-markdown'

export interface Props {
  label: string
  selected: boolean
  cost: string
  costMonthly: string
  content: { id: string; body: string; summary: string; monthly: string; annually: string }
  ncdYears?: string
}

const AddonAccordian = ({ label, selected, cost, costMonthly, content, ncdYears }: Props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Accordion
      style={{ marginBottom: 16, border: '1px solid #d8d8d8', borderRadius: 0 }}
      expanded={open}
      elevation={0}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary
        style={{ backgroundColor: '#eff0f0', borderBottom: open ? '1px solid #d8d8d8' : 0 }}
        expandIcon={open ? <RemoveIcon color="secondary" /> : <AddIcon color="secondary" />}
      >
        <Typography style={{ flexBasis: '55%', flexShrink: 0 }}>{label}</Typography>
        {selected ? <CheckIcon /> : <CloseIcon color="secondary" />}
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography component="div">
          {content?.body ? (
              <Typography>{content.body.replace('NCD_YEARS', ncdYears || '')}</Typography>
            ) : null}
            <ReactMarkdown linkTarget="__blank">{content.summary}</ReactMarkdown>
          </Typography>

          <Typography style={{ fontWeight: 600 }}>{content.annually.replace('COST_PER_YEAR', cost)}</Typography>

          <Typography>{content.monthly.replace('COST_PER_MONTH', costMonthly)}</Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default AddonAccordian
