import { brandPalette } from 'theme/brandPalette'

export const formFieldStyles = {
  MuiInputLabel: {
    root: {
      fontSize: 16,
      color: brandPalette.bodyText,
      '&$focused:not($disabled):not($error)': {
        color: brandPalette.bodyText,
      },
    },
  },
  MuiOutlinedInput: {
    input: {
      fontSize: 16,
    },
    root: {
      height: 50,
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        border: '1px solid',
        borderColor: brandPalette.brand.primary.light,
      },
    },
    notchedOutline: {
      border: '1px solid',
      borderRadius: 4,
    },
  },
}
