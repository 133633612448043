import { AccountHolder } from 'guidewire/policy/types/accountHolder'
import { getTypekeyValue } from 'guidewire/typekeys/getTypekeyValue'
import moment from 'moment'
import { capitalizeString } from './capitalizeString'

export const accountHolderToDisplay = (accountHolder: AccountHolder) => {
  const accountHolderDisplay = Object.fromEntries(
    Object.keys(accountHolder).map((key: keyof AccountHolder) => {
      if (typeof accountHolder[key] === 'boolean') return [key, accountHolder[key] === true ? 'Yes' : 'No']

      if (key === 'dateOfBirth') return [key, moment(accountHolder[key]).format('DD/MM/YYYY')]

      if (key === 'occupation') {
        return [key, getTypekeyValue(accountHolder[key], 'typekey.OccupationType_itb')]
      }
      if (key === 'employersBusiness') {
        return [key, getTypekeyValue(accountHolder[key], 'typekey.EmployersBusinessType_itb')]
      }

      if (key === 'title') return [key, capitalizeString(accountHolder[key])]

      return [key, accountHolder[key]]
    }),
  )

  return accountHolderDisplay
}
