import { useEffect } from "react"

const TalkDesk = () => {
    useEffect(() => {

      var webchat;
      (function(window, document, node, props, configs) {

        var divContainer = document.createElement("div");
        divContainer.id = node;
        var src = "https://talkdeskchatsdk.talkdeskapp.com/v2/talkdeskchatsdk.js";
        var script = document.createElement("script");
        var firstScriptTag = document.getElementsByTagName("script")[0];
        script.type = "text/javascript";
        script.charset = "UTF-8";
        script.id = "tdwebchatscript";
        script.src = src;
        script.async = true;
        firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
        script.onload = function() {
          webchat = TalkdeskChatSDK(node, props);
          webchat.init(configs);
           function setContext() {
             webchat.setContextParam({ "brand": "tya" })
           }
           // Send data when the chat conversation is initiated
           webchat.onConversationStart = function() {
             setContext()
           }
           // Send data when the chat widget is open
           webchat.onOpenWebchat = function() {
             setContext()
           }
        };
      })
      (
        window,
        document,
        "tdWebchat",
        { touchpointId: "5021cd62cb214ad9ae94353386b862b1", accountId: "", region: "td-eu-1" },
        { enableValidation: false, enableEmoji: true, enableUserInput: true, enableResponsiveLayout: true, enableAttachments: true, 
          styles: {
            // custom styles for TYA
            chatLauncherColor: "#ff0022",
            chatHoverLauncherColor: "#ff0022",
          }, }
      );
      
    },[])

    return (
        <div id="tdWebchat"  style={{position: "relative"}}></div>
    )
}

export default TalkDesk