import { Grid, Typography } from '@material-ui/core'
import SVG from 'react-inlinesvg'

export const BreakdownHeader = ({
  title = '',
  titleIcon = '',
  subtitle = '',
}: {
  title: string
  titleIcon: string
  subtitle: string
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ display: 'flex', alignItems: 'center' }}>
          <span>
            <SVG
              style={{
                height: 50,
                marginRight: 20,
              }}
              src={titleIcon}
            />
          </span>
          <div>{title}</div>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>{subtitle}</Typography>
      </Grid>
    </>
  )
}
