import { useState } from 'react'

import { makeStyles, useTheme, Button, Grid } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import ContactUsModal from 'components/ui/ContactUsModal'

import { ReactComponent as ReactLogo } from 'assets/ToyotaLogoWhite.svg'
import { ReactComponent as LiveChatBackground } from 'assets/liveChatBackground.svg'
import { ReactComponent as LiveChatIcon } from 'assets/liveChatIcon.svg'
import { PolicyDetails } from 'types/policyTypes'
import { EndPoint } from 'types/endpoint'
import { history } from 'utils'

import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import TalkDesk from './talkdesk'

export interface Props {
  showLogoutButton: boolean
  showMyAccountButton: boolean
  isPolicyCancelled?: boolean //  Added for ticket TOA-2675
  onBrandLogo: () => void
  onLiveChat: () => void
  onLogout: () => void
}

const useStyles = makeStyles(theme => ({
  topBarFixed: {
    position: 'fixed',
    zIndex: 10,
    left: 0,
    right: 0,
  },
  buttons: {
    display: 'flex',
  },
  topBarButton: {
    color: 'white',
    '&:disabled': {
      color: theme.palette.text.secondary,
    },
  },
}))

const TopBar = ({
  onBrandLogo,
  onLiveChat,
  onLogout,
  isPolicyCancelled, //  Added for ticket TOA-2675
  showLogoutButton = false,
  showMyAccountButton = false,
}: Props) => {
  const theme = useTheme()
  const classes = useStyles()
  
  const isLoggedInTDFunction = () => {
    if (showMyAccountButton){
      return 'talkdeskLoggedIn'
    } else {
      return 'talkdeskNotLoggedIn'
    }
  }
  return (
    <div className={classes.topBarFixed}>
      <Layout background={theme.palette.primary.main}>
        <Grid container direction="row" justify="space-between" alignItems="center" id="topBar">
          <Grid item>
            <ReactLogo onClick={onBrandLogo} id="brandLogo" />
          </Grid>

          <Grid item>
            <div className={classes.buttons}>
              <div id='talkDeskWrapper' className={isLoggedInTDFunction()}>
                <TalkDesk/>
              </div>
              {showMyAccountButton && (
                <Button
                  id="topBarButton"
                  className={classes.topBarButton}
                  disabled={isPolicyCancelled}
                  onClick={() => history.push(EndPoint.MY_ACCOUNT)}
                  startIcon={<PersonAddIcon />}
                >
                  My account
                </Button>
              )}

              {showLogoutButton && (
                <Button
                  id="topBarButton"
                  className={classes.topBarButton}
                  onClick={() => onLogout()}
                  startIcon={<ExitToAppIcon />}
                >
                  Logout
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Layout>
    </div>
  )
}

export default TopBar
