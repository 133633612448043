import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'

import { getPoliciesAction, getPolicyByIdAction, policySelectAction, setPolicyIdsAction } from 'redux/policy/actions'
import {
  forgotPasswordAction,
  getLoginPageContentAction,
  isLoggedInAction,
  loginAction,
  pageRefreshAction,
  refreshedAccessTokenAction,
  resetPasswordAction,
  logoutAction,
} from './actions'

import { forgotPassword, getTypekeys, login, resetPassword } from './client'
import { getPolicies, getPolicyById } from 'redux/policy/client'

import { setIsLoadingAction } from 'redux/loading/actions'

import { Action } from 'redux/actionCreators/types'
import { AuthorizationTokens, PolicyDetailsResponse, RequestResponse } from 'types/responses'
import { LoginRequest } from 'types/requests'
import { EndPoint } from 'types/endpoint'

import { getPolicyIds } from 'guidewire/formatTools'
import { history } from 'utils/history'
import { tokenValidCheck } from 'utils/tokenRefresh/tokenRefresh'

// Mock content
import fallbackTypekeys from 'mock/typekeys/typekeys.json'
import fallbackLoginContent from 'mock/cms/page_login.json'
import { setPolicyDocumentsAction } from 'redux/policyDocuments/actions'

export function* onGetLoginPageContent() {
  try {
    yield put(setIsLoadingAction.success({ isLoading: true }))
    // TODO: CMS space => content
    // const { data } = yield call(() => getLoginPageContent()) as any
    // const { content } = data
    yield put(getLoginPageContentAction.success({ content: fallbackLoginContent }))
    yield put(setIsLoadingAction.success({ isLoading: false }))
  } catch (error) {
    yield put(getLoginPageContentAction.success({ content: fallbackLoginContent }))
    yield put(setIsLoadingAction.success({ isLoading: false }))
  }
}

export function* onLogin(action: Action<{ requestObject: LoginRequest; redirect?: string }>) {
  try {
    // Basic flow
    // 1. login with user email and password
    // 2. get token - set to storage
    // 3. token needs to be included with all api calls
    // 4. Get polices - returns a list of all policies
    // 5. get policy by id
    // 6. Set policy to "current" in redux

    // Show the global Circular progress spinner
    yield put(setIsLoadingAction.success({ isLoading: true }))

    const {
      payload: { requestObject, redirect },
    } = action

    const {
      response: {
        status: loginStatus,
        data: { access_token, refresh_token, expires_in },
      },
    }: RequestResponse<AuthorizationTokens> = yield call(() => login(requestObject))

    const expiresSeconds = parseInt(expires_in) * 1000 - 3000

    // Login token successful steps
    if (loginStatus === 200) {
      yield put(loginAction.success({ refreshAfterTime: Date.now() + expiresSeconds }))
      // both tokens go into session strorage for 401 and / or page refreshes
      sessionStorage.setItem('access_token', access_token)
      sessionStorage.setItem('refresh_token', refresh_token)
      sessionStorage.setItem('refresh_after', JSON.stringify(Date.now() + expiresSeconds))

      const {
        response: { status: policiesStatus, data: policyList },
      } = yield call(() => getPolicies(access_token))

      if (policiesStatus === 200) {
        // Set documents to their own state in redux to make things easier to retrieve
        const documents = (policyList as any[]).map((item: any) => {
          return (item.periods as any[]).flatMap((period: any) => {
            return { policyId: period.policyId, status: period.status, documents: period.documents }
          })
        })

        yield put(setPolicyDocumentsAction.success({ period: documents }))
        //

        yield put(getPoliciesAction.success({ policies: policyList }))

        const policyIds = getPolicyIds(policyList)

        yield put(setPolicyIdsAction.success({ policyIds }))

        const {
          response: { status: policyDetailsStatus, data: policyDetails },
        }: RequestResponse<PolicyDetailsResponse> = yield call(() => getPolicyById(policyIds[0].value, access_token))

        if (policyDetailsStatus === 200) {
          // TODO: Needs to include Renewal and Amended renewal policies when available
          // Will also need adding to the pageRefreshAction (see further down page)
          yield put(
            getPolicyByIdAction.success({
              current: policyDetails.currentPeriod,
              renewed: policyDetails.renewedPeriod,
              alternateRenewed: policyDetails.alternateRenewedPeriod,
              autoRenew: policyDetails.autoRenew,
            }),
          )

          yield put(isLoggedInAction.success(true))

          // Set the default policy index to sessionstorage for retrieval on page refresh
          sessionStorage.setItem('policy_index', '0')

          // Get typekeys
          const {
            response: { data: typkeys, status: typekeysStatus },
          }: RequestResponse<any> = yield call(() => getTypekeys())

          if (typekeysStatus === 200) {
            sessionStorage.setItem('typekeys', JSON.stringify(typkeys))
          } else {
            sessionStorage.setItem('typekeys', JSON.stringify(fallbackTypekeys))
          }

          if (!redirect) {
            yield call(() => history.push(EndPoint.MY_POLICY))
          } else {
            yield call(() => history.push(redirect))
          }
        } else {
          yield put(loginAction.error({ loginErrorMessage: 'Error retrieving policies' }))
        }
      }

      if (policiesStatus !== 200) {
        yield put(loginAction.error({ loginErrorMessage: 'Error retrieving policies' }))
      }
    }

    // Incorrect login credentials
    if (loginStatus === 401) {
      yield put(loginAction.error({ loginErrorMessage: 'Please check your username or password is correct' }))

      //if sign in comes from modal and went wrong
      if (redirect) {
        yield call(() => onLogout())
      }
    }

    // Network error from login service
    if (loginStatus === 500) {
      yield put(loginAction.error({ loginErrorMessage: 'Network error' }))

      //if sign in comes from modal and went wrong
      if (redirect) {
        yield call(() => onLogout())
      }
    }

    // Stop the global Circular progress spinner at the end of the process
    yield put(setIsLoadingAction.success({ isLoading: false }))
  } catch (error) {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield put(loginAction.error({ loginErrorMessage: 'Something went wrong' }))
  }
}

export function* onForgotPassword(action: Action<{ email: string }>) {
  const {
    payload: { email },
  } = action

  try {
    const { data } = yield call(() => forgotPassword(email))
  } catch (error) {
    //
  }
}

export function* onResetPassword(action: Action<{ password: string; token: string }>) {
  const {
    payload: { password, token },
  } = action

  try {
    const { data } = yield call(() => resetPassword(password, token))
  } catch (error) {
    //
  }
}

export function* onRefreshedActionToken(action: Action<{ access_token: string }>) {
  const {
    payload: { access_token },
  } = action

  yield put(loginAction.success({ access_token }))
}

export function* onLogout() {
  sessionStorage.clear()

  yield put({ type: 'RESET_STORE' })

  history.push(EndPoint.LOGIN)
}

export function* onPageRefreshAction() {
  const policy_index = parseInt(sessionStorage.getItem('policy_index') as string)

  const { isValid } = yield call(() => tokenValidCheck())

  const access_token = sessionStorage.getItem('access_token')

  if (isValid === true) {
    const {
      response: { status: policiesStatus, data: policyList },
    } = yield call(() => getPolicies(access_token as string))

    if (policiesStatus === 200) {
      // Set documents to their own state in redux to make things easier to retrieve
      const documents = (policyList as any[]).map((item: any) => {
        return (item.periods as any[]).flatMap((period: any) => {
          return { policyId: period.policyId, status: period.status, documents: period.documents }
        })
      })

      yield put(setPolicyDocumentsAction.success({ period: documents }))
      //

      yield put(getPoliciesAction.success({ policies: policyList }))

      const policyIds = getPolicyIds(policyList)

      yield put(setPolicyIdsAction.success({ policyIds }))

      const {
        response: { status: policyDetailsStatus, data: policyDetails },
      }: RequestResponse<PolicyDetailsResponse> = yield call(() =>
        getPolicyById(policyIds[policy_index].value, access_token as string),
      )

      if (policyDetailsStatus === 200) {
        yield put(policySelectAction.success({ index: policy_index }))
        yield put(
          getPolicyByIdAction.success({
            current: policyDetails.currentPeriod,
            renewed: policyDetails.renewedPeriod,
            alternateRenewed: policyDetails.alternateRenewedPeriod,
            autoRenew: policyDetails.autoRenew,
          }),
        )
      } else {
        yield call(() => onLogout())
      }

      yield put(isLoggedInAction.success(true))
    }
  } else {
    yield call(() => onLogout())
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getLoginPageContentAction.start, onGetLoginPageContent),
    takeLeading(loginAction.start, onLogin),
    takeLatest(forgotPasswordAction.start, onForgotPassword),
    takeLatest(refreshedAccessTokenAction.start, onRefreshedActionToken),
    takeLatest(resetPasswordAction.start, onResetPassword),
    takeLatest(logoutAction.start, onLogout),
    takeLatest(pageRefreshAction.start, onPageRefreshAction),
  ])
}
