import React from 'react'

import { makeStyles, Grid, Typography, Button, TextField, Collapse, Box } from '@material-ui/core'

import ReadOnlyField from 'components/ui/ReadOnlyField'

import { ContactDetails, LabelValuePair } from 'types/generalTypes'
import { Field } from 'formik'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
}))

export interface Props {
  policyHolderContact: ContactDetails
  edit: boolean
  setEdit: (x: boolean) => void
  onSaveChanges: () => void
  onCancelChanges: () => void
}

const ContactDetailsSection = ({ policyHolderContact, edit, setEdit, onSaveChanges, onCancelChanges }: Props) => {
  const classes = useStyles()

  const readOnlyFields: LabelValuePair[] = [
    { label: 'Email address', value: 'emailAddress1' },
    { label: 'Mobile number', value: 'cellNumber' },
    { label: 'Landline', value: 'homePhone' },
  ]

  return (
    <>
      <Grid container spacing={4}>
        {edit === false &&
          readOnlyFields.map(item => (
            <Grid item xs={12} sm={4} key={item.label}>
              <Typography className={classes.readOnlyLabel}>{item.label}</Typography>
              <ReadOnlyField value={policyHolderContact[item.value] || ''} />
            </Grid>
          ))}
      </Grid>

      <Collapse in={edit}>
        <Box pb={4} />
        <Grid container spacing={4}>
          {readOnlyFields.map(item => (
            <React.Fragment key={item.label}>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.label}>{item.label}</Typography>
                <ReadOnlyField value={policyHolderContact[item.value] || ''} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.label}>{`New ${item.label.toLowerCase()}`}</Typography>
                <Field
                  fullWidth
                  variant="outlined"
                  name={`${item.value}New`}
                  type={item.value === 'emailAddress1' ? 'text' : 'tel'}
                  component={QuestionWithTextField}
                  maxLength={item.value === 'emailAddress1' ? 60 : 11}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.label}>{`Confirm ${item.label.toLowerCase()}`}</Typography>
                <Field
                  fullWidth
                  variant="outlined"
                  name={`${item.value}Confirm`}
                  type={item.value === 'emailAddress1' ? 'text' : 'tel'}
                  component={QuestionWithTextField}
                  maxLength={item.value === 'emailAddress1' ? 60 : 11}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Collapse>

      <Box pb={4} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            {edit && (
              <>
                <Button
                  style={{ marginRight: 10 }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setEdit(false)
                    onCancelChanges()
                  }}
                >
                  Cancel
                </Button>
                <Button variant="outlined" color="primary" onClick={() => onSaveChanges()}>
                  Save changes
                </Button>
              </>
            )}
            {edit === false && (
              <Button variant="outlined" color="secondary" onClick={() => setEdit(true)}>
                Make a change
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ContactDetailsSection
