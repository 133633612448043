const Cookies = require('js-cookie')

const COOKIE_NAME = 'acceptedCookies'

export enum AcceptedCookies {
  ALL = 'all',
  NONE = 'none',
}

export function setPreferences(
  functionCookies: boolean,
  analyticsCookies: boolean,
  advertisingCookies: boolean,
  setCookieModalOpen?: (x: boolean) => void,
) {
  if (functionCookies && analyticsCookies && advertisingCookies) {
    Cookies.set(COOKIE_NAME, 'all', { expires: 360 })
  } else {
    const settings = []
    if (functionCookies) {
      settings.push('function')
    }
    if (analyticsCookies) {
      settings.push('analytics')
    }
    if (advertisingCookies) {
      settings.push('advertising')
    }
    Cookies.set(COOKIE_NAME, settings.join('|'), { expires: 360 })
  }

  if (setCookieModalOpen) setCookieModalOpen(false)
}

export function setCookies(value: string, setCookieModalOpen?: (x: boolean) => void) {
  Cookies.set(COOKIE_NAME, value, { expires: 360 })
  if (setCookieModalOpen) setCookieModalOpen(false)
}

export function getCookie() {
  return Cookies.get(COOKIE_NAME)
}
