import { useFormik } from 'formik'

import { Box, Button, Grid } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import Title from 'components/ui/Title'
import StoreCard from 'components/payment/StoreCard'
import DirectDebitDetails from 'components/payment/DirectDebitDetails'
import InstallmentAgreement from 'components/payment/InstallmentAgreement'

import { Display } from 'guidewire/policy/enums/display'
import { PaymentPlanDisplay } from 'guidewire/policy/types/paymentPlan'
import { renewalQuote } from 'guidewire/policy/quote/renewalQuote'

import paymentMonthlyValidation from 'guidewire/policy/formValidation/paymentMonthly'
import { DirectDebitPaymentForm, DirectDebitPaymentRequest } from 'guidewire/policy/types/cardPaymentRequest'

import { RadioYesNoProps } from 'components/formUI/RadioYesNo/RadioYesNo'
import { LabelValuePair } from 'types'
import { RenewalQuote } from 'guidewire/policy/types/renewalQuote'
import { history } from 'utils'

export interface Props {
  quote: RenewalQuote
  policyNumber: LabelValuePair
  renewalValidateDDIAction: ({
    requestObject,
    quoteID,
  }: {
    requestObject: DirectDebitPaymentRequest
    quoteID: string
  }) => void
}

const RenewalMonthlyPayment = ({ quote, policyNumber, renewalValidateDDIAction }: Props) => {
  const gw = renewalQuote(quote)

  const selectedPaymentPlan = gw.getBindData.getSelectedPaymentPlan(Display.DISPLAY) as PaymentPlanDisplay

  const { validationSchema, initialValues } = paymentMonthlyValidation

  const handleSubmit = (values: DirectDebitPaymentForm) => {
    const paymentRequest = gw.createMonthlyPaymentRequest(values)

    renewalValidateDDIAction({
      requestObject: paymentRequest,
      quoteID: policyNumber.value,
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => handleSubmit(values),
  })

  return (
    <>
      <Layout background="white">
        <Section>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Title
                title="You have chosen to pay by monthly instalments"
                text={`__Please read the following information about your credit agreement carefully:__ \n\n - We use Premium Credit Limited (PCL) to provide finance exclusively. We may receive a commission for introducing customers to them. \n\n - Your personal information and the bank details you provide will be passed to PCL and they will contact you via email or text and send PDF or Word documents. \n\n - By proceeding, an application will be made to PCL for credit. Credit is subject to status, and is not guaranteed. \n\n - PCL may use a credit reference agency together with any information that leaves a record of the search or other information about you to carry out credit and anti-money laundering checks. \n\n - If credit is granted, PCL will contact you by email, and direct you to their online portal where you will be provided with PCL's full terms and conditions and asked to sign your credit agreement. \n\n - PCL's credit agreements are on a running account basis. If you renew your policy, you will not need to sign your agreement again. We'll continue to pass your information to Premium Credit unless you instruct us otherwise. \n\n - PCL charge the following fees for certain actions against the credit agreement: \n\n    - £25.00 for reminding you to sign your credit agreement \n\n    - As stated in your Credit Agreement, PCL will charge you £25.00 each time you miss a payment or a Direct Debit is returned from \n\n    - PCL do not apply any fees to change the monthly payment date. \n\n - If you have any questions about your instalments, contact Premium Credit on 0344 736 9836.
          `}
              />
            </Grid>

            <Grid item xs={12}>
              <InstallmentAgreement
                selectedPaymentPlan={selectedPaymentPlan}
                checkboxProps={{
                  formik,
                  name: 'agreement',
                  label: `Click to confirm that you have read the above information and are aware that by proceeding with your application for credit, a search will be made of the records held about you by a credit reference agency. This search will leave a 'footprint' on your record with that agency, and may leave a record that could be seen by other lenders.`,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Title
                title="Direct Debit Details"
                text={`__Important information about paying by Direct Debit__ \n\n To use the facility you must be 18 years old and hold a bank or building society account which supports Direct Debit. \n\n The bank details you provide on the Direct Debit Instruction below will be passed to our third party finance provider, Premium Credit Limited. Premium Credit Limited may begin collecting your Direct Debits before you sign your credit agreement to pay for any insurance cover you are receiving. \n\n You can set up your Direct Debit here in Your Portal or by calling us. All the normal Direct Debit safeguards and guarantees apply. No changes in the amount, date or frequency to be debited can be made without notifying you at least five days in advance of your account being debited. In the event of an error you are entitled to an immediate refund from your bank or building society. You have the right to cancel a Direct Debit at any time simply by writing to your bank or building society with a copy to Premium Credit.`}
                divider
              />
            </Grid>

            <Grid item xs={12}>
              <StoreCard
                title="Bank Account details"
                text={`### You have chosen a monthly payment of ${selectedPaymentPlan.installment}. Please enter the bank account details for Direct Debit installments \n\n __Important: Do you want us to store your direct debit details to allow us to automatically renew your policy when your policy ends?__ \n\n By selecting yes, you're setting up a Continuous Payment Authority. This means we'll securely save your direct debit details to use for any policy changes you make until your policy ends and automatically renew your policy, making sure you won't be left without insurance. \n\n The first of ${selectedPaymentPlan.numberOfInstallments} payments for the new policy term will be taken using the details we have on file, within 10 days of your renewal date. You can opt out of this at any time during your policy by visiting Your Portal and selecting 'My Renewal Preferences'; or by contacting our team on Live Chat or by calling us on 0333 103 7791 \n\n If you want to opt out after we've issued your renewal notice, you must do this 3 days prior to your policy expiry date or we will take payment and your policy will automatically renew. \n\n By selecting No, your direct debit details won't be saved and your policy will not automatically renew. If you don't have a motor insurance policy in force and you use your car on public roads, you run the risk of prosecution. \n\n If you want to accept our renewal offer, you'll need to do this in Your Portal or call us on 0333 103 7791.`}
                radioProps={{ name: 'continuousAuthorityGranted_itb', formik, pb: 0 } as RadioYesNoProps}
              />
            </Grid>

            <Grid item xs={12}>
              <Title
                title="We now need your Direct Debit Details"
                text={`In order to set up your Direct Debit instruction online you will need to provide the following information:
                Bank or building society name and account number, sort code and branch address. \n\n If you are not the account holder or you do not wish to set up your instruction online, payment by Direct Debit is not available. \n\n If this is a personal account, continue with the set up process. \n\n If it is a business account and more than one person is required to authorise debits on this account, please download the Direct Debit Instruction or contact us for a paper Direct Debit Instruction to be sent to the payers for completion.
              `}
              />

              <DirectDebitDetails formik={formik} />
            </Grid>
          </Grid>
        </Section>
      </Layout>

      <Layout background="#eff0f0">
        <Box pt={8} pr={2.5} pl={2.5} pb={4}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <Button variant="outlined" color="secondary" onClick={() => history.push('/renewal-documents')}>
                Go back
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={formik.submitForm}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default RenewalMonthlyPayment
