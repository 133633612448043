import { makeStyles, Typography } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import { ContentType } from 'types/contentTypes'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 400,
  },
  body: {
    '& h3': {
      fontWeight: 'normal',
      fontSize: 20,
      margin: '20px 0',
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        fontSize: 30,
      },
    },
  },
}))

export interface Props {
  content: ContentType
}

const IntroductionText = ({ content }: Props) => {
  const classes = useStyles()

  const { title, body } = content

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {title || ''}
      </Typography>
      <Typography component="div" className={classes.body}>
        <ReactMarkdown>{body || ''}</ReactMarkdown>
      </Typography>
    </>
  )
}

export default IntroductionText
