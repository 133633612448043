import { BaseData } from 'guidewire/policy/types/baseData'
import moment from 'moment'

export const baseDataToDisplay = (baseData: BaseData) => {
  const baseDataDisplay = Object.fromEntries(
    Object.keys(baseData).map((key: keyof BaseData) => {
      if (key === 'periodStartDate' || key === 'periodEndDate') return [key, moment(baseData[key]).format('DD/MM/YYYY')]
      // Not this is done because TYA GW returns Traditional Motor, however the display requirement is comprehensive
      if (key === 'productCode') return [key, 'Comprehensive']
      return [key, baseData[key]]
    }),
  )

  return baseDataDisplay
}
