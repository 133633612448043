import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { breakpoints } from '../breakPoints'

interface Props {
  '#hero': CSSProperties
  '#heroDivider': CSSProperties
  '#heroCarImage': CSSProperties
  '#heroTitle': CSSProperties
  '#heroSubtitle': CSSProperties
}

export const heroSection: Partial<Props> = {
  '#hero': {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    color: '#ffffff',
    // PaddingTop positions content away from the TopBar
    // Margin bottom adds an offset below the hero so content below it is not clipped with the hero image
    [breakpoints.up(breakpoints.values.sm)]: {
      height: '530px',
      paddingTop: '100px',
      // marginBottom: '20px',
    },
    [breakpoints.between(0, breakpoints.values.sm)]: {
      height: '430px',
      paddingTop: '85px',
      marginBottom: '20px',
    },
  },
  '#heroDivider': {
    margin: '10px 0',
    opacity: '0.2',
    backgroundColor: '#ffffff',
  },
  '#heroCarImage': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: '0 auto',
    // offset for how far the hero image extends over the bottom edge
    transform: 'translate(0, 105px)',
    [breakpoints.up(breakpoints.values.sm)]: {
      maxWidth: '60%',
    },
    [breakpoints.between(0, breakpoints.values.sm)]: {
      maxWidth: '90%',
    },
  },
  '#heroTitle': {
    fontSize: '30px',
    [breakpoints.up(breakpoints.values.sm)]: {
      fontSize: '50px',
    },
    fontWeight: 400,
    textAlign: 'left',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  '#heroSubtitle': {
    fontSize: '20px',
    fontWeight: 400,
    textAlign: 'left',
    textTransform: 'uppercase',
    [breakpoints.up(breakpoints.values.sm)]: {
      fontSize: '25px',
    },

    '& p': {
      margin: 0,
    },
  },
}
