import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'

import ReactMarkdown from 'react-markdown'

export interface Props {
  title: string
  subtitle?: string
  customerName?: string
  titleAlign?: 'left' | 'center' | 'right'
  subtitleAlign?: 'left' | 'center' | 'right'
  removeSubtitleMargin?: boolean
  divider?: boolean
  starting?: boolean
  text?: string
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: '20px',
  },
  noMargin: {
    '& p': {
      margin: 0,
    },
  },
}))

const Title = ({
  title,
  subtitle,
  customerName,
  titleAlign,
  subtitleAlign,
  removeSubtitleMargin = false,
  divider = false,
  starting = false,
  text,
}: Props) => {
  const classes = useStyles()
  return (
    <Grid container direction="column" spacing={4} className={classes.root}>
      <Grid item>
        <Typography variant="h1" align={titleAlign ? titleAlign : 'left'}>
          {starting ? (
            <>
              {customerName && <span id="emphasis">{`${customerName} -`}</span>} {title}
            </>
          ) : (
            <>
              {title} {customerName && <span id="emphasis">{` ${customerName}`}</span>}
            </>
          )}
        </Typography>
      </Grid>

      {subtitle && (
        <Grid item>
          <Typography className={classes.subtitle} component="div" align={subtitleAlign ? subtitleAlign : 'left'}>
            <ReactMarkdown linkTarget="_blank" className={removeSubtitleMargin ? classes.noMargin : undefined}>
              {subtitle}
            </ReactMarkdown>
          </Typography>
        </Grid>
      )}

      {text && (
        <Grid item>
          <ReactMarkdown>{text}</ReactMarkdown>
        </Grid>
      )}

      {divider && (
        <Grid item>
          <Divider variant="fullWidth" />
        </Grid>
      )}
    </Grid>
  )
}

export default Title
