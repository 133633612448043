import { connect } from 'react-redux'

import {
  updateContactDetailsAction,
  sendChangePasswordAction,
  updateCorrespondenceAddressAction,
  getAccountInformationAction,
  getAddressByIdAction,
  getAddressByPostalCodeAction,
  updateMarketingPreferencesAction,
  updateCommunicationPreferenceAction,
} from 'redux/account/actions'
import { policySelectAction } from 'redux/policy/actions'

import { IReduxState } from 'types/stateTypes'
import MyAccountPage from './MyAccountPage'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  currentPolicyIndex: state.policy.currentPolicyIndex,
  correspondenceAddress: state.account.correspondenceAddress,
  lookupAddresses: state.account.lookupAddresses,
  selectedAddress: state.account.selectedAddress,
  communicationPreference: state.account.documentationPreference, // GW accepts value in documentationPreference field however TYA portal takes value from Comm Preference field
  marketingPreference: state.account.marketingPreference,
})

const mapDispatchToProps = {
  sendChangePassword: sendChangePasswordAction.start,
  updateContactDetailsAction: updateContactDetailsAction.start,
  policySelectAction: policySelectAction.start,
  updateCorrespondenceAddress: updateCorrespondenceAddressAction.start,
  getAccountInformationAction: getAccountInformationAction.start,
  getAddressByPostalCodeAction: getAddressByPostalCodeAction.start,
  getAddressByIdAction: getAddressByIdAction.start,
  editMarketingPreferences: updateMarketingPreferencesAction.start,
  updateCommunicationPreferenceAction: updateCommunicationPreferenceAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage)
