import { costObjectToString } from 'guidewire/format/costObjectToString'
import { paymentPlanToDisplay } from 'guidewire/format/paymentPlanToDisplay'
import { LabelValuePair } from 'types/generalTypes'
import { Display } from '../enums/display'
import { BindData } from '../types/renewalQuote'

export const bindDataUtils = (bindData: BindData) => {
  const { selectedPaymentPlan, paymentPlans, sagePayURL, billingAddress, paymentDetails } = bindData
  const { billingId } = selectedPaymentPlan

  const details = () => bindData

  const getPaymentPlans = () => paymentPlans

  const getPlanCosts = () => {
    let annual = ''
    let installment = ''

    paymentPlans.map(item =>
      item.billingId === 'bc:5'
        ? (annual = costObjectToString(item.totalPremiumRPT))
        : (installment = costObjectToString(item.installment)),
    )

    return {
      annual,
      installment,
    }
  }

  const getSelectedPaymentPlan = (display?: Display.DISPLAY) => {
    if (display) {
      return paymentPlanToDisplay(selectedPaymentPlan)
    }

    return selectedPaymentPlan
  }

  const getSelectedBillingId = () => billingId

  const getRepresentativeExample = () => {
    const plan = getSelectedPaymentPlan(Display.DISPLAY)

    return [
      { label: 'Annual Premium', value: plan.totalPremiumRPT },
      { label: 'Amount of credit', value: plan.creditAmount_itb },
      { label: 'Interest rate fixed', value: plan.interestRate_itb },
      { label: 'Transaction fee', value: plan.fees_itb },
      { label: '11 monthly payments of', value: plan.installment },
      { label: 'Total amount payable', value: plan.total },
      { label: 'Representative APR', value: plan.aprPercentage_itb },
    ] as LabelValuePair[]
  }

  const getSagePayURL = () => sagePayURL

  const getBillingAddress = () => billingAddress

  const getDirectDebitDetails = () => paymentDetails

  return {
    details,
    getPaymentPlans,
    getPlanCosts,
    getSelectedPaymentPlan,
    getSelectedBillingId,
    getRepresentativeExample,
    getSagePayURL,
    getBillingAddress,
    getDirectDebitDetails,
  }
}
