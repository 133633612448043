import { Checkbox, FormControlLabel, Grid, makeStyles, Paper, Radio, RadioGroup } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'

import Question from '../Question'

const useStyles = makeStyles(theme => ({
  field: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      width: '100%',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
    backgroundColor: theme.palette.background.default,
    color: `${theme.palette.text.primary}80`,
    borderRadius: 4,
    padding: '14px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    minHeight: 50,

    '& p': {
      margin: 0,
    },
  },
  check: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      width: '100%',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
  },
}))

export interface Props {
  question: string
  value: any
  type?: 'default' | 'radio'
  hint?: string
  toolTip?: string
  checkboxLabel?: string
  onCheck?: () => void
}

const QuestionReadOnlyField = ({ question, value, type = 'default', hint, toolTip, checkboxLabel, onCheck }: Props) => {
  const classes = useStyles()

  return (
    <Question question={question} hint={hint} toolTip={toolTip}>
      {type === 'default' && (
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.field} elevation={0}>
              <ReactMarkdown linkTarget="_blank">{value}</ReactMarkdown>
            </Paper>
          </Grid>
          {checkboxLabel && onCheck && (
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox name="confirm" onChange={onCheck} />}
                label={checkboxLabel}
                className={classes.check}
              />
            </Grid>
          )}
        </Grid>
      )}
      {type === 'radio' && (
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.field} elevation={0}>
              <RadioGroup value={value}>
                <FormControlLabel disabled value={value} control={<Radio />} label={value} />
              </RadioGroup>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Question>
  )
}

export default QuestionReadOnlyField
