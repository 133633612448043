import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 640, // mobile
    md: 1024, // tablet
    lg: 1440, // desktop
    xl: 1920,
  },
})
