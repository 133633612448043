import ReactMarkdown from 'react-markdown'
import SVG from 'react-inlinesvg'

import { Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ContentType } from 'types/contentTypes'

const useStyles = makeStyles(theme => ({
  topText: {
    [theme.breakpoints.between(0, 'sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 25,
    },
  },
  premiumText: {
    margin: '10px 0',
    [theme.breakpoints.between(0, 'sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
  },
  icon: {
    width: '100%',
  },
}))

export interface Props {
  content: ContentType
  annualPremium: string
  pt?: number
  pb?: number
}

const YourPremiumSection = ({ content, annualPremium, pt, pb }: Props) => {
  const classes = useStyles()
  const { title, subtitle, body, iconImage } = content

  return (
    <Box pb={pb !== undefined ? pb : 5} pt={pt !== undefined ? pt : 5}>
      <Grid container spacing={3} justify="space-between" alignItems="flex-end">
        <Grid item xs={12} sm={7}>
          <Typography component="div" className={classes.topText}>
            {title}
          </Typography>

          <Typography component="div" className={classes.premiumText}>
            {subtitle}
            <span>{annualPremium}</span>
          </Typography>

          <Typography component="div">
            <ReactMarkdown>{body || ''}</ReactMarkdown>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          {iconImage?.includes('.svg') ? (
            <SVG className={classes.icon} src={`https:${iconImage}`} />
          ) : (
            <img className={classes.icon} src={iconImage} />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default YourPremiumSection
