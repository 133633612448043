import { Box, Button, Collapse, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import ReadOnlyPasswordField from 'components/ui/ReadOnlyPasswordField'
import { Field } from 'formik'
import QuestionWithPasswordField from 'components/ui/QuestionWithPasswordField'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
}))

export interface Props {
  edit: boolean
  setEdit: (x: boolean) => void
  onSaveChanges: () => void
  onCancelChanges: () => void
}

const PasswordSection = ({ edit, setEdit, onSaveChanges, onCancelChanges }: Props) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={4}>
        {edit === false && (
          <Grid item xs={12} sm={4}>
            <Typography className={classes.readOnlyLabel}>Password</Typography>
            <ReadOnlyPasswordField value="xxxxxx" />
          </Grid>
        )}
      </Grid>

      <Collapse in={edit}>
        <Box pb={4} />
        <Grid container spacing={4}>
          <React.Fragment>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.label}>{`Current password`}</Typography>
              <Field
                fullWidth
                variant="outlined"
                name="password"
                type="password"
                component={QuestionWithPasswordField}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.label}>
                {`New password`}
                <Tooltip
                  title={
                    'Password must be at least 8 characters, contain at least 1 digit, 1 uppercase and 1 lowercase character.'
                  }
                  placement="top"
                >
                  <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                </Tooltip>
              </Typography>
              <Field
                fullWidth
                variant="outlined"
                name="passwordNew"
                type="password"
                component={QuestionWithPasswordField}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.label}>{`Confirm new password`}</Typography>
              <Field
                fullWidth
                variant="outlined"
                name="passwordConfirm"
                type="password"
                component={QuestionWithPasswordField}
              />
            </Grid>
          </React.Fragment>
        </Grid>
      </Collapse>

      <Box pb={4} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            {edit && (
              <>
                <Button
                  style={{ marginRight: 10 }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setEdit(false)
                    onCancelChanges()
                  }}
                >
                  Cancel
                </Button>
                <Button variant="outlined" color="primary" onClick={() => onSaveChanges()}>
                  Save changes
                </Button>
              </>
            )}
            {edit === false && (
              <Button variant="outlined" color="secondary" onClick={() => setEdit(true)}>
                Make a change
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PasswordSection
