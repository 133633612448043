import { connect } from 'react-redux'
import { cancelMTAChangesAction, continueAddDriverAction } from 'redux/mta/actions'
import { IReduxState } from 'types/stateTypes'
import MTAAddDriver from './MTAAddDriver'

const mapStateToProps = (state: IReduxState) => ({
  policyId: state.policy.current.policyNumber,
  addedDrivers: state.mta.addedDrivers,
  startDate: state.mta.startDate,
  current: state.policy.current,
  jobId: state.mta.jobId,
})

const mapDispatchToProps = {
  continueAddDriverAction: continueAddDriverAction.start,
  cancelMTAChangesAction: cancelMTAChangesAction.start,
  // TODO: Make sure the convictions codes API is working
  // getConvictionCodesAction: getConvictionCodesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MTAAddDriver)
