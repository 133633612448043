import { capitalizeString } from 'guidewire/format/capitalizeString'
import { MyExcessPayment } from '../types/myExcessPayment'

export const myExcessPaymentUtils = (myExcessPayment: MyExcessPayment) => {
  const details = () => myExcessPayment

  const getGlassRepairExcess = () => myExcessPayment.glassRepairExcess

  const getGlassReplacementExcess = () => myExcessPayment.glassReplacementExcess

  const getExcessByDriverDisplayname = (displayName: string) => {
    return myExcessPayment.damageExcesses.find(item => item.driver.toUpperCase() === displayName.toUpperCase())
  }

  const getVoluntary = (withCurrency?: 'withCurrency') => {
    const total = myExcessPayment.damageExcesses[0].voluntaryExcess

    if (withCurrency) return `£${total.toFixed(2)}`

    return total
  }

  const getTotalExcess = (withCurrency?: 'withCurrency') => {
    const total = myExcessPayment.damageExcesses[0].totalExcess

    if (withCurrency) return `£${total.toFixed(2)}`

    return total
  }

  const getMyExcessDriversTable = () => {
    const { glassRepairExcess, glassReplacementExcess } = myExcessPayment
    const currencySymbol = glassRepairExcess.charAt(0)

    const damageExcesses = myExcessPayment.damageExcesses.map(item => {
      return Object.fromEntries(
        Object.keys(item).map((key: string) => {
          if (key !== 'driver') return [key, `${currencySymbol}${item[key]}`]
          return [key, capitalizeString(item[key])]
        }),
      )
    })

    return {
      damageExcesses,
      glassRepairExcess,
      glassReplacementExcess,
    }
  }

  return {
    details,
    getGlassRepairExcess,
    getGlassReplacementExcess,
    getExcessByDriverDisplayname,
    getVoluntary,
    getTotalExcess,
    getMyExcessDriversTable,
  }
}
