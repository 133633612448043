import { Box, Button, Collapse, Grid, makeStyles, Typography } from '@material-ui/core'
import QuestionWithSelector from 'components/ui/QuestionWithSelector'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'
import ReadOnlyField from 'components/ui/ReadOnlyField'
import { Field, Form, Formik, FormikProps } from 'formik'
import React from 'react'

import { LabelValuePair } from 'types/generalTypes'
import { PrimaryAddress } from 'types/policy'
import { AddressById, LookupAddressType } from 'types/responses'
import validationSchema, { initialValues } from './validationSchema'
import { addressSuggestionsOptions } from 'guidewire/formatTools'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
}))

export interface Props {
  screenPadding: string
  correspondenceAddress: PrimaryAddress
  lookupAddresses: LookupAddressType[]
  selectedAddress: AddressById
  getAddressByPostalCodeAction: (postalCode: string) => void
  getAddressByIdAction: (value: string) => void
  submitCorrespondenceAddress: (selectedAddress: any) => void
}

const CorrespondenceSection = ({
  screenPadding,
  correspondenceAddress,
  lookupAddresses,
  selectedAddress,
  getAddressByPostalCodeAction,
  getAddressByIdAction,
  submitCorrespondenceAddress,
}: Props) => {
  const classes = useStyles()
  const formRef = React.useRef<FormikProps<any>>(null)

  const addressSuggestions = addressSuggestionsOptions(lookupAddresses)

  const handleSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      setEdit(false)
      submitCorrespondenceAddress(selectedAddress)
    }
  }

  const selectAddress = (value: any) => {
    getAddressByIdAction(value)
  }

  const findAddress = () => {
    const postalCode = formRef.current?.values['postCodeLookup']

    getAddressByPostalCodeAction(postalCode)
  }

  const [edit, setEdit] = React.useState(false)

  const readOnlyFields: LabelValuePair[] = [
    { label: 'Address line 1', value: 'addressLine1' },
    { label: 'Town / City', value: 'city' },
    { label: 'Postcode', value: 'postalCode' },
  ]

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={async values => handleSubmit(values)}
        validationSchema={validationSchema()}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Box pt={2.5} className={screenPadding}>
              <Grid container spacing={4}>
                {readOnlyFields.map(item => (
                  <Grid item xs={12} sm={4} key={item.label}>
                    <Typography className={classes.readOnlyLabel}>{item.label}</Typography>
                    <ReadOnlyField value={correspondenceAddress[item.value]} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Collapse in={edit}>
              <Box pt={4}>
                <Typography>New address</Typography>
              </Box>

              <Box pt={2.5} pb={4} className={screenPadding}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={QuestionWithTextField}
                      name="postCodeLookup"
                      question={''}
                      inputStyle="uppercase"
                      onChange={() => formRef.current?.setFieldValue('startedLookup', false)}
                      lookupError={''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Button variant="outlined" color="primary" onClick={findAddress}>
                      Find my address
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {formRef.current?.values['postCodeLookup'] !== '' && addressSuggestions.length > 0 && (
                      <Field
                        component={QuestionWithSelector}
                        name="addressSelect"
                        options={addressSuggestions}
                        placeholderText={`${addressSuggestions.length} address(s) found`}
                        onSelect={(value: string) => {
                          selectAddress(value)
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Collapse>

            {/* On renewals this needs to be hidden for now. The component is still used on the "/my-account" page */}
            {window.location.pathname === '/my-account' && (
              <Box pt={2.5} className={screenPadding}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box pb={2} />
                    <Grid container justify="flex-end">
                      {edit && (
                        <>
                          <Button
                            style={{ marginRight: 10 }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => setEdit(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              const submit = async () => await props.submitForm()
                              submit()
                            }}
                          >
                            Confirm address
                          </Button>
                        </>
                      )}
                      {edit === false && (
                        <Button variant="outlined" color="secondary" onClick={() => setEdit(true)}>
                          Make a change
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CorrespondenceSection
