import { ReactComponent as DrivingAbroadLogo } from 'assets/drivingAbroad.svg'
import { ReactComponent as BreakdownLogo } from 'assets/breakdownCover.svg'
import { ReactComponent as LegalExpensesLogo } from 'assets/legalExpenses.svg'
import { ReactComponent as CourtesyCarLogo } from 'assets/enhancedCourtesyCar.svg'
import { ReactComponent as KeyProtectLogo } from 'assets/keyProtect.svg'
import { ReactComponent as TrailerLogo } from 'assets/trailerCover.svg'

export interface Props {
  addons?: any
}

const getLogo = (id: string) => {
  switch (id) {
    case 'PMDrivingAbroadCov':
      return <DrivingAbroadLogo height={35} />
    case 'TMBreakdownCov':
      return <BreakdownLogo height={35} />
    case 'PMLegalExpensesCov':
      return <LegalExpensesLogo height={35} />
    case 'TMEnhancedCourtesyCarCov':
      return <CourtesyCarLogo height={35} />
    case 'TMKeyProtectCov':
      return <KeyProtectLogo height={35} />
    case 'PMTrailerCov':
      return <TrailerLogo height={35} />

    default:
      return <></>
  }
}

const AddonsMyPolicy = ({ addons }: Props) => {
  return (
    <div>
      {addons.map((item: any) => (
        <div
          key={item.id}
          style={{
            display: 'flex',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            padding: 16,
            alignItems: 'center',
          }}
        >
          <div style={{ flexBasis: '15%', marginRight: 16 }}>{getLogo(item.id)}</div>
          <div style={{ flexBasis: '40%', marginRight: 16 }}>{item.label}</div>
          <div style={{ marginLeft: 'auto', textAlign: 'right' }}>{item.value}</div>
        </div>
      ))}
    </div>
  )
}

export default AddonsMyPolicy
