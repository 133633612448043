import { handleActions } from 'redux-actions'
import { setLiveChatAction } from './actions'

const defaultState = {
  isOpen: false,
}

export default handleActions(
  {
    [setLiveChatAction.success]: (state, { payload }: { payload: boolean }) => {
      return {
        ...state,
        isOpen: payload,
      }
    },
  },
  defaultState,
)
