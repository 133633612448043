import { typeKeyTools } from 'guidewire/typeKeyTools'
import { ContentDataType, ContentItemGroupType, ContentItemType, ContentSectionButtonType } from 'types'

import { yesOrNo, ncdYears } from 'utils/content/options'

const mapGroupValuesToTypelistKeyValues = (contentItemGroup: ContentItemGroupType) => {
  const { id, label, typelist, additionalText, hint } = contentItemGroup
  const optionsKey = `${contentItemGroup.id}Options`
  const captionKey = `${contentItemGroup.id}Caption`
  const toolTipKey = `${contentItemGroup.id}ToolTip`

  const getOptions = (optionType: string) => {
    if (optionType === 'YesOrNoOptions') return yesOrNo
    if (optionType === 'ncdYearsOptions') return ncdYears
    if (optionType === 'placeholderOptions') return []

    // This field in the design combines 2 fields for GW
    // 'hasBeenModified' : boolean
    // 'modification' : string value from typekey options
    if (optionType === 'VehicleModificationType_itb') {
      const typekeyOptions = typeKeyTools().getDropDownOptions(optionType)
      return [{ label: 'No modifications', value: 'noModifications' }, ...typekeyOptions]
    }

    return typeKeyTools().getDropDownOptions(optionType)
  }

  return {
    [id]: label,
    ...(typelist && {
      [optionsKey]: getOptions(typelist),
    }),
    ...(additionalText && { [captionKey]: additionalText }),
    ...(hint && { [toolTipKey]: hint }),
  }
}

const mapGroupValues = (groups: ContentItemGroupType[]) => {
  let mappedGroupValues = {}
  groups.map((item: ContentItemGroupType) => {
    const typeListKeyValues = mapGroupValuesToTypelistKeyValues(item)
    mappedGroupValues = { ...mappedGroupValues, ...typeListKeyValues }
  })
  return mappedGroupValues
}

const mapButtonValues = (buttons: ContentSectionButtonType[]) => {
  let mappedButtonValues = {}
  buttons.map((buttonContent: ContentSectionButtonType) => {
    const buttonContentObj = { [buttonContent.id]: buttonContent.title }
    mappedButtonValues = { ...mappedButtonValues, ...buttonContentObj }
  })
  return mappedButtonValues
}

export const formContentItem = (contentItem: ContentItemType): ContentDataType => {
  const { title, subtitle, body, group, id, buttons } = contentItem

  return {
    id,
    title,
    subtitle,
    body,
    ...(!!group && mapGroupValues(group)),
    ...(!!buttons && mapButtonValues(buttons)),
  }
}
