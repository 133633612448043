import { Button } from '@material-ui/core'

import ReactMarkdown from 'react-markdown'

export interface Props {
  onBackToSignIn: VoidFunction
}

const CheckEmail = ({ onBackToSignIn }: Props) => {
  return (
    <>
      <h2>{'Check your email'}</h2>
      <ReactMarkdown>{`An email has been sent to you with a link to reset your password. The link will remain active for 30 minutes.\n\nIf you don't receive the email within 15 minutes, please return to the password reset page and re-enter the correct email address.`}</ReactMarkdown>
      <Button variant="outlined" color="secondary" onClick={() => onBackToSignIn()}>
        {'Back to Sign in'}
      </Button>
    </>
  )
}

export default CheckEmail
