import ReactMarkdown from 'react-markdown'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  speechBubbleRight: {
    color: 'white',
    width: '90%',
    borderRadius: 50,
    backgroundColor: '#6a6b70',
    padding: 10,
    position: 'relative',
    height: 'fit-content',

    '& p, strong': {
      fontSize: 12,
    },
    '& stong': {
      marginBottom: 0,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: 'calc(100% / 2.35)',
      borderStyle: 'solid',
      borderWidth: '10px 0 10px 25px',
      right: '-25px',
      borderColor: 'transparent #6a6b70',
      width: 0,
      zIndex: 1,
    },
  },
  speechBubbleLeft: {
    color: 'white',
    width: '90%',
    borderRadius: 50,
    backgroundColor: '#6a6b70',
    padding: 10,
    position: 'relative',
    height: 'fit-content',

    '& p, strong': {
      fontSize: 12,
    },
    '& stong': {
      marginBottom: 0,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: 'calc(100% / 2.35)',
      borderStyle: 'solid',
      borderWidth: '10px 25px 10px 0px',
      left: '-25px',
      borderColor: 'transparent #6a6b70',
      width: 0,
      zIndex: 1,
    },
  },
}))

export interface Props {
  body: string
  leftOrRight?: 'left' | 'right'
}

const SpeechBubble = ({ body, leftOrRight = 'right' }: Props) => {
  const classes = useStyles()
  return (
    <div className={leftOrRight === 'right' ? classes.speechBubbleRight : classes.speechBubbleLeft}>
      <Typography component="div" align="center">
        <ReactMarkdown linkTarget="_blank">{body}</ReactMarkdown>
      </Typography>
    </div>
  )
}

export default SpeechBubble
