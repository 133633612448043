import { handleActions } from 'redux-actions'
import { setPolicyDocumentsAction } from './actions'

const defaultState = {
  period: [],
}

export default handleActions(
  {
    [setPolicyDocumentsAction.success]: (state, { payload }: any) => {
      return {
        ...state,
        period: payload.period,
      }
    },
  },
  defaultState,
)
