import ReactMarkdown from 'react-markdown'

import { Box } from '@material-ui/core'

import FieldUI from 'components/formUI/FieldUI'
import ThreeColumnContainer from 'components/formUI/ThreeColumnContainer'
import Title from 'components/ui/Title'
import { FormikProps } from 'formik'

export interface BillingDetailsLabels {
  cardHolderFirstname?: string
  cardHolderSurname?: string
  cardHolderEmailAddress?: string
  cardHolderPhoneNumber?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  county?: string
  postalCode?: string
}

interface BillingDetailsProps {
  cardNotRegisteredFields?: boolean
  title?: string
  labelsOverride?: BillingDetailsLabels
  formik?: FormikProps<any>
}

const defaultLabels: BillingDetailsLabels = {
  cardHolderFirstname: 'First name',
  cardHolderSurname: 'Surname',
  cardHolderEmailAddress: 'Email address',
  cardHolderPhoneNumber: 'Phone number',
  addressLine1: 'Billing address line 1',
  addressLine2: 'Billing address line 2',
  city: 'Billing city',
  county: 'Billing county',
  postalCode: 'Billing postcode',
}

const BillingDetails = ({
  cardNotRegisteredFields = false,
  title = '',
  labelsOverride,
  formik,
}: BillingDetailsProps) => {
  const labels: BillingDetailsLabels = {
    ...defaultLabels,
    ...labelsOverride,
  }

  return (
    <div>
      {title !== '' && (
        <>
          <Title title={title} />
          <Box pb={4} />
        </>
      )}

      {cardNotRegisteredFields && (
        <>
          <ThreeColumnContainer>
            <FieldUI
              label={labels['cardHolderFirstname']}
              name="cardHolderFirstname"
              type="text-capitalize"
              formik={formik}
              asterix
            />
            <FieldUI
              label={labels['cardHolderSurname']}
              name="cardHolderSurname"
              type="text-capitalize"
              formik={formik}
              asterix
            />
            <FieldUI label={labels['cardHolderEmailAddress']} name="cardHolderEmailAddress" formik={formik} asterix />
            <FieldUI label={labels['cardHolderPhoneNumber']} name="cardHolderPhoneNumber" formik={formik} asterix />
          </ThreeColumnContainer>
          <Box pb={4} />
        </>
      )}

      <ThreeColumnContainer>
        <FieldUI label={labels['addressLine1']} name="addressLine1" type="text-capitalize" formik={formik} asterix />
        <FieldUI label={labels['addressLine2']} name="addressLine2" type="text-capitalize" formik={formik} />
        <FieldUI label={labels['city']} name="city" type="text-capitalize" formik={formik} asterix />
        <FieldUI label={labels['county']} name="county" type="text-capitalize" formik={formik} />
        <FieldUI label={labels['postalCode']} name="postalCode" type="text-uppercase" formik={formik} asterix />
      </ThreeColumnContainer>

      <ReactMarkdown>{`Please note the payment reference on your bank statement will be PC/AIOI INSURANCE`}</ReactMarkdown>
    </div>
  )
}

export default BillingDetails
