import fallbackTypekeys from 'mock/typekeys/typekeys.json'

const typekeys: any[] =
  sessionStorage.getItem('typekeys') !== null
    ? JSON.parse(sessionStorage.getItem('typekeys') as string)
    : fallbackTypekeys

export const getTypekeyValue = (valueToReplace: string, typeKeyName: string): string => {
  const typekeyObject = typekeys.find(({ typeName }) => typeName === typeKeyName)
  const gwValue = typekeyObject?.keyValuePairs.find(({ key }: any) => {
    return key.substring(key.lastIndexOf('.') + 1) === valueToReplace
  })

  if (gwValue) return gwValue.value
  return ''
}
