import ReactMarkdown from 'react-markdown'

import RadioYesNo from 'components/formUI/RadioYesNo'
import { RadioYesNoProps } from 'components/formUI/RadioYesNo/RadioYesNo'
import Title from 'components/ui/Title'

interface Props {
  title?: string
  text?: string
  radioProps?: RadioYesNoProps
}

// Asking the user if they want to store card details for automatic renewal
const StoreCard = ({ title = '', text = '', radioProps }: Props) => {
  return (
    <div>
      {title !== '' && <Title title={title} />}

      {text !== '' && <ReactMarkdown>{text}</ReactMarkdown>}

      <RadioYesNo {...radioProps} />
    </div>
  )
}

export default StoreCard
