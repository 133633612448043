import moment from 'moment'

import {
  formatDriverToDisplay,
  formatMyExcessPaymentToDisplay,
  formatPolicyHolderToDisplay,
  formatVehicleToDisplay,
  translateTypekey,
} from './formatToDisplay'
import { intToYearsString } from './formatTools'

import {
  MyCoverValues,
  SelectedPolicy,
  VehicleInfo,
  VehicleInfoDisplay,
  PolicyDocument,
  Driver,
  DriverDisplay,
} from 'types/policy'
import { Policy } from 'types/policyTypes'

interface PolicyDocumentsReturn {
  [key: string]: PolicyDocument[]
  current: PolicyDocument[]
  renewal: PolicyDocument[]
}
export const policyDocuments = (policies: Policy[], currentPolicyIndex: number): PolicyDocumentsReturn => {
  return {
    current: policies[currentPolicyIndex].periods[0].documents as PolicyDocument[],
    renewal: policies[currentPolicyIndex].periods[1]?.documents as PolicyDocument[],
  }
}

export const portalTools = (policy: SelectedPolicy) => {
  const {
    vehicleInfo,
    overnightParkingAddress,
    myExcessPayment,
    policyHolder,
    status,
    effective,
    expiration,
    coverType,
    ncdYears,
    drivers,
    policyNumber,
    includedAddOns,
  } = policy

  const policyId = () => policyNumber

  const policyHolderInfo = () => {
    const details = () => policyHolder

    const display = () => formatPolicyHolderToDisplay(details())

    const primaryAddress = () => details().primaryAddress

    const getValues = (...keys: string[]) => {
      const filtered = Object.keys(details())
        .filter(key => keys.includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: details()[key],
          }
        }, {})

      return filtered
    }

    return {
      details,
      display,
      getValues,
      primaryAddress,
    }
  }

  const allDrivers = () => {
    const details = () => drivers

    const display = () => {
      return drivers.map((driver: Driver) => {
        return formatDriverToDisplay(driver)
      }) as DriverDisplay[]
    }

    const detailsByIndex = (index: number) => details()[index]

    const mainDriver = (display?: 'display') => {
      return display
        ? (formatDriverToDisplay(details().find(item => item.mainDriver === true) as Driver) as DriverDisplay)
        : (details().find(item => item.mainDriver === true) as Driver)
    }

    const policyHolder = (display?: 'display') => {
      return display
        ? (formatDriverToDisplay(details().find(item => item.policyHolder === true) as Driver) as DriverDisplay)
        : (details().find(item => item.policyHolder === true) as Driver)
    }

    const additionalDrivers = (display?: 'display') => {
      if (display) {
        return allDrivers()
          .display()
          .filter(item => item.mainDriver === 'No') as DriverDisplay[]
      }

      return details().filter(item => item.mainDriver === false) as Driver[]
    }

    const total = () => details().length

    return {
      details,
      display,
      detailsByIndex,
      mainDriver,
      additionalDrivers,
      total,
      policyHolder,
    }
  }

  const vehicle = () => {
    const details = () => vehicleInfo

    const display = () => formatVehicleToDisplay(vehicleInfo as VehicleInfo) as VehicleInfoDisplay

    const vehicleDescription = () => {
      const { make, model, firstRegisteredYear } = details()
      return `${make} ${model} ${firstRegisteredYear}`
    }

    return {
      details,
      display,
      vehicleDescription,
    }
  }

  const overnightParking = () => {
    const details = () => overnightParkingAddress

    return {
      details,
    }
  }

  const getMyExcessPayments = () => {
    const details = () => myExcessPayment

    const display = () => formatMyExcessPaymentToDisplay(details())

    return {
      details,
      display,
    }
  }

  const getSectionValues = () => {
    const myCover = () => {
      return {
        status,
        effective: moment(effective)
          .utc()
          .format('DD / MM / YYYY'),
        expiration: moment(expiration)
          .utc()
          .format('DD / MM / YYYY'),
        ncdYears: intToYearsString(ncdYears),
        coverType: coverType,
        ncdProtection: vehicle().display().ncdProtection,
        classOfUse: translateTypekey(vehicle().display().classOfUse, 'typekey.VehicleClassOfUseType_itb'),
        keyCover: 'Included as standard',
        addOns: includedAddOns,
      } as MyCoverValues
    }

    const myCarDetails = () => {
      return vehicle().display()
    }

    const myCoverRenewal = () => {
      return {
        policyNumber,
        coverType,
        ncdYears: intToYearsString(ncdYears),
        otherDrivers: `${allDrivers().total() - 1}`,
        effective: moment(effective)
          .utc(false)
          .format('DD/MM/YYYY'),
        expiration: moment(expiration)
          .utc(false)
          .format('DD/MM/YYYY'),
        excess: getDriversTotalExcess(allDrivers().policyHolder('display').displayName),
        windscreenReplace: myExcessPayment.glassReplacementExcess,
        windscreenRepair: myExcessPayment.glassRepairExcess,
        classOfUse: translateTypekey(vehicle().display().classOfUse, 'typekey.VehicleClassOfUseType_itb'),
        keyCover: checkDefactoDateforKeys(),
      }
    }

    return {
      myCover,
      myCarDetails,
      myCoverRenewal,
    }
  }

  const checkDefactoDateforKeys = () => {
  const defactoDate = moment('20230601', 'YYYYMMDD')
  const effectiveDate = moment(effective, 'YYYYMMDD')
    if ((effectiveDate).isSameOrAfter(defactoDate)){
        return 'Included as standard'
    } else {
    return ''
    }
  }

  const getDriversTotalExcess = (name: string) => {
    const driverExcessPayments = myExcessPayment.damageExcesses.find(
      item => item.driver.toUpperCase() === name.toUpperCase(),
    )

    if (driverExcessPayments) {
      return `£${driverExcessPayments.totalExcess.toFixed(2)}`
    }

    return ''
  }

  return {
    policyHolderInfo,
    vehicle,
    overnightParking,
    getMyExcessPayments,
    getSectionValues,
    allDrivers,
    policyId,
    getDriversTotalExcess,
  }
}
