import { Cost } from 'guidewire/policy/types/cost'

export const costObjectToString = (value: Cost) => {
  try {
    const { currency, amount } = value

    const currencyCode: { [x: string]: any } = {
      gbp: '£',
    }

    return `${currencyCode[currency as string]}${amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
  } catch (error) {
    return `No price information`
  }
}
