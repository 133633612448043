import { ApplicationType } from '../enums/applicationType'
import { YesNo } from '../enums/yesNo'
import { Address } from '../types/address'
import {
  CardPaymentForm,
  CardPaymentRequest,
  DirectDebitPaymentForm,
  DirectDebitPaymentRequest,
} from '../types/cardPaymentRequest'
import { RenewalQuote } from '../types/renewalQuote'
import { accountHolderUtils } from '../utils/accountHolderUtils'
import { baseDataUtils } from '../utils/baseDataUtils'
import { bindDataUtils } from '../utils/bindDataUtils.ts'
import { driversUtils } from '../utils/driversUtils'
import { includedAddonsUtils } from '../utils/includedAddonsUtils'
import { myExcessPaymentUtils } from '../utils/myExcessPaymentUtils'
import { vehicleUtils } from '../utils/vehicleUtils'

export const renewalQuote = (quote: RenewalQuote) => {
  const {
    accountHolder,
    bindData,
    baseData,
    includedAddOns,
    vehicles,
    drivers,
    myExcessPayment,
    sessionUUID,
    quoteID,
    quoteData,
  } = quote

  const { offeredQuotes } = quoteData
  const requestQuote = offeredQuotes[0]

  /**
   * Console logs out the entire quote
   */
  const test = () => console.log(quote)

  /**
   * Get account holder information
   */
  const getAccountHolder = accountHolderUtils(accountHolder)

  const getBaseData = baseDataUtils(baseData)

  const getBindData = bindDataUtils(bindData)

  // IncludedAddOns
  const getIncludedAddons = includedAddonsUtils(includedAddOns)

  // Vehicles - Note: comes as array for brands with multi cars. TYA / ITB only allow one vehicle
  const getVehicle = vehicleUtils(vehicles[0])

  // drivers
  const getDrivers = driversUtils(drivers)

  // Excess payments
  const getMyExcessPayment = myExcessPaymentUtils(myExcessPayment)

  // createAnnualPaymentRequest & createMonthlyPaymentRequest create the final object to request payment
  const createAnnualPaymentRequest = (values: CardPaymentForm) => {
    const paymentRequest: CardPaymentRequest = {
      // brand
      applicationType: ApplicationType.TYA,
      // info taken from quote, not form details
      selectedQuote: getBindData?.getSelectedPaymentPlan().periodID as string,
      contactEmail: getBindData?.details().contactEmail as string,
      documentationPreference: getBindData?.details().documentationPreference as string,
      selectedPaymentPlan: getBindData?.getSelectedBillingId() as string,
      // values from form
      autoRenew: values.isAutomaticRenewal === YesNo.YES,
      billingAddress: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        county: values.county,
        postalCode: values.postalCode,
      },
      paymentDetails: {
        creditCardData: {
          isCardRegisteredToYou: values.isCardRegisteredToYou === YesNo.YES,
          // extra values if card not registered to you
          ...(values.isCardRegisteredToYou === YesNo.NO && {
            cardHolderFirstname: values.cardHolderFirstname,
            cardHolderSurname: values.cardHolderSurname,
            cardHolderPhoneNumber: values.cardHolderPhoneNumber,
            cardHolderEmailAddress: values.cardHolderEmailAddress,
          }),
        },
      },
    }

    return paymentRequest
  }

  const createMonthlyPaymentRequest = (values: DirectDebitPaymentForm) => {
    const paymentRequest: DirectDebitPaymentRequest = {
      applicationType: ApplicationType.TYA,
      selectedQuote: getBindData?.getSelectedPaymentPlan().periodID as string,
      contactEmail: getBindData?.details().contactEmail as string,
      autoRenew: getBindData?.details().autoRenew as boolean,
      selectedPaymentPlan: getBindData?.getSelectedBillingId() as string,
      documentationPreference: getBindData?.details().documentationPreference as string,
      continuousAuthorityGranted_itb: values.continuousAuthorityGranted_itb === YesNo.YES,
      billingAddress: getBindData?.getBillingAddress() as Address,
      paymentDetails: {
        bankAccountData: {
          bankAccountNumber: values.bankAccountNumber,
          bankAccountType: values.bankAccountType,
          accountHolderName: values.accountHolderName,
          sortCode: values.sortCode,
          isAuthorisedPayment: values.isAuthorisedPayment === YesNo.YES,
        },
      },
    }

    return paymentRequest
  }

  const createUpdateCoveragesRequest = (id: string, name?: string, code?: string) => {
    const defaultRequest = {
      // stay the same
      sessionUUID,
      quoteID,
      quote: requestQuote,
      // editable depending on addon
      coverages: [],
      voluntaryExcess: getMyExcessPayment.getVoluntary(),
      ncdProtection: getVehicle.getNcdProtection(),
    }

    const addonSelected = () => {
      let addon = undefined
      if (id.includes('Level')) {
        addon = includedAddOns.find(item => item.codeIdentifier === 'TMBreakdownCov')
      } else {
        addon = includedAddOns.find(item => item.codeIdentifier === id)
      }

      if (name !== undefined && code !== undefined && addon) {
        addon.terms[0] = { ...addon.terms[0], chosenTerm: code, chosenTermValue: name }
        addon.terms[0].updated = true
        addon.terms[0].required = true
      }

      return [{ ...addon, selected: true }]
    }

    const drivingAbroadSelected = () => {
      const addon = includedAddOns.find(item => item.codeIdentifier === id)

      if (addon) {
        //switch options
        const newChosenTermValue = addon.terms[0].chosenTermValue === '3' ? '90' : '3'

        const termOption = addon.terms[0].options.find(option => option.name === newChosenTermValue)
        if (termOption) {
          addon.terms[0].chosenTerm = termOption.code
          addon.terms[0].chosenTermValue = newChosenTermValue
        }

        addon.terms[0].updated = true
        addon.terms[0].required = true
      }

      return [{ ...addon, selected: true }]
    }

    switch (id) {
      case 'addonNcdProtection':
        return {
          ...defaultRequest,
          ncdProtection: !getVehicle.getNcdProtection(),
        }

      case 'PMLegalExpensesCov':
      case 'PMTrailerCov':
      case 'TMEnhancedCourtesyCarCov':
      case 'TMBreakdownCov':
      case 'TMKeyProtectCov':
        return {
          ...defaultRequest,
          coverages: addonSelected(),
        }

      case 'PMDrivingAbroadCov':
        return {
          ...defaultRequest,
          coverages: drivingAbroadSelected(),
        }

      default:
        break
    }
  }

  const createRemoveCoverageRequest = (id: string) => {
    const addon = includedAddOns.find(item => item.codeIdentifier === id)

    return {
      sessionUUID,
      quoteID,
      quote: requestQuote,
      coverages: addon ? [{ ...addon, selected: false }] : [],
      voluntaryExcess: getMyExcessPayment.getVoluntary(),
      ncdProtection: getVehicle.getNcdProtection(),
    }
  }

  return {
    test,
    getAccountHolder,
    getBindData,
    getBaseData,
    getIncludedAddons,
    getVehicle,
    getDrivers,
    getMyExcessPayment,
    createAnnualPaymentRequest,
    createMonthlyPaymentRequest,
    createUpdateCoveragesRequest,
    createRemoveCoverageRequest,
  }
}
