import { connect } from 'react-redux'
import { closeMTAModalAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'
import MTAModal from './MTAModal'

const mapStateToProps = (state: IReduxState) => ({
  showMTAModal: state.mta.showMTAModal,
  redirect: state.mta.redirect,
})

const mapDispatchToProps = {
  closeMTAModalAction: closeMTAModalAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MTAModal)
