import { makeStyles, TextField, Tooltip, Typography } from '@material-ui/core'
import { FieldProps, getIn } from 'formik'
import ReactMarkdown from 'react-markdown'
import InfoIcon from '@material-ui/icons/Info'

interface Props {
  name: string
  question: string
  required?: boolean
  hint?: string
  toolTip?: string
  type?: string
  lookupError?: string
  inputStyle?: 'uppercase' | 'capitalize'
  onChange?: (value: string) => void
  maxLength?: number
}

const useStyles = makeStyles(theme => ({
  inputLabel: {
    '& [type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& ::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  disabled: {
    backgroundColor: theme.palette.background.default,
    color: `${theme.palette.text.primary}80`,
  },
  toolTip: {
    marginLeft: 5,
  },
}))

const QuestionWithTextField = ({
  field,
  form,
  name,
  question,
  required = false,
  hint,
  toolTip,
  onChange,
  type = 'text',
  lookupError = '',
  inputStyle = undefined,
  maxLength = undefined,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()

  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const errorText = error && touched ? error : null

  const value = field.value || (field.value === 0 ? field.value : '')

  const submitCount = form.submitCount

  return (
    <>
      <Typography component="div" style={{ display: 'flex' }}>
        <ReactMarkdown linkTarget="_blank">{question}</ReactMarkdown>

        {toolTip && (
          <Tooltip title={toolTip} className={classes.toolTip}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        )}
      </Typography>
      <TextField
        className={classes.inputLabel}
        {...field}
        {...props}
        type={type}
        fullWidth
        name={name}
        variant="outlined"
        id={name}
        size="small"
        onChange={event => {
          const { value } = event.target
          onChange && onChange(value)
          form.setFieldValue(field.name, value, submitCount > 0)
          form.setTouched({ ...form.touched, [field.name]: true })
        }}
        value={value}
        error={!!errorText || !!lookupError}
        helperText={errorText || lookupError}
        required={required}
        //   inputProps={{ textTransform: inputStyle, maxLength: maxLength }}
        inputProps={{ style: { textTransform: inputStyle }, ...(maxLength !== undefined && { maxLength }) }}
      />
    </>
  )
}

export default QuestionWithTextField
