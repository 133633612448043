import React, { Dispatch, SetStateAction } from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'

import { Tabs } from 'components/ui/Tab'
import { TabDetails } from 'components/ui/Tab/Tabs'

export interface RenewalTabDetails {
  status: string
  price: string
}

export interface Props {
  renewalTabs: TabDetails<RenewalTabDetails>[]
  onTabSelect: Dispatch<SetStateAction<TabDetails<RenewalTabDetails>>>
  tabSelected: TabDetails<RenewalTabDetails>
}

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 100,
    paddingTop: 16,
  },
  contentBold: {
    fontSize: 20,
    fontWeight: 600,
  },
  status: {
    color: theme.palette.secondary.main,
  },
  text: {
    marginTop: 16,
  },
}))

const RenewalTabs = ({ renewalTabs, tabSelected, onTabSelect }: Props) => {
  const classes = useStyles()
  // Max amount of tabs visable is all 3.
  // In the initial renewal phase only two tabs are seen, renewal and current
  // If there is an amendement made by user, amended will also be visable
  // Renewal is the default active tab
  // Amended should be the default if there is one

  // local state

  return (
    <>
      <Tabs
        tabs={renewalTabs}
        onTabSelect={tab => {
          onTabSelect(tab)
        }}
      />

      {tabSelected.id !== 'current' && (
        <Box pr={2.5} pl={2.5} pt={4}>
          <div className={classes.content}>
            <Typography
              className={classes.contentBold}
            >{`The annual premium for your renewal is ${tabSelected.price}`}</Typography>
            <Typography>
              Status: <span className={classes.status}>{tabSelected.status}</span>
            </Typography>
          </div>

          <Typography className={classes.text}>
            This quote includes Insurance Premium Tax and is valid until your policy expires, unless you change any of
            your details.
          </Typography>
        </Box>
      )}
    </>
  )
}

export default RenewalTabs
