import ReactMarkdown from 'react-markdown'

import FieldUI from 'components/formUI/FieldUI'
import RadioYesNo from 'components/formUI/RadioYesNo'
import ThreeColumnContainer from 'components/formUI/ThreeColumnContainer'

import { FormikProps } from 'formik'

export interface DirectDebitDetailsProps {
  formik?: FormikProps<any>
}

const DirectDebitDetails = ({ formik }: DirectDebitDetailsProps) => {
  return (
    <div>
      <ReactMarkdown>{`__Instruction to your Bank or Building Society to pay by Direct Debit__`}</ReactMarkdown>
      <br />

      <ThreeColumnContainer>
        <FieldUI
          formik={formik}
          name="accountHolderName"
          label="Account holder's name"
          asterix
          type="text-capitalize"
        />
        <FieldUI formik={formik} name="bankAccountNumber" label="Account number" asterix type="account-number" />
        <FieldUI formik={formik} name="sortCode" label="Sort code" asterix type="sort-code" />
      </ThreeColumnContainer>

      <RadioYesNo
        formik={formik}
        label="Is the account you wish to use in your name, and can you confirm that it does not require someone else’s signature to authorise debits?"
        name="isAuthorisedPayment"
        pb={2}
      />
    </div>
  )
}

export default DirectDebitDetails
