import { VehicleType } from 'types/policy'
import ReadOnlyField from '../ReadOnlyField'

interface Props {
  vehicle: VehicleType
  registration: string | undefined
}

const CarDetailsFromLookupSection = ({ vehicle, registration }: Props) => {
  const {
    make,
    enhancedVehicleDescription,
    firstRegisteredYear,
    engineCapacity,
    model,
    description,
    bodyType,
    fuelType,
    transmission,
  } = vehicle

  const altEnhancedDescription =
    model + ' ' + description + 'bhp ' + bodyType + ' ' + fuelType + (transmission === 'MAN' ? ' Manual' : ' Automatic')

  return (
    <ReadOnlyField
      value={`${registration?.toUpperCase()} \n\n ${firstRegisteredYear} ${make} ${
        enhancedVehicleDescription ? enhancedVehicleDescription : altEnhancedDescription
      } ${engineCapacity} CC`}
    />
  )
}

export default CarDetailsFromLookupSection
