import { all, call, put, takeLatest } from 'redux-saga/effects'
import { setIsLoadingAction } from 'redux/loading/actions'
import { isTokenValid } from 'redux/login/client'
import { onLogout } from 'redux/login/saga'
import { quoteRenewalAction } from 'redux/renewal/actions'
import { history } from 'utils'
import { renewalAnnualGetSagepayurlAction, renewalBindPaymentAction, renewalValidateDirectDebitAction } from './actions'
import { bindPayment, getQuoteSagePayURL, getValidateDDI } from './client'

export function* onRenewalAnnualGetSagepayurlAction(action: any) {
  const {
    payload: { requestObject, quoteID },
  } = action

  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const { data, status } = yield call(() => getQuoteSagePayURL(requestObject, quoteID, access_token))

    if (status === 200) {
      yield put(quoteRenewalAction.success({ quote: data, activeStep: 3 }))

      yield put(setIsLoadingAction.success({ isLoading: false }))
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onRenewalValidateDirectDebitAction(action: any) {
  const {
    payload: { requestObject, quoteID },
  } = action

  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const { data, status } = yield call(() => getValidateDDI(requestObject, quoteID, access_token))

    if (status === 200) {
      yield put(quoteRenewalAction.success({ quote: data, activeStep: 3 }))

      yield put(setIsLoadingAction.success({ isLoading: false }))

      yield call(() => history.push('/renewal-monthly-mandate'))
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onRenewalBindPaymentAction(action: any) {
  const {
    payload: { requestObject, quoteID, redirectUrl },
  } = action

  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const { data, status } = yield call(() => bindPayment(requestObject, quoteID, access_token))

    if (status === 200) {
      yield put(quoteRenewalAction.success({ quote: data, activeStep: 4 }))

      yield put(setIsLoadingAction.success({ isLoading: false }))

      yield call(() => history.push(redirectUrl))
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(renewalAnnualGetSagepayurlAction.start, onRenewalAnnualGetSagepayurlAction),
    takeLatest(renewalValidateDirectDebitAction.start, onRenewalValidateDirectDebitAction),
    takeLatest(renewalBindPaymentAction.start, onRenewalBindPaymentAction),
  ])
}
