import { createPhasedAction } from 'redux/actionCreators'

export const { sendChangePasswordAction } = createPhasedAction('SEND_CHANGE_PASSWORD_ACTION')

export const { updateContactDetailsAction } = createPhasedAction('UPDATE_CONTACT_DETAILS_ACTION')

export const { showLoginModalAction } = createPhasedAction('SHOW_LOGIN_MODAL_ACTION')

export const { closeLoginModalAction } = createPhasedAction('CLOSE_LOGIN_MODAL_ACTION')

export const { getAddressByPostalCodeAction } = createPhasedAction('GET_ADDRESS_BY_POSTAL_CODE_ACTION')

export const { getAddressByIdAction } = createPhasedAction('GET_ADDRESS_BY_ID_ACTION')

export const { updateCorrespondenceAddressAction } = createPhasedAction('UPDATE_CORRESPONDENCE_ADDRESS_ACTION')

export const { getAccountInformationAction } = createPhasedAction('GET_ACCOUNT_INFORMATION_ACTION')

export const { updateMarketingPreferencesAction } = createPhasedAction('UPDATE_MARKETING_PREFERENCES_ACTION')

export const { updateCommunicationPreferenceAction } = createPhasedAction('UPDATE_COMMUNICATION_PREFERENCE_ACTION')

export const { updateRenewalPreferenceAction } = createPhasedAction('UPDATE_RENEWAL_PREFERENCE_ACTION')
