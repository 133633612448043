import { handleActions } from 'redux-actions'
import {
  getCarPageContentAction,
  getRegiYearsAction,
  getVehicleByRegistrationAction,
  getVehiclesAction,
  selectVehicleAction,
  setRegistrationAction,
  setSelectedVehicleAction,
  setCarPageFormValuesAction,
  setDriversAction,
  resetVehicleAction,
} from './actions'

const defaultState = {
  pageButtons: [],
  lookupAddresses: [],
  selectedVehicle: {},
  vehicleLookupError: false,
  registration: undefined,
  formValues: {},
  drivers: {},
}

export default handleActions(
  {
    [getCarPageContentAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.mappedContent) {
        return state
      }
      return {
        ...state,
        content: payload.mappedContent,
        pageButtons: payload.pageButtons,
      }
    },
    [getRegiYearsAction.success]: (state, { payload }: { payload: any }) => {
      const { regiYears } = payload
      if (!regiYears) {
        return state
      }
      return {
        ...state,
        regiYears,
      }
    },
    [getVehiclesAction.success]: (state, { payload }: { payload: any }) => {
      const { vehicles } = payload
      if (!vehicles) {
        return state
      }
      return {
        ...state,
        vehicles,
      }
    },
    [selectVehicleAction.success]: (state, { payload }: { payload: any }) => {
      const { selectedVehicle } = payload
      if (!selectedVehicle) {
        return state
      }
      return {
        ...state,
        selectedVehicle,
      }
    },
    [getVehicleByRegistrationAction.success]: (state, { payload }: { payload: any }) => {
      const { selectedVehicle } = payload
      if (!selectedVehicle) {
        return state
      }
      return {
        ...state,
        selectedVehicle,
        vehicleLookupError: false,
      }
    },
    [getVehicleByRegistrationAction.error]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        selectedVehicle: {},
        vehicleLookupError: true,
      }
    },
    [resetVehicleAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        selectedVehicle: {},
        vehicleLookupError: false,
      }
    },
    [setRegistrationAction.success]: (state, { payload }: { payload: any }) => {
      const { registration } = payload
      return {
        ...state,
        registration,
      }
    },
    [setSelectedVehicleAction.success]: (state, { payload }: { payload: any }) => {
      const { selectedVehicle } = payload
      return {
        ...state,
        selectedVehicle,
      }
    },
    [setDriversAction.success]: (state, { payload }: { payload: any }) => {
      const { drivers } = payload
      return {
        ...state,
        drivers,
      }
    },
    [setCarPageFormValuesAction.success]: (state, { payload }: { payload: any }) => {
      const { formValues } = payload
      return {
        ...state,
        formValues,
      }
    },
  },
  defaultState,
)
