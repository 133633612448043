import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'

import { EndPoint } from 'types/endpoint'

import { history } from 'utils'

const NoRenewalError = () => {
  return (
    <div style={{ marginTop: 90 }}>
      <Layout>
        <Section>
          <div>
            <h1>This policy is not currently up for renewal</h1>
            <p>
              Please select a different policy or return to{' '}
              <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => history.push(EndPoint.MY_POLICY)}>
                My Policy
              </span>
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  )
}

export default NoRenewalError
