import { OutlinedInput, InputAdornment, IconButton, makeStyles } from '@material-ui/core'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles(theme => ({
  password: {
    backgroundColor: theme.palette.background.default,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
}))

interface Props {
  value: string
}

const ReadOnlyPasswordField = ({ value }: Props) => {
  const classes = useStyles()
  return (
    <OutlinedInput
      className={classes.password}
      type="password"
      fullWidth
      value="xxxxxx"
      disabled
      endAdornment={
        <InputAdornment position="end">
          <IconButton disabled>
            <VisibilityOff />
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default ReadOnlyPasswordField
