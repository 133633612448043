import React from 'react'

const SagePaySuccess = () => {
  React.useEffect(() => {
    window?.top?.postMessage(
      JSON.stringify({
        error: false,
        message: 'success',
      }),
      '*',
    )
  }, [])

  return (
    <div>
      <div style={{ width: '100%', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1>Processing successful...</h1>
      </div>
    </div>
  )
}

export default SagePaySuccess
