import { baseDataToDisplay } from 'guidewire/format/baseDataToDisplay'
import moment from 'moment'
import { BaseData } from '../types/baseData'

export const baseDataUtils = (baseData: BaseData) => {
  const { periodStartDate } = baseData
  const details = () => baseData

  const display = () => {
    return baseDataToDisplay(baseData)
  }

  const getStartDate = (format?: string) => {
    if (format) return moment(periodStartDate).format(format)

    return periodStartDate
  }

  return {
    details,
    display,
    getStartDate,
  }
}
