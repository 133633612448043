import { createPhasedAction } from 'redux/actionCreators'

export const { amendRenewalAction } = createPhasedAction('AMEND_RENEWAL_ACTION')
export const { continueRenewalAction } = createPhasedAction('CONTINUE_RENEWAL_ACTION')
export const { addRenewalDriverAction } = createPhasedAction('ADD_RENEWAL_DRIVER_ACTION')
export const { editExistingRenewalDriverAction } = createPhasedAction('EDIT_EXISTING_RENEWAL_DRIVER_ACTION')
export const { quoteRenewalAction } = createPhasedAction('QUOTE_RENEWAL_ACTION')
export const { continueToPaymentAction } = createPhasedAction('CONTINUE_TO_PAYMENT_ACTION')
export const { addBillingDetailsAndGetSagePayUrlAction } = createPhasedAction(
  'ADD_BILLING_DETAILS_AND_GET_SAGE_PAY_URL_ACTION',
)
export const { quoteRenewalDirectBuyAction } = createPhasedAction('QUOTE_RENEWAL_DIRECT_BUY_ACTION')
export const { quoteAmendedRenewalDirectBuyAction } = createPhasedAction('QUOTE_AMENDED_RENEWAL_DIRECT_BUY_ACTION')

export const { validateDdiAction } = createPhasedAction('VALIDATE_DDI_ACTION')
export const { bindRenewalPaymentAction } = createPhasedAction('BIND_RENEWAL_PAYMENT_ACTION')
export const { ddiAction } = createPhasedAction('DDI_ACTION')

export const { setPaymentPlanAction } = createPhasedAction('SET_PAYMENT_PLAN_ACTION')
export const { updateAddonsAction } = createPhasedAction('UPDATE_ADDONS_ACTION')

export const { setVoluntaryExcessAction } = createPhasedAction('SET_VOLUNTARY_EXCESS_ACTION')

export const { updateQuoteAction } = createPhasedAction('UPDATE_QUOTE_ACTION')
