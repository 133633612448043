import React from 'react'
import { FormControl, Select, MenuItem, makeStyles, Typography } from '@material-ui/core'
import { LabelValuePair } from 'types/contentTypes'

interface Props {
  name: string
  label: string
  options: LabelValuePair[]
  fullWidth?: boolean
  onSelect: (name: string, value: string) => void
  controlledValue?: string | undefined
}

const useStyles = makeStyles(theme => ({
  fullInputLabel: {
    width: '100%',
    '& .MuiInputLabel-formControl': {
      left: `-${theme.spacing(2)}px`,
    },
    margin: 0,
  },
  inputLabel: {
    width: '100%',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      maxWidth: theme.spacing(35),
    },
    '& .MuiInputLabel-formControl': {
      left: `-${theme.spacing(2)}px`,
    },
    margin: 0,
  },
  placeholderColor: {
    color: theme.palette.text.disabled,
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}))

const QbSelector = ({ name, label, options, fullWidth = false, onSelect, controlledValue }: Props) => {
  const classes = useStyles()
  const [value, setValue] = React.useState('none')

  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <FormControl variant="outlined" className={fullWidth ? classes.fullInputLabel : classes.inputLabel} fullWidth>
        <Select
          className={value === 'none' ? classes.placeholderColor : undefined}
          defaultValue="none"
          value={controlledValue ? controlledValue : value}
          fullWidth
          id={name}
          name={name}
          onChange={event => {
            const { name, value } = event.target
            onSelect(name || '', value as string)
            setValue(value as string)
          }}
        >
          <MenuItem value="none" disabled>
            Please select an option
          </MenuItem>
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default QbSelector
