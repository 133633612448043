import { validateYupSchema } from 'formik'
import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .min(8, 'Please enter at least 8 characters')
      .matches(/^(?=.*[a-z])/, 'Must Contain at least One Lowercase Character')
      .matches(/^(?=.*[A-Z])/, 'Must Contain at least One Uppercase Character')
      .matches(/^(?=.*[0-9])/, 'Must Contain at least 1 Digit')
      .required('Please complete the field'),
    passwordRepeat: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

export const initialValues = {
  password: '',
}

export default validationSchema
