import { all, put, takeLatest } from 'redux-saga/effects'
import { setLiveChatAction } from './actions'

export function* onSetLiveChat(action: any) {
  const { payload } = action

  yield put(setLiveChatAction.success(payload))
}

export default function* rootSaga() {
  yield all([takeLatest(setLiveChatAction.start, onSetLiveChat)])
}
