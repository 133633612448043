import { Button, ButtonGroup, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import validationSchema, { initialValues } from './validationSchema'
import { Field, Form, Formik, FormikProps } from 'formik'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: '#6a6b70',
    paddingTop: 20,
    paddingBottom: 20,
  },
  formLink: {
    textDecoration: 'underline',
    paddingTop: 20,
    paddingBottom: 40,
  },
  title: {
    textAlign: 'center',
    width: '100%',
    fontSize: 25,
  },
  buttonGroup: {
    paddingTop: 20,
    display: 'flex',
    width: '100%',
  },
  backButton: {
    color: '#6a6b70',
  },
}))

export interface Props {
  onBackToSignIn: VoidFunction
  onReset: (email: string) => void
}

const ForgotPassword = ({ onBackToSignIn, onReset }: Props) => {
  const onFormSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      onReset(values.email)
    }
  }

  const classes = useStyles()

  const formRef = React.useRef<FormikProps<any>>(null)

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema()}
        validateOnBlur={false}
        isInitialValid={false}
        validateOnChange={false}
        enableReinitialize
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={4}>
              <Typography className={classes.title}>{'Forgotten password'}</Typography>
              <span className={classes.formLabel}>{'Email address'}</span>
              <Field
                required
                fullWidth
                component={QuestionWithTextField}
                name="email"
                placeholder={'Enter your email address'}
              />

              <div className={classes.buttonGroup}>
                <Button onClick={() => onBackToSignIn()} className={classes.backButton}>
                  {'Back to Sign in'}
                </Button>
                <Button
                  onClick={() => {
                    const submit = async () => await formRef?.current?.submitForm()
                    submit()
                  }}
                  style={{ marginLeft: 'auto' }}
                  variant="outlined"
                  color="primary"
                >
                  {'Reset'}
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ForgotPassword
