import { PolicyDetails } from '../types/policyDetails'
import { includedAddonsUtils } from '../utils/includedAddonsUtils'

export const policyDetails = (policy: PolicyDetails) => {
  const { includedAddOns } = policy

  const test = () => console.log(policy)

  // IncludedAddOns
  const getIncludedAddons = includedAddonsUtils(includedAddOns)

  return {
    test,
    getIncludedAddons,
  }
}
