import { useLocation } from 'react-router'
import { Box } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import { DeclineMessage } from 'components/decline'
import Section from 'components/ui/Section'

const DeclinePage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const errorId = searchParams.get('error')

  return (
    <Box mt={16}>
      <Layout>
        <Section>
          <DeclineMessage errorCode={errorId || ''} />
        </Section>
      </Layout>
    </Box>
  )
}

export default DeclinePage
