import { FormikProps } from 'formik'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'

import { capitalizeString } from 'guidewire/format/capitalizeString'

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'flex-end',
  },
}))

interface FieldUIProps {
  label?: string
  asterix?: boolean
  type?: 'text' | 'text-capitalize' | 'text-uppercase' | 'account-number' | 'checkbox-agreement' | 'sort-code'
  formik?: FormikProps<any>
  name?: string
}

const FieldUI = ({ label = '', name = '', asterix = false, type = 'text', formik }: FieldUIProps) => {
  const classes = useStyles()

  const value = formik?.values[name]
  const submitCount = formik?.submitCount || 0
  const error = formik?.errors[name]

  return (
    <div className={classes.fieldContainer}>
      <Typography>
        {label !== '' && type !== 'checkbox-agreement' && label}
        {asterix && (
          <Typography component="span" color="secondary">
            {' *'}
          </Typography>
        )}
      </Typography>

      {type === 'text' && (
        <TextField
          variant="outlined"
          type="text"
          name={name}
          value={value}
          error={error && submitCount > 0 ? true : false}
          helperText={error && submitCount > 0 ? error : undefined}
          onChange={formik?.handleChange}
        />
      )}

      {type === 'text-capitalize' && (
        <TextField
          variant="outlined"
          type="text"
          name={name}
          value={value}
          error={error && submitCount > 0 ? true : false}
          helperText={error && submitCount > 0 ? error : undefined}
          onChange={event => {
            const str = event
            str.target.value = capitalizeString(str.target.value)
            formik?.handleChange(str)
          }}
        />
      )}

      {type === 'text-uppercase' && (
        <TextField
          variant="outlined"
          type="text"
          name={name}
          value={value}
          error={error && submitCount > 0 ? true : false}
          helperText={error && submitCount > 0 ? error : undefined}
          onChange={event => {
            const str = event
            str.target.value = str.target.value.toUpperCase()
            formik?.handleChange(str)
          }}
        />
      )}

      {type === 'sort-code' && (
        <TextField
          variant="outlined"
          type="text"
          name={name}
          value={value}
          error={error && submitCount > 0 ? true : false}
          helperText={error && submitCount > 0 ? error : undefined}
          onChange={event => {
            const value = event.target.value
            const removed = value.split('-').join('')
            const parts = removed.match(/.{1,2}/g)
            const dashed = parts?.join('-') || ''
            event.target.value = dashed.replace(/[^\d-]/g, '')
            dashed.length <= 8 ? formik?.handleChange(event) : null
          }}
        />
      )}

      {type === 'account-number' && (
        <TextField
          variant="outlined"
          type="text"
          name={name}
          value={value}
          error={error && submitCount > 0 ? true : false}
          helperText={error && submitCount > 0 ? error : undefined}
          onChange={event => {
            const value = event.target.value
            event.target.value = value.replace(/[^\d]/g, '')
            formik?.handleChange(event)
          }}
        />
      )}

      {type === 'checkbox-agreement' && (
        <FormControl error={error && submitCount > 0 ? true : false}>
          <div
            style={{
              border: `1px solid ${error && submitCount > 0 ? '#ff0022' : '#d8d8d8'}`,
              padding: 16,
              borderRadius: 4,
              backgroundColor: '#eff0f0',
            }}
          >
            <FormControlLabel
              label={label}
              control={<Checkbox style={{ marginRight: 10 }} name={name} onChange={formik?.handleChange} />}
            />
          </div>

          {error && submitCount > 0 && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </div>
  )
}

export default FieldUI
