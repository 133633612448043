import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Box, Button, Grid, Typography } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import RenewalTabs from 'components/renewal/RenewalTabs'
import MyCoverDetails from 'components/myPolicy/MyCoverDetails'
import CoverList from 'components/ui/CoverList'
import AddonsMyPolicy from 'components/addons/AddonsMyPolicy'

// Old GW tools. Still fine to use but needs refactoring into the new tools below
import { portalTools } from 'guidewire/portalTools'
// New GW tools
import { policyDetails } from 'guidewire/policy/policyDetails/policyDetails'

import { SelectedPolicy } from 'types/policy'

import { myCarDetailsRenewal, myCoverRenewal } from 'mock/cms/current_policy_tab'

export interface Props {
  current: SelectedPolicy
  renewed?: SelectedPolicy
  alternateRenewed?: SelectedPolicy
  quoteRenewalDirectBuyAction: ({ policyId }: { policyId: string }) => void
  amendRenewalAction: ({ current }: { current: SelectedPolicy }) => void
  quoteAmendedRenewalDirectBuyAction: (x: any) => void
}

const MyRenewalPage = ({
  current,
  renewed,
  alternateRenewed,
  quoteAmendedRenewalDirectBuyAction,
  amendRenewalAction,
  quoteRenewalDirectBuyAction,
}: Props) => {
  if (renewed) {
    const renewalIdAndLabel = (status: string) => {
      if (status === 'In Force')
        return {
          label: 'Current policy',
          id: 'current',
        }

      if (status === 'Renewing' || status === 'Accepted')
        return {
          label: 'Your renewal',
          id: 'renewal',
        }

      if (status === 'Quoted')
        return {
          label: 'Amended renewal',
          id: 'amended',
        }

      return {
        label: '',
        id: '',
      }
    }

    const renewalTabs = () => {
      return [alternateRenewed, renewed, current]
        .filter(policy => policy !== undefined)
        .flatMap(item => {
          if (item)
            return {
              status: item.status,
              price: `£${item.premium.amount.toFixed(2)}`,
              ...renewalIdAndLabel(item.status),
            }

          return [{ id: '', status: '', price: '', label: '' }]
        })
    }

    const [tabSelected, setTabSelected] = React.useState(renewalTabs()[0])
    const [policyNumber, setPolicyNumber] = React.useState(current.policyNumber)

    const currentPolicy = portalTools(current)

    // Renewal tabs
    // Orginal renewal
    const renewedPolicy = portalTools(renewed as SelectedPolicy)
    const renewalValues: any = renewedPolicy.getSectionValues().myCoverRenewal()
    const renewalCarValues: any = renewedPolicy.getSectionValues().myCarDetails()
    // Amended renewal
    const amendedPolicy = alternateRenewed ? portalTools(alternateRenewed as SelectedPolicy) : undefined
    const amendedValues: any = amendedPolicy ? amendedPolicy.getSectionValues().myCoverRenewal() : undefined
    const amandedCarValues: any = amendedPolicy ? amendedPolicy.getSectionValues().myCarDetails() : undefined
    // both original and amanded
    const renewalPleaseNote =
      '*Please Note:* \n\n *Incorrect car usage may affect any claim you make or could result in your insurance being invalid. We do not provide cover if your car is being used for hiring or for the carriage of passengers or goods for hire or reward, racing, pacemaking, speed testing or trials, motor rallies or trails, reliability testing or trials or competitions or for diplomatic use or for emergency services and military purposes or for any use in connection with the Motor Trade other than by a member of the Motor Trade for the purposes of overhaul, upkeep or repair.*'

    // necessary to update tabs if the user selects a different policy
    // without this the tabs retain the previous policies state locally
    React.useEffect(() => {
      if (current.policyNumber !== policyNumber) {
        setPolicyNumber(current.policyNumber)
        setTabSelected(renewalTabs()[0])
      }
    }, [policyNumber, current])

    return (
      <>
        <Layout>
          <Box pt={8} />
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <Section>
                <RenewalTabs renewalTabs={renewalTabs()} tabSelected={tabSelected} onTabSelect={setTabSelected} />
              </Section>
            </Grid>

            <Grid item xs={12}>
              <Section>
                {tabSelected.id === 'current' && (
                  <MyCoverDetails
                    myCoverValues={currentPolicy.getSectionValues().myCover()}
                    myCarDetailsValues={currentPolicy.getSectionValues().myCarDetails()}
                    // no edit car button on MyRenewalPage
                    editCar={() => null}
                    showChangeCar={false}
                  />
                )}

                {tabSelected.id === 'renewal' && renewed && (
                  <>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ fontWeight: 600, marginBottom: 56 }}>My Cover</Typography>

                        <CoverList list={myCoverRenewal} values={renewalValues} type="renewalMyCover" />

                        <Typography style={{ fontWeight: 600, marginBottom: 56, marginTop: 32 }}>
                          Included add-ons
                        </Typography>

                        <AddonsMyPolicy
                          addons={policyDetails(renewed as any).getIncludedAddons.getPolicyAddonSummary()}
                        />

                        <Typography component="div" style={{ fontSize: 12, marginTop: 32 }}>
                          <ReactMarkdown>{renewalPleaseNote}</ReactMarkdown>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography style={{ fontWeight: 600, marginBottom: 56 }}>My car details</Typography>

                        <CoverList list={myCarDetailsRenewal} values={renewalCarValues} type="renealMyCar" />
                      </Grid>

                      <Grid item xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', columnGap: 16 }}>
                          {alternateRenewed === undefined && (renewed as SelectedPolicy).status !== 'Accepted' && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => amendRenewalAction({ current })}
                            >
                              Amend details
                            </Button>
                          )}

                          {alternateRenewed === undefined && (renewed as SelectedPolicy).status !== 'Accepted' && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => quoteRenewalDirectBuyAction({ policyId: current.policyNumber })}
                            >
                              Renew Policy
                            </Button>
                          )}
                          {alternateRenewed !== undefined &&
                            (alternateRenewed as SelectedPolicy).status !== 'Accepted' && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => quoteRenewalDirectBuyAction({ policyId: current.policyNumber })}
                              >
                                Renew Policy
                              </Button>
                            )}
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}

                {tabSelected.id === 'amended' && alternateRenewed && (
                  <>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <Typography style={{ fontWeight: 600, marginBottom: 56 }}>My Cover</Typography>

                        <CoverList list={myCoverRenewal} values={amendedValues} type="myCover" />

                        {/* Add this too amended renewal tab too */}
                        <Typography style={{ fontWeight: 600, marginBottom: 56, marginTop: 32 }}>
                          Included add-ons
                        </Typography>

                        {alternateRenewed && (
                          <AddonsMyPolicy
                            addons={policyDetails(alternateRenewed as any).getIncludedAddons.getPolicyAddonSummary()}
                          />
                        )}

                        <Typography component="div" style={{ fontSize: 12, marginTop: 32 }}>
                          <ReactMarkdown>{renewalPleaseNote}</ReactMarkdown>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography style={{ fontWeight: 600, marginBottom: 56 }}>My car details</Typography>

                        <CoverList list={myCarDetailsRenewal} values={amandedCarValues} type="myCar"/>
                      </Grid>

                      <Grid item xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', columnGap: 16 }}>
                          {alternateRenewed !== undefined &&
                            (alternateRenewed as SelectedPolicy).status !== 'Accepted' && (
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => amendRenewalAction({ current })}
                              >
                                Amend details
                              </Button>
                            )}

                          {alternateRenewed !== undefined &&
                            (alternateRenewed as SelectedPolicy).status !== 'Accepted' && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => quoteAmendedRenewalDirectBuyAction({ current: alternateRenewed })}
                              >
                                Renew Policy
                              </Button>
                            )}
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Section>
            </Grid>
          </Grid>
        </Layout>
      </>
    )
  } else return <></>
}

export default MyRenewalPage
