import React from 'react'
import moment from 'moment'
import { Box, Button, Divider, Grid, Grow, makeStyles } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import { Field, FieldProps, Form, Formik, FormikProps, useFormik } from 'formik'
import validationSchema, { initialValues } from './validationSchema'

import QuestionWithDatePicker from 'components/ui/QuestionWithDatePicker'
import DateAndTimePicker from 'components/ui/DateAndTimePicker'
import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns'
import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSectionHardCoded'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'
import QuestionWithSelector from 'components/ui/QuestionWithSelector'

import { isEmpty } from 'guidewire/formatTools'

import { LabelValuePair } from 'types/generalTypes'
import { SelectedPolicy, VehicleType } from 'types/policy'
import { AddressById, LookupAddressType } from 'types/responses'
import SearchRegistrationSection from 'components/ui/SearchRegistrationSection'
import CarDetailsFromLookupSection from 'components/ui/CarDetailsFromLookupSection'

const carSecurityFeaturesOptions = [
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Alarm',
    value: 'Alarm',
  },
  {
    label: 'Immobiliser',
    value: 'Immobiliser',
  },
  {
    label: 'Alarm and immobiliser',
    value: 'AlarmAndImmobiliser',
  },
]

const nightParkingOptions = [
  {
    label: 'Garaged Overnight',
    value: 'GaragedOvernight',
  },
  {
    label: 'On Drive',
    value: 'OnDrive',
  },
  {
    label: 'Public Road',
    value: 'PublicRoad',
  },
  {
    label: 'Secure Car Park',
    value: 'SecureCarPark',
  },
  {
    label: 'Unsecured Off Road Parking',
    value: 'UnsecuredOffRoadParking',
  },
]

const daytimeParkingOptions = [
  {
    label: 'Garaged At Home',
    value: 'GaragedAtHome',
  },
  {
    label: 'On Drive At Home',
    value: 'OnDriveAtHome',
  },
  {
    label: 'Public Road At Home',
    value: 'PublicRoadAtHome',
  },
  {
    label: 'Secure Car Park',
    value: 'SecureCarPark',
  },
  {
    label: 'Station Car Park',
    value: 'StationCarPark',
  },
  {
    label: 'Public Road Away From Home',
    value: 'PublicRoadAwayFromHome',
  },
  {
    label: 'Other Unsecured Car Park',
    value: 'OtherUnsecuredCarPark',
  },
  {
    label: 'Unsecured Off Road Parking',
    value: 'UnsecuredOffRoadParking',
  },
]

const vehicleOwnerOptions = [
  {
    label: 'Proposer',
    value: 'Proposer',
  },
  {
    label: 'Spouse',
    value: 'Spouse',
  },
  {
    label: 'Civil Partner',
    value: 'CivilPartner',
  },
  {
    label: 'Common Law Partner',
    value: 'CommonLawPartner',
  },
  {
    label: 'Parent/Guardian (including in-law)',
    value: 'Parent',
  },
  {
    label: 'Child (including in-law)',
    value: 'Child',
  },
  {
    label: 'Brother / Sister (including in-law)',
    value: 'BrotherSister',
  },
  {
    label: 'Grandparent',
    value: 'Grandparent',
  },
  {
    label: 'Grandchild',
    value: 'Grandchild',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Vcl lsg Co',
    value: 'VCL',
  },
]

const vehicleLeasingCompanyOptions = [
  {
    label: 'Car Benefits Solutions (CBS)',
    value: 'CBS',
  },
  {
    label: 'Toyota Manufacturing UK (TMUK)',
    value: 'TMUK',
  },
  {
    label: 'Kinto PCH (12 months plus)',
    value: 'KINTO',
  },
  {
    label: 'Privately Leased',
    value: 'PrivateLease',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

const carUsageOptions = [
  {
    label: 'Social Domestic and Pleasure only (including commuting)',
    value: 'SDAndPleasureOnly',
  },
  {
    label: 'SD and P + business use by you the policyholder and any named driver',
    value: 'SDAndPPBU',
  },
  {
    label: 'SD and P + business use, including commercial travelling, by you the policyholder and any named driver',
    value: 'SDAndPPBUICT',
  },
]

let generalAfterMarketModificationValues = [
  {
      label: 'A-Frame Towing Facility',
      value: "A-Frame Towing Facility"
    },
    {
      label: 'Air Conditioning',
      value: "Air Conditioning"
    },
    {
      label: 'Bluetooth Kit',
      value: "Bluetooth Kit"
    },
    {
      label: 'Camera in car CCTV',
      value: "Camera in car CCTV"
    },
    {
      label: 'Climate Control',
      value: "Climate Control"
    },
    {
      label: 'Cruise Control',
      value: 'Cruise Control'
    },
    {
      label: 'High Level Brake Light',
      value: 'High Level Brake Light'
    },
    {
      label: 'Modification due to disability',
      value: 'Modification due to disability'
    },
    {
      label: 'Parking Sensors/Parking Camera',
      value: 'Parking Sensors/Parking Camera'
    },
    {
      label: 'Roof Rack',
      value: 'Roof Rack'
    },
    {
      label: 'Satellite Navigation Equipment',
      value: 'Satellite Navigation Equipment'
    },
    {
      label: 'Sports Steering Wheel',
      value: 'Sports Steering Wheel'
    },
    {
      label: 'Telematics Device',
      value: 'Telematics Device'
    },
    {
      label: 'Tow Bar',
      value: 'Tow Bar'
    },
    {
      label: 'Other',
      value: 'Other'
    }
]

let bodyWorkModificationValues = [
  {
      value: 'Body Coloured Bumpers',
      label: 'Body Coloured Bumpers'
    },
    {
      label: 'Debadging',
      value: "Debadging"
    },
    {
      label: 'Decals/Stickers',
      value: "Decals/Stickers"
    },
    {
      label: 'Fog Lamps/Rally Lights/Spot Lights/Driving Lights',
      value: "Fog Lamps/Rally Lights/Spot Lights/Driving Lights"
    },
    {
      label: 'Front Spoiler/Splitter',
      value: "Front Spoiler/Splitter"
    },
    {
      label: 'Paint Protection Film',
      value: 'Paint Protection Film'
    },
    {
      label: 'Rear Spoiler/Diffuser',
      value: 'Rear Spoiler/Diffuser'
    },
    {
      label: 'Signwriting(Permanent or Removable)',
      value: 'Signwriting(Permanent or Removable)'
    },
    {
      label: 'Sunroof',
      value: 'Sunroof'
    },
    {
      label: 'Tinted Windows(Front) less than 30%',
      value: 'Tinted Windows(Front) less than 30%'
    },
    {
      label: 'Tinted Windows(Front) more than 30%',
      value: 'Tinted Windows(Front) more than 30%'
    },
    {
      label: 'Tinted Windows(Rear)',
      value: 'Tinted Windows(Rear)'
    },
    {
      label: 'Total or Partial Vinyl Wrap',
      value: 'Total or Partial Vinyl Wrap'
    },
    {
      label: 'Side Skirts',
      value: 'Side Skirts'
    },
    {
      label: 'Other',
      value: 'Other'
    }
]

let engineModificationValues = [
  {
      label: 'Biodegradable Fuel Conversion',
      value: "Biodegradable Fuel Conversion"
    },
    {
      label: 'LPG Conversion',
      value: "LPG Conversion"
    },
    {
      label: 'None Standard Air Filter/Air Box',
      value: "None Standard Air Filter/Air Box"
    },
    {
      label: 'Replacement Engine(same specification)',
      value: "Replacement Engine(same specification)"
    },
    {
      label: 'Other',
      value: "Other"
    }
]

let wheelsOrTyresModificationValues = [
  {
      label: 'Locking Wheel Nuts',
      value: "Locking Wheel Nuts"
    },
    {
      label: 'Replacement Wheels',
      value: "Replacement Wheels"
    },
    {
      label: 'Wheel Trims',
      value: "Wheel Trims"
    },
    {
      label: 'Other',
      value: "Other"
    }
]

let otherModificationValues = [
  {
      label: 'Any other modification not detailed above',
      value: "Any other modification not detailed above"
    }
]

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  bottomText: {
    marginLeft: 24,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
  questionBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectedModsContainer: {
    marginBottom: '10px'
  },
  selectedValuesMods: {
    width: 'fit-content',
    color: '#fff',
    border: '1px solid',
    borderColor: '#ff0022',
    backgroundColor: '#ff0022',
    padding: '5px 15px',
    borderRadius: '25px',
    display: 'inline-block',
    marginRight: '5px',
    marginBottom: '0px',
    cursor: 'pointer'
}
}))

export interface Props {
  current: SelectedPolicy
  selectedVehicle: VehicleType | undefined
  vehicleLookupError: boolean
  registration: string | undefined
  startDate: string
  jobId: string
  continueCarChangeAction: (value: any) => void
  cancelMTAChangesAction: VoidFunction
  getVehicleByRegistrationAction: (regi: string) => void
}

const ChangeOvernightAddressPage = ({
  current,
  selectedVehicle,
  vehicleLookupError,
  registration,
  startDate,
  jobId,
  continueCarChangeAction,
  cancelMTAChangesAction,
  getVehicleByRegistrationAction,
}: Props) => {
const classes = useStyles()
const formRef = React.useRef<FormikProps<any>>(null)
const [seed, setSeed] = React.useState(1);
const [mulitChangeSubmit, setMultiChangeSubmit] = React.useState(false)
const [generalModsArray, setGeneralModsArray] = React.useState<Array<string>>([])
const [bodyModsArray, setBodyModsArray] = React.useState<Array<string>>([])
const [engineModsArray, setEngineModsArray] = React.useState<Array<string>>([])
const [wheelsModsArray, setWheelsModsArray] = React.useState<Array<string>>([])
const [otherModsArray, setOtherModsArray] = React.useState<Array<string>>([])


  const removeHandlerGeneral = (value: string) => {
    const tempObj = {
      label: value,
      value: value
    }
    generalAfterMarketModificationValues = [...generalAfterMarketModificationValues, tempObj];

    setGeneralModsArray(generalModsArray.filter(function( obj ) {
      return obj !== value;
    }))
    formRef.current?.setFieldValue('generalAfterMarketMods', '');
    generalModsArray.length <= 1 && setGeneralModsArray([])
    setSeed(Math.random());
  }

  const removeHandlerBods = (value: string) => {
    const tempObj = {
      label: value,
      value: value
    }

    bodyWorkModificationValues = [...bodyWorkModificationValues, tempObj];

    setBodyModsArray(bodyModsArray.filter(function( obj ) {
      return obj !== value;
    }))
    formRef.current?.setFieldValue('bodyWorkMods', '');
    bodyModsArray.length <= 1 && setBodyModsArray([])
    setSeed(Math.random());
  }
  const removeHandlerEngine = (value: string) => {
    const tempObj = {
      label: value,
      value: value
    }
    engineModificationValues = [...engineModificationValues, tempObj];

    setEngineModsArray(engineModsArray.filter(function( obj ) {
      return obj !== value;
    }))
    formRef.current?.setFieldValue('engineMods', '');
    engineModsArray.length <= 1 && setEngineModsArray([])
    setSeed(Math.random());
  }
  const removeHandlerWheels = (value: string) => {
    const tempObj = {
      label: value,
      value: value
    }
    wheelsOrTyresModificationValues = [...wheelsOrTyresModificationValues, tempObj];

    setWheelsModsArray(wheelsModsArray.filter(function( obj ) {
      return obj !== value;
    }))
    formRef.current?.setFieldValue('wheelsOrTyresMods', '');
    wheelsModsArray.length <= 1 && setWheelsModsArray([])
    setSeed(Math.random());
  }
  
  const removeHandlerOther = (value: string) => {
    const tempObj = {
      label: value,
      value: value
    }
    otherModificationValues = [...otherModificationValues, tempObj];
    setOtherModsArray(otherModsArray.filter(function( obj ) {
      return obj !== value;
    }))
    formRef.current?.setFieldValue('otherMods', '');
    otherModsArray.length <= 1 && setOtherModsArray([])
    setSeed(Math.random());
  }

  const clearMods = (type: string) => {
    if (type === 'generalAfterMarketMods'){
      generalModsArray.map(obj => {
        generalAfterMarketModificationValues = [...generalAfterMarketModificationValues, {label:obj,value:obj}]
      })
      
      setGeneralModsArray([])
    }
    if (type === 'bodyWorkMods'){
      bodyModsArray.map(obj => {
        bodyWorkModificationValues = [...bodyWorkModificationValues, {label:obj,value:obj}]
      })
      setBodyModsArray([])
    }
    if (type === 'engineMods'){
      engineModsArray.map(obj => {
        engineModificationValues = [...engineModificationValues, {label:obj,value:obj}]
      })
      setEngineModsArray([])
    }
    if (type === 'wheelsOrTyresMods'){
      wheelsModsArray.map(obj => {
        wheelsOrTyresModificationValues = [...wheelsOrTyresModificationValues, {label:obj,value:obj}]
      })
      setWheelsModsArray([])
    }
    if (type === 'otherMods'){
      otherModsArray.map(obj => {
        otherModificationValues = [...otherModificationValues, {label:obj,value:obj}]
      })
      setOtherModsArray([])
    }
    formRef.current?.setFieldValue(type, '');
  }

  const handleSubmit = (values: any) => {
    values.generalAfterMarketMods = generalModsArray
    values.bodyWorkMods = bodyModsArray
    values.engineMods = engineModsArray
    values.wheelsOrTyresMods = wheelsModsArray
    values.otherMods = otherModsArray

    if (formRef?.current?.isValid) {
      //combine date and time
      const startDateValue = moment(values.startDate).utc(true)
      const startTimeValue = moment(values.startTime).utc(true)

      startDateValue.set('hours', startTimeValue.hours())
      startDateValue.set('minutes', startTimeValue.minutes())

      const {
        carPurchaseDate,
        marketValue,
        hasBeenModified,
        generalAfterMarketMods,
        bodyWorkMods,
        engineMods,
        wheelsOrTyresMods,
        conversionMods,
        otherMods,
        securityDevices,
        nightTimeParking,
        dayTimeParking,
        classOfUse,
        registeredKeeper,
        securityTracker,
        imported,
        rightHandDrive,
        lessThanEightSeats,
        vehicleIsLeased,
        vehicleLeasingCompany,
        vehicleOwner,
      } = values

      continueCarChangeAction({
        startDate: startDate.length > 0 ? startDate : startDateValue.toISOString(),
        furtherChanges: mulitChangeSubmit,
        selectedVehicle: selectedVehicle,
        carPurchaseDate,
        marketValue,
        hasBeenModified,
        generalAfterMarketMods,
        bodyWorkMods,
        engineMods,
        wheelsOrTyresMods,
        conversionMods,
        otherMods,
        securityDevices,
        nightTimeParking,
        dayTimeParking,
        classOfUse,
        registeredKeeper,
        securityTracker,
        imported,
        rightHandDrive,
        lessThanEightSeats,
        current,
        jobId,
        registrationNumber: registration,
        vehicleIsLeased:
        registeredKeeper === 'yes' ? null : vehicleIsLeased,
        vehicleLeasingCompany:
        registeredKeeper === 'yes' || vehicleIsLeased !== 'yes'
          ? null
          : vehicleLeasingCompany,
        vehicleOwner:
          registeredKeeper === 'yes' ||
          vehicleIsLeased !== 'yes' ||
          vehicleLeasingCompany === 'none' ||
          vehicleLeasingCompany === 'other'
            ? null
            : vehicleOwner,
              })
            }
          }

  const editInitialValues = initialValues
  editInitialValues.startDate = startDate
  editInitialValues.startTime = startDate

  const yesNoOptions: LabelValuePair[] = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ]
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={startDate.length > 0 ? editInitialValues : initialValues}
        onSubmit={async values => handleSubmit(values)}
        validationSchema={validationSchema()}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Layout>
              <Box pt={5} pb={5}>
                <DateAndTimePicker disabled={startDate.length > 0} formRef={formRef} minimumDate={current.effective} />
              </Box>
              <Box pt={5}>
                <Grid item xs={12}>
                  <Section title="Change your car">
                    <Box pt={2.5} pr={2.5} pl={2.5}>
                      <Box pb={5}>
                        <Field name="registrationNumber">
                          {({ field, form }: FieldProps) => (
                            <SearchRegistrationSection
                              form={form}
                              field={field}
                              onVehicleLookup={(registration: string) => {
                                getVehicleByRegistrationAction(registration.toUpperCase())
                              }}
                              vehicleLookupError={vehicleLookupError}
                            />
                          )}
                        </Field>
                      </Box>
                      {isEmpty(selectedVehicle) === false && selectedVehicle != undefined && (
                        <Grow in={!!selectedVehicle} timeout={300} unmountOnExit>
                          <Box pb={5}>
                            <CarDetailsFromLookupSection vehicle={selectedVehicle} registration={registration} />
                          </Box>
                        </Grow>
                      )}
                    </Box>
                    <Box pt={2.5} pr={2.5} pl={2.5}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} className={classes.questionBox}>
                          <Field
                            required
                            component={QuestionWithDatePicker}
                            name="carPurchaseDate"
                            question={'When did you buy the car?'}
                            hint={''}
                            toolTip={''}
                            format="MM / yyyy"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithTextField}
                            name="marketValue"
                            type="number"
                            question={'What is the current market value of the car (£)? '}
                            hint={''}
                            toolTip={
                              'This is the cost or replacement with one of the same make, model and specification, considering the age, mileage and condition.                            '
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithRadioBtns}
                            row
                            name="hasBeenModified"
                            question={'Has the car been modified or altered in any way, other than:'}
                            longHintText={true}
                            bullet1={'Any genuine manufacturer approved options or accessories'}
                            bullet2={'Manufacturer approved “Special Editions”'}
                            bullet3={'Any adaptation to accommodate the use of the vehicle by a disabled person'}
                            
                            options={yesNoOptions}
                            
                            onSelect={(value: string) => {
                              if (value === 'no'){
                                setGeneralModsArray([])
                                setBodyModsArray([])
                                setEngineModsArray([])
                                setWheelsModsArray([])
                                setOtherModsArray([])
                              }
                            }}
                          />
                        </Grid>
                        </Grid>
                      </Box>
                      {props.values['hasBeenModified'] === 'yes' && (
                    <Box pt={2.5} pr={2.5} pl={2.5}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={8}>
                      <Field
                        component={QuestionWithSelector}
                        name="generalAfterMarketMods"
                        question={'General aftermarket accessories'}
                        hint={''}
                        toolTip={''}
                        options={generalAfterMarketModificationValues}
                        onSelect={(value: any) => {
                          setGeneralModsArray((arry) => [...arry, value ])
                          generalAfterMarketModificationValues = generalAfterMarketModificationValues.filter(function( obj ) {
                            return obj.value !== value;
                          });
                        }}
                      />
                        
                        
                      <div className={classes.selectedModsContainer}>
                        {generalModsArray.length > 0 ?
                          generalModsArray.map(obj => (
                          <>
                            {obj !== '' ? <p className={classes.selectedValuesMods}>{obj} <span onClick={() => removeHandlerGeneral(obj)}> X</span></p> :<></>}
                          </>
                          ))
                        : <></>}
                      </div>
                      {generalModsArray.length > 0 ? 
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => clearMods('generalAfterMarketMods')}
                        >
                      Clear all
                      </Button> : <></>}

                     
                      
                      <Field
                        component={QuestionWithSelector}
                        name="bodyWorkMods"
                        question={'Bodywork Modifications'}
                        placeholderText={bodyModsArray.length !== 0 ? 'Add more?' : 'Please select an option'}
                        hint={''}
                        toolTip={''}
                        options={bodyWorkModificationValues}
                        onSelect={(value: any) => {
                          setBodyModsArray((arry) => [...arry, value ])
                          bodyWorkModificationValues = bodyWorkModificationValues.filter(function( obj ) {
                            return obj.value !== value;
                          });
                        }}
                      />
                      <div className={classes.selectedModsContainer}>
                        {bodyModsArray.length > 0 ?
                          bodyModsArray.map(obj => (
                            
                          <>
                            {obj !== '' ? <p className={classes.selectedValuesMods}>{obj} <span onClick={() => removeHandlerBods(obj)}> X</span></p> :<></>}
                          </>
                          ))
                        : <></>}
                      </div>

                      {bodyModsArray.length > 0 ? 
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => clearMods('bodyWorkMods')}
                        >
                      Clear all
                      </Button> : <></>}

                      
                     
                        <Field
                          component={QuestionWithSelector}
                          name="engineMods"
                          question={'Engine modifications'}
                          placeholderText={engineModsArray.length !== 0 ? 'Add more?' : 'Please select an option'}
                          hint={''}
                          toolTip={''}
                          options={engineModificationValues}
                          onSelect={(value: any) => {
                            setEngineModsArray((arry) => [...arry, value ])
                            engineModificationValues = engineModificationValues.filter(function( obj ) {
                              return obj.value !== value;
                            });
                          }}
                        />
                        <div className={classes.selectedModsContainer}>
                          {engineModsArray.length > 0 ?
                          engineModsArray.map(obj => (
                            
                          <>
                            {obj !== '' ? <p className={classes.selectedValuesMods}>{obj} <span onClick={() => removeHandlerEngine(obj)}> X</span></p> :<></>}
                          </>
                          ))
                        : <></>}
                        </div>
                        
                        {engineModsArray.length > 0 ? 
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => clearMods('engineMods')}
                          >
                        Clear all
                        </Button> : <></>}

                        <Field
                          component={QuestionWithSelector}
                          name="wheelsOrTyresMods"
                          question={'Wheels or Tyre Modifications'}
                          placeholderText={wheelsModsArray.length !== 0 ? 'Add more?' : 'Please select an option'}
                          hint={''}
                          toolTip={''}
                          options={wheelsOrTyresModificationValues}
                          onSelect={(value: any) => {
                            setWheelsModsArray((arry) => [...arry, value ])
                            wheelsOrTyresModificationValues = wheelsOrTyresModificationValues.filter(function( obj ) {
                              return obj.value !== value;
                            });
                          }}
                        />
                        <div className={classes.selectedModsContainer}>
                          {wheelsModsArray.length > 0 ?
                          wheelsModsArray.map(obj => (
                            
                          <>
                            {obj !== '' ? <p className={classes.selectedValuesMods}>{obj} <span onClick={() => removeHandlerWheels(obj)}> X</span></p> :<></>}
                          </>
                          ))
                        : <></>}
                        </div>
                        
                        {wheelsModsArray.length > 0 ? 
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => clearMods('wheelsOrTyresMods')}
                          >
                        Clear all
                        </Button> : <></>}

                        <Field
                          component={QuestionWithSelector}
                          name="otherMods"
                          question={'Other modifications'}
                          placeholderText={otherModsArray.length !== 0 ? 'Add more?' : 'Please select an option'}
                          hint={''}
                          toolTip={''}
                          options={otherModificationValues}
                          onSelect={(value: any) => {
                            setOtherModsArray((arry) => [...arry, value ])
                            otherModificationValues = otherModificationValues.filter(function( obj ) {
                              return obj.value !== value;
                            });
                          }}
                        />
                        <div className={classes.selectedModsContainer}>
                          {otherModsArray.length > 0 ?
                          otherModsArray.map(obj => (
                            
                          <>
                            {obj !== '' ? <p className={classes.selectedValuesMods}>{obj} <span onClick={() => removeHandlerOther(obj)}> X</span></p> :<></>}
                          </>
                          ))
                        : <></>}
                        </div>

                        {otherModsArray.length > 0 ? 
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => clearMods('otherMods')}
                          >
                        Clear all
                        </Button> : <></>}

                        </Grid>
                        </Grid>
                    </Box>
                       )}
                       
                    
                    <Box pt={2.5} pr={2.5} pl={2.5}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} className={classes.questionBox}>
                          <Field
                            component={QuestionWithSelector}
                            name="securityDevices"
                            question={'What security devices does this car have?'}
                            hint={''}
                            toolTip={''}
                            options={carSecurityFeaturesOptions}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.questionBox}>
                          <Field
                            component={QuestionWithSelector}
                            name="nightTimeParking"
                            question={'Where is your car parked overnight?'}
                            hint={''}
                            toolTip={''}
                            options={nightParkingOptions}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithSelector}
                            name="dayTimeParking"
                            question={'Where is your car parked during the day?'}
                            hint={''}
                            toolTip={''}
                            options={daytimeParkingOptions}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pt={2.5} pr={2.5} pl={2.5}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={8}>
                          <Field
                            component={QuestionWithSelector}
                            name="classOfUse"
                            question={'How do you use your car?'}
                            hint={
                              'Incorrect car usage may affect any claim you make or could result in your insurance being invalid.'
                            }
                            toolTip={
                              'We do not provide cover if your car is being used for hiring or for the carriage of passengers or goods for hire or reward, racing, pacemaking, speed testing or trials, motor rallies or trails, reliability testing or trials or competitions or for diplomatic use or for emergency services and military purposes or for any use in connection with the Motor Trade other than by a member of the Motor Trade for the purposes of overhaul, upkeep or repair.'
                            }
                            options={carUsageOptions}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pt={2.5} pr={2.5} pl={2.5}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={8}>
                          <Field
                            component={QuestionWithRadioBtns}
                            row
                            name="registeredKeeper"
                            question={'Will you* be the owner and registered keeper of the car?'}
                            hint={'*or your spouse, civil partner or common law partner'}
                            toolTip={
                              'The owner is the person who bought the car and holds the legal title to it.\n The registered keeper is the person who is actually using / keeping the vehicle. Their name will be recorded with the Driver and Vehicle Licensing Agency (DVLA). Often the owner and registered keeper are the same person. In some circumstances this is not the case, such as when a car is leased.'
                            }
                            options={yesNoOptions}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {props.values.registeredKeeper === 'no' && (
                      <Box pt={2.5} pr={2.5} pl={2.5}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={8}>
                            <Field
                              component={QuestionWithRadioBtns}
                              row
                              name="vehicleIsLeased"
                              question={'Do you lease the car?'}
                              hint={''}
                              toolTip={''}
                              options={yesNoOptions}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    {props.values.registeredKeeper === 'no' && props.values.vehicleIsLeased === 'yes' && (
                      <Box pt={2.5} pr={2.5} pl={2.5}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={8}>
                          <Field
                              component={QuestionWithSelector}
                              name="vehicleLeasingCompany"
                              question={'Who is the leasing company (Registered Keeper)?'}
                              hint={''}
                              toolTip={''}
                              options={vehicleLeasingCompanyOptions}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    {props.values.registeredKeeper === 'no' &&
                      props.values.vehicleIsLeased === 'yes' &&
                      props.values.vehicleLeasingCompany !== 'none' &&
                      props.values.vehicleLeasingCompany !== 'other' && (
                        <Box pt={2.5} pr={2.5} pl={2.5}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={8}>
                            <Field
                                component={QuestionWithSelector}
                                name="vehicleOwner"
                                question={'Who is the car owner?'}
                                hint={''}
                                toolTip={'The owner is either the person who bought the car and holds legal title to it, or is the person named on the leasing agreement.'}
                                options={vehicleOwnerOptions}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithRadioBtns}
                            row
                            name="securityTracker"
                            question={'Does this car have an active security tracker?'}
                            hint={''}
                            toolTip={''}
                            options={yesNoOptions}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithRadioBtns}
                            row
                            name="imported"
                            question={'Has the car been imported?'}
                            hint={''}
                            toolTip={''}
                            options={yesNoOptions}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithRadioBtns}
                            row
                            name="rightHandDrive"
                            question={'Is the car right hand drive?'}
                            hint={''}
                            toolTip={''}
                            options={yesNoOptions}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            component={QuestionWithRadioBtns}
                            row
                            name="lessThanEightSeats"
                            question={'Does the car have less than 8 seats?'}
                            hint={''}
                            toolTip={''}
                            options={yesNoOptions}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item>
                          <Button variant="outlined" color="secondary" onClick={cancelMTAChangesAction}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              setMultiChangeSubmit(true)
                              const submit = async () => await props.submitForm()
                              submit()
                            }}
                          >
                            Make further changes
                          </Button>
                        </Grid>
                        {startDate.length == 0 && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ margin: 'auto' }}
                              onClick={() => {
                                setMultiChangeSubmit(false)
                                const submit = async () => await props.submitForm()
                                submit()
                              }}
                            >
                              Continue
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Section>
                </Grid>
              </Box>

              <Box pt={5}>
                <Grid item xs={12}>
                  <Section title="Helpful information">
                    <HelpSection showTitle={false} />
                  </Section>
                </Grid>
              </Box>
            </Layout>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangeOvernightAddressPage
