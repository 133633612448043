import React from 'react'
import moment from 'moment'

import { Field } from 'formik'

import { v4 as uniqeID } from 'uuid'

import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grow,
  FormHelperText,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'

import QbDatePicker from 'components/ui/QbDatePicker'
import QbSelector from 'components/ui/QbSelector'

import { LabelValuePair } from 'types/contentTypes'
import { subYears } from 'date-fns'
import { Claim } from 'types/policyTypes'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    // [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
    //   width: '80%',
    // },
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  placeholderColor: {
    color: theme.palette.text.disabled,
  },
  listContainer: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
}))

export interface IClaimsSection {
  title: string
  body: string
  claimDate: string
  claimType: string
  claimTypeOptions: LabelValuePair[]
  addBtn: string
  confirmAllBtn: string
  id: string
}

export interface Props {
  content: IClaimsSection
  onConfirm: (pmClaims: Claim[]) => void
  claims?: []
  confirmed?: boolean
}

const Claims = ({ content, onConfirm, claims = [], confirmed = false }: Props) => {
  const [claim, setClaim] = React.useState({})
  const [pmClaims, setPmClaims] = React.useState<Claim[] | []>(claims)

  const [open, setOpen] = React.useState(!confirmed)

  const [dateError, setDateError] = React.useState<string | undefined>(undefined)

  //form values
  const [claimDateValue, setClaimDateValue] = React.useState<string | null>(null)
  const [claimTypeValue, setClaimTypeValue] = React.useState<string>('none')

  const { title, body, claimDate, claimType, claimTypeOptions, addBtn, confirmAllBtn } = content

  const classes = useStyles()

  const removeClaimDate = () => {
    const temp: any = claim
    delete temp['claimDate']
    setClaim(temp)
  }

  const onSelect = (name: string, value: string | Date) => {
    if (name === 'claimDate' && moment(value).isValid()) {
      const dateNow = moment(new Date()).utc(true)

      const fiveYearsAgo = moment(dateNow)
        .subtract(5, 'y')
        .utc(true)

      // If valid date
      if (moment(value).isBetween(fiveYearsAgo, dateNow)) {
        setDateError(undefined)
        setClaim({ ...claim, [name]: value })
      }

      // If future date
      if (moment(value).isAfter(dateNow)) {
        setDateError('Can not be a future date')

        removeClaimDate()
      }

      // If date too old
      if (moment(value).isBefore(fiveYearsAgo)) {
        setDateError('Only claims within the last 5 years are required')

        removeClaimDate()
      }
    }

    // If invalid date
    if (name === 'claimDate' && moment(value).isValid() === false) {
      removeClaimDate()
    }

    // All other fields require no validation other than being selected
    if (name !== 'claimDate') setClaim({ ...claim, [name]: value })
  }

  const onAddClaim = () => {
    setPmClaims([...pmClaims, claim as Claim])
    //reset controlled values
    setClaimDateValue(null)
    setClaimTypeValue('none')
    setClaim({})
  }

  const onRemoveClaim = (index: number) => {
    const toRemove = pmClaims[index]
    const claimsToSet = pmClaims.filter((item, itemIndex) => !(item === toRemove && index === itemIndex))
    setPmClaims(claimsToSet)
    onConfirm(claimsToSet)
  }

  const onConfirmAll = () => {
    setOpen(!open)
    onConfirm(pmClaims)
  }

  const getClaimLabel = (key: string) => {
    const keyValuePair = claimTypeOptions.find(item => item.value === key)

    return keyValuePair?.label
  }

  return (
    <>
      <Accordion elevation={0} className={classes.container} square expanded={open} onChange={() => setOpen(!open)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
          <Typography variant="h1">{title}</Typography>
        </AccordionSummary>

        <div className={classes.content}>
          <Typography>{body}</Typography>
          <Box pt={5}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{claimDate}</Typography>
                <Field
                  component={QbDatePicker}
                  fullWidth
                  name="claimDate"
                  disableFuture
                  minDate={subYears(new Date(), 5)}
                  onDateChanged={(name: string, value: string) => {
                    onSelect(name, value)
                    setClaimDateValue(value)
                  }}
                  controlledValue={claimDateValue}
                />

                {dateError && <FormHelperText error>{dateError}</FormHelperText>}
              </Grid>

              <Grid item xs={12} sm={6}>
                <QbSelector
                  fullWidth
                  name="claimType"
                  label={claimType}
                  onSelect={(name, value) => {
                    onSelect(name, value)
                    setClaimTypeValue(value)
                  }}
                  options={claimTypeOptions}
                  controlledValue={claimTypeValue}
                />
              </Grid>

              <Grid xs={12} item container justify="flex-end">
                <Button
                  disabled={Object.keys(claim).length !== 2}
                  variant="outlined"
                  color="secondary"
                  onClick={() => onAddClaim()}
                >
                  {addBtn}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Accordion>

      <Grow in={pmClaims.length > 0} timeout={300} unmountOnExit>
        <TableContainer className={classes.listContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{claimType}</TableCell>
                <TableCell>{claimDate}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {pmClaims.map((item, index) => (
                <TableRow key={uniqeID()}>
                  <TableCell>{getClaimLabel(item.claimType)}</TableCell>
                  <TableCell>{moment(item.claimDate).format('DD / MM / YYYY')}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => onRemoveClaim(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {open && (
            <Grid container>
              <Grid xs={12} item container justify="flex-end">
                <Box pt={5} pb={1}>
                  <Button
                    disabled={pmClaims.length === 0}
                    variant="outlined"
                    color="primary"
                    onClick={() => onConfirmAll()}
                  >
                    {confirmAllBtn}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </TableContainer>
      </Grow>
    </>
  )
}

export default Claims
