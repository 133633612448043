import React from 'react'

import { Button, Checkbox, Grid, makeStyles, Switch, Typography } from '@material-ui/core'

import Modal from '../Modal'
import { setCookies, setPreferences, getCookie } from 'utils/cookie'
import { ContentType } from 'types/contentTypes'

const useStyles = makeStyles({
  cookieButton: {
    margin: 5,
  },
  settingDiv: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      fontWeight: 800,
    },
  },
  settingAction: {
    marginLeft: 'auto',
  },
  disabledCheck: {
    color: '#ff7e8f !important',
  },
})

export interface Props {
  content: ContentType[]
}

const CookieModal = ({ content }: Props) => {
  const [cookieModalOpen, setCookieModalOpen] = React.useState(false)
  const [showPreferences, setShowPreferences] = React.useState(false)
  const [functionCookies, setFunctionCookies] = React.useState(false)
  const [analyticsCookies, setAnalyticsCookies] = React.useState(false)
  const [advertisingCookies, setAdvertisingCookies] = React.useState(false)

  const classes = useStyles()

  const getButtonText = (buttons: { title: string; id: string }[], id: string) => {
    const btn = buttons ? buttons.find(item => item.id === id) : ''

    return btn ? btn.title : ''
  }

  const getCookieSetting = (group: { title: string; id: string; body: string }[], id: string) => {
    const cookieSetting = group ? group.find(item => item.id === id) : { title: '', id: '', body: '' }

    return cookieSetting ? cookieSetting : { title: '', id: '', body: '' }
  }

  const cookieContentSettings = content.find(item => item.id === 'cookieContentSettings')
  const cookieContentPreferences = content.find(item => item.id === 'cookieContentPreferences')

  const cookieButtonNo = getButtonText(
    cookieContentSettings?.buttons as { title: string; id: string }[],
    'cookieButtonNo',
  )
  const cookieButtonReject = getButtonText(
    cookieContentSettings?.buttons as { title: string; id: string }[],
    'cookieButtonReject',
  )
  const cookieButtonYes = getButtonText(
    cookieContentSettings?.buttons as { title: string; id: string }[],
    'cookieButtonYes',
  )

  const cookiesStrictlyNecessary = getCookieSetting(
    cookieContentPreferences?.group as { title: string; id: string; body: string }[],
    'cookiesStrictlyNecessary',
  )

  const cookiesFunctional = getCookieSetting(
    cookieContentPreferences?.group as { title: string; id: string; body: string }[],
    'cookiesFunctional',
  )

  const cookiesAnalytics = getCookieSetting(
    cookieContentPreferences?.group as { title: string; id: string; body: string }[],
    'cookiesAnalytics',
  )

  const cookiesAdvertising = getCookieSetting(
    cookieContentPreferences?.group as { title: string; id: string; body: string }[],
    'cookiesAdvertising',
  )

  React.useEffect(() => {
    setCookieModalOpen(!getCookie())
  }, [])

  return (
    <Modal open={cookieModalOpen} onClose={() => setCookieModalOpen(cookieModalOpen)}>
      {!showPreferences ? (
        <>
          {cookieContentSettings && (
            <Grid container spacing={4}>
              <Grid item>
                <Typography variant="h1">{cookieContentSettings?.title || ''}</Typography>
              </Grid>
              <Grid item>
                <Typography>{cookieContentSettings?.body || ''}</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.cookieButton}
                  onClick={() => setShowPreferences(true)}
                >
                  {cookieButtonNo}
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.cookieButton}
                  onClick={() => setCookies('none', setCookieModalOpen)}
                >
                  {cookieButtonReject}
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.cookieButton}
                  onClick={() => setCookies('all', setCookieModalOpen)}
                >
                  {cookieButtonYes}
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          {cookieContentPreferences && (
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Typography variant="h1">{cookieContentPreferences.title}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.settingDiv}>
                <Typography align="left">{cookiesStrictlyNecessary.title}</Typography>

                <Checkbox checked={true} className={classes.settingAction + ' ' + classes.disabledCheck} disabled />
              </Grid>
              <Grid item xs={12}>
                <Typography align="left">{cookiesStrictlyNecessary.body}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.settingDiv}>
                <Typography align="left">{cookiesFunctional.title} </Typography>

                <Switch
                  checked={functionCookies}
                  className={classes.settingAction}
                  onChange={() => setFunctionCookies(!functionCookies)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="left">{cookiesFunctional.body}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.settingDiv}>
                <Typography align="left">{cookiesAnalytics.title} </Typography>
                <Switch
                  checked={analyticsCookies}
                  className={classes.settingAction}
                  onChange={() => setAnalyticsCookies(!analyticsCookies)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography align="left">{cookiesAnalytics.body}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.settingDiv}>
                <Typography align="left">{cookiesAdvertising.title}</Typography>
                <Switch
                  checked={advertisingCookies}
                  className={classes.settingAction}
                  onChange={() => setAdvertisingCookies(!advertisingCookies)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="left">{cookiesAdvertising.body}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setPreferences(functionCookies, analyticsCookies, advertisingCookies, setCookieModalOpen)
                  }
                >
                  {cookieContentPreferences.buttons ? cookieContentPreferences.buttons[0].title : ''}
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Modal>
  )
}

export default CookieModal
