import { makeStyles, Grid, Typography, Select, MenuItem, Button, Box } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import Layout from 'components/ui/Layout'

import { history } from 'utils'

import { ContentType } from 'types/contentTypes'
import { LabelValuePair } from 'types/generalTypes'
import { EndPoint } from 'types/endpoint'

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
  },
  contactUsButton: {
    color: 'black',
  },
  policyBar: {
    paddingTop: 25,
    paddingBottom: 25,
    marginTop: 0,
  },
  policyHeader: {
    fontSize: '30px',
    color: 'white',
  },
  policySubtitle: {
    fontSize: '12px',
    color: 'white',
    paddingBottom: 9,
    marginBottom: 9,
    paddingTop: 5,
  },
  selector: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '12px 20px',
    borderRadius: 4,
  },
  renewalText: {
    display: 'flex',
    color: 'white',

    '& p': {
      fontSize: 30,
    },

    '& svg': {
      marginTop: 12,
      marginRight: 10,
    },
  },
  renewalSubText: {
    color: 'white',
    fontSize: 12,
  },
  renewalbutton: {
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      marginTop: 16,
      justifyContent: 'flex-start',

      '& button': {
        width: ' 100%',
      },
    },
  },
}))

export interface Props {
  content: ContentType
  policyIds?: LabelValuePair[]
  currentPolicyIndex: number
  renewalBanner?: {
    showRenewWarning: boolean
    showRenewButton: boolean
    expirationDate: string
    daysUntilRenewalAllowed: number
    daysUntilExpire: number
  }
  onPolicySelect: (action: { index: number; policyId: string }) => void
}

const PolicyHeader = ({ content, policyIds, currentPolicyIndex, renewalBanner, onPolicySelect }: Props) => {
  const classes = useStyles()

  const { title, subtitle } = content

  const url = history.location.pathname

  const getDaysString = () => {
    if (renewalBanner?.showRenewButton === false && renewalBanner?.daysUntilRenewalAllowed >= 0) {
      return `Your renewal details will appear here ${
        renewalBanner?.daysUntilRenewalAllowed === 1 ? 'tomorrow' : `in ${renewalBanner?.daysUntilRenewalAllowed} days.`
      }`
    }

    return ''
  }

  const getExpiresInDays = () => {
    if (renewalBanner?.showRenewButton === true && renewalBanner?.daysUntilExpire === 0) {
      return 'Your current policy is due to expire tomorrow'
    }

    return ''
  }

  return (
    <div>
      {url === '/my-policy' && renewalBanner !== undefined && (
        <Layout background={'#ff0022'}>
          <Box pt={'25px'} pb={'25px'}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} sm={6}>
                <div className={classes.renewalText}>
                  <WarningIcon />
                  <Typography>Policy Renewal</Typography>
                </div>
                <Typography className={classes.renewalSubText}>
                  {`Your policy is due for renewal on ${renewalBanner.expirationDate}. ${getDaysString()}`}
                </Typography>
                <Typography className={classes.renewalSubText}>{`${getExpiresInDays()}`}</Typography>
              </Grid>

              {renewalBanner.showRenewButton && (
                <Grid item xs={12} sm={6} className={classes.renewalbutton}>
                  <Button variant="outlined" color="secondary" onClick={() => history.push(EndPoint.MY_RENEWAL)}>
                    Complete renewal
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Layout>
      )}

      {/* Policy Select */}
      <Layout background={'#494950'}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          id="policyBar"
          className={classes.policyBar}
          spacing={4}
        >
          <Grid item xs={12} sm={8}>
            <Typography className={classes.policyHeader}>
              {url === '/error-message' ? 'Get in contact with us' : title}
            </Typography>
            {subtitle && url !== '/error-message' && (
              <Typography className={classes.policySubtitle}>{subtitle}</Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Select
              className={classes.selector}
              IconComponent={() => <KeyboardArrowDownIcon color="secondary" />}
              value={currentPolicyIndex}
            >
              {policyIds &&
                policyIds.map((policy, index) => (
                  <MenuItem
                    value={index}
                    key={index}
                    onClick={() => onPolicySelect({ index: index, policyId: policy.value })}
                  >
                    {policy.label}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </Layout>
    </div>
  )
}

export default PolicyHeader
