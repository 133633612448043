import React from 'react'
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { MyExcessPaymentDisplay } from 'types/policy'

const useStyles = makeStyles(theme => ({
  toolTipPositionDesktop: {
    position: 'relative',
    marginLeft: 5,
    top: 2,
  },
  toolTipPositionMobile: {
    display: 'flex',
    alignItems: 'center',
  },
  driverHeaderMobile: {
    backgroundColor: `${theme.palette.primary.light}20`,
  },
  heading: {
    fontWeight: 800,
  },
  rowBorder: {
    border: 0,
  },
}))

export interface Props {
  myExcessPayment: any
}

const ExcessTable = ({ myExcessPayment }: Props) => {
  const classes = useStyles()

  const { glassRepairExcess, glassReplacementExcess, damageExcesses } = myExcessPayment

  // Hardcoded content
  const headers = [
    { label: 'Driver', value: 'driver' },
    {
      label: 'Compulsory',
      infoIcon:
        'Compulsory excess is the amount that is set for your policy and cannot be changed. If you make a claim, you will have to pay both the compulsory and the voluntary excess.',
      value: 'compulsoryExcess',
    },
    {
      label: 'Voluntary',
      infoIcon:
        'Voluntary excess is the amount you choose when buying your policy. A higher voluntary excess can lower the cost of your insurance. If you make a claim, you will have to pay both the compulsory and the voluntary excess.',
      value: 'voluntaryExcess',
    },
    { label: 'Total', value: 'totalExcess' },
  ]

  return (
    <>
      {/* Desktop - Drivers table */}
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Grid container justify="center">
          <Grid item sm={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell key={header.label}>
                        <div style={{ display: 'flex', justifyContent: index === 0 ? 'flex-start' : 'flex-end' }}>
                          <Typography className={classes.heading}>{header.label}</Typography>
                          {header.infoIcon && (
                            <Tooltip
                              className={classes.toolTipPositionDesktop}
                              title={header.infoIcon as string}
                              placement="top"
                            >
                              <InfoIcon fontSize="small" color="primary" />
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {damageExcesses.map((driverInfo: any) => (
                    <TableRow key={driverInfo.driver}>
                      <TableCell>{driverInfo.driver}</TableCell>
                      <TableCell align="right">{driverInfo.compulsoryExcess}</TableCell>
                      <TableCell align="right">{driverInfo.voluntaryExcess}</TableCell>
                      <TableCell align="right">{driverInfo.totalExcess}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>

      {/* Mobile - Drivers table */}
      <Box display={{ xs: 'block', sm: 'none' }}>
        <TableContainer>
          <Table>
            <TableBody>
              {damageExcesses.map((driverInfo: any) =>
                headers.map((header, index) => (
                  <React.Fragment key={`${driverInfo.driver} ${header.label}`}>
                    <TableRow className={index === 0 ? classes.driverHeaderMobile : undefined}>
                      <TableCell className={classes.toolTipPositionMobile} align="left">
                        {header.label}
                        {header.infoIcon && (
                          <Tooltip title={header.infoIcon as string} placement="right">
                            <InfoIcon fontSize="small" color="primary" />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="right">{driverInfo[header.value]}</TableCell>
                    </TableRow>
                  </React.Fragment>
                )),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Other excess table */}
      <Box pt={5}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.heading} align={'left'}>
                      {'Other compulsory excesses'}
                    </TableCell>

                    {/* if there is only one header but 2 columns, material ui spaces the table incorrect. 
                      A blank placeholder can be added to the CMS but to avoid confusion its easier here */}
                    <TableCell>{''}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Windscreen replacement</TableCell>
                    <TableCell align="right">{glassReplacementExcess}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowBorder}>Windscreen repair</TableCell>
                    <TableCell className={classes.rowBorder} align="right">
                      {glassRepairExcess}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ExcessTable
