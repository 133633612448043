export interface Detail {
  type: string
  body: string
  divider: boolean
}

export interface HelpSectionContent {
  id: string
  title: string
  details: Detail[]
}

export interface ContentType {
  title?: string
  subtitle?: string
  body?: string
  iconImage?: string
  group?: ContentType[]
  buttons?: { title: string; id: string }[]
  id?: string
  label?: string
  value?: string
  helpSection?: HelpSectionContent[]
  hint?: string
  additionalText?: string
}

export interface LabelValuePair {
  label: string
  value: any
  info?: string
  infoIcon?: string
}

export enum LicenseType {
  FullUK = 'FullUK',
  ProvisionalUK = 'ProvisionalUK',
  FullEU = 'FullEU',
  ProvisionalEU = 'ProvisionalEU',
  OTHER = 'Other',
}

export interface PaymentPlanDisplay {
  [key: string]: string | number
  name: string
  downPayment: string
  total: string
  installment: string
  billingId: string
  numberOfInstallments: number
  creditAmount_itb: string
  aprPercentage_itb: string
  fees_itb: string
  interestRate_itb: string
  totalPremiumRPT: string
  periodID: string
  firstInstallment: string
  pclfacilityFee: string
}
