import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import BasicAccordion from '../BasicAccordion'

export interface Props {
  title?: string
  children: React.ReactNode
  accordian?: boolean
  childrenPadding?: boolean
}

const useStyles = makeStyles(theme => ({
  screenPaddingAccordian: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
    },
  },
  screenPadding: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      padding: 16,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: 20,
    },
  },
}))

const Section = ({ title = '', children, accordian = false, childrenPadding = true }: Props) => {
  const classes = useStyles()
  return (
    <Paper elevation={0}>
      <Box pl={0.5} pr={0.5} pt={2.5} pb={2.5}>
        {accordian === false && (
          <Box p={2}>
            <Typography>
              <b>{title}</b>
            </Typography>
            {children}
          </Box>
        )}

        {accordian && (
          <BasicAccordion title={title} useBorder={false}>
            <div className={classes.screenPaddingAccordian}>{children}</div>
          </BasicAccordion>
        )}
      </Box>
    </Paper>
  )
}

export default Section
