import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  getRegiYearsAction,
  getVehiclesAction,
  selectVehicleAction,
  setRegiYearsAction,
  setVehiclesAction,
  getVehicleByRegistrationAction,
  setRegistrationAction,
  setSelectedVehicleAction,
  setCarPageFormValuesAction,
} from './actions'
import { getVehicleByRegiNumber } from './client'

import { isEmpty } from 'guidewire/formatTools'
import { VehicleType } from 'types/policy'
import { Action } from 'types/generalTypes'

export function* onSelectVehicle(action: Action<{ vehicle: VehicleType }>) {
  const { payload: selectedVehicle } = action
  yield put(selectVehicleAction.success({ selectedVehicle }))
}

export function* onSetRegiYears(action: Action<{ years: string[] }>) {
  const { payload: regiYears } = action
  yield put(getRegiYearsAction.success({ regiYears }))
}

export function* onSetVehicles(action: Action<{ vehicles: VehicleType[] }>) {
  const { payload: vehicles } = action
  yield put(getVehiclesAction.success({ vehicles }))
}

export function* onGetVehicleByRegiNumber(action: Action<string>) {
  try {
    const { payload } = action
    const { data: selectedVehicle } = yield call(() => getVehicleByRegiNumber(payload))

    if (isEmpty(selectedVehicle) || selectedVehicle.bodyType === undefined) {
      yield put(getVehicleByRegistrationAction.error({}))
      yield put(setRegistrationAction.error({ registration: payload }))
    } else {
      yield put(getVehicleByRegistrationAction.success({ selectedVehicle }))
      yield put(setRegistrationAction.success({ registration: payload }))
    }
  } catch (error) {
    yield put(getVehicleByRegistrationAction.error({}))
    yield put(setRegistrationAction.error({}))
  }
}

export function* onSetRegistration(action: Action<string>) {
  const { payload: registration } = action
  yield put(setRegistrationAction.success({ registration }))
}

export function* onSetSelectedVehicle(action: Action<VehicleType>) {
  const { payload: selectedVehicle } = action
  yield put(setSelectedVehicleAction.success({ selectedVehicle }))
}

export function* onSetCarPageFormValues(values: any) {
  const { payload: formValues } = values
  yield put(setCarPageFormValuesAction.success({ formValues }))
}

export default function* rootSaga() {
  yield all([
    takeLatest(selectVehicleAction.start, onSelectVehicle),
    takeLatest(setRegiYearsAction.start, onSetRegiYears),
    takeLatest(setVehiclesAction.start, onSetVehicles),
    takeLatest(getVehicleByRegistrationAction.start, onGetVehicleByRegiNumber),
    takeLatest(setRegistrationAction.start, onSetRegistration),
    takeLatest(setSelectedVehicleAction.start, onSetSelectedVehicle),
    takeLatest(setCarPageFormValuesAction.start, onSetCarPageFormValues),
  ])
}
