import React from 'react'
import { FieldInputProps, FormikProps, getIn } from 'formik'
import { Box, Grid, TextField, Button, Typography, makeStyles } from '@material-ui/core'

interface Props {
  field: FieldInputProps<string>
  form: FormikProps<any>
  onVehicleLookup: (registration: string) => void
  vehicleLookupError?: boolean
}

const useStyles = makeStyles(theme => ({
  inputMobile: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
  inputLabel: {
    width: '100%',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
  },
  buttonMobile: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      marginLeft: 'auto',
    },
  },
  clickHere: {
    marginRight: 5,
    cursor: 'pointer',
  },
}))

const SearchRegistrationSection = ({ field, form, onVehicleLookup, vehicleLookupError }: Props) => {
  const [reg, setReg] = React.useState('')

  const classes = useStyles()
  const error = getIn(form.errors, field.name || '')
  const touched = getIn(form.touched, field.name || '')
  const errorText = error && touched ? error : null
  const submitCount = form.submitCount

  const onChange = (value: string) => {
    form?.setFieldValue(field?.name || '', value, submitCount > 0)
    form?.setTouched({ ...form.touched, [field?.name as string]: true })
  }

  return (
    <>
      <Box pb={5} pt={5} pl={2} pr={2}>
        <Grid container spacing={4}>
          <Grid item container justify="flex-start" spacing={4}>
            <Grid item className={classes.inputMobile}>
              <TextField
                // If you have searched for a car, decided to change and return, old value is prefilled
                defaultValue={field?.value || ''}
                className={classes.inputLabel}
                fullWidth
                variant="outlined"
                inputProps={{ style: { textTransform: 'uppercase' } }}
                onChange={event => {
                  const { value } = event.target
                  onChange(value as string)
                  setReg(value)
                }}
              />
            </Grid>
            <Grid item className={classes.buttonMobile}>
              <Button
                variant="outlined"
                color="primary"
                disabled={reg === '' || !!errorText}
                onClick={() => field?.value && onVehicleLookup(field?.value)}
              >
                {'Find my car'}
              </Button>
            </Grid>
          </Grid>
          {vehicleLookupError && (
            <Grid item>
              <Typography color="secondary">
                {
                  'We could not find this car. Please provide your car registration number or enter your car details manually'
                }
              </Typography>
            </Grid>
          )}
          {errorText && (
            <Grid item>
              <Typography color="secondary">{errorText}</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default SearchRegistrationSection
