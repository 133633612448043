import { FormHelperText, IconButton, InputAdornment, makeStyles, OutlinedInput } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { FieldProps, getIn } from 'formik'
import React from 'react'

interface Props {
  name: string
  question: string
  required?: boolean
  hint?: string
  toolTip?: string
  type?: string
  lookupError?: string
  inputStyle?: 'uppercase' | 'capitalize'
  onChange?: (value: string) => void
}

const useStyles = makeStyles(theme => ({
  inputLabel: {},
  icon: {
    paddingRight: 0,
  },
}))

const QuestionWithPasswordField = ({
  field,
  form,
  name,
  question,
  required = false,
  hint,
  toolTip,
  onChange,
  type = 'text',
  lookupError = '',
  inputStyle = undefined,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const errorText = error && touched ? error : null
  const value = field.value || (field.value === 0 ? field.value : '')

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <>
      <OutlinedInput
        className={classes.inputLabel}
        {...field}
        {...props}
        fullWidth
        type={showPassword || value.length === 0 ? 'text' : 'password'}
        name={name}
        id={name}
        value={value}
        onChange={event => {
          const { value } = event.target
          onChange && onChange(value)
          form.setFieldValue(field.name, value, true)
          form.setTouched({ ...form.touched, [field.name]: true })
        }}
        endAdornment={
          <InputAdornment position="end" className={classes.icon}>
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        error={!!errorText || !!lookupError}
        required={required}
        inputProps={{ style: { textTransform: inputStyle } }}
      />
      {(!!errorText || !!lookupError) && (
        <FormHelperText error id="accountId-error">
          {errorText || lookupError}
        </FormHelperText>
      )}
    </>
  )
}

export default QuestionWithPasswordField
