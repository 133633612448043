import { Box, Typography } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import { ContentType } from 'types/contentTypes'

export interface Props {
  content: ContentType
}

const LoginFooter = ({ content }: Props) => {
  const { body } = content

  return (
    <Box pt={10}>
      <Typography component="div" style={{ fontSize: 12 }}>
        <ReactMarkdown>{body || ''}</ReactMarkdown>
      </Typography>
    </Box>
  )
}

export default LoginFooter
