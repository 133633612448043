import { connect } from 'react-redux'
import { renewalAnnualGetSagepayurlAction, renewalBindPaymentAction } from 'redux/payment/actions'

import { IReduxState } from 'types/stateTypes'
import RenewalAnnualPayment from './RenewalAnnualPayment'

const mapStateToProps = (state: IReduxState) => ({
  quote: state.renewal.quote,
  policyNumber: state.policy.policyIds[state.policy.currentPolicyIndex],
})

const mapDispatchToProps = {
  renewalAnnualGetSagepayurlAction: renewalAnnualGetSagepayurlAction.start,
  renewalBindPaymentAction: renewalBindPaymentAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalAnnualPayment)
