import React from 'react'
import { connect } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'

import { CssBaseline, MuiThemeProvider } from '@material-ui/core'

import { PageContainer } from 'components/ui/Layout'
import SnackbarMessage from 'components/ui/SnackbarMessage'
import LogoutWarningModal from 'components/ui/LogoutWarningModal'

import { theme as toyotaTheme } from 'theme'

import { history, startLogoutTimeout } from 'utils'
import { isEmpty, isPolicyCancelled } from 'guidewire/formatTools'

import { policySelectAction, getTypekeysAction } from 'redux/policy/actions'
import { isSnackbarOpenAction } from 'redux/snackbarMessage/actions'
import { loginAction, logoutAction, pageRefreshAction } from 'redux/login/actions'
import { setLiveChatAction } from 'redux/livechat/actions'
import { isTokenValid } from 'redux/login/client'
import { closeLoginModalAction } from 'redux/account/actions'

import { EndPoint } from 'types/endpoint'
import { IReduxState } from 'types/stateTypes'
import { SelectedPolicy } from 'types/policy'
import { LabelValuePair } from 'types/generalTypes'
import { ContentType } from 'types/contentTypes'
import { LoginRequest } from 'types/requests'
import { Policies } from 'types/policies'

import content from 'mock/cms/header_footer_cookie.json'

import LoginPage from 'pages/LoginPage'
import MyPolicyPage from 'pages/MyPolicyPage'

import MyAccountPage from 'pages/MyAccountPage'
import ErrorPage from 'pages/ErrorPage'
import MyDocumentsPage from 'pages/MyDocumentsPage'

import MTAModal from 'components/myPolicy/MTAModal'
import MTAConfirmModal from 'components/myPolicy/MTAConfirmModal'
import MTAExecuteModal from 'components/myPolicy/MTAExecuteModal'
import ChangeOvernightAddressPage from 'pages/ChangeOvernightAddressPage'
import MTASummaryPage from 'pages/MTASummaryPage'
import MTAConfirmPage from 'pages/MTAConfirmPage'
import MTASuccessPage from 'pages/MTASuccessPage'
import ChangeCarPage from 'pages/ChangeCarPage'
import MTAMuliChangePage from 'pages/MTAMuliChangePage'
import MTAAddDriver from 'pages/MTAAddDriver'
import MTANewCardPage from 'pages/MTANewCardPage'

import MyRenewalPage from 'pages/MyRenewalPage'
import RenewalAboutYouPage from 'pages/RenewalAboutYouPage'
import RenewalAddDriverPage from 'pages/RenewalAddDriverPage'
import RenewalYourCarPage from 'pages/RenewalYourCarPage'
import RenewalAddonPage from 'pages/RenewalAddonPage'
import RenewalYourQuotePage from 'pages/RenewalYourQuotePage'

import RenewalPaymentCompletePage from 'pages/RenewalPaymentCompletePage'

import RenewalMonthlyMandatePage from 'pages/RenewalMonthlyMandatePage'

import RenewalDocuments from 'pages/RenewalDocuments'

import SagePayIframe from 'pages/SagePayIframe'
import SagePayFailure from 'pages/SagePayFailure'
import SagePaySuccess from 'pages/SagePaySuccess'
import DeclinePage from 'pages/DeclinePage'
import RenewalAnnualPayment from 'pages/RenewalAnnualPayment'
import RenewalMonthlyPayment from 'pages/RenewalMonthlyPayment'

import NoRenewalError from 'components/renewal/NoRenewalError'
import moment from 'moment'

interface Props {
  isLoading: boolean
  current: SelectedPolicy
  renewed: SelectedPolicy
  policyIds: LabelValuePair[]
  policies: Policies[]
  currentPolicyIndex: number
  snackbarMessage: { isOpen: boolean; message: string; error: boolean }
  isLoggedIn: boolean
  showLoginModal: boolean
  contactNumbersMessage: string
  emailOrPasswordMessage: string
  loginRedirect?: string
  isOpen: boolean
  showNewContent?: boolean
  activeStep: number
  typekeys: any[]
  setLiveChatAction: (values: any) => void
  isSnackbarOpenAction: (action: { isOpen: boolean; message: string; error: boolean }) => void
  policySelectAction: (action: any) => void
  pageRefreshAction: () => void
  logout: () => void
  signIn: (requestObject: { requestObject: LoginRequest }) => void
  closeLoginModalAction: () => void
  getTypekeys: VoidFunction
}

const App = ({
  isLoading,
  current,
  renewed,
  policyIds,
  currentPolicyIndex,
  snackbarMessage,
  isLoggedIn,
  showLoginModal,
  contactNumbersMessage,
  emailOrPasswordMessage,
  loginRedirect,
  isOpen,
  policies,
  activeStep,
  typekeys,
  setLiveChatAction,
  isSnackbarOpenAction,
  policySelectAction,
  pageRefreshAction,
  logout,
  signIn,
  closeLoginModalAction,
  getTypekeys,
}: Props) => {
  const [location, setLocation] = React.useState('')
  const [showWarning, setShowWarning] = React.useState(false)

  const [footerContent, setFooterContent] = React.useState<ContentType>()
  const [headerContent, setHeaderContent] = React.useState<ContentType[]>([])
  const [cookieContent, setCookieContent] = React.useState<ContentType[]>([])

  const [timeoutId, setTimeoutId] = React.useState(null)
  const [warnTimeoutId, setWarnTimeoutId] = React.useState(null)
  const now = moment()
  let showNewContent = false
  if (now.isSameOrAfter('2023-11-01T00:00:00Z')) {
    showNewContent = true
  }

  const continueLogin = async () => {
    let isValid = undefined
    await isTokenValid().then(({ response }) => (isValid = response))

    if (isValid === true) {
       startLogoutTimeout(setShowWarning, logout, timeoutId, warnTimeoutId, setTimeoutId, setWarnTimeoutId)
    } else logout()
  }

  React.useEffect(() => {
    // Site Refresh
    if (
      isEmpty(current) &&
      window.location.pathname !== '/' &&
      window.location.pathname !== EndPoint.AUTH_RESET_PASSWORD &&
      window.location.pathname !== EndPoint.PAYMENT_FAILURE &&
      window.location.pathname !== EndPoint.PAYMENT_SUCCESS &&
      window.location.pathname !== EndPoint.DECLINED &&
      window.location.pathname !== EndPoint.DECLINED
    ) {
      pageRefreshAction()
    }

    // TODO: When a CMS space is created, this needs to move to a redux saga
    // CMS Content
    setTimeout(() => {
      if(showNewContent === true) {
      setFooterContent(content.find(item => item.id === 'footerContentNew'))}
      else{
        setFooterContent(content.find(item => item.id === 'footerContent'))
      }
      setHeaderContent(content.filter(item => item.id.includes('headerContent')))
      setCookieContent(content.filter(item => item.id.includes('cookieContent')))
    }, 1000)

    // Logout timer
    setLocation(history.location.pathname)
    startLogoutTimeout(setShowWarning, logout, timeoutId, warnTimeoutId, setTimeoutId, setWarnTimeoutId)

    // Call typekeys if they are empty
    if (isEmpty(typekeys)) getTypekeys()

    return () => {
      if (timeoutId) clearInterval(timeoutId)
      if (warnTimeoutId) clearInterval(warnTimeoutId)

      history.listen(location => {
        // Makes sure when the user transitions between pages, it starts at the top  of the page
        window.scrollTo(0, 0)

        const { pathname } = location
        setLocation(pathname)
      })
    }
  }, [history, history.listen, location])

  return (
    <MuiThemeProvider theme={toyotaTheme}>
      <CssBaseline />

      <LogoutWarningModal
        modalOpen={showWarning}
        setModalOpen={show => setShowWarning(show)}
        continueLoggedIn={() => continueLogin()}
        logout={() => logout()}
      />

      <MTAModal />

      <MTAConfirmModal />

      <MTAExecuteModal />

      {footerContent && (
        <Router history={history}>
          <Switch>
            {/* Redirect components, appear in the Iframe and send a postMessage to trigger bind payment */}
            <Route exact path={EndPoint.PAYMENT_FAILURE} component={SagePayFailure} />
            <Route exact path={EndPoint.PAYMENT_SUCCESS} component={SagePaySuccess} />

            <PageContainer
              footerContent={footerContent}
              headerContent={headerContent}
              cookieContent={cookieContent}
              // Footer does not show on the Login page
              showFooter={window.location.pathname !== '/' && window.location.pathname !== EndPoint.AUTH_RESET_PASSWORD}
              current={current}
              renewed={renewed}
              isLoading={isLoading}
              policyIds={policyIds}
              currentPolicyIndex={currentPolicyIndex}
              policySelectAction={policySelectAction}
              // Login modal
              showLoginModal={showLoginModal}
              contactNumbersMessage={contactNumbersMessage}
              emailOrPasswordMessage={emailOrPasswordMessage}
              closeLoginModalAction={closeLoginModalAction}
              logout={logout}
              signIn={signIn}
              loginRedirect={loginRedirect}
              isPolicyCancelled={isPolicyCancelled(policies)}
              isOpen={isOpen}
              setLiveChatAction={setLiveChatAction}
              activeStep={activeStep}
            >
              <Route exact path={EndPoint.LOGIN} component={LoginPage} />
              <Route exact path={EndPoint.AUTH_RESET_PASSWORD} component={LoginPage} />
              <Route exact path={EndPoint.DECLINED} component={DeclinePage} />

              {isEmpty(current) === false && isLoggedIn === true && (
                <>
                  <Route exact path={EndPoint.MY_POLICY} component={MyPolicyPage} />
                  <Route exact path={EndPoint.MY_DOCUMENTS} component={MyDocumentsPage} />

                  {current.status.toLowerCase() !== ('cancelled' || 'lapsed') && (
                    <Route exact path={EndPoint.MY_ACCOUNT} component={MyAccountPage} />
                  )}
                  <Route exact path={EndPoint.ERROR_MESSAGE} component={ErrorPage} />

                  <Route exact path={EndPoint.EDIT_OVERNIGHT_ADDRESS} component={ChangeOvernightAddressPage} />
                  <Route exact path={EndPoint.EDIT_CAR} component={ChangeCarPage} />
                  <Route exact path={EndPoint.MTA_SUMMARY} component={MTASummaryPage} />
                  <Route exact path={EndPoint.MTA_MULTI_CHANGE} component={MTAMuliChangePage} />
                  <Route exact path={EndPoint.MTA_CONFIRM} component={MTAConfirmPage} />
                  <Route exact path={EndPoint.MTA_NEW_CARD} component={MTANewCardPage} />
                  <Route exact path={EndPoint.MTA_SUCCESS} component={MTASuccessPage} />

                  <Route exact path={EndPoint.ADD_DRIVER} component={MTAAddDriver} />
                  {/* Shows the payment in Iframe */}
                  <Route exact path={EndPoint.PAYMENT_PAGE} component={SagePayIframe} />
                  {/* Redirect components, appear in the Iframe and send a postMessage to trigger bind payment */}
                  <Route exact path={EndPoint.PAYMENT_FAILURE} component={SagePayFailure} />
                  <Route exact path={EndPoint.PAYMENT_SUCCESS} component={SagePaySuccess} />

                  <Route exact path={EndPoint.MY_RENEWAL} component={MyRenewalPage} />
                  <Route exact path={EndPoint.RENEWAL_ABOUT_YOU} component={RenewalAboutYouPage} />
                  <Route exact path={EndPoint.RENEWAL_ADD_DRIVER} component={RenewalAddDriverPage} />
                  <Route exact path={EndPoint.RENEWAL_YOUR_CAR} component={RenewalYourCarPage} />
                  <Route exact path={EndPoint.RENEWAL_ADDONS} component={RenewalAddonPage} />

                  <Route exact path={EndPoint.RENEWAL_YOUR_QUOTE} component={RenewalYourQuotePage} />
                  <Route exact path={EndPoint.RENEWAL_DOCUMENTS} component={RenewalDocuments} />
                  <Route exact path={EndPoint.RENEWAL_PAYMENT_ANNUAL} component={RenewalAnnualPayment} />
                  <Route exact path={EndPoint.RENEWAL_PAYMENT_MONTHLY} component={RenewalMonthlyPayment} />
                  <Route exact path={EndPoint.RENEWAL_MONTHLY_MANDATE} component={RenewalMonthlyMandatePage} />
                  <Route exact path={EndPoint.RENEWAL_PAYMENT_COMPLETE} component={RenewalPaymentCompletePage} />
                </>
              )}

              {/* My renewal when a non-renewal policy is selected from the dropdown */}
              {window.location.pathname === EndPoint.MY_RENEWAL && renewed === undefined && (
                <Route component={() => <NoRenewalError />} />
              )}
            </PageContainer>
          </Switch>
        </Router>
      )}

      <SnackbarMessage
        isOpen={snackbarMessage.isOpen}
        message={snackbarMessage.message}
        error={snackbarMessage.error}
        isSnackbarOpenAction={isSnackbarOpenAction}
      />
    </MuiThemeProvider>
  )
}

const mapStateToProps = (state: IReduxState) => ({
  isLoading: state.loading.isLoading,
  isLoggedIn: state.login.isLoggedIn,
  loginRedirect: state.account.redirect,
  current: state.policy.current,
  renewed: state.policy.renewed,
  policyIds: state.policy.policyIds,
  policies: state.policy.policies,
  currentPolicyIndex: state.policy.currentPolicyIndex,
  snackbarMessage: state.snackbarMessage,
  showLoginModal: state.account.showLoginModal,
  contactNumbersMessage: state.account.contactNumbersMessage,
  emailOrPasswordMessage: state.account.emailOrPasswordMessage,
  typekeys: state.policy.typekeys,
  isOpen: state.livechat.isOpen,
  activeStep: state.renewal.activeStep,
})

const mapDispatchToProps = {
  policySelectAction: policySelectAction.start,
  logout: logoutAction.start,
  isSnackbarOpenAction: isSnackbarOpenAction.start,
  pageRefreshAction: pageRefreshAction.start,
  signIn: loginAction.start,
  closeLoginModalAction: closeLoginModalAction.start,
  getTypekeys: getTypekeysAction.start,
  setLiveChatAction: setLiveChatAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
