import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSectionHardCoded'
import ReadOnlyField from 'components/ui/ReadOnlyField'
import BasicAccordion from 'components/ui/BasicAccordion'

import { history } from 'utils'
import { portalTools } from 'guidewire/portalTools'
import { dateStringToReadable, formatCurrencyObject, getDateXdaysBefore } from 'guidewire/formatTools'
import { formatVehicleToDisplay, formatDriverToDisplay } from 'guidewire/formatToDisplay'

import { EndPoint } from 'types/endpoint'
import { PrimaryAddress, SelectedPolicy, VehicleInfo } from 'types/policy'
import { LabelValuePair } from 'types/contentTypes'
import moment from 'moment'

const myCarDetails: LabelValuePair[] = [
  { label: 'Registration', value: 'registrationNumber' },
  { label: 'Fuel type', value: 'fuelType' },
  { label: 'Purchased date', value: 'purchasedDate' },
  { label: 'Year of registration', value: 'firstRegisteredYear' },
  {
    label: 'Market value',
    value: 'marketValue',
    infoIcon:
      'This is the cost or replacement with one of the same make, model and specification, considering the age, mileage and condition.  ',
  },
  { label: 'Estimated annual mileage', value: 'annualMileage' },
  { label: 'Security devices', value: 'securityDevices' },
  { label: 'Security tracker', value: 'securityTracker' },
  {
    label: 'Car modified',
    value: 'hasBeenModified',
    infoIcon:
      'This does not include modifications by the manufacturer or for a disability. Contact us if you have questions about a specific modification.',
  },
  { label: 'Right hand drive', value: 'rightHandDrive' },
  { label: 'Less than 8 seats', value: 'lessThanEightSeats' },
  { label: 'Car imported', value: 'imported' },
  {
    label: 'Registered keeper and owner of the car',
    value: 'registeredKeeper',
    infoIcon:
      'The owner is the person who has legal title to the car. The registered keeper is the person or organisation recorded by the DVLA as the legal keeper of the car.',
  },
]

const readOnlyFields: LabelValuePair[] = [
  { label: 'Address line 1', value: 'addressLine1' },
  { label: 'Town / City', value: 'city' },
  { label: 'Postcode', value: 'postalCode' },
]

const driverOtherDetails: LabelValuePair[] = [
  { label: 'Primary occupation', value: 'occupation' },
  { label: 'Marital status', value: 'maritalStatus' },
  { label: 'UK residency', value: 'ukResident5Years' },
  { label: 'Licence type', value: 'licenseType' },
  { label: 'Access to other vehicles', value: 'accessToOtherVehicles' },
  { label: 'Additional driving qualifications', value: 'drivingQualifications' },
  { label: 'Medical conditions', value: 'medicalConditions' },
  { label: 'Unspent non-motoring offences', value: 'nonMotoringConvictions' },
  { label: 'Previously cancelled insurance by an insurer', value: 'cancelledPreviousInsurance' },
  { label: 'Convictions', value: 'hasMotorConvictions' },
  { label: 'Claims', value: 'hasMotorClaims' },
]

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  bottomText: {
    marginLeft: 24,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
  darkBox: {
    background: '#6a6b70',
    color: 'white',
  },
  divider: {
    background: 'white',
  },
  policyBar: {
    paddingTop: 25,
    paddingBottom: 25,
    marginTop: 90,
  },
  policyHeader: {
    fontSize: '30px',
    color: 'white',
  },
  policySubtitle: {
    fontSize: '12px',
    color: 'white',
    paddingBottom: 9,
    marginBottom: 9,
    paddingTop: 5,
  },
}))

export interface Props {
  mtaResponse: any
  startDate: string
  current: SelectedPolicy
  correspondenceAddress: PrimaryAddress
  addedDrivers: any[]
  autoRenew: boolean
  cancelMTAChangesAction: VoidFunction
  showExecuteMTAModalAction: VoidFunction
  executeMTAChangesAction: (x: any) => void
}

const MTASummaryPage = ({
  mtaResponse,
  startDate,
  current,
  correspondenceAddress,
  addedDrivers,
  autoRenew,
  cancelMTAChangesAction,
  showExecuteMTAModalAction,
  executeMTAChangesAction,
}: Props) => {
  const classes = useStyles()

  const { myExcessPayment, drivers, overnightParkingAddress, vehicles, homeOwner } = mtaResponse

  const currentPolicy = portalTools(current)
  const currentOvernightParking = currentPolicy.overnightParking().details()

  //format vehicle values
  const newVehicles = vehicles.filter((x: { hasChanged: boolean }) => x.hasChanged == true)
  const newCar = newVehicles.length > 0 ? formatVehicleToDisplay(newVehicles[0] as VehicleInfo) : undefined
  //add anual mileage to newCar
  if (newCar != undefined) {
    newCar.annualMileage = current.productMiles
  }

  //format driver values
  //TODO check why newlyAdded is not set right with vithal
  //const newlyAddedDrivers = drivers.filter((x: { newlyAdded: boolean }) => x.newlyAdded == true)
  // const newDrivers = newlyAddedDrivers.map(x => formatDriverToDisplay(x))
  const newDrivers = addedDrivers.map(x => formatDriverToDisplay(x))
  const policyHolderDriver = currentPolicy.allDrivers().policyHolder('display')

  //is installemnt
  const isInstallment = mtaResponse?.paymentPlanId === 'bc:6' //is less then 2 installemnts left
  const installmentsLeft = current?.installmentDetails?.remainingInstallments || -1
  //is renewing
  const todaysDate = moment(new Date())
    .utc(false)
    .toISOString()
  const renewDate = getDateXdaysBefore(21, current?.expiration)
  const isRenewing = moment(todaysDate).isAfter(renewDate) && moment(todaysDate).isBefore(current?.expiration)

  //6 day payment cycle before my 2nd from last payment
  const is6DaysBefore2ndFromLastPayment =
    current?.installmentDetails?.remainingInstallments &&
    current?.installmentDetails?.nextPaymentDueDate &&
    current?.installmentDetails?.remainingInstallments === 2 &&
    moment(current?.installmentDetails?.nextPaymentDueDate)
      .subtract(6, 'd')
      .isAfter(moment())

  const showContactCallCenter = () => {
    return (
      isInstallment && is6DaysBefore2ndFromLastPayment && !isRenewing && !autoRenew && mtaResponse?.totalCost.amount < 0 //AC04 AC08 AC12 of TOA-2851
    )
  }

  const submit = () => {
    console.log(isInstallment)
    console.log(!isRenewing)
    console.log(!is6DaysBefore2ndFromLastPayment)
    console.log(current?.installmentDetails?.remainingInstallments >= 2)
    if (overnightParkingAddress.displayName !== currentOvernightParking.displayName) {
      history.push(EndPoint.MTA_CONFIRM)
    } else {
      if (
        (mtaResponse?.totalCost.amount < 0 && !showContactCallCenter()) ||
        (isInstallment &&
          !isRenewing &&
          !is6DaysBefore2ndFromLastPayment &&
          current?.installmentDetails?.remainingInstallments >= 2)
      ) {
        executeMTAChangesAction({
          current: current,
          mtaResponse: mtaResponse,
          existingCard: true,
        })
      } else {
        showExecuteMTAModalAction()
      }
    }
  }

  return (
    <>
      <Layout background={'#494950'}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          id="policyBar"
          className={classes.policyBar}
          spacing={4}
        >
          <Grid item xs={12} sm={8}>
            <Typography className={classes.policyHeader}>{'Your quote'}</Typography>
            {mtaResponse.totalCost.amount > 0 && (
              <Typography className={classes.policyHeader}>
                {'Additional cost ' + formatCurrencyObject(mtaResponse.totalCost)}
              </Typography>
            )}
            {mtaResponse.paymentPlanId === 'bc:6' && mtaResponse.totalCost.amount > 0 && (
              <Typography className={classes.policySubtitle}>
                {'This quote includes the change in premium for the remainder of your policy term, insurance premium tax at the appropriate rate and our fees to make the change.\n\nThis does not include the ' +
                  mtaResponse.pclFee +
                  '% transaction fee, which will be charged by Premium Credit Limited, and added to the below amount when calculating the revised monthly instalments.'}
              </Typography>
            )}
            {mtaResponse.paymentPlanId === 'bc:5' && mtaResponse.totalCost.amount > 0 && (
              <Typography className={classes.policySubtitle}>
                {
                  'This quote includes the change in premium for the remainder of your policy term, insurance premium tax at the appropriate rate and our fees to make the change.'
                }
              </Typography>
            )}
            {mtaResponse.totalCost.amount < 0 && (
              <Typography className={classes.policySubtitle}>
                {
                  'This is the amount that will be refunded to you, if you make this change to your policy. This refund includes the change in premium for the remainder of your policy term, insurance premium tax at the appropriate rate and our fees to make the change.'
                }
              </Typography>
            )}
          </Grid>
        </Grid>
      </Layout>
      <Layout>
        <Box pt={5}>
          <Grid item xs={12}>
            <Section>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>{mtaResponse.changeInCost.amount >= 0 ? 'Additional Premium' : 'Return Premium'}</b>
                      </TableCell>
                      <TableCell style={{ width: '50%' }}>
                        {formatCurrencyObject(
                          mtaResponse.changeInCost.amount > 0
                            ? mtaResponse.changeInCost
                            : { amount: mtaResponse.changeInCost.amount * -1, currency: 'gbp' },
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Our fees</b>
                      </TableCell>
                      <TableCell style={{ width: '50%' }}>{formatCurrencyObject(mtaResponse.itbFees)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{mtaResponse.totalCost.amount > 0 ? 'Total cost' : 'Total refund'}</b>
                      </TableCell>
                      <TableCell style={{ width: '50%' }}>
                        {formatCurrencyObject(
                          mtaResponse.totalCost.amount > 0
                            ? mtaResponse.totalCost
                            : { amount: mtaResponse.totalCost.amount * -1, currency: 'gbp' },
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Section>
          </Grid>
        </Box>
        <Box pt={5}>
          <Grid item xs={12}>
            <Section title="Review excess">
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Drivers</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Compulsory</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Voluntary</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>Total</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {myExcessPayment.damageExcesses.map(
                        (
                          item: {
                            driver: string
                            compulsoryExcess: number
                            voluntaryExcess: number
                            totalExcess: number
                          },
                          index: any,
                        ) => (
                          <TableRow key={`${index}`}>
                            <TableCell>{item.driver}</TableCell>
                            <TableCell align="right">{'£' + item.compulsoryExcess}</TableCell>
                            <TableCell align="right">{'£' + item.voluntaryExcess}</TableCell>
                            <TableCell align="right">{'£' + item.totalExcess}</TableCell>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Other compulsory excesses</b>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{'Windscreen replacement'}</TableCell>
                        <TableCell align="right">{myExcessPayment.glassReplacementExcess}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{'Windscreen repair'}</TableCell>
                        <TableCell align="right">{myExcessPayment.glassRepairExcess}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Section>
          </Grid>
        </Box>
        {mtaResponse.totalCost.amount > 0 &&
          mtaResponse.paymentPlanId !== 'bc:5' &&
          installmentsLeft >= 2 &&
          !(is6DaysBefore2ndFromLastPayment && !isRenewing && !autoRenew) && (
            <Box pt={5}>
              <Grid item xs={12}>
                <Paper elevation={0} className={classes.darkBox}>
                  <Box pl={0.5} pr={0.5} pt={2.5} pb={2.5}>
                    <Box p={2}>
                      <Typography>
                        <b>{'How to pay'}</b>
                      </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                    <Box p={2}>
                      {'The cost of £' + mtaResponse.totalCost.amount + ' will be added to your remaining instalments.'}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Box>
          )}
        {mtaResponse.totalCost.amount == 0 && (
          <Box pt={5}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.darkBox}>
                <Box pl={0.5} pr={0.5} pt={2.5} pb={2.5}>
                  <Box p={2}>
                    <Typography>
                      <b>{'How to pay'}</b>
                    </Typography>
                  </Box>
                  <Divider className={classes.divider} />
                  <Box p={2}>{'There is no charge for making this policy change.'}</Box>
                </Box>
              </Paper>
            </Grid>
          </Box>
        )}
        {mtaResponse.totalCost.amount < 0 && mtaResponse.paymentPlanId === 'bc:5' && (
          <Box pt={5}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.darkBox}>
                <Box pl={0.5} pr={0.5} pt={2.5} pb={2.5}>
                  <Box p={2}>
                    <Typography>
                      <b>{'How we will refund you'}</b>
                    </Typography>
                  </Box>
                  <Divider className={classes.divider} />
                  <Box p={2}>
                    <Typography>
                      {'We will refund £' +
                        mtaResponse.totalCost.amount +
                        ' to the most recently used card within 7-10 working days, following these changes being made to your policy.'}
                      <br />
                      <br />
                      <i>
                        {
                          'Please note we can only make a refund to a payment card that has been used for a policy transaction, such as buying your policy or making a mid-term adjustment.'
                        }
                      </i>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Box>
        )}
        {mtaResponse.totalCost.amount < 0 && mtaResponse.paymentPlanId !== 'bc:5' && (
          <Box pt={5}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.darkBox}>
                <Box pl={0.5} pr={0.5} pt={2.5} pb={2.5}>
                  <Box p={2}>
                    <Typography>
                      <b>{'How we will refund you'}</b>
                    </Typography>
                  </Box>
                  <Divider className={classes.divider} />
                  <Box p={2}>
                    <Typography>
                      {'The refund of £' +
                        mtaResponse.totalCost.amount +
                        ' will be deducted from your remaining instalments. Premium Credit Limited will contact you to confirm your revised instalment payments after this amendment is finalised.'}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Box>
        )}
        <Box pt={5}>
          <Grid item xs={12}>
            <Section title="Summary of changes">
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Change effective from</b>
                        </TableCell>
                        <TableCell>{dateStringToReadable(startDate)}</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box>
            </Section>
            <Section title="About you">
              <BasicAccordion title={policyHolderDriver.displayName}>
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Policy Holder</TableCell>
                          <TableCell align="right">{policyHolderDriver.displayName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Date of birth</TableCell>
                          <TableCell align="right">{policyHolderDriver.dateOfBirth.toString()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Primary occupation</TableCell>
                          <TableCell align="right">{policyHolderDriver.occupation}</TableCell>
                        </TableRow>
                        {correspondenceAddress && (
                          <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell align="right">
                              {correspondenceAddress?.displayName?.length > 0
                                ? correspondenceAddress.displayName
                                : overnightParkingAddress.displayName}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Home Owner</TableCell>
                          <TableCell align="right">{homeOwner ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </BasicAccordion>
            </Section>
            {newDrivers.length > 0 && (
              <Section title="New driver">
                {newDrivers.map((driver: any, index: any) => (
                  <BasicAccordion title={driver.firstName + ' ' + driver.lastName} key={driver.lastName + index}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {driverOtherDetails.map((item, index) => (
                            <TableRow key={`${item.value} ${index}`}>
                              <TableCell>{item.label}</TableCell>
                              <TableCell align="right">{driver[item.value]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BasicAccordion>
                ))}
              </Section>
            )}
            {newCar && (
              <Section title="New car">
                <BasicAccordion title={newCar.make + ' ' + newCar.model}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {myCarDetails.map(item => (
                          <TableRow key={item.label}>
                            <TableCell>
                              <div style={{ display: 'flex' }}>
                                {item.label}
                                {item.infoIcon && (
                                  <Tooltip title={item.infoIcon} placement="top">
                                    <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="right" style={{ width: '50%' }}>
                              {newCar[item.value]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </BasicAccordion>
              </Section>
            )}
            {overnightParkingAddress.displayName !== currentOvernightParking.displayName && (
              <Section title="New overnight address">
                <BasicAccordion title={overnightParkingAddress.displayName}>
                  <Box pt={2.5}>
                    <Grid container spacing={4}>
                      {readOnlyFields.map(item => (
                        <Grid item xs={12} sm={4} key={item.label}>
                          <Typography className={classes.readOnlyLabel}>{item.label}</Typography>
                          <ReadOnlyField value={overnightParkingAddress[item.value]} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </BasicAccordion>
              </Section>
            )}
            <Paper elevation={0}>
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                <Grid container justify="center" spacing={2}>
                  <Grid item>
                    <Button variant="outlined" color="secondary" onClick={cancelMTAChangesAction}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" color="primary" onClick={() => submit()}>
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Box>

        <Box pt={5}>
          <Grid item xs={12}>
            <Section title="Helpful information">
              <HelpSection showTitle={false} />
            </Section>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default MTASummaryPage
