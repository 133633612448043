import { connect } from 'react-redux'
import { getVehicleByRegistrationAction } from 'redux/car/actions'
import { continueCarChangeAction, cancelMTAChangesAction } from 'redux/mta/actions'
import { IReduxState } from 'types/stateTypes'
import ChangeCarPage from './ChangeCarPage'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  selectedVehicle: state.car.selectedVehicle,
  vehicleLookupError: state.car.vehicleLookupError,
  registration: state.car.registration,
  startDate: state.mta.startDate,
  jobId: state.mta.jobId,
})

const mapDispatchToProps = {
  getVehicleByRegistrationAction: getVehicleByRegistrationAction.start,
  continueCarChangeAction: continueCarChangeAction.start,
  cancelMTAChangesAction: cancelMTAChangesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCarPage)
