import React from 'react'
import { Button, FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core'

import { Field, Form, Formik, FormikProps } from 'formik'

import QuestionWithTextField from 'components/ui/QuestionWithTextField'
import QuestionWithPasswordField from 'components/ui/QuestionWithPasswordField'

import validationSchema, { initialValues } from './validationSchema'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: theme.palette.text.secondary,
    paddingTop: 20,
    paddingBottom: 20,
  },
  formLink: {
    textDecoration: 'underline',
    paddingTop: 20,
    paddingBottom: 40,
    cursor: 'pointer',
    width: '100%',
  },
}))

export interface Props {
  loginError: boolean
  loginErrorMessage: string
  signIn: (email: string, password: string) => void
  onPasswordForgottenClick: VoidFunction
}

const LoginForm = ({ loginError, loginErrorMessage, signIn, onPasswordForgottenClick }: Props) => {
  const onFormSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      signIn(values.email, values.password)
    }
  }

  const classes = useStyles()
  const formRef = React.useRef<FormikProps<any>>(null)

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema()}
        validateOnBlur={false}
        isInitialValid={false}
        enableReinitialize
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={4}>
              <Typography className={classes.formLabel}>{'Email address'}</Typography>
              <Field
                required
                fullWidth
                component={QuestionWithTextField}
                name="email"
                placeholder={'Enter your email address'}
              />

              <Typography className={classes.formLabel}>{'Password'}</Typography>
              <Field
                required
                fullWidth
                component={QuestionWithPasswordField}
                name="password"
                placeholder={'Enter your password'}
              />

              {/* TODO: QOL change - Hide the error message once the user starts retyping the email or password */}
              {loginError && <FormHelperText error={loginError}>{loginErrorMessage}</FormHelperText>}

              <a className={classes.formLink} onClick={onPasswordForgottenClick}>
                {'Forgotten your password?'}{' '}
              </a>

              <Button
                onClick={() => {
                  const submit = async () => await formRef?.current?.submitForm()
                  submit()
                }}
                fullWidth
                variant="outlined"
                color="primary"
                type="submit"
              >
                {'Sign in'}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default LoginForm
