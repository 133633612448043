import { makeStyles } from '@material-ui/core'
import DocumentIcon from '@material-ui/icons/Description'

export interface DocumentListItemProps {
  label: string
  onClick: () => void
}

const useStyles = makeStyles(theme => ({
  cursor: {
    cursor: 'pointer',
  },
  listItem: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
}))

const DocumentListItem = ({ label, onClick }: DocumentListItemProps) => {
  const classes = useStyles()

  return (
    <div className={classes.cursor} onClick={onClick}>
      <div className={classes.listItem}>
        <div>{label}</div>
        <DocumentIcon color="secondary" />
      </div>
    </div>
  )
}

export default DocumentListItem
