import { handleActions } from 'redux-actions'
import { isSnackbarOpenAction } from './actions'

const defaultState = {
  isOpen: false,
  message: '',
  error: false,
}

export default handleActions(
  {
    [isSnackbarOpenAction.success]: (
      state,
      { payload }: { payload: { isOpen: boolean; message: string; error: boolean } },
    ) => {
      return {
        ...state,
        isOpen: payload.isOpen,
        message: payload.message ? payload.message : '',
        error: payload.error ? payload.error : false,
      }
    },
  },
  defaultState,
)
