import { Accordion, AccordionSummary, Grid, makeStyles, Typography, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    border: '0',
    boxShadow: 'none',
    borderBottom: (props: any) => (props.useBorder ? '1px solid #eff0f0' : 0),
    '&:last-child': {
      borderRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row',
    },
  },
  label: {
    fontWeight: 600,
    flexBasis: '40%',
  },
  value: {
    flexBasis: '60%',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      marginLeft: 22,
    },
  },
  container: {
    width: '100%',
  },
}))

export interface Props {
  title: string
  value: string
  children: React.ReactNode
  useBorder?: boolean
}

const SummaryAccordion = ({ title, value, children, useBorder = true }: Props) => {
  const props = { useBorder }
  const classes = useStyles(props)

  return (
    <Accordion className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.dropdown}>
              <Typography className={classes.label}>{title}</Typography>
              <Typography className={classes.value}>{value}</Typography>
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div>{children}</div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default SummaryAccordion
