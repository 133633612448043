import { createPhasedAction } from 'redux/actionCreators'

export const { showMTAModalAction } = createPhasedAction('SHOW_M_T_A_MODAL_ACTION')

export const { closeMTAModalAction } = createPhasedAction('CLOSE_M_T_A_MODAL_ACTION')

export const { continueAddressChangeAction } = createPhasedAction('CONTINUE_ADDRESS_CHANGE_ACTION')

export const { continueCarChangeAction } = createPhasedAction('CONTINUE_CAR_CHANGE_ACTION')

export const { continueAddDriverAction } = createPhasedAction('CONTINUE_ADD_DRIVER_ACTION')

export const { deleteAddedDriverAction } = createPhasedAction('DELETE_ADDED_DRIVER_ACTION')

export const { cancelMTAChangesAction } = createPhasedAction('CANCEL_M_T_A_CHANGES_ACTION')

export const { confirmMTAChangesAction } = createPhasedAction('CONFIRM_M_T_A_CHANGES_ACTION')

export const { executeMTAChangesAction } = createPhasedAction('EXECUTE_M_T_A_CHANGES_ACTION')

export const { multiMTAAction } = createPhasedAction('MULTI_M_T_A_ACTION')

export const { showExecuteMTAModalAction } = createPhasedAction('SHOW_EXECUTE_M_T_A_MODAL_ACTION')

export const { bindPaymentAction } = createPhasedAction('BIND_PAYMENT_ACTION')

export const { resetAction } = createPhasedAction('RESET_ACTION')

export const { setShowExecuteModalAction } = createPhasedAction('SET_SHOW_EXECUTE_MODAL_ACTION')
