import { handleActions } from 'redux-actions'
import { getLoginPageContentAction, loginAction, forgotPasswordAction, isLoggedInAction, logoutAction } from './actions'

const defaultState = {
  content: [],
  loginError: false,
  loginErrorMessage: '',
  isLoggedIn: false,
  refreshAfterTime: 0,
}

export default handleActions(
  {
    [getLoginPageContentAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.content) {
        return state
      }
      return {
        ...state,
        content: payload.content,
      }
    },

    [loginAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.refreshAfterTime) {
        return state
      }
      return {
        ...state,
        refreshAfterTime: payload.refreshAfterTime,
        loginError: false,
      }
    },

    [isLoggedInAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        isLoggedIn: payload,
      }
    },

    [forgotPasswordAction.success]: (state, { payload }: { payload: any }) => {
      return state
    },

    [loginAction.error]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        loginError: true,
        loginErrorMessage: payload.loginErrorMessage || 'ERROR',
      }
    },
    [logoutAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        access_token: '',
      }
    },
  },
  defaultState,
)
