import { connect } from 'react-redux'
import { loginAction, forgotPasswordAction, resetPasswordAction, getLoginPageContentAction } from 'redux/login/actions'

import { IReduxState } from 'types/stateTypes'
import LoginPage from './LoginPage'

const mapStateToProps = (state: IReduxState) => ({
  content: state.login.content,
  loginError: state.login.loginError,
  loginErrorMessage: state.login.loginErrorMessage,
})

const mapDispatchToProps = {
  getLoginPageContentAction: getLoginPageContentAction.start,
  signIn: loginAction.start,
  sendResetPasswordToEmail: forgotPasswordAction.start,
  sendResetPassword: resetPasswordAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
