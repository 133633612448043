import React from 'react'

import { Button, Grid, makeStyles, Typography } from '@material-ui/core'

import Modal from 'components/ui/Modal'
import { LoginRequest } from 'types/requests'
import validationSchema, { initialValues } from '../LoginForm/validationSchema'
import { Field, Form, Formik, FormikProps } from 'formik'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'
import QuestionWithPasswordField from 'components/ui/QuestionWithPasswordField'

export interface Props {
  showLoginModal: boolean
  contactNumbersMessage: string
  emailOrPasswordMessage: string
  redirect?: string
  closeLoginModalAction: () => void
  onLogout: () => void
  signIn: (requestObject: { requestObject: LoginRequest; redirect?: string }) => void
}

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: theme.palette.text.secondary,
    paddingTop: 20,
    paddingBottom: 20,
  },
  formButtons: {
    margin: 'auto',
    paddingTop: 20,
  },
}))

const LoginModal = ({
  showLoginModal,
  contactNumbersMessage,
  emailOrPasswordMessage,
  redirect,
  closeLoginModalAction,
  onLogout,
  signIn,
}: Props) => {
  const classes = useStyles()
  const formRef = React.useRef<FormikProps<any>>(null)

  const onFormSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      signIn({ requestObject: { email: values.email, password: values.password }, redirect })
      closeLoginModalAction()
    }
  }

  return (
    <Modal open={showLoginModal} onClose={() => console.log('Closed')}>
      <Typography>{contactNumbersMessage}</Typography>
      <Typography>{emailOrPasswordMessage}</Typography>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema()}
        validateOnBlur={false}
        isInitialValid={false}
        enableReinitialize
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={4}>
              <Typography className={classes.formLabel}>{'Email address'}</Typography>
              <Field
                required
                fullWidth
                component={QuestionWithTextField}
                name="email"
                placeholder={'Enter your email address'}
              />

              <Typography className={classes.formLabel}>{'Password'}</Typography>
              <Field
                required
                fullWidth
                component={QuestionWithPasswordField}
                name="password"
                placeholder={'Enter your password'}
              />

              <div className={classes.formButtons}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    onLogout()
                    closeLoginModalAction()
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const submit = async () => await formRef?.current?.submitForm()
                    submit()
                  }}
                >
                  Login
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default LoginModal
