import React from 'react'
import { FieldProps, getIn } from 'formik'
import DateFnsUtils from '@date-io/date-fns'
import subYears from 'date-fns/subYears'
import addMonths from 'date-fns/addMonths'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'

export interface Props {
  name: string
  format?: string
  disablePast?: boolean
  disableFuture?: boolean
  dob?: boolean
  oneMonthRange?: boolean
  fullWidth?: boolean
  minDate?: string
  onDateChanged?: (name: string, date: Date) => void
}

const useStyles = makeStyles(theme => ({
  fullInputLabel: {
    width: '100%',
    '& .MuiInputLabel-formControl': {
      left: `-${theme.spacing(2)}px`,
    },
    margin: 0,
  },
  inputLabel: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      width: '100%',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
    '& .MuiInputLabel-formControl': {
      left: `-${theme.spacing(2)}px`,
    },
    '& .MuiOutlinedInput-adornedEnd ': {
      paddingRight: 0,
    },
    margin: 0,
  },
}))

const QbDatePicker = ({
  field,
  form,
  name,
  format = 'HH : mm',
  disablePast,
  disableFuture,
  dob = false,
  oneMonthRange = false,
  fullWidth = false,
  minDate,
  onDateChanged,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(field.value)
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const submitCount = form.submitCount
  const errorText = error && touched ? error : null

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        {...field}
        {...props}
        ampm={false}
        openTo="hours"
        format="HH:mm"
        value={selectedDate || null}
        placeholder="Choose a time"
        onChange={date => {
          setSelectedDate(date)
          form.setFieldValue(field.name, date, submitCount > 0)
          form.setTouched({ ...form.touched, [field.name]: true })
          if (date && onDateChanged) {
            onDateChanged(field.name, date)
          }
        }}
        inputVariant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        views={format === 'HH : mm' ? ['hours', 'minutes'] : undefined}
        error={!!errorText}
        helperText={errorText}
        keyboardIcon={<AccessTimeIcon />}
      />
    </MuiPickersUtilsProvider>
  )
}

export default QbDatePicker
