import { Field } from 'formik'

import { Box, Grid } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'

export interface BillingDetailsSectionContent {
  id: string
  title: string
  body: string
  cardHolderFirstname: string
  cardHolderFirstnameCaption?: string
  cardHolderFirstnameToolTip?: string
  cardHolderSurname: string
  cardHolderSurnameCaption?: string
  cardHolderSurnameToolTip?: string
  cardHolderEmailAddress: string
  cardHolderEmailAddressCaption?: string
  cardHolderEmailAddressToolTip: string
  cardHolderPhoneNumber: string
  cardHolderPhoneNumberCaption?: string
  cardHolderPhoneNumberToolTip?: string
}

export interface Props {
  content: BillingDetailsSectionContent
}

const BillingDetailsSection = ({ content }: Props) => {
  const {
    cardHolderFirstname,
    cardHolderFirstnameCaption,
    cardHolderFirstnameToolTip,
    cardHolderSurname,
    cardHolderSurnameCaption,
    cardHolderSurnameToolTip,
    cardHolderEmailAddress,
    cardHolderEmailAddressCaption,
    cardHolderEmailAddressToolTip,
    cardHolderPhoneNumber,
    cardHolderPhoneNumberCaption,
    cardHolderPhoneNumberToolTip,
  } = content

  return (
    <Layout>
      <Box pt={5} pl={2} pr={2}>
        <Grid container direction="column" spacing={4}>
          <Field
            required
            component={QuestionWithTextField}
            name="cardHolderFirstname"
            question={cardHolderFirstname}
            hint={cardHolderFirstnameCaption}
            toolTip={cardHolderFirstnameToolTip}
            inputStyle="capitalize"
          />
          <Field
            required
            component={QuestionWithTextField}
            name="cardHolderSurname"
            question={cardHolderSurname}
            hint={cardHolderSurnameCaption}
            toolTip={cardHolderSurnameToolTip}
            inputStyle="capitalize"
          />
          <Field
            required
            component={QuestionWithTextField}
            name="cardHolderEmailAddress"
            question={cardHolderEmailAddress}
            hint={cardHolderEmailAddressCaption}
            toolTip={cardHolderEmailAddressToolTip}
          />
          <Field
            required
            component={QuestionWithTextField}
            name="cardHolderPhoneNumber"
            question={cardHolderPhoneNumber}
            hint={cardHolderPhoneNumberCaption}
            toolTip={cardHolderPhoneNumberToolTip}
          />
        </Grid>
      </Box>
    </Layout>
  )
}

export default BillingDetailsSection
