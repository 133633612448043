import React from 'react'

import { Modal as MaterialModal, Box, Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      padding: 30,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: 50,
    },
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    width: '90%',
    maxWidth: 700,
    maxHeight: '90vh',
    overflowX: 'scroll',
  },
}))

export interface Props {
  children: React.ReactNode
  open: boolean
  onClose: VoidFunction
}

const Modal = ({ children, open, onClose }: Props) => {
  const classes = useStyles()

  return (
    <MaterialModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.box}>
        <Grid container spacing={4}>
          {React.Children.map(children, child => {
            return (
              <Grid item xs={12}>
                {child}
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </MaterialModal>
  )
}

export default Modal
