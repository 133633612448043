import { Button, Grid, makeStyles } from '@material-ui/core'

import Modal from 'components/ui/Modal'
import { SelectedPolicy } from 'types/policy'
import { AddressById } from 'types/responses'

export interface Props {
  current: SelectedPolicy
  startDate: string
  changedAddress: AddressById | undefined
  homeOwner: string
  dayTimeParking: string
  nightTimeParking: string
  addedDrivers: any[]
  changedCar: any
  carPurchaseDate: string
  marketValue: string
  hasBeenModified: string
  generalAfterMarketMods : any[]
  bodyWorkMods: any[]
  engineMods: any[]
  wheelsOrTyresMods: any[]
  conversionMods: any[]
  otherMods: any[]
  securityDevices: string
  classOfUse: string
  registeredKeeper: string
  securityTracker: string
  imported: string
  rightHandDrive: string
  lessThanEightSeats: ''
  showMTAConfirmModal: boolean
  registration: string
  vehicleIsLeased: boolean
  vehicleLeasingCompany: string
  vehicleOwner: string
  confirmMTAChangesAction: (x: any) => void
  cancelMTAChangesAction: VoidFunction
}

const useStyles = makeStyles(theme => ({
  firstButton: {
    marginLeft: 'auto',
    marginRight: 10,
  },
  modalText: {
    paddingTop: 0,
    marginTop: 0,
  },
}))

const MTAConfirmModal = ({
  current,
  startDate,
  changedAddress,
  homeOwner,
  dayTimeParking,
  nightTimeParking,
  addedDrivers,
  changedCar,
  carPurchaseDate,
  marketValue,
  hasBeenModified,
  generalAfterMarketMods,
  bodyWorkMods,
  engineMods,
  wheelsOrTyresMods,
  conversionMods,
  otherMods,
  securityDevices,
  classOfUse,
  registeredKeeper,
  securityTracker,
  imported,
  rightHandDrive,
  lessThanEightSeats,
  showMTAConfirmModal,
  registration,
  vehicleIsLeased,
  vehicleLeasingCompany,
  vehicleOwner,
  confirmMTAChangesAction,
  cancelMTAChangesAction,
}: Props) => {
  const classes = useStyles()

  return (
    <Modal open={showMTAConfirmModal} onClose={cancelMTAChangesAction}>
      <Grid item xs={12}>
        <h1>{`Please note`}</h1>
        <p className={classes.modalText}>
          {
            'Each policy change will incur a fee when processed individually but if you make multiple changes at once, you will only pay the highest fee incurred for a single change. Please refer to our '
          }
          <a href={window.location.origin + '/TYA_Important_Information_v3.pdf'} target="_blank" rel="noreferrer">
            {'Important Information document'}
          </a>
          {' for a summary of our charges.'}
        </p>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.firstButton}
          onClick={() => {
            cancelMTAChangesAction()
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            confirmMTAChangesAction({
              startDate,
              changedAddress,
              homeOwner,
              dayTimeParking,
              nightTimeParking,
              addedDrivers,
              changedCar,
              carPurchaseDate,
              marketValue,
              hasBeenModified,
              generalAfterMarketMods,
              bodyWorkMods,
              engineMods,
              wheelsOrTyresMods,
              conversionMods,
              otherMods,
              securityDevices,
              classOfUse,
              registeredKeeper,
              securityTracker,
              imported,
              rightHandDrive,
              lessThanEightSeats,
              current,
              jobId: '',
              registrationNumber: registration,
              vehicleIsLeased,
              vehicleLeasingCompany,
              vehicleOwner,
            })
          }
        >
          Continue
        </Button>
      </Grid>
    </Modal>
  )
}

export default MTAConfirmModal
