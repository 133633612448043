import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core'

import Question from 'components/ui/Question'
import { ContentType } from 'types/contentTypes'

import { OvernightParkingAddress } from 'types/policy'

const useStyles = makeStyles(theme => ({
  address: {
    padding: 14,
    width: '100%',
    marginLeft: 'auto',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
    backgroundColor: theme.palette.background.default,
  },
  changeButton: {
    marginLeft: 'auto',
  },
}))

export interface Props {
  address: OvernightParkingAddress
  editAddress: VoidFunction
}

const YourAddress = ({ address, editAddress }: Props) => {
  const classes = useStyles()

  const { addressLine1, addressLine2, addressLine3, city, county, postalCode } = address

  return (
    <Grid container direction="column" spacing={4}>
      <Question question={'Your address'}>
        <Paper className={classes.address} elevation={0}>
          <Typography>{addressLine1}</Typography>
          {addressLine2 && <Typography>{addressLine2}</Typography>}
          {addressLine3 && <Typography>{addressLine3}</Typography>}
          <Typography>{city}</Typography>
          <Typography>{county}</Typography>
          <Typography>{postalCode}</Typography>
        </Paper>
      </Question>

      {/* Disabled MTA */}
      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <Button variant="outlined" color="primary" className={classes.changeButton} onClick={editAddress}>
            Update overnight parking address
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default YourAddress
