import { Box, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import Layout from 'components/ui/Layout'
import Question from 'components/ui/Question'
import QuestionRowButton from 'components/ui/QuestionRowButton'
import { FormikProps } from 'formik'
import React from 'react'
import { Address } from 'types/generalTypes'

const useStyles = makeStyles(theme => ({
  address: {
    padding: 14,
    width: '100%',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
    backgroundColor: theme.palette.background.default,
  },
}))

export interface YourChosenAddressContent {
  id: string
  yourChosenAddress: string
  yourChosenAddressCaption: string
  changeAddressBtn: string
}

export interface Props {
  content: YourChosenAddressContent
  address: Address
  addressLineOneEdit: boolean
  form: FormikProps<any>
  onChangeAddress: () => void
  editAddressLineOne: (value: string) => void
}

const YourAddress = ({
  content,
  address,
  addressLineOneEdit = false,
  form,
  onChangeAddress,
  editAddressLineOne,
}: Props) => {
  const classes = useStyles()

  const { yourChosenAddress, yourChosenAddressCaption, changeAddressBtn } = content
  const { addressLine1, addressLine2, addressLine3, city, county, postalCode } = address

  return (
    <Layout>
      <Box pt={5} pb={5} pl={2} pr={2}>
        <Grid container direction="column" spacing={4}>
          <Question question={yourChosenAddress} hint={yourChosenAddressCaption}>
            <Paper className={classes.address} elevation={0}>
              {addressLineOneEdit === false && <Typography>{addressLine1}</Typography>}
              {addressLineOneEdit === true && (
                <TextField
                  name="addressLine1"
                  value={form?.values.addressLine1}
                  onChange={form?.handleChange}
                  onBlur={() => editAddressLineOne(form?.values.addressLine1)}
                />
              )}

              {addressLine2 && <Typography>{addressLine2}</Typography>}
              {addressLine3 && <Typography>{addressLine3}</Typography>}
              <Typography>{city}</Typography>
              <Typography>{county}</Typography>
              <Typography>{postalCode}</Typography>
            </Paper>
          </Question>

          <QuestionRowButton
            btnText={changeAddressBtn}
            variant="outlined"
            color="secondary"
            onClick={onChangeAddress}
          />
        </Grid>
      </Box>
    </Layout>
  )
}

export default YourAddress
