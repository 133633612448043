import { Cost } from 'guidewire/policy/types/cost'
import { getTypekeyValue } from 'guidewire/typekeys/getTypekeyValue'
import moment from 'moment'
import { Vehicle } from '../policy/types/vehicle'
import { capitalizeString } from './capitalizeString'
import { costObjectToString } from './costObjectToString'

export const vehicleToDisplay = (vehicle: Vehicle) => {
  const vehicleDisplay = Object.fromEntries(
    Object.keys(vehicle).map((key: keyof Vehicle) => {
      if (typeof vehicle[key] === 'boolean') return [key, vehicle[key] === true ? 'Yes' : 'No']

      if (key === 'colour' || key === 'firstRegisteredMonth' || key === 'purchasedMonth') {
        return [key, capitalizeString(vehicle[key].toLocaleLowerCase())]
      }
      if (key === 'marketValue' || key === 'ncdCosts') {
        return [key, costObjectToString(vehicle[key] as Cost)]
      }
      if (key === 'securityDevices') {
        return [key, getTypekeyValue(vehicle[key], 'typekey.VehicleSecurityDeviceType_itb')]
      }
      if (key === 'classOfUse') {
        return [key, getTypekeyValue(vehicle[key], 'typekey.VehicleClassOfUseType_itb')]
      }
      if (key === 'dayTimeParking') {
        return [key, getTypekeyValue(vehicle[key], 'typekey.VehicleDayTimeParkingType_itb')]
      }
      if (key === 'nightTimeParking') {
        return [key, getTypekeyValue(vehicle[key], 'typekey.VehicleNightTimeParkingType_itb')]
      }
      if (key === 'registrationNumber') return [key, vehicle[key].toLocaleUpperCase()]

      return [key, vehicle[key]]
    }),
  )

  vehicleDisplay.firstRegisteredDate = moment(`${vehicle.firstRegisteredMonth} ${vehicle.firstRegisteredYear}`).format(
    'MM / YYYY',
  )

  vehicleDisplay.purchasedDate = moment(`${vehicle.purchasedMonth} ${vehicle.purchasedYear}`).format('MM / YYYY')

  return vehicleDisplay
}
