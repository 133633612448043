import React from 'react'
import { Field } from 'formik'

import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'

import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns'

import Convictions, { IConvictionsSection } from './Convictions'
import Claims, { IClaimsSection } from './Claims'

import { LabelValuePair } from 'types/contentTypes'
import { Conviction, Claim } from 'types/policyTypes'

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}))

export interface IConvictionsAndClaimsSectionContent {
  id?: string
  title: string
  convictions: string
  convictionsCaption?: string
  convictionsToolTip?: string
  convictionsOptions: LabelValuePair[]
  claims: string
  claimsCaption?: string
  claimsToolTip?: string
  claimsOptions: LabelValuePair[]
}

interface Props {
  content: IConvictionsAndClaimsSectionContent
  convictionsContent: IConvictionsSection
  claimsContent: IClaimsSection
  values?: any
  convictionCodes: LabelValuePair[]
  confirmConvictions: (x: Conviction[]) => void
  confirmClaims: (x: Claim[]) => void
}

const ConvictionsAndClaimsSection = ({
  content,
  values,
  convictionCodes,
  convictionsContent,
  claimsContent,
  confirmConvictions,
  confirmClaims,
}: Props) => {
  const {
    title,
    convictions,
    convictionsCaption,
    convictionsToolTip,
    convictionsOptions,
    claims,
    claimsCaption,
    claimsToolTip,
    claimsOptions,
  } = content

  const classes = useStyles()

  const [showConvictions, setShowConvictions] = React.useState(values.hasMotorConvictions === 'yes')
  const [showClaims, setShowClaims] = React.useState(values.hasMotorClaims === 'yes')

  const onClaimsOrConvictions = (name: string, value: string) => {
    if (name === 'hasMotorConvictions' || name === 'hasMotorClaims') {
      if (name === 'hasMotorConvictions' && value === 'yes') setShowConvictions(true)
      if (name === 'hasMotorConvictions' && value === 'no') setShowConvictions(false)

      if (name === 'hasMotorClaims' && value === 'yes') setShowClaims(true)
      if (name === 'hasMotorClaims' && value === 'no') setShowClaims(false)
    }
  }

  return (
    <>
      <Typography style={{ fontSize: 20, fontWeight: 800 }}>{title}</Typography>
      <Box pt={4} pb={2}>
        <Divider />
      </Box>

      <Box pl={2.5} pr={2.5} pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Field
              component={QuestionWithRadioBtns}
              row
              name="hasMotorConvictions"
              question={convictions}
              hint={convictionsCaption}
              toolTip={convictionsToolTip}
              options={convictionsOptions}
              onChange={({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) =>
                onClaimsOrConvictions(name, value)
              }
            />
          </Grid>

          {/* 
            TODO: Add onConfirm functionality - Should be possible to copty direct from QB
            TODO: Change edit to work with a dynamic value depending on if the user is editing or not 
          */}

          {showConvictions && (
            <Grid item xs={12} className={classes.fieldContainer}>
              <Convictions
                content={convictionsContent}
                convictionCodes={convictionCodes}
                onConfirm={pmConvictions => confirmConvictions(pmConvictions)}
                convictions={values.pmMotorConvictions ? values.pmMotorConvictions : []}
                // confirmed={edit}
                confirmed={false}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={8} className={classes.fieldContainer}>
            <Field
              component={QuestionWithRadioBtns}
              row
              name="hasMotorClaims"
              question={claims}
              options={claimsOptions}
              hint={claimsCaption}
              toolTip={claimsToolTip}
              onChange={({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) =>
                onClaimsOrConvictions(name, value)
              }
            />
          </Grid>

          {/* 
            TODO: Add onConfirm functionality - Should be possible to copty direct from QB
            TODO: Change edit to work with a dynamic value depending on if the user is editing or not 
          */}

          {showClaims && (
            <Grid item xs={12} className={classes.fieldContainer}>
              <Claims
                content={claimsContent}
                onConfirm={pmClaims => confirmClaims(pmClaims)}
                claims={values.pmClaims ? values.pmClaims : []}
                // confirmed={edit}
                confirmed={false}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default ConvictionsAndClaimsSection
