import {
  Box,
  Button,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import SVG from 'react-inlinesvg'
import moment from 'moment'
import InfoIcon from '@material-ui/icons/Info'

import { LabelValuePair } from 'types/generalTypes'
import { MyCoverValues, VehicleInfoDisplay, AddOn } from 'types/policy'

// CMS Content
import { myCoverfirstRow, myCover, myAddons, myCarDetailsfirstRow, myCarDetails } from 'mock/cms/current_policy_tab'
import { getBreakdownName } from 'utils/content/options'

const useStyles = makeStyles(theme => ({
  changeButton: {
    marginLeft: 'auto',
  },
}))

const defactoDate = moment('20230601', 'YYYYMMDD')

export interface Props {
  myCoverValues: MyCoverValues
  myCarDetailsValues: VehicleInfoDisplay
  showChangeCar?: boolean
  editCar: VoidFunction
}

const showAddons = (allAddons: LabelValuePair[], selectedAddons: AddOn[], myCoverValues: any) => {
  const effectiveDate = moment(myCoverValues.effective)

  if ((effectiveDate).isSameOrAfter(defactoDate)){

    const itemToRemoveIndex = allAddons.findIndex(function(item) {
       return item.codeIdentifier === 'TMKeyProtectCov';
    });
    if(itemToRemoveIndex !== -1){
      allAddons.splice(itemToRemoveIndex, 1);
    }
  } else {
    // do nothing
  }
  return allAddons.map(addon => {
    if (addon.codeIdentifier) {
      //check if it is selecetd
      const selectedAddon = selectedAddons.find(x => x.codeIdentifier === addon.codeIdentifier)
      if (selectedAddon !== undefined) {
        let value = selectedAddon?.terms.length > 0 ? selectedAddon.terms[0].chosenTermValue : 'Yes'

        if (addon.codeIdentifier === 'PMDrivingAbroadCov') {
          value += ' Days'
        } else if (addon.codeIdentifier === 'TMBreakdownCov') {
          value = getBreakdownName(value)
        }

        return {
          ...addon,
          value: value,
        }
      }
    }
    return addon
  })
}

export const MyCoverDetails = ({ myCoverValues, myCarDetailsValues, showChangeCar = true, editCar }: Props) => {
  const classes = useStyles()
  const effectiveDate = moment(myCoverValues.effective)
  if ((effectiveDate).isSameOrAfter(defactoDate)){
       // do nothing
      } else {
      const itemToRemoveIndex = myCover.findIndex(function(item) {
         return item.label === 'Key Cover';
      });
      if(itemToRemoveIndex !== -1){
        myCover.splice(itemToRemoveIndex, 1);
      }
  }

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: 600, marginBottom: 56 }}>My Cover</Typography>

          <TableContainer>
            <Table>
              <TableBody>
                {/* Emphasis first row */}
                <TableRow>
                  <TableCell>
                    <div style={{ display: 'flex' }}>
                      {myCoverfirstRow.label}
                      {myCoverfirstRow.infoIcon && (
                        <Tooltip title={myCoverfirstRow.infoIcon} placement="top">
                          <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="right" style={{ color: 'red', width: '50%' }}>
                    {myCoverValues[myCoverfirstRow.value] === 'Canceled'
                      ? 'Cancelled'
                      : myCoverValues[myCoverfirstRow.value]}
                  </TableCell>
                </TableRow>

                {/* List loop */}
                {myCover.map(item => (
                  <TableRow key={item.label}>
                    <TableCell>
                      <div style={{ display: 'flex' }}>
                        {item.label}
                        {item.infoIcon && (
                          <Tooltip title={item.infoIcon} placement="top">
                            <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right" style={{ width: '50%' }}>
                      {myCoverValues[item.value]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box pb={4} />

          <Typography style={{ fontWeight: 600, marginBottom: 56 }}>Included add-ons</Typography>
          <TableContainer>
            <Table>
              <TableBody>
                {showAddons(myAddons, myCoverValues.addOns, myCoverValues).map(item => (
                  <TableRow key={item.label}>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SVG style={{ marginRight: 10 }} src={item.icon as string} width={35} title={item.label} />

                        {item.label}

                        {item.infoIcon && (
                          <Tooltip title={item.infoIcon} placement="top">
                            <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right" style={{ width: '50%' }}>
                      {item.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: 600, marginBottom: 56 }}>My Car Details</Typography>
          <TableContainer>
            <Table>
              <TableBody>
                {/* Emphasis first row */}
                <TableRow>
                  <TableCell>{myCarDetailsfirstRow.label}</TableCell>
                  <TableCell align="right" style={{ color: 'red', width: '50%' }}>
                    {myCarDetailsValues[myCarDetailsfirstRow.value]}
                  </TableCell>
                </TableRow>

                {myCarDetails.map(item => (
                  <TableRow key={item.label}>
                    <TableCell>
                      <div style={{ display: 'flex' }}>
                        {item.label}
                        {item.infoIcon && (
                          <Tooltip title={item.infoIcon} placement="top">
                            <InfoIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right" style={{ width: '50%' }}>
                      {myCarDetailsValues[item.value]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {showChangeCar && (
          <Grid item xs={12} sm={12}>
            <Grid container justify="flex-end">
              <Button variant="outlined" color="primary" className={classes.changeButton} onClick={editCar}>
                Change car
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default MyCoverDetails
