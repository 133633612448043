import { validateYupSchema } from 'formik'
import moment from 'moment'
import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    startDate: yup
      .string()
      .nullable()
      .required('Please complete the field')
      .test('value', 'Invalid date', value => moment(value).isValid()),
    startTime: yup
      .string()
      .required('Please complete the field')
      .test('value', 'Please choose a time that is 30 minutes or more in the future', function(value) {
        const { startDate } = this.parent
        const pStartDate = moment(startDate).utc(true)
        const pStartTime = moment(value).utc(true)

        pStartDate.set('hours', pStartTime.hours())
        pStartDate.set('minutes', pStartTime.minutes())

        //check with now and add 30 mins
        const now = moment().utc(true)
        now.add(30, 'minute')

        return now.isBefore(pStartDate)
      }),
    // Address lookup
    postCodeLookup: yup
      .string()
      .required('Please complete the field')
      .test(
        'value',
        `Please ensure you are providing a valid postcode`,
        value =>
          value !== undefined &&
          /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/.test(
            value.toUpperCase(),
          ),
      ),
    addressSelect: yup.string().required('Please complete the field'),
    homeOwner: yup.string().required('Please choose one of the possible options'),
    dayTimeParking: yup
      .string()
      .required()
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    nightTimeParking: yup
      .string()
      .required()
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
  })

export const initialValues = {
  startDate: '',
  startTime: '',
  // Address lookup
  postCodeLookup: '',
  addressSelect: '',
  homeOwner: '',
  dayTimeParking: '',
  nightTimeParking: '',
}

export default validationSchema
