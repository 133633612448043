import { connect } from 'react-redux'
import { getAddressByIdAction, getAddressByPostalCodeAction } from 'redux/account/actions'
import { continueAddressChangeAction, cancelMTAChangesAction } from 'redux/mta/actions'
import { IReduxState } from 'types/stateTypes'
import ChangeOvernightAddressPage from './ChangeOvernightAddressPage'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  lookupAddresses: state.account.lookupAddresses,
  selectedAddress: state.account.selectedAddress,
  startDate: state.mta.startDate,
  jobId: state.mta.jobId,
})

const mapDispatchToProps = {
  getAddressByPostalCodeAction: getAddressByPostalCodeAction.start,
  getAddressByIdAction: getAddressByIdAction.start,
  continueAddressChangeAction: continueAddressChangeAction.start,
  cancelMTAChangesAction: cancelMTAChangesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOvernightAddressPage)
