import moment from 'moment'
import { LicenseType } from 'types/contentTypes'
import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .required()
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    firstName: yup
      .string()
      .required('Please complete the field')
      .test('value', 'Too long', value => value !== undefined && value.length <= 30)
      .test(
        'value',
        'Your name contains invalid characters. Please check and re-enter using letters, hyphens and spaces only',
        value => value !== undefined && /^[a-zA-Z]+(?:[-' \s][a-zA-Z]+)*$/.test(value),
      ),
    lastName: yup
      .string()
      .required('Please complete the field')
      .test('value', 'Too long', value => value !== undefined && value.length <= 30)
      .test(
        'value',
        'Your name contains invalid characters. Please check and re-enter using letters, hyphens and spaces only',
        value => value !== undefined && /^[a-zA-Z]+(?:[-' \s][a-zA-Z]+)*$/.test(value),
      ),
    dateOfBirth: yup
      .string()
      .nullable()
      .required('Please complete the field')
      .test('value', 'Invalid date', value => moment(value).isValid())
      .test(
        'value',
        'This is outside the age range we cover',
        value => value !== undefined && moment(value).isBefore(moment(new Date()).subtract(17, 'years')),
      )
      .test('value', 'This is outside the age range we cover', value => {
        let year = 0
        //get year from date string
        if (value) {
          const dateArray = value.toString().split(' ')
          for (let i = 0; i < dateArray.length; i++) {
            const dateElement = dateArray[i]

            if (dateElement.length === 4) {
              year = parseInt(dateElement)
              break
            }
          }
        }
        return value !== undefined && year >= 1900
      }),
    ukResident5Years: yup.string().required('Please choose one of the possible options'),
    ukResidentSinceDate: yup.string().when('ukResident5Years', {
      is: (value: string) => value === 'no',
      then: yup
        .string()
        .nullable()
        .required('Please complete the field')
        .test('value', 'Invalid date', value => moment(value).isValid())
        .test(
          'value',
          'Please choose a valid past date',
          value => value !== undefined && moment(moment(value)).isBefore(moment(new Date())),
        )
        .test(
          'value',
          'You have selected a date which is more than 5 years ago',
          value => value !== undefined && moment(value).isAfter(moment(new Date()).subtract(5, 'years')),
        ),
    }),
    homeOwner: yup.string().required('Please choose one of the possible options'),
    maritalStatus: yup
      .string()
      .required('Please choose one of the possible options')
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    gender: yup.string().required('Please choose one of the possible options'),
    occupation: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable()
      .required('Please choose one of the possible options'),
    occupationStatus: yup
      .string()
      .required('Please choose one of the possible options')
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    employersBusiness: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable()
      .required('Please choose one of the possible options'),
    phRelationship: yup
      .string()
      .required('Please choose one of the possible options')
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    // Experience section
    licenseType: yup.string().required('Please choose one of the possible options'),
    testPassed10Years: yup.string().when(['licenseType'], {
      is: (licenseType: string) => licenseType === LicenseType.FullEU || licenseType === LicenseType.FullUK,
      then: yup
        .string()
        .required('Please choose one of the possible options')
        .test('value', 'The date of birth you have provided prevents this option', (value, testData) => {
          if (value === 'yes') {
            return parseInt(moment(testData.parent.dateOfBirth).fromNow(true)) - 10 >= 17
          }
          if (value === 'no') {
            return true
          }
          return true
        }),
    }),
    whenPassTest: yup.string().when(['licenseType', 'testPassed10Years'], {
      is: (licenseType: string, testPassed10Years: string) =>
        (licenseType === LicenseType.FullEU && testPassed10Years === 'no') ||
        (licenseType === LicenseType.FullUK && testPassed10Years === 'no'),
      then: yup
        .string()
        .required('Please complete the field')
        .test('value', 'Please enter a valid date', value => moment(value).isValid())
        .test(
          'value',
          'Please enter a valid date',
          value => value !== undefined && moment(moment(value)).isBefore(moment(new Date())),
        ),
    }),
    firstDroveOnProvisionalLicence: yup.string().when('licenseType', {
      is: (value: LicenseType) => value === LicenseType.ProvisionalEU || value === LicenseType.ProvisionalUK,
      then: yup
        .string()
        .required('Please complete the field')
        .test('value', 'Please enter a valid date', value => moment(value).isValid())
        .test(
          'value',
          'Please enter a valid date',
          value => value !== undefined && moment(moment(value)).isBefore(moment(new Date())),
        ),
    }),
    drivingQualifications: yup
      .string()
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    medicalConditions: yup
      .string()
      .test('value', 'Please choose one of the possible options', value => value !== 'none'),
    accessToOtherVehicles: yup.string().required('Please choose one of the possible options'),
    nonMotoringConvictions: yup.string().required('Please choose one of the possible options'),
    cancelledPreviousInsurance: yup.string().required('Please choose one of the possible options'),
    // TODO: For both hasMotorConvictions & hasMotorClaims
    // If 'yes' then at least one item must be added tp pmClaims or pmConvictions
    hasMotorConvictions: yup.string().when(['pmMotorConvictions'], {
      is: (pmMotorConvictions: any[]) => pmMotorConvictions?.length === 0,
      then: yup
        .string()
        .required('Please choose one of the possible options')
        .test('value', 'Must confirm all convictions when set to yes', value => value !== 'yes'),
    }),
    hasMotorClaims: yup.string().when(['pmClaims'], {
      is: (pmClaims: any[]) => pmClaims?.length === 0,
      then: yup
        .string()
        .required('Please choose one of the possible options')
        .test('value', 'Must confirm all claims when set to yes', value => value !== 'yes'),
    }),
  })

// Dropdowns have the default value of 'none' this preselects the placeholder MenuItem
// Test for validation added to  "title" field above
export const initialValues = {
  // your details
  title: 'none',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  ukResident5Years: '',
  ukResidentSinceDate: undefined,
  homeOwner: '',
  maritalStatus: 'none',
  gender: '',
  occupation: null,
  occupationStatus: 'none',
  employersBusiness: null,
  phRelationship: 'none',
  // your experience
  licenseType: '',
  testPassed10Years: undefined,
  whenPassTest: undefined,
  firstDroveOnProvisionalLicence: undefined,
  drivingQualifications: 'none',
  medicalConditions: 'none',
  accessToOtherVehicles: '',
  nonMotoringConvictions: '',
  cancelledPreviousInsurance: '',
  hasMotorConvictions: '',
  hasMotorClaims: '',
  pmMotorConvictions: [],
  pmClaims: [],
}

export default validationSchema
