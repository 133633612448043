import { Box } from '@material-ui/core'
import Title from 'components/ui/Title'

export interface ICustomerNameContent {
  title: string
  subtitle?: string
}

export interface Props {
  content: ICustomerNameContent
  customerName: string
  starting?: boolean
}

const CustomerName = ({ content, customerName, starting = false }: Props) => {
  const { title, subtitle } = content

  return (
    <Box pt={5}>
      <Title title={title} subtitle={subtitle} customerName={customerName} starting={starting} />
    </Box>
  )
}

export default CustomerName
