import { all, put, takeLatest } from 'redux-saga/effects'
import { isSnackbarOpenAction } from './actions'

export function* onIsSnackbarOpenAction(action: any) {
  const {
    payload: { isOpen, message },
  } = action

  yield put(isSnackbarOpenAction.success({ isOpen, message }))
}

export default function* rootSaga() {
  yield all([takeLatest(isSnackbarOpenAction.start, onIsSnackbarOpenAction)])
}
