import { LocalConvenienceStoreOutlined } from '@material-ui/icons'
import moment from 'moment'
import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    isCardRegisteredToYou: yup.string().required('Please choose one of the possible options'),
    cardHolderFirstname: yup.string().when('isCardRegisteredToYou', {
      is: (value: string) => value === 'no',
      then: yup
        .string()
        .required('Please complete the field')
        .test('value', 'Too long', value => value !== undefined && value.length <= 30)
        .test(
          'value',
          'Your name contains invalid characters. Please check and re-enter using letters, hyphens and spaces only',
          value => value !== undefined && /^[a-zA-Z]+(?:[-' \s][a-zA-Z]+)*$/.test(value),
        ),
    }),
    cardHolderSurname: yup.string().when('isCardRegisteredToYou', {
      is: (value: string) => value === 'no',
      then: yup
        .string()
        .required('Please complete the field')
        .test('value', 'Too long', value => value !== undefined && value.length <= 30)
        .test(
          'value',
          'Your name contains invalid characters. Please check and re-enter using letters, hyphens and spaces only',
          value => value !== undefined && /^[a-zA-Z]+(?:[-' \s][a-zA-Z]+)*$/.test(value),
        ),
    }),
    cardHolderEmailAddress: yup.string().when('isCardRegisteredToYou', {
      is: (value: string) => value === 'no',
      then: yup
        .string()
        .max(60, 'Please enter a valid email address')
        .required('Please complete the field')
        .matches(
          /^(([a-zA-Z0-9])|([a-zA-Z0-9]{1,}[a-zA-Z0-9.-][a-z0-9]{1,}))+@(([a-zA-Z0-9])|([a-zA-Z0-9]{1,}[a-zA-Z0-9.-]{1,}[a-z0-9]{1,}))+\.[a-z]{2,3}$/,
          'Please enter a valid email address',
        ),
    }),
    cardHolderPhoneNumber: yup.string().when('isCardRegisteredToYou', {
      is: (value: string) => value === 'no',
      then: yup
        .string()
        .required('Please complete the field')
        .test('value', 'This number contains invalid characters. Please enter numbers only', value => {
          return value !== undefined && /^[0-9]+$/.test(value)
        })
        .test('value', 'Please enter a valid phone number', value => {
          return value !== undefined && value.startsWith('0')
        })
        .min(11, 'Please enter a valid phone number')
        .max(11, 'Please enter a valid phone number'),
    }),
    postalCodeLookup: yup.string().when('showAddressLookup', {
      is: (value: boolean) => value === true,
      then: yup
        .string()
        .required('Please complete the field')
        .test(
          'value',
          `Please ensure you are providing a valid postcode`,
          value =>
            value !== undefined &&
            /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/.test(
              value.toUpperCase(),
            ),
        ),
    }),
  })

export const initialValues = {
  isCardRegisteredToYou: 'yes',
  cardHolderFirstname: '',
  cardHolderSurname: '',
  cardHolderEmailAddress: '',
  cardHolderPhoneNumber: '',
  // monthly
  bankAccountType: 'none',
  accountHolderName: '',
  bankAccountNumber: '',
  sortCode: '',
  isAuthorisedPayment: 'yes',
  isAutomaticRenewal: '',
  startDate: new Date(),
  showAddressLookup: false,
  postalCodeLookup: '',
}

export default validationSchema
