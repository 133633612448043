import { connect } from 'react-redux'
import { getVehicleByRegistrationAction } from 'redux/car/actions'
import { continueRenewalAction, quoteRenewalAction, quoteRenewalDirectBuyAction } from 'redux/renewal/actions'

import { IReduxState } from 'types/stateTypes'
import RenewalYourCarPage from './RenewalYourCarPage'

const mapStateToProps = (state: IReduxState) => ({
  formValues: state.renewal.formValues,
  vehicle: state.renewal.vehicle,
  selectedVehicle: state.car.selectedVehicle,
  vehicleLookupError: state.car.vehicleLookupError,
  registration: state.car.registration,
  quote: state.renewal.quote,
  additionalDrivers: state.renewal.additionalDrivers,
  addedDrivers: state.renewal.addedDrivers,
  current: state.policy.current,
})

const mapDispatchToProps = {
  continueRenewalAction: continueRenewalAction.start,
  quoteRenewalAction: quoteRenewalAction.start,
  getVehicleByRegistrationAction: getVehicleByRegistrationAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalYourCarPage)
