import { connect } from 'react-redux'

import { IReduxState } from 'types/stateTypes'
import RenewalAddonPage from './RenewalAddonPage'

import mockContent from 'mock/cms/page_addons.json'
import { updateCoveragesAction } from 'redux/updateCoverages/actions'
import { policySelectAction } from 'redux/policy/actions'

const mapStateToProps = (state: IReduxState) => ({
  quote: state.renewal.quote,
  content: mockContent,
  policyId: state.policy.policyIds[state.policy.currentPolicyIndex],
  index: state.policy.currentPolicyIndex,
})

const mapDispatchToProps = {
  updateCoveragesAction: updateCoveragesAction.start,
  policySelectAction: policySelectAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalAddonPage)
