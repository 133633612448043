import { handleActions } from 'redux-actions'

import {
  amendRenewalAction,
  continueRenewalAction,
  addRenewalDriverAction,
  quoteRenewalDirectBuyAction,
  quoteRenewalAction,
  continueToPaymentAction,
  addBillingDetailsAndGetSagePayUrlAction,
  validateDdiAction,
  setPaymentPlanAction,
  setVoluntaryExcessAction,
  updateQuoteAction,
} from './actions'

const defaultState = {
  mainDriver: {},
  additionalDrivers: [],
  formValues: {},
  activeStep: 0,
  addedDrivers: [],
  vehicle: {},
  quote: {},
  billingId: '',
  paymentUrl: '',
  amend: false,
  vehicleIsLeased: '',
}

export default handleActions(
  {
    [amendRenewalAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        mainDriver: payload.mainDriver,
        additionalDrivers: payload.additionalDrivers,
        formValues: payload.formValues,
        vehicle: payload.vehicle,
        activeStep: 0,
        quote: payload.quote,
        amend: true,
        addedDrivers: [], //reset newly added drivers on click on admend renewal
      }
    },
    [continueRenewalAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        formValues: payload.formValues,
        activeStep: payload.activeStep,
        ...(payload.vehicle != undefined && { vehicle: payload.vehicle }),
        ...(payload.additionalDrivers != undefined && { additionalDrivers: payload.additionalDrivers }),
        ...(payload.addedDrivers != undefined && { addedDrivers: payload.addedDrivers }),
      }
    },
    [addRenewalDriverAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        addedDrivers: payload.addedDrivers,
        additionalDrivers: payload.additionalDrivers,
      }
    },
    [quoteRenewalDirectBuyAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        quote: payload.quote,
        activeStep: payload.activeStep,
      }
    },
    [quoteRenewalAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        quote: payload.quote,
        activeStep: payload.activeStep,
      }
    },
    [setVoluntaryExcessAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        quote: payload.quote,
      }
    },
    [updateQuoteAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        quote: payload.quote,
      }
    },
    [continueToPaymentAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        billingId: payload.billingId,
        activeStep: 3,
      }
    },
    [addBillingDetailsAndGetSagePayUrlAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        paymentUrl: payload,
      }
    },
    [validateDdiAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        ddi: payload,
      }
    },
    [setPaymentPlanAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.selectedPaymentPlan) {
        return state
      }
      return {
        ...state,
        quote: {
          ...state.quote,
          bindData: {
            ...state.quote.bindData,
            selectedPaymentPlan: payload.selectedPaymentPlan,
          },
        },
      }
    },
  },
  defaultState,
)
