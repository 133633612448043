import { connect } from 'react-redux'

import { IReduxState } from 'types/stateTypes'
import RenewalDocuments from './RenewalDocuments'

const mapStateToProps = (state: IReduxState) => ({
  quote: state.renewal.quote,
  billingId: state.renewal.billingId,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalDocuments)
