import React from 'react'
import moment from 'moment'
import subYears from 'date-fns/subYears'
import { v4 as uniqeID } from 'uuid'

import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grow,
  FormHelperText,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'

import QbDatePicker from 'components/ui/QbDatePicker'
import QbSelector from 'components/ui/QbSelector'
import { Field } from 'formik'

import { numberArrayToOptions } from 'guidewire/formatTools'
import { LabelValuePair } from 'types/contentTypes'
import { Conviction } from 'types/policyTypes'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    // [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
    //   width: '80%',
    // },
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  placeholderColor: {
    color: theme.palette.text.disabled,
  },
  listContainer: {
    width: '100%',

    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
}))

export interface IConvictionsSection {
  body: string
  convictionCode: string
  convictionDate: string
  convictionPoints: string
  disqualificationMonths: string
  disqualificationMonthsOptions: LabelValuePair[]
  id: string
  title: string
  addBtn: string
  confirmAllBtn: string
}

export interface Props {
  content: IConvictionsSection
  convictionCodes: LabelValuePair[]
  onConfirm: (pmConvictions: Conviction[]) => void
  convictions?: Conviction[]
  confirmed?: boolean
}

const Convictions = ({ content, convictionCodes, onConfirm, convictions = [], confirmed = false }: Props) => {
  const [conviction, setConviction] = React.useState({})
  const [pmConvictions, setPmConvictions] = React.useState<Conviction[] | []>(convictions)

  const [open, setOpen] = React.useState(!confirmed)

  const [dateError, setDateError] = React.useState<string | undefined>(undefined)
  //form values
  const [codeID, setCodeID] = React.useState<string>('none')
  const [convictionDateValue, setConvictionDateValue] = React.useState<string | null>(null)
  const [convictionPointsValue, setConvictionPointsValue] = React.useState<string>('none')
  const [disqualificationMonthsValue, setDisqualificationMonthsValue] = React.useState<string>('none')

  const {
    title,
    body,
    convictionCode,
    convictionDate,
    convictionPoints,
    disqualificationMonths,
    disqualificationMonthsOptions,
    addBtn,
    confirmAllBtn,
  } = content

  const classes = useStyles()

  const removeConvictionDate = () => {
    const temp: any = conviction
    delete temp['convictionDate']
    setConviction(temp)
  }

  const onSelect = (name: string, value: string | Date) => {
    // Conviction date range test
    // This validation is on the fly and not part of the standard form validation
    // Standard is validated after a submission attempt
    // This conviction date should not allow the user to add a conviction unless its in the correct date range and inform the user via an error message

    if (name === 'convictionDate' && moment(value).isValid()) {
      const dateNow = moment(new Date()).utc(true)

      const fiveYearsAgo = moment(dateNow)
        .subtract(5, 'y')
        .utc(true)

      // If valid date
      if (moment(value).isBetween(fiveYearsAgo, dateNow)) {
        setDateError(undefined)
        setConviction({ ...conviction, [name]: value })
      }

      // If future date
      if (moment(value).isAfter(dateNow)) {
        setDateError('Can not be a future date')

        removeConvictionDate()
      }

      // If date too old
      if (moment(value).isBefore(fiveYearsAgo)) {
        setDateError('Only convictions within the last 5 years are required')

        removeConvictionDate()
      }
    }

    // If invalid date
    if (name === 'convictionDate' && moment(value).isValid() === false) {
      removeConvictionDate()
    }

    // All other fields require no validation other than being selected
    if (name !== 'convictionDate') setConviction({ ...conviction, [name]: value })
  }

  const onAddConviction = () => {
    setPmConvictions([...pmConvictions, conviction as Conviction])
    //reset controlled values
    setCodeID('none')
    setConvictionDateValue(null)
    setConvictionPointsValue('none')
    setDisqualificationMonthsValue('none')
    setConviction({})
  }

  const onRemoveConviction = (index: number) => {
    const toRemove = pmConvictions[index]
    const toSet = pmConvictions.filter((item, itemIndex) => !(item === toRemove && index === itemIndex))
    setPmConvictions(toSet)
    onConfirm(toSet)
  }

  const onConfirmAll = () => {
    setOpen(!open)
    onConfirm(pmConvictions)
  }

  return (
    <>
      <Accordion elevation={0} className={classes.container} square expanded={open} onChange={() => setOpen(!open)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
          <Typography variant="h1">{title}</Typography>
        </AccordionSummary>

        <div className={classes.content}>
          <Typography>{body}</Typography>
          <Box pt={5}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <QbSelector
                  fullWidth
                  name="codeID"
                  label={convictionCode}
                  onSelect={(name, value) => {
                    onSelect(name, value)
                    setCodeID(value)
                  }}
                  options={convictionCodes}
                  controlledValue={codeID}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{convictionDate}</Typography>

                <Field
                  component={QbDatePicker}
                  fullWidth
                  name="convictionDate"
                  disableFuture
                  minDate={subYears(new Date(), 5)}
                  onDateChanged={(name: string, value: string) => {
                    onSelect(name, value)
                    setConvictionDateValue(value)
                  }}
                  controlledValue={convictionDateValue}
                />

                {dateError && <FormHelperText error>{dateError}</FormHelperText>}
              </Grid>

              <Grid item xs={12} sm={6}>
                <QbSelector
                  fullWidth
                  name="convictionPoints"
                  label={convictionPoints}
                  onSelect={(name, value) => {
                    onSelect(name, value)
                    setConvictionPointsValue(value)
                  }}
                  options={numberArrayToOptions({ values: Array.from(Array(12).keys()) })}
                  controlledValue={convictionPointsValue}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <QbSelector
                  fullWidth
                  name="disqualificationMonths"
                  label={disqualificationMonths}
                  onSelect={(name, value) => {
                    onSelect(name, value)
                    setDisqualificationMonthsValue(value)
                  }}
                  options={disqualificationMonthsOptions}
                  controlledValue={disqualificationMonthsValue}
                />
              </Grid>

              <Grid xs={12} item container justify="flex-end">
                <Button
                  disabled={Object.keys(conviction).length !== 4}
                  variant="outlined"
                  color="secondary"
                  onClick={() => onAddConviction()}
                >
                  {addBtn}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Accordion>

      <Grow in={pmConvictions.length > 0} timeout={300} unmountOnExit>
        <TableContainer className={classes.listContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{convictionCode}</TableCell>
                <TableCell>{convictionDate}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {pmConvictions.map((item, index) => (
                <TableRow key={uniqeID()}>
                  <TableCell>{item.codeID}</TableCell>
                  <TableCell>{moment(item.convictionDate).format('DD / MM / YYYY')}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => onRemoveConviction(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {open && (
            <Grid container>
              <Grid xs={12} item container justify="flex-end">
                <Box pt={5} pb={1}>
                  <Button
                    disabled={pmConvictions.length === 0}
                    variant="outlined"
                    color="primary"
                    onClick={() => onConfirmAll()}
                  >
                    {confirmAllBtn}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </TableContainer>
      </Grow>
    </>
  )
}

export default Convictions
