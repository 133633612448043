import { Box, makeStyles, MenuItem, Select } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import React, { Dispatch, SetStateAction } from 'react'

import Tab from './Tab'

export type TabDetails<T = unknown> = T & {
  label: string
  id: string
}

interface Props {
  tabs: TabDetails<unknown>[]
  onTabSelect: Dispatch<SetStateAction<TabDetails<any>>>
}

const useStyles = makeStyles(theme => ({
  tabs: {
    display: 'flex',
    columnGap: 10,
    justifyContent: 'space-between',
  },
  tab: {
    width: '100%',
  },
  selector: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '12px 20px',
    borderRadius: 4,
  },
}))

const Tabs = ({ tabs, onTabSelect }: Props) => {
  const classes = useStyles()

  const [tabSelected, setTabSelected] = React.useState<TabDetails<unknown>>(tabs[0])

  return (
    <>
      {/* Desktop - Tabs selector */}
      <Box display={{ xs: 'none', sm: 'block' }}>
        <div className={classes.tabs}>
          {tabs.map(item => (
            <div
              key={item.id}
              onClick={() => {
                // local state
                setTabSelected(item)
                // state for the parent component
                onTabSelect(item)
              }}
              className={classes.tab}
            >
              <Tab label={item.label} selected={item.id === tabSelected.id} />
            </div>
          ))}
        </div>
      </Box>

      {/* Mobile - Dropdown selector */}
      <Box display={{ xs: 'block', sm: 'none' }}>
        <Select
          className={classes.selector}
          variant="outlined"
          IconComponent={() => <KeyboardArrowDownIcon color="secondary" />}
          value={tabSelected.id}
        >
          {tabs.map(item => (
            <MenuItem
              value={item.id}
              key={item.id}
              onClick={() => {
                // local state
                setTabSelected(item)
                // state for the parent component
                onTabSelect(item)
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  )
}

export default Tabs
