import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Paper, Typography, Box, Breadcrumbs, Link, makeStyles, useTheme } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import { ContentType } from 'types/contentTypes'

const useStyles = makeStyles({
  text: {
    fontSize: 12,
  },
  contactUs: {
    cursor: 'pointer',
  },
})

export interface Props {
  content: ContentType
}

const Footer = ({ content }: Props) => {
  const classes = useStyles()

  const { group, body } = content
  const contactUsHyperLink = "https://quote.toyotainsuranceservices.co.uk/contact-us"
  return (
    <Layout>
      <Box pb={5} pt={5}>
        <Paper elevation={0}>
          <Box p={2.5}>
            <Box pb={2}>
              <Breadcrumbs separator="|" className={classes.text}>
                {group &&
                  group.map(link => {
                    return link.value === 'contactUsModal' ? (
                      <Link key={link.label} className={classes.contactUs} target="_blank" href={contactUsHyperLink}>
                        {link.label}
                      </Link>
                    ) : (
                      <Link key={link.label} target="_blank" href={link.value}>
                        {link.label}
                      </Link>
                    )
                  })}
              </Breadcrumbs>
            </Box>

            <Typography component={'div'} className={classes.text}>
              <ReactMarkdown>{body || ''}</ReactMarkdown>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Layout>
  )
}

export default Footer
