import {
  AddOn,
  Driver,
  MyExcessPayment,
  MyExcessPaymentDisplay,
  PolicyHolder,
  VehicleInfo,
  VehicleInfoDisplay,
} from 'types/policy'
import {
  booleanToYesOrNo,
  capitalizeAllFirstLetters,
  formatCurrencyObject,
  formatDateObject,
  formatNumberToPercent,
  monthAndYearToIso,
} from './formatTools'

import fallbackTypekeys from 'mock/typekeys/typekeys.json'
import moment from 'moment'

import { PaymentPlan } from 'types/policyTypes'
import { PaymentPlanDisplay } from 'types/contentTypes'
import { LabelValuePair } from 'types'

const typekeys: any[] =
  sessionStorage.getItem('typekeys') !== null
    ? JSON.parse(sessionStorage.getItem('typekeys') as string)
    : fallbackTypekeys

export const translateTypekey = (valueToReplace: string, typeKeyName: string): string => {
  const typekeyObject = typekeys.find(({ typeName }) => typeName === typeKeyName)
  const gwValue = typekeyObject?.keyValuePairs.find(({ key }: any) => {
    return key.substring(key.lastIndexOf('.') + 1) === valueToReplace
  })

  if (gwValue) return gwValue.value
  return ''
}

export const formatPolicyHolderToDisplay = (policyHolder: PolicyHolder) => {
  const policyHolderDisplay = Object.fromEntries(
    Object.keys(policyHolder).map((key: string) => {
      if (policyHolder[key] === true || policyHolder[key] === false)
        return [key, booleanToYesOrNo(policyHolder[key] as boolean)]

      return [key, policyHolder[key]]
    }),
  )

  return policyHolderDisplay
}

export const formatDriverToDisplay = (driver: Driver) => {
  const driverDisplay = Object.fromEntries(
    Object.keys(driver).map((key: string) => {
      if (driver[key] === true || driver[key] === false) return [key, booleanToYesOrNo(driver[key] as boolean)]

      if (key === 'dateOfBirth') return [key, formatDateObject(driver[key], 'DD / MM / YYYY')]

      // Typkey values to human readable display values
      if (key === 'occupation') return [key, translateTypekey(driver[key], 'typekey.OccupationType_itb')]
      if (key === 'maritalStatus') return [key, translateTypekey(driver[key], 'typekey.MaritalStatus')]
      if (key === 'licenseType') return [key, translateTypekey(driver[key], 'typekey.DrivingLicenseType_itb')]
      if (key === 'medicalConditions') return [key, translateTypekey(driver[key], 'typekey.MedicalConditions_itb')]
      if (key === 'drivingQualifications')
        return [key, translateTypekey(driver[key], 'typekey.DrivingQualifications_itb')]

      return [key, driver[key]]
    }),
  )

  return driverDisplay
}

export const formatVehicleToDisplay = (vehicle: VehicleInfo): any => {
  const vehicleDisplay = Object.fromEntries(
    Object.keys(vehicle).map((key: string) => {
      if (vehicle[key] === true || vehicle[key] === false) return [key, booleanToYesOrNo(vehicle[key] as boolean)]
      if (key === 'marketValue') return [key, formatCurrencyObject(vehicle[key])]
      if (key === 'securityDevices') {
        return [key, translateTypekey(vehicle[key], 'typekey.VehicleSecurityDeviceType_itb')]
      }

      return [key, vehicle[key]]
    }),
  )

  const purchasedDate = moment()
  if (vehicle.purchasedMonth) {
    purchasedDate.set(
      'month',
      parseInt(
        moment()
          .month(vehicle.purchasedMonth)
          .format('M'),
      ) - 1,
    )
  }

  if (vehicle.purchasedYear) {
    purchasedDate.set('year', vehicle.purchasedYear)
  }

  const purchasedDateDisplay = vehicle.purchasedDate
    ? moment(vehicle.purchasedDate).format('MM/YYYY')
    : purchasedDate.format('MM/YYYY')

  if (!vehicleDisplay.registrationNumber) {
    // Note: there is no registration number in a simple individual key in vehicleInfo, its included in the displayName.
    // There is an individual value found under polices > period > id that matches current policy but this is a lot of extra redux / response legwork to justify
    const betweenBrackets = /\(([^)]+)\)/
    const registrationNumber = [...(betweenBrackets.exec(vehicle.displayName) as RegExpExecArray)]

    return {
      ...vehicleDisplay,
      purchasedDate: purchasedDateDisplay,
      registrationNumber: registrationNumber[1],
    }
  }

  return {
    ...vehicleDisplay,
    purchasedDate: purchasedDateDisplay,
  }
}

export const formatMyExcessPaymentToDisplay = (myExcessPayment: MyExcessPayment) => {
  const { glassRepairExcess, glassReplacementExcess } = myExcessPayment
  const currencySymbol = glassRepairExcess.charAt(0)

  const damageExcesses = myExcessPayment.damageExcesses.map(item => {
    return Object.fromEntries(
      Object.keys(item).map((key: string) => {
        if (key !== 'driver') return [key, `${currencySymbol}${item[key]}`]
        return [key, capitalizeAllFirstLetters(item[key])]
      }),
    )
  })

  return {
    damageExcesses,
    glassRepairExcess,
    glassReplacementExcess,
  } as MyExcessPaymentDisplay
}

export const formatPaymentPlansToDisplay = (paymentPlans: PaymentPlan[]) => {
  const dispay = paymentPlans.map(item => {
    return Object.fromEntries(
      Object.keys(item).map((key: string) => {
        if (
          key === 'creditAmount_itb' ||
          key === 'downPayment' ||
          key == 'fees_itb' ||
          key === 'installment' ||
          key === 'total' ||
          key === 'totalPremiumRPT' ||
          key === 'firstInstallment' ||
          key === 'pclfacilityFee'
        ) {
          return [key, formatCurrencyObject(item[key])]
        }

        if (key === 'interestRate_itb') return [key, formatNumberToPercent(item[key])]

        return [key, item[key]]
      }),
    )
  })

  return dispay as PaymentPlanDisplay[]
}
