import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core'

import InfoIcon from '@material-ui/icons/Info'

import ReadOnlyField from 'components/ui/ReadOnlyField'
import BasicAccordion from 'components/ui/BasicAccordion'
import DriverAccordion from 'components/ui/DriverAccordion'

import { DriverDisplay } from 'types/policy'
import { LabelValuePair } from 'types/generalTypes'
import { ContentType } from 'types/contentTypes'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
  },
  label: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
  },
}))

export interface Props {
  mainDriver?: DriverDisplay
  additionalDrivers?: DriverDisplay[]
  content: ContentType
  addDriver: (action: any) => void
}

const driverDetails: LabelValuePair[] = [
  { label: 'First name', value: 'firstName' },
  { label: 'Surname', value: 'lastName' },
  { label: 'Date of birth', value: 'dateOfBirth' },
]

const driverOtherDetails: LabelValuePair[] = [
  { label: 'Primary occupation', value: 'occupation' },
  { label: 'Marital status', value: 'maritalStatus' },
  { label: 'UK residency', value: 'ukResidency' },
  { label: 'Licence type', value: 'licenseType' },
  { label: 'Access to other vehicles', value: 'accessToOtherVehicles' },
  { label: 'Additional driving qualifications', value: 'drivingQualifications' },
  { label: 'Medical conditions', value: 'medicalConditions' },
  {
    label: 'Unspent non-motoring offences',
    value: 'nonMotoringConvictions',
    infoIcon:
      'An unspent conviction is a conviction where the rehabilitation period has not expired. Custodial sentences of more than two and a half years can never become spent.',
  },
  { label: 'Previously cancelled insurance by an insurer', value: 'cancelledPreviousInsurance' },
  { label: 'Convictions', value: 'hasMotorConvictions' },
  {
    label: 'Claims',
    value: 'hasMotorClaims',
    infoIcon:
      'You need to inform us about all types of damage and incidents you’ve been involved in (even if not at fault, or whilst driving a different vehicle). This applies to accidents, claims or losses you have reported (incl. fire, theft, glass or malicious damage).',
  },
]

const MyDrivers = ({ mainDriver = undefined, additionalDrivers, content, addDriver }: Props) => {
  const classes = useStyles()

  const mainDriverReadOnlyFields = content?.group?.find(item => item.id === 'mainDriverReadOnlyFields')
    ?.group as LabelValuePair[]
  const mainDriverOtherDetails = content?.group?.find(item => item.id === 'mainDriverOtherDetails')
    ?.group as LabelValuePair[]

  return (
    <div>
      <Grid container spacing={4}>
        {mainDriver != undefined && (
          <Grid item xs={12}>
            <Typography className={classes.title}>Main driver</Typography>
          </Grid>
        )}

        {/* main driver */}
        {mainDriver != undefined &&
          driverDetails.map(item => (
            <Grid item xs={12} sm={4} key={item.value}>
              <Typography className={classes.label}>{item.label}</Typography>
              <ReadOnlyField value={mainDriver[item.value]} />
            </Grid>
          ))}
        {mainDriver != undefined && (
          <Grid item xs={12}>
            <BasicAccordion title="Other details" useBorder={true}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {driverOtherDetails.map((item, index) => (
                      <TableRow key={`${item.value} ${index}`}>
                        <TableCell>{item.label}</TableCell>
                        <TableCell align="right">{mainDriver[item.value]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </BasicAccordion>
          </Grid>
        )}

        {/* Additional drivers */}
        {additionalDrivers && additionalDrivers.length > 0 && (
          <>
            <Grid item xs={12}>
              {mainDriver != undefined && <Typography className={classes.title}>All drivers</Typography>}
            </Grid>

            <Grid item xs={12}>
              <DriverAccordion
                useBorder={true}
                driverDetails={driverDetails}
                driverOtherDetails={driverOtherDetails}
                additionalDrivers={additionalDrivers}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={addDriver}
            startIcon={<PersonAddIcon />}
          >
            {'Add a new driver'}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default MyDrivers
