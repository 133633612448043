import React from 'react'
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import { LabelValuePair } from 'types/generalTypes'

const useStyles = makeStyles(theme => ({
  headerMobile: {
    backgroundColor: `${theme.palette.primary.light}20`,
  },
  heading: {
    fontWeight: 800,
  },
  body: {
    border: 0,
  },
}))

export interface Props {
  policyHolderDetails: any
  headers: LabelValuePair[]
}

const PolicyHolderTable = ({ policyHolderDetails, headers }: Props) => {
  const classes = useStyles()

  return (
    <div>
      {/* Desktop */}
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Grid container justify="center">
          <Grid item sm={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell key={header.label}>
                        <div style={{ display: 'flex', justifyContent: index < 1 ? 'flex-start' : 'flex-end' }}>
                          <Typography className={classes.heading}>{header.label}</Typography>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell align={index < 1 ? 'left' : 'right'} key={index} className={classes.body}>
                        {policyHolderDetails[header.value]}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>

      {/* Mobile */}
      <Box display={{ xs: 'block', sm: 'none' }}>
        <TableContainer>
          <Table>
            <TableBody>
              {headers.map((header, index) => (
                <React.Fragment key={`${header.label} ${index}`}>
                  <TableRow className={index === 0 ? classes.headerMobile : undefined}>
                    <TableCell align="left">{header.label}</TableCell>
                    <TableCell align="right">{policyHolderDetails[header.value]}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  )
}

export default PolicyHolderTable
