export const brandPalette = {
  brand: {
    primary: {
      light: '#cecfd0',
      main: '#282830',
    },
    secondary: {
      light: '#ff0022',
      main: '#ff0022',
    },
  },
  error: {
    light: '#ff0022',
    main: '#ff0022',
  },
  headers: {
    light: '#ffffff',
    main: '#282830',
    journey: '#343534',
    emphasis: '#ff0022',
  },
  bodyText: '#282830',
  lightText: '#6a6b70',
  background: '#eff0f0',
  surface: '#ffffff',
  stepper: '#ff0022',
  notification: '#ffdb44',
  button: {
    default: {
      color: '#ff0022',
      background: '#ffffff',
      borderColor: '#ffffff',
      iconFill: '#ff0022',
      iconBackground: '#ffffff',
    },
    primary: {
      color: '#ffffff',
      background: '#ff0022',
      borderColor: '#ff0022',
      iconFill: '#ffffff',
      iconBackground: '#ff0022',
    },
    secondary: {
      color: '#ff0022',
      background: '#ffffff',
      borderColor: '#ff0022',
      iconFill: '#ffffff',
      iconBackground: '#ff0022',
    },
  },
}
