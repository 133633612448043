import { call, put } from 'redux-saga/effects'
import { isTokenValid } from 'redux/login/client'
import { getPoliciesAction } from 'redux/policy/actions'
import { getPolicies } from 'redux/policy/client'
import { setPolicyDocumentsAction } from 'redux/policyDocuments/actions'

export function* tokenValidCheck() {
  const access_token_original = sessionStorage.getItem('access_token') as string

  const { response: isValid } = yield call(() => isTokenValid())

  const access_token = sessionStorage.getItem('access_token') as string

  if (isValid === true && access_token_original !== access_token) {
    const {
      response: { status: policiesStatus, data: policyList },
    } = yield call(() => getPolicies(access_token))

    if (policiesStatus === 200) {
      // Set documents to their own state in redux to make things easier to retrieve
      const documents = (policyList as any[]).map((item: any) => {
        return (item.periods as any[]).flatMap((period: any) => {
          return { policyId: period.policyId, status: period.status, documents: period.documents }
        })
      })

      yield put(setPolicyDocumentsAction.success({ period: documents }))
      //

      yield put(getPoliciesAction.success({ policies: policyList }))
    } else {
      // TODO: Error messages here. Snackbar?
      console.log('Error getting documets')
    }
  }

  return { isValid }
}
