import { Box, Divider, Grid, makeStyles } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import ReactMarkdown from 'react-markdown'

import CallOutlinedIcon from '@material-ui/icons/Call'
import ChatIcon from '@material-ui/icons/Chat'
import Section from 'components/ui/Section'
import { useLocation } from 'react-router-dom'
import { getContentByErrorCode } from 'utils/decline'
import React from 'react'

interface ErrorContent {
  title: string
  text: string
}

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
    paddingBottom: 20,
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  bottomText: {
    marginLeft: 24,
  },
  errorText: {
    paddingLeft: 12,
    '& p': {
      marginTop: 0,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
}))

export interface Props {
  setLiveChatAction: (values: any) => void
}

const ErrorPage = ({ setLiveChatAction }: Props) => {
  const classes = useStyles()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const errorId = searchParams.get('error')

  const [errorContent, setErrorContent] = React.useState<ErrorContent>({ title: '', text: '' })

  React.useEffect(() => {
    setErrorContent(getContentByErrorCode(errorId))
    //workouround for scroll up on iphone
    setTimeout(() => window?.scrollTo(0, 0), 500)
  }, [])

  return (
    <>
      <Layout>
        <Box pt={5}>
          <Grid container direction="column" spacing={4}>
            <Section>
              <ReactMarkdown className={classes.errorText}>{errorContent.text}</ReactMarkdown>
              <Divider style={{ marginBottom: '20px', marginTop: '20px' }} />
              <div className={classes.infoContainer}>
                   <ChatIcon color="secondary" />
                   <div className={classes.errorText}>
                      {`Speak to a member of our team on `}
                      <a onClick={() => setLiveChatAction(true)} className={classes.infoLink}>Live Chat:</a>
                      <br />
                      {`We're here 8am - 7:45pm Monday to Friday, 9am - 4pm Saturday and 8am - 4pm Bank Holidays`}
                   </div>
                   <br />
              </div>
              <div className={classes.infoContainer}>
                <CallOutlinedIcon color="secondary" />
                   <div className={classes.errorText}>
                      {`Call us on `}
                      <a className={classes.infoLink}>0333 103 7791</a>
                      <br />
                      {`Our lines are open 8:30am – 6pm Monday to Friday, 9am – 1pm Saturday and 8am – 4pm Bank Holidays`}
                   </div>

              </div>
            </Section>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default ErrorPage
