import { combineReducers } from 'redux'
import login from './login/reducer'
import policy from './policy/reducer'
import loading from './loading/reducer'
import snackbarMessage from './snackbarMessage/reducer'
import mta from './mta/reducer'
import account from './account/reducer'
import car from './car/reducer'
import livechat from './livechat/reducer'
import renewal from './renewal/reducer'
import policyDocuments from './policyDocuments/reducer'

const appReducer = combineReducers({
  login,
  policy,
  loading,
  snackbarMessage,
  account,
  mta,
  car,
  livechat,
  renewal,
  policyDocuments,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STORE') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
