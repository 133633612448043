import { PaymentPlan, PaymentPlanDisplay } from 'guidewire/policy/types/paymentPlan'
import { costObjectToString } from './costObjectToString'

export const paymentPlanToDisplay = (plan: PaymentPlan) => {
  const display = Object.fromEntries(
    Object.keys(plan).map((key: keyof PaymentPlan) => {
      if (plan[key].amount || plan[key].amount === 0) {
        return [key, costObjectToString(plan[key])]
      }

      if ((key as string).includes('interestRate')) {
        return typeof plan[key] === 'number' ? [key, `${plan[key].toFixed(2)}%`] : [key, plan[key]]
      }

      return [key, plan[key]]
    }),
  )

  return display as PaymentPlanDisplay
}
