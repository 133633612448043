import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import SVG from 'react-inlinesvg'
import ReactMarkdown from 'react-markdown'
import { ContentType } from 'types/contentTypes'

export interface Props {
  content: ContentType
  addonCostPerYearText: string
  costs?: any
  ncdYears?: string
  codeIdentifier: string
  onAddon: (id: string) => void
  onRemove?: (id: string) => void
  selected?: boolean
  showNew?: boolean
  showNewAfterNow?: boolean
  showNewAfterNowPCPD?: boolean
}

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 50,
    marginRight: 20,
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'left',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      textAlign: 'right',
    },
  },
}))

const BasicAddonSection = ({
  content,
  addonCostPerYearText,
  costs,
  ncdYears,
  codeIdentifier,
  onAddon,
  onRemove,
  selected,
  showNew = false,
  showNewAfterNow = false,
  showNewAfterNowPCPD = false,
}: Props) => {
  const classes = useStyles()
  const { title, subtitle, iconImage, buttons, id } = content
  let { body } = content

  let perYear = ''
  perYear = costs ? addonCostPerYearText.replace('ADDON_COST_PER_YEAR', costs.costPerYear) : addonCostPerYearText

  let perMonth = ''
  perMonth = costs && subtitle ? subtitle.replace('ADDON_COST_PER_MONTH', costs.costPerMonth) : ''

  const pcpdOld =
  'https://assets.ctfassets.net/numinvtctmda/6AJsK5H1untCWODvKvKdtX/16b94daaf95f2c38df76c930ed4b6633/TYA_PCPD_v5_Key_Provider_Change.pdf'
  const pcpdNew =
  'https://assets.ctfassets.net/numinvtctmda/WmiPaKx9Irk4N7LKXPqJp/3a75d372e1bd06b5c26a67f8b8cbd417/TYA_PCPD_v6_Key_Provider_Change.pdf'

  const ECCCPD1=
    'https://assets.ctfassets.net/numinvtctmda/5H0OfuB6STp46qpua5wDJg/88d6a849873656db417de857b083aca0/TYA_ECCC_Policy_Document_v1_WEB.pdf'
  const ECCCPD2=
    'https://assets.ctfassets.net/numinvtctmda/5UxAcdSSiZmYX5s4MvOT4/3426feb0e31abb00f2618ae4e7f46e78/TYA_ECCC_Policy_Document_v2_WEB.pdf'
  
  const LECPD1=
    'https://assets.ctfassets.net/numinvtctmda/1nM1061d5Oq3C8Dxctz1CC/18d8e33cc55615a9d1b5e8d5e91c3350/TYA_LEC_Policy_Document_v1_WEB.pdf'
  const LECPD2=
    'https://assets.ctfassets.net/numinvtctmda/4OTWgzKSPVAq6XOewRQJYy/41b23f7bc800f179185580c240aa43ad/TYA_LEC_Policy_Document_v2_WEB.pdf'

  if (id === 'PMDrivingAbroadCov') {
    body =
      `Your policy covers you to drive in the EU/EEA for a maximum period of __3 days__. If you are looking to drive outside the UK for more than 3 days, you can add our Driving Abroad Extension cover to give you a __maximum of 90 days EU/EEA cover__.\n\nThe full benefits of your policy apply whilst driving in the EU/EEA, including when your car is being transported. \n\nIf you wish to drive abroad, you must ensure that you have any documentation required to evidence insurance.\n\nTemporary replacement cars are not available abroad and repairs undertaken abroad are not guaranteed as they are not carried out by our approved network of repairers.\n\nPlease see Section 6 of the [Private Car Policy Document](` +
      (showNewAfterNowPCPD ? pcpdNew : pcpdOld) +
      `) for full details of the cover terms and exclusions that apply. `
  }

  if (id === 'PMTrailerCov') {
    body =
      `Protect your trailer with a market value of up to £2,500 for Accidental Damage, Fire and Theft.\n\nThis cover will pay the cost of repairs to your trailer or make a cash payment in the event of accidental damage or loss, or if your trailer is stolen but not recovered.\n\nYou can find out more about this cover and full details of any exclusions in sections 1 and 2 of the [Private Car Policy Document](` +
      (showNewAfterNowPCPD ? pcpdNew : pcpdOld) +
      `). \n\nPolicy Excesses apply for any claims under this cover.`
  }

  if(id ==='TMEnhancedCourtesyCarCov')
  {
    body =
    'Keep yourself mobile if your car is unrepairable, or stolen and not recovered. \n\nIncluded in Enhanced Courtesy Car cover is a courtesy car for up to 21 days if your car is declared:\n\n- Unrepairable following an accident that was your fault or as a result of fire, lightning, flood, vandalism, explosion or attempted theft, or;\n\n- Stolen and not recovered\n\nTo be eligible for a courtesy car you must have held a full driving licence for at least 6 months, and not work as an entertainer or professional sports person. If you are not eligible for a courtesy car, you’ll receive up to £250 towards alternative transport costs.\n\nA £350 excess applies for most claims. A £75 + VAT excess applies for glass claims.\n\nView the [Insurance Product Information Document](https://assets.ctfassets.net/numinvtctmda/2FKLiShgQtceMcNs2Yo9AJ/a6ebf419be622d6dfa07e76a83fb79cc/TYA_ECCC_IPID_v1_FINAL.pdf) to understand the main benefits / restrictions. You can access the [Policy Document]('+ 
    (showNewAfterNow ? ECCCPD2 : ECCCPD1) +
      ') for full terms and conditions.'
  }

  if (id ==='PMLegalExpensesCov') {
    body =
    'Looking for additional cover to protect you against losses not covered by your motor insurance policy?\n\nLegal Expenses Cover includes:\n\n- Up to £100,000 for legal costs and expenses to claim compensation for personal injury or losses from a person responsible for an accident\n\n- Up to £50,000 to cover legal costs if you are investigated in relation to, or charged with a motoring offence\n\nEligible claims must have at least a 51% chance of success. Costs that you incur without our consent or which exceed the sum we would pay a law firm from our panel, parking infringements or non-payment of parking or other motoring related fines, penalties or compensation ordered against you are not covered.\n\nView the [Insurance Product Information Document](https://assets.ctfassets.net/numinvtctmda/MLaMAuicweyn5C0SdafWH/b531c6a890d4799f8c8974aa063ce53c/TYA_LEC_IPID_v1_FINAL.pdf) to understand the main benefits / restrictions. You can access the [Policy Document](' + 
    (showNewAfterNow ? LECPD2 : LECPD1) +
    ') for full terms and conditions.'
  }
  return (
    <Box pt={5} pb={5}>
      <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" className={classes.title}>
            <span>
              <SVG className={classes.icon} src={iconImage ? iconImage : ''} />
            </span>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography className={classes.subtitle}>{perYear}</Typography>
          <Typography className={classes.subtitle} color="secondary">
            {perMonth}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {ncdYears === undefined && <ReactMarkdown linkTarget="_blank">{body ? body : ''}</ReactMarkdown>}
          {ncdYears !== undefined && (
            <ReactMarkdown linkTarget="_blank">{body ? body.replace('NCD_YEARS', ncdYears) : ''}</ReactMarkdown>
          )}
        </Grid>
        <Grid item xs={12}>
          {selected && onRemove !== undefined ? (
            <Button variant="outlined" color="primary" onClick={() => onRemove(codeIdentifier)}>
              Remove
            </Button>
          ) : (
            <Button variant="outlined" color="secondary" onClick={() => onAddon(codeIdentifier)}>
              Add cover
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default BasicAddonSection
