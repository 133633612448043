import moment from 'moment'

import {
  formatDriverToDisplay,
  formatMyExcessPaymentToDisplay,
  formatPaymentPlansToDisplay,
  formatVehicleToDisplay,
} from './formatToDisplay'

import {
  booleanToYesOrNo,
  capitalizeAllFirstLetters,
  formatCurrencyObject,
  formatDateObject,
  formatYourCoverSummaryToDisplay,
  intToYearsString,
  numberArrayToOptions,
  placeholderReplace,
  stringArrayToOptions,
} from './formatTools'

import { LabelValuePair, PaymentPlanDisplay } from 'types/contentTypes'
import { Driver, VehicleInfo, CurrencyType } from 'types/policy'
import { AccountHolder, BankAccountData, BindData, PaymentPlan, Quote, QuoteData } from 'types/policyTypes'

export const guidewireQuoteTools = (quote: any) => {
  const {
    policyHolder,
    drivers,
    myExcessPayment,
    vehicle,
    convictionCodes,
    ncdYearsOptions,
    periodStartDate,
    bindData,
    productName,
    quoteID,
    includedAddOns,
    sessionUUID,
    quoteData,
    policyAddress,
  } = quote

  /**
   * @description Retrieve information about the main accountholder
   *
   * Note: Inofrmation here is not necessarily the same as the accountholder driver object
   */
  const primaryAccountHolder = () => {
    /**
     * @description Return details as GW sends them
     */
    const details = () => policyHolder

    /**
     * @description Account holder display name
     *
     * ('withTile') => Mr Example Name
     *
     * () => Example Name
     */
    const displayName = (withTitle?: 'withTitle') => {
      const { displayName, title } = details() as AccountHolder

      if (withTitle === undefined) return displayName

      return capitalizeAllFirstLetters(`${title} ${displayName}`)
    }
    /**
     * @description Return primaryAddress as GW sends it
     */
    const address = () => (details() as AccountHolder).primaryAddress

    const contactEmail = () => (details() as AccountHolder).emailAddress1

    const getContactNumber = (type: 'cell' | 'home' | 'contact') => {
      switch (type) {
        case 'cell':
          return (details() as AccountHolder).cellNumber
        /*case 'home':
          return (details() as AccountHolder).homeNumber*/

        default:
          return (details() as AccountHolder).cellNumber
      }
    }

    return {
      details,
      displayName,
      address,
      contactEmail,
      getContactNumber,
    }
  }

  /**
   * @description Retrieve Annual / Monthly / Premium costs of a quote
   */
  const premium = () => {
    const { offeredQuotes } = quoteData as QuoteData

    /**
     * @description Returns the total premium cost as a GW Currency Object or a formatted string
     *
     *  This values comes from the offeredQuotes object and not a selected plan as it contains the total (premium) for the offered quote (both annual and monthly)
     *
     * The premium total is the same for both monthly and annual payment options.
     * Do not confuse with the total of a selected payment plan cost which is actually preumium cost plus fees
     */
    const total = (display?: 'display') => {
      if (display === 'display') return formatCurrencyObject(offeredQuotes[0].premium.total)
      return `${offeredQuotes[0].premium.total.amount}`
    }

    const monthlyBreakdownText = (text: string, paymentPlanDisplay: PaymentPlanDisplay) => {
      return placeholderReplace(text, {
        MONTHLY_INSTALLMENT: paymentPlanDisplay?.installment,
        MONTHLY_FIRST_INSTALLMENT: paymentPlanDisplay?.firstInstallment,
        MONTHLY_DOWNPAYMENT: paymentPlanDisplay?.downPayment,
        MONTHLY_TOTAL: paymentPlanDisplay?.total,
        MONTHLY_NUMBER_OF_INSTALLMENTS: paymentPlanDisplay?.numberOfInstallments,
      })
    }

    return {
      total,
      monthlyBreakdownText,
    }
  }

  const primaryVehicle = () => {
    const details = () => vehicle

    const display = () => formatVehicleToDisplay(details() as any)

    const summaryList = (labels: LabelValuePair[]): LabelValuePair[] => {
      return labels.map(item => {
        if (item.value === 'makeEnhancedVehilceDescriptionYear') {
          return {
            label: item.label,
            // eslint-disable-next-line prettier/prettier
            value: `${display().make} ${display().model} ${display().fuelType} ${display().bodyType} ${
              display().firstRegisteredYear
              // eslint-disable-next-line prettier/prettier
            }`,
          }
        }
        if (item.value === 'overnightParkingAddress') {
          return {
            label: item.label,
            value: getPolicyAddress().displayName,
          }
        }
        return {
          label: item.label,
          value: `${display()[item.value]}`,
        }
      })
    }

    return {
      details,
      display,
      summaryList,
    }
  }

  const policy = () => {
    const start = () => {
      const details = () => periodStartDate

      const display = (format?: string) => formatDateObject(details(), format)

      return {
        details,
        display,
      }
    }

    const end = () =>
      moment(periodStartDate)
        .add(1, 'year')
        .subtract(1, 'day')

    return {
      start,
      end,
    }
  }

  const ddiPayment = () => {
    const { paymentDetails, selectedPaymentPlan } = bindData as BindData
    const bankAccountData = paymentDetails?.bankAccountData as BankAccountData

    const accountHolderName = () => capitalizeAllFirstLetters(bankAccountData.accountHolderName)

    const accountNumber = () => bankAccountData.bankAccountNumber

    const sortCode = () => bankAccountData.sortCode

    return {
      accountHolderName,
      accountNumber,
      sortCode,
    }
  }

  const yourCoverSummary = () => {
    const details = () => {
      return {
        periodStartDate,
        periodEndDate: moment(periodStartDate)
          .add(1, 'year')
          .subtract(1, 'day'),
        productName,
        classOfUse: vehicle.classOfUse,
      }
    }

    const display = (): {
      [key: string]: string
      periodStartDate: string
      periodEndDate: string
      productName: string
      classOfUse: string
    } => formatYourCoverSummaryToDisplay(details())

    const createDisplayList = (labels: LabelValuePair[]): LabelValuePair[] => {
      return labels.map(item => {
        return {
          label: item.label,
          value: display()[item.value],
        }
      })
    }

    return {
      details,
      display,
      createDisplayList,
    }
  }

  const getQuoteId = () => quoteID

  const getPolicyAddress = () => policyAddress

  const mainAccountHolder = () => {
    const details = () => policyHolder

    const policyDisplayName = (withTitle?: 'withTitle') => {
      const { displayName, title } = details() as AccountHolder

      if (withTitle === undefined) return displayName

      return capitalizeAllFirstLetters(`${title} ${displayName}`)
    }

    return {
      details,
      policyDisplayName,
    }
  }

  // Excess
  const getMyExcessPayments = () => {
    const details = () => myExcessPayment

    const display = () => formatMyExcessPaymentToDisplay(details())

    return {
      details,
      display,
    }
  }

  // Conviction codes
  const getConvictionCodes = () => {
    const details = () => convictionCodes.sort()

    const asDropDown = () => stringArrayToOptions({ values: details() })

    return {
      details,
      asDropDown,
    }
  }

  // ncdYears
  const getNcdYearsOptions = () => {
    const details = () => ncdYearsOptions

    const ncdYearsOptionsDropDown = () => numberArrayToOptions({ values: details() })

    return {
      details,
      ncdYearsOptionsDropDown,
    }
  }

  // Policy start
  const policyStartDate = () => {
    const details = () => periodStartDate

    const display = (format?: string) => formatDateObject(details(), format)

    return {
      details,
      display,
    }
  }

  const getVehicle = () => {
    const details = () => vehicle

    const display = () => formatVehicleToDisplay(details() as any)

    const createDisplayList = (labels: LabelValuePair[]): LabelValuePair[] => {
      return labels.map(item => {
        if (item.value === 'makeEnhancedVehilceDescriptionYear') {
          return {
            label: item.label,
            // eslint-disable-next-line prettier/prettier
            value: `${display().make} ${display().model} ${display().fuelType} ${display().bodyType} ${
              display().firstRegisteredYear
              // eslint-disable-next-line prettier/prettier
            }`,
          }
        }
        if (item.value === 'overnightParkingAddress') {
          return {
            label: item.label,
            value: getPolicyAddress().displayName,
          }
        }
        return {
          label: item.label,
          value: `${display()[item.value]}`,
        }
      })
    }

    const numberOfNcdYears = (type: 'details' | 'display') => {
      return type === 'details' ? details().ncdYears : intToYearsString(details().ncdYears)
    }

    const makeModelRegistrationDisplay = () => {
      const { make, model, regNumUnknown, registrationNumber } = details()

      const makeModel = `${make} ${model}`

      if (regNumUnknown) return makeModel

      return `${registrationNumber} ${makeModel}`
    }

    return {
      details,
      display,
      createDisplayList,
      numberOfNcdYears,
      makeModelRegistrationDisplay,
    }
  }

  const getBindData = () => {
    const details = () => bindData

    const accountNumber = () => {
      if (details() === undefined) return undefined
      return (details() as BindData).accountNumber
    }

    const billingAddress = () => {
      if (details() === undefined) return undefined
      return (details() as BindData).billingAddress
    }

    const contactEmail = () => {
      if (details() === undefined) return undefined
      return (details() as BindData).contactEmail
    }

    const getSelectedPaymentPlan = () => {
      const details = () => {
        if (getBindData().details() === undefined) return undefined
        return (getBindData().details() as BindData).selectedPaymentPlan
      }

      const getTotalPremiumPriceDetails = () => details()?.totalPremiumRPT

      const getTotalPremiumPriceDisplay = () => {
        return formatCurrencyObject(details()?.totalPremiumRPT as CurrencyType)
      }

      return {
        details,
        getTotalPremiumPriceDetails,
        getTotalPremiumPriceDisplay,
      }
    }

    const paymentPlans = () => {
      const details = () => {
        if (getBindData().details() === undefined) return undefined
        // TODO - The Toyota quote response is still incorrect and shows 6 plans
        // This picks out the first two for now, needs replacing when GW is fixed
        return (getBindData().details() as BindData).paymentPlans.slice(0, 2)
      }

      const display = () => {
        const data = details()

        if (data !== undefined) return formatPaymentPlansToDisplay(data)

        return undefined
      }

      const annualDetails = () => details()?.find(item => item.billingId === 'bc:5')

      const monthlyDetails = () => details()?.find(item => item.billingId === 'bc:6')

      const annualDetailsDisplay = () => display()?.find(item => item.billingId === 'bc:5')

      const monthlyDetailsDisplay = () => display()?.find(item => item.billingId === 'bc:6')

      const getAnnualTotal = () => annualDetailsDisplay()?.total
      const getMonthlyTotal = () => monthlyDetailsDisplay()?.installment

      const monthlyBreakdownText = (text: string) => {
        const monthlyPlanDisplay = monthlyDetailsDisplay()
        return placeholderReplace(text, {
          MONTHLY_INSTALLMENT: monthlyPlanDisplay?.installment,
          MONTHLY_DOWNPAYMENT: monthlyPlanDisplay?.downPayment,
          MONTHLY_TOTAL: monthlyPlanDisplay?.total,
          MONTHLY_NUMBER_OF_INSTALLMENTS: monthlyPlanDisplay?.numberOfInstallments,
        })
      }

      return {
        details,
        display,
        annualDetails,
        monthlyDetails,
        annualDetailsDisplay,
        monthlyDetailsDisplay,
        getAnnualTotal,
        getMonthlyTotal,
        monthlyBreakdownText,
      }
    }

    return {
      details,
      accountNumber,
      billingAddress,
      contactEmail,
      paymentPlans,
      getSelectedPaymentPlan,
    }
  }

  const updateCoveragesHelper = () => {
    const updateVoluntaryExcess = (amount: number) => {
      return {
        sessionUUID,
        quoteID,
        coverages: includedAddOns,
        // Toyota still has ITB payment plans left over, the first in the array is the correct one
        quote: quoteData?.offeredQuotes[0],
        voluntaryExcess: amount,
        ncdProtection: false,
      }
    }

    return {
      updateVoluntaryExcess,
    }
  }

  return {
    yourCoverSummary,
    mainAccountHolder,
    getQuoteId,
    getPolicyAddress,
    getMyExcessPayments,
    getConvictionCodes,
    getNcdYearsOptions,
    policyStartDate,
    getVehicle,
    getBindData,
    updateCoveragesHelper,
    // Refactored Tools
    premium,
    primaryAccountHolder,
    primaryVehicle,
    policy,
    ddiPayment,
  }
}
