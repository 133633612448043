import { handleActions } from 'redux-actions'
import {
  getPoliciesAction,
  policySelectAction,
  getPolicyByIdAction,
  setPolicyIdsAction,
  getMyPolicyPageContentAction,
  getTypekeysAction,
  getDetailsPageContentAction,
  getConvictionCodesAction,
} from './actions'

import typekeys from 'mock/guidewire/typekeys.json'

const defaultState = {
  content: [],
  policies: [],
  currentPolicyIndex: 0,
  policyIds: [],
  autoRenew: undefined,
  typekeys: typekeys,
}

export default handleActions(
  {
    [getMyPolicyPageContentAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.content) {
        return state
      }
      return {
        ...state,
        content: payload.content,
      }
    },
    [getPoliciesAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.policies) {
        return state
      }
      return {
        ...state,
        policies: payload.policies,
      }
    },

    [policySelectAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        currentPolicyIndex: payload.index,
      }
    },

    [setPolicyIdsAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.policyIds) {
        return state
      }
      return {
        ...state,
        policyIds: payload.policyIds,
      }
    },

    [getPolicyByIdAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.current && !payload.autoRenew) {
        return state
      }
      return {
        ...state,
        current: payload.current,
        renewed: payload.renewed,
        alternateRenewed: payload.alternateRenewed,
        autoRenew: payload.autoRenew,
      }
    },
    [getTypekeysAction.success]: (state, { payload }: { payload: any }) => {
      const { typekeys } = payload

      return {
        ...state,
        typekeys: typekeys,
      }
    },
    [getDetailsPageContentAction.success]: (state, { payload }: { payload: any }) => {
      if (!payload.mappedContent) {
        return state
      }
      return {
        ...state,
        content: payload.mappedContent,
        pageButtons: payload.pageButtons,
      }
    },
    [getConvictionCodesAction.success]: (state, { payload }: { payload: any }) => {
      return {
        ...state,
        convictionCodes: payload,
      }
    },
  },
  defaultState,
)
