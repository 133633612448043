import React from 'react'
import { FieldProps, getIn } from 'formik'
import DateFnsUtils from '@date-io/date-fns'
import subYears from 'date-fns/subYears'
import addMonths from 'date-fns/addMonths'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'
import { addDays } from 'date-fns'
import moment from 'moment'

export interface Props {
  name: string
  format?: string
  disablePast?: boolean
  disableFuture?: boolean
  dob?: boolean
  oneMonthRange?: boolean
  sevenDaysRange?: boolean
  fullWidth?: boolean
  minDate?: string
  controlledValue?: string
  onDateChanged?: (name: string, date: Date) => void
}

const useStyles = makeStyles(theme => ({
  fullInputLabel: {
    width: '100%',
    '& .MuiInputLabel-formControl': {
      left: `-${theme.spacing(2)}px`,
    },
    margin: 0,
  },
  inputLabel: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      width: '100%',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: theme.spacing(35),
    },
    '& .MuiInputLabel-formControl': {
      left: `-${theme.spacing(2)}px`,
    },
    '& .MuiOutlinedInput-adornedEnd ': {
      paddingRight: 0,
    },
    margin: 0,
  },
}))

const QbDatePicker = ({
  field,
  form,
  name,
  format = 'dd  /  MM  /  yyyy',
  disablePast,
  disableFuture,
  dob = false,
  oneMonthRange = false,
  sevenDaysRange = false,
  fullWidth = false,
  minDate,
  controlledValue,
  onDateChanged,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(field.value)
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const submitCount = form.submitCount
  const errorText = error && touched ? error : null

  const minDrivingAge = subYears(new Date(), 17)
  const oneMonth = addMonths(new Date(), 1)
  const sevenDays = addDays(new Date(), 7)

  const calanderMaxDate = () => {
    if (dob === true) return minDrivingAge
    if (oneMonthRange === true) return oneMonth
    if (sevenDaysRange === true) return sevenDays

    return undefined
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...field}
        {...props}
        disablePast={disablePast}
        disableFuture={disableFuture}
        className={fullWidth ? classes.fullInputLabel : classes.inputLabel}
        inputVariant="outlined"
        format={format}
        margin="normal"
        value={typeof controlledValue !== 'undefined' ? controlledValue : selectedDate || null}
        placeholder="Choose a date"
        maxDate={calanderMaxDate()}
        minDate={minDate ? moment(minDate) : undefined}
        onChange={date => {
          setSelectedDate(date)
          form.setFieldValue(field.name, date, submitCount > 0)
          form.setTouched({ ...form.touched, [field.name]: true })
          if (date && onDateChanged) {
            onDateChanged(field.name, date)
          }
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        views={format === 'MM / yyyy' ? ['month', 'year'] : undefined}
        error={!!errorText}
        helperText={errorText}
      />
    </MuiPickersUtilsProvider>
  )
}

export default QbDatePicker
