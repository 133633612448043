import { all, call, put, takeLatest } from 'redux-saga/effects'

import { setIsLoadingAction } from 'redux/loading/actions'
import { onLogout } from 'redux/login/saga'

import { PolicyDetailsResponse, RequestResponse } from 'types/responses'

import { tokenValidCheck } from 'utils/tokenRefresh/tokenRefresh'
import { formContentItem } from 'utils/content/contentItemMapper'
import fallbackYourDetailsPageContent from 'mock/cms/yourDetailsPage.json'
import { ContentItemType } from 'types'
import {
  getMyPolicyPageContentAction,
  getPolicyByIdAction,
  policySelectAction,
  getTypekeysAction,
  getDetailsPageContentAction,
  getConvictionCodesAction,
} from './actions'
import { getPolicyById, getTypekeys, getDetailsPageContent, getConvictionCodes } from './client'

import fallbackMyPolicyPageContent from 'mock/cms/page_my_policy.json'

export function* onGetMyPolicyPageContent() {
  try {
    yield put(setIsLoadingAction.success({ isLoading: true }))
    // TODO: CMS space => content
    // const { data } = yield call(() => getLoginPageContent()) as any
    // const { content } = data
    yield put(getMyPolicyPageContentAction.success({ content: fallbackMyPolicyPageContent }))
    yield put(setIsLoadingAction.success({ isLoading: false }))
  } catch (error) {
    yield put(getMyPolicyPageContentAction.success({ content: fallbackMyPolicyPageContent }))
    yield put(setIsLoadingAction.success({ isLoading: false }))
  }
}

export function* onPolicySelectAction(action: any) {
  const {
    payload: { index, policyId },
  } = action

  const { isValid } = yield call(() => tokenValidCheck())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    try {
      yield put(setIsLoadingAction.success({ isLoading: true }))

      yield put(policySelectAction.success({ index }))
      sessionStorage.setItem('policy_index', `${index}`)

      const {
        response: { status: policyDetailsStatus, data: policyDetails },
      }: RequestResponse<PolicyDetailsResponse> = yield call(() => getPolicyById(policyId, access_token))

      if (policyDetailsStatus === 200) {
        yield put(
          getPolicyByIdAction.success({
            current: policyDetails.currentPeriod,
            renewed: policyDetails.renewedPeriod,
            alternateRenewed: policyDetails.alternateRenewedPeriod,
            autoRenew: policyDetails.autoRenew,
          }),
        )
      }

      if (policyDetailsStatus === 500) {
        // TODO: Need a redirect to an error page
        console.log('Network error')
      }

      yield put(setIsLoadingAction.success({ isLoading: false }))
    } catch (error) {
      // TODO: Need a redirect to an error page
      console.log('Something went wrong')
    }
  } else {
    yield call(() => onLogout())
  }
}

export function* onGetTypekeys() {
  try {
    const { status, data } = yield call(() => getTypekeys())

    if (status === 200) yield put(getTypekeysAction.success(data))
  } catch (error) {
    yield put(getTypekeysAction.error({}))
  }
}

export function* onGetDetailsPageContent() {
  try {
    const { data } = yield call(() => getDetailsPageContent()) as any
    const mappedContent = data.content.map((item: ContentItemType) => formContentItem(item))
    const { pageButtons } = data
    yield put(getDetailsPageContentAction.success({ mappedContent, pageButtons }))
  } catch (error) {
    const { content, pageButtons } = fallbackYourDetailsPageContent
    const mappedContent = (content as ContentItemType[]).map((item: ContentItemType) => formContentItem(item))
    yield put(getDetailsPageContentAction.success({ mappedContent, pageButtons }))
  }
}

export function* onGetConvictionCodesAction() {
  const { response, error } = yield call(() => getConvictionCodes())
  if (response) {
    const { data } = response
    yield put(getConvictionCodesAction.success(data))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(policySelectAction.start, onPolicySelectAction),
    takeLatest(getMyPolicyPageContentAction.start, onGetMyPolicyPageContent),
    takeLatest(getTypekeysAction.start, onGetTypekeys),
    takeLatest(getDetailsPageContentAction.start, onGetDetailsPageContent),
    takeLatest(getConvictionCodesAction.start, onGetConvictionCodesAction),
  ])
}
