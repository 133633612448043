import { all } from 'redux-saga/effects'
import loginSaga from './login/saga'
import policySaga from './policy/saga'
import loading from './loading/saga'
import account from './account/saga'
import snackbarMessage from './snackbarMessage/saga'
import mta from './mta/saga'
import car from './car/saga'
import renewal from './renewal/saga'
import livechat from './livechat/saga'
import payment from './payment/saga'
import updateCoverages from './updateCoverages/saga'

export default function* rootSaga() {
  yield all([
    loginSaga(),
    policySaga(),
    loading(),
    account(),
    snackbarMessage(),
    mta(),
    car(),
    renewal(),
    livechat(),
    payment(),
    updateCoverages(),
  ])
}
