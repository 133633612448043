export function checkSessionTimeout(timeoutFunction: VoidFunction) {
  if (isInProcess()) timeoutFunction()
}

function isInProcess() {
  const processLocations = ['/my-policy', '/my-messages', '/my-account', '/renewal-payment-annual']

  return processLocations.includes(window.location.pathname)
}

export const SESSION_TIMEOUT_IN_MS = 30 * 60 * 1000 // 30 min
export const SESSION_TIMEOUT_WARNING_IN_MS = 29 * 60 * 1000 // 29 min

export const PAYMENT_SESSION_TIMEOUT_IN_MS = 15 * 60 * 1000 // 10 min
export const PAYMENT_SESSION_TIMEOUT_WARNING_IN_MS = 14 * 60 * 1000 // 9 min

export function startLogoutTimeout(
  setShowWarning: (show: boolean) => void,
  logout: VoidFunction,
  timeoutId: any,
  warnTimeoutId: any,
  setTimeoutId: (x: any) => void,
  setWarnTimeoutId: (x: any) => void,
) {
  setShowWarning(false)

  if (timeoutId) clearTimeout(timeoutId)
  if (warnTimeoutId) clearTimeout(warnTimeoutId)

  setTimeoutId(setTimeout(() => checkSessionTimeout(() => logout()), window.location.pathname.includes('renewal-payment-annual') ? PAYMENT_SESSION_TIMEOUT_IN_MS : SESSION_TIMEOUT_IN_MS))
  setWarnTimeoutId(setTimeout(() => checkSessionTimeout(() => setShowWarning(true)), window.location.pathname.includes('renewal-payment-annual') ? PAYMENT_SESSION_TIMEOUT_WARNING_IN_MS : SESSION_TIMEOUT_WARNING_IN_MS))
}
