import { Divider, makeStyles, Typography, Box, Grid } from '@material-ui/core'
import BasicAccordion from 'components/ui/BasicAccordion'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ReactMarkdown from 'react-markdown'

export interface Props {
  showTitle?: boolean
}

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: 20,
  },
  title: {
    margin: '20px 0',
  },
  infoContainer: {
    display: 'flex',

    '& p': {
      marginTop: 0,
      marginLeft: 12,
    },
    '& strong': {
      fontWeight: 'normal',
      color: theme.palette.secondary.main,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  infoIcon: {
    width: 18,
  },
  textWrap: {
    overflowWrap: 'anywhere',
  },
  claimText: {
    marginBottom: 20,
    marginLeft: 30,
    fontSize: 20,
    lineHeight: 1.5,
  },
  subTitle: {
    marginLeft: 30,
    marginBottom: 20,
  },
  //
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  rightText: {
    marginLeft: 24,
  },
  subHeader: {
    fontSize: 20,
  },
}))

const HelpSection = ({ showTitle = true }: Props) => {
  const classes = useStyles()
  return (
    <div>
      {showTitle && (
        <Typography variant="h3" className={classes.title}>
          Need help?
        </Typography>
      )}

      <BasicAccordion title="Contact us">
        <Divider className={classes.divider} />

        <Grid container spacing={4} className={classes.divider}>
          <Grid item xs={12}>
            <Typography component="i">
              {`The cost of calls to 03 prefixed numbers are charged at national call rates (charges may vary dependent on your network provider) and are usually included in inclusive minute plans from landlines and mobiles.`}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">
                {
                  'Contact our customer service team \n\n Our Live Chat team are here to help:\n Monday-Friday: 8am-7:45pm\n Saturday: 9am-4pm\n Bank Holidays: 8am-4pm \n\n Alternatively, speak to a member of the team by calling us on  __0333 103 7791__, available: \n\n Monday-Friday: 9am-5pm \n Saturday: 9am-4pm \n Bank Holidays: 8am-4pm'
                }
              </ReactMarkdown>
            </Typography>
          </div>
        </Grid>

        <Divider className={classes.divider} />
        <Grid item>
          <Typography className={classes.claimText}>
            {`If you need to make a claim, our dedicated Claims team are here to help 24/7. 365 days a year`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">{`Emergency telephone number \n\n  __0333 103 7790__`}</ReactMarkdown>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">
                {`Discuss an existing claim \n\n __0333 103 7790__ \n\n Mon-Fri 9am-5pm \n\n Email: __[claims@toyotainsuranceservices.co.uk](mailto:claims@toyotainsuranceservices.co.uk)__`}
              </ReactMarkdown>
            </Typography>
          </div>
        </Grid>
        <Divider className={classes.divider} />

        <Grid item xs={12}>
          <Typography className={classes.subTitle}>{`Other useful numbers`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">
                {`Windscreen and glass damage claims \n\n __0345 125 4069__`}
              </ReactMarkdown>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">
                {`Road Rescue and emergency breakdown assistance \n\n __0120 681 2784__`}
              </ReactMarkdown>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">{`Key Protect \n\n __0203 794 9309__`}</ReactMarkdown>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">{`Legal Expenses Cover \n\n __0333 000 7906__`}</ReactMarkdown>
            </Typography>
          </div>
        </Grid>
      </BasicAccordion>

      <BasicAccordion title="Complaints">
        <Divider className={classes.divider} />

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">
                {`We aim to provide a high level of service but if we don’t meet your expectations, please contact us: \n\n 0333 103 7791 \n\n Email: __[complaints.car@toyotainsuranceservices.co.uk](mailto:complaints.car@toyotainsuranceservices.co.uk)__`}
              </ReactMarkdown>
              <div style={{ fontWeight: 800, marginBottom: 16 }}>
                <ReactMarkdown linkTarget="__blank">
                  {`Post: Complaints Officer, Toyota Insurance Services, PO Box 1308, Newcastle upon Tyne, NE12 2BF`}
                </ReactMarkdown>
              </div>
              <ReactMarkdown linkTarget="__blank">
                {`For more information on our complaints procedure, please see our __[Private Car Policy Document.](https://assets.ctfassets.net/numinvtctmda/6qBToLDi5L0MPUtX40DaDG/a3703562a36caa7bbbe86f21a1a476bb/TYA_PCPD_v4.0.pdf)__`}
              </ReactMarkdown>
            </Typography>
          </div>
        </Grid>
      </BasicAccordion>

      <BasicAccordion title="Data protection">
        <Divider className={classes.divider} />

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">
                {`If you have any questions about how we collect, store or use your personal information, please contact our Data Protection Officer: \n\n Email: __[DPO.car@toyotainsuranceservices.co.uk](mailto:DPO.car@toyotainsuranceservices.co.uk)__`}
              </ReactMarkdown>
              <div style={{ fontWeight: 800, marginBottom: 16 }}>
                <ReactMarkdown linkTarget="__blank">
                  {`Post: The Data Protection Officer, Toyota Insurance Services, PO Box 1308, Newcastle upon Tyne, NE12 2BF`}
                </ReactMarkdown>
              </div>
              <ReactMarkdown linkTarget="__blank">
                {`For more information on how we use your data, please see our __[Privacy Notice](https://assets.ctfassets.net/numinvtctmda/2dKZgMPSSWk880lgwyOuxf/8b59481024af093989cb11d56fbdfe79/TYA_Privacy_Notice_v2.0.pdf)__ and __[Cookie Policy](https://assets.ctfassets.net/numinvtctmda/69bPyHFR1FkcqDlZMVWV5r/6a3ebfe13332abd29708efccdfd15c7c/TYA_Cookie_Policy_v1.0.pdf)__`}
              </ReactMarkdown>
            </Typography>
          </div>
        </Grid>
      </BasicAccordion>

      <BasicAccordion title="Premium Credit LTD">
        <Divider className={classes.divider} />

        <Grid item xs={12}>
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
            <Typography component="div" className={classes.textWrap}>
              <ReactMarkdown linkTarget="__blank">
                {`Do you pay by monthly instalments? If you have any queries regarding your Premium Credit agreement or direct debits please click __[here.](https://www.premiumcredit.com/faq/frequently-asked-questions)__ \n\n Alternatively, please call on __0344 736 9836__ \n\n  Any calls to numbers starting 03 cost the same as calls to numbers starting 01 or 02 and may be included in any inclusive calls package you may have. \n\n Toyota Insurance Services does not earn any revenue from the use of the 03 number.`}
              </ReactMarkdown>
            </Typography>
          </div>
        </Grid>
      </BasicAccordion>
    </div>
  )
}

export default HelpSection
