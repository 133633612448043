import { connect } from 'react-redux'
import { policySelectAction } from 'redux/policy/actions'
import { continueToPaymentAction, setPaymentPlanAction, setVoluntaryExcessAction } from 'redux/renewal/actions'

import { IReduxState } from 'types/stateTypes'
import RenewalYourQuotePage from './RenewalYourQuotePage'

const mapStateToProps = (state: IReduxState) => ({
  quote: state.renewal.quote,
  amend: state.renewal.amend,
  policyId: state.policy.policyIds[state.policy.currentPolicyIndex],
  index: state.policy.currentPolicyIndex,
})

const mapDispatchToProps = {
  continueToPaymentAction: continueToPaymentAction.start,
  setPaymentPlanAction: setPaymentPlanAction.start,
  setVoluntaryExcessAction: setVoluntaryExcessAction.start,
  policySelectAction: policySelectAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalYourQuotePage)
