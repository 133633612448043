import axios from 'axios'

import { LoginRequest } from 'types/requests'

import { onLogout } from './saga'

const base64 = require('base-64')

const CONTENT_BASE_URL = `/api/contentful-service`
const AUTHORIZATION_BASE_URL = `/api/login-service`
const POLICY_BASE_URL = `/api/policy-service`
const BASE_URL = `${process.env.PORTAL_BASE_URL}`

// CMS content
export const getLoginPageContent = () => axios.get(CONTENT_BASE_URL + '/login-page')

// Typekeys
export const getTypekeys = () =>
  axios
    .get(POLICY_BASE_URL + '/typekeys/all')
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

// Login with email / password => returns authorisation tokens
export const login = async (requestObject: LoginRequest) => {
  const response = await axios({
    url: `${AUTHORIZATION_BASE_URL}/token?brand=tya`,
    method: 'get',
    headers: {
      'x-authorization': 'Basic ' + base64.encode(requestObject.email + ':' + requestObject.password),
    },
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

  return response
}

export const forgotPassword = async (email: string) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/users/resetPassword?brand=tya`,
    method: 'post',
    headers: {
      'x-authorization': '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      email,
      newPasswordEntryUrl: `${BASE_URL}/auth/resetpassword`,
      brand_itb: 'tya',
    }),
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

  return response
}

export const resetPassword = async (password: string, token: string) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/users/createNewPassword`,
    method: 'post',
    headers: {
      'x-authorization': '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ code: token, new_password: password, brand_itb: 'tya' }),
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

  return response
}

export const isTokenValid = async () => {
  const refreshToken = sessionStorage.getItem('refresh_token')
  const refreshAfterTime = parseInt(sessionStorage.getItem('refresh_after') as string)

  if (Date.now() < refreshAfterTime) {
    return { response: true }
  }

  if (refreshToken === null) {
    onLogout()
  }

  const refresh = await axios({
    url: `${AUTHORIZATION_BASE_URL}/refresh-token?brand=tya`,
    method: 'get',
    headers: {
      'x-authorization': refreshToken,
    },
  })
    .then(response => {
      if (response.status === 200) {
        const {
          data: { access_token, refresh_token, expires_in },
        } = response

        const expiresSeconds = parseInt(expires_in) * 1000 - 3000

        sessionStorage.setItem('access_token', access_token)
        sessionStorage.setItem('refresh_token', refresh_token)
        sessionStorage.setItem('refresh_after', JSON.stringify(Date.now() + expiresSeconds))

        return { response: true }
      } else return { response: false }
    })
    .catch(error => {
      console.log(error)
      onLogout()
      return { response: false }
    })

  return refresh
}
