import axios from 'axios'
import { AddonRequest } from 'types/policyTypes'
import { AddBillingDetailsRequest } from 'types/requests'

const POLICY_BASE_URL = `/api/policy-service`

export const amendRenewal = async (policyId: string, calculateQuote: boolean, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const renewal: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/renewals/TYA%252F${policyId}/amend?calculateQuote=${calculateQuote}`,
    method: 'GET',
    headers: headers,
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return renewal
}

export const quoteRenewal = async (policyId: string, token: string, data: any) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const renewal: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/renewals/TYA%252F${policyId}/quote`,
    method: 'POST',
    headers: headers,
    data: JSON.stringify(data),
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return renewal
}

export const quoteRenewalDirectBuy = async (policyId: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const renewal = await axios({
    url: `${POLICY_BASE_URL}/renewals/TYA%252F${policyId}`,
    method: 'GET',
    headers: headers,
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return renewal
}

export const addBillingDetailsAndGetSagePayUrl = async (
  requestObject: AddBillingDetailsRequest,
  quoteID: string,
  token: string,
) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const response = await axios({
    url: `${POLICY_BASE_URL}/renewals/TYA%252F${quoteID}/addBillingDetailsAndGetSagePayUrl`,
    method: 'post',
    data: requestObject,
    headers: headers,
  }).then(response => {
    //the response holds the value "sagePayURL_itb" (located in the object "bindData")
    if (response.status === 200) return response
  })

  return response
}

export const updateQuotedAndValidateDDI = async (
  requestObject: AddBillingDetailsRequest,
  quoteID: string,
  token: string,
) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const response = await axios({
    url: `${POLICY_BASE_URL}/renewals/TYA%252F${quoteID}/updateQuotedAndValidateDDI`,
    method: 'post',
    data: requestObject,
    headers: headers,
  }).then(response => {
    if (response.status === 200) return response
  })

  return response
}

export const bindRenewalPayment = async (requestObject: AddBillingDetailsRequest, quoteID: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const response = await axios({
    url: `${POLICY_BASE_URL}/renewals/TYA%252F${quoteID}/bindRenewalPayment`,
    method: 'post',
    data: requestObject,
    headers: headers,
  }).then(response => {
    if (response.status === 200) return response
  })

  return response
}

//updates quote with addons. Only changed addons are needed
// TODO: can be refactored to use updateCoverages below. They are essentially the same but the name covers addons / voluntary excess / NCD Protection
export const updateAddons = async (requestObject: AddonRequest, quoteID: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const response = await axios({
    url: `${POLICY_BASE_URL}/renewals/TYA%252F${quoteID}/updatecoverages`,
    method: 'put',
    data: requestObject,
    headers: headers,
  }).then(response => {
    if (response.status === 200) return response
  })

  return response
}

export const updateCoverages = async (requestObject: AddonRequest, quoteID: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const response = await axios({
    url: `${POLICY_BASE_URL}/renewals/${quoteID}/updatecoverages`,
    method: 'put',
    data: requestObject,
    headers: headers,
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return response
}
