import { connect } from 'react-redux'

import { cancelMTAChangesAction, confirmMTAChangesAction, deleteAddedDriverAction } from 'redux/mta/actions'
import { getMyPolicyPageContentAction } from 'redux/policy/actions'

import { IReduxState } from 'types/stateTypes'
import MTASummaryPage from './MTAMultiPage'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  content: state.policy.content,
  registration: state.car.registration,
  mta: state.mta,
})

const mapDispatchToProps = {
  getMTAMulitChangePageContentAction: getMyPolicyPageContentAction.start,
  cancelMTAChangesAction: cancelMTAChangesAction.start,
  deleteAddedDriverAction: deleteAddedDriverAction.start,
  confirmMTAChangesAction: confirmMTAChangesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MTASummaryPage)
