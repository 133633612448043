import { useState } from 'react'
import { Field, getIn } from 'formik'

import { Box, Divider, Grid, Grow, makeStyles, Typography } from '@material-ui/core'

import QuestionWithAutoCompletion from 'components/ui/QuestionWithAutoCompletion'
import QuestionWithTextField from 'components/ui/QuestionWithTextField'
import QuestionWithSelector from 'components/ui/QuestionWithSelector'
import QuestionWithDatePicker from 'components/ui/QuestionWithDatePicker'
import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns'

import { LabelValuePair } from 'types/contentTypes'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  row: {
    alignItems: 'flex-end',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  hideOnMobile: {
    display: 'block',
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      display: 'none',
    },
  },
}))

export interface IAboutYouContent {
  id?: string
  title: string
  yourTitle: string
  yourTitleCaption?: string
  yourTitleToolTip?: string
  yourTitleOptions: LabelValuePair[]
  yourFirstName: string
  yourFirstNameCaption?: string
  yourFirstNameToolTip?: string
  yourSurname: string
  yourSurnameCaption?: string
  yourSurnameToolTip?: string
  yourDateOfBirth: string
  yourDateOfBirthCaption?: string
  yourDateOfBirthToolTip?: string
  ukResidentMoreThan5Years: string
  ukResidentMoreThan5YearsCaption?: string
  ukResidentMoreThan5YearsToolTip?: string
  ukResidentMoreThan5YearsOptions: LabelValuePair[]
  ukResidentMoreThan5YearsSince: string
  ukResidentMoreThan5YearsSinceCaption?: string
  ukResidentMoreThan5YearsSinceToolTip?: string
  yourMaritalStatus: string
  yourMaritalStatusCaption?: string
  yourMaritalStatusToolTip?: string
  yourMaritalStatusOptions: LabelValuePair[]
  yourGender: string
  yourGenderCaption?: string
  yourGenderToolTip?: string
  yourGenderOptions: LabelValuePair[]
  yourOccupation: string
  yourOccupationCaption?: string
  yourOccupationToolTip?: string
  yourOccupationOptions: LabelValuePair[]
  yourEmploymentStatus?: string
  yourEmploymentStatusCaption?: string
  yourEmploymentStatusToolTip?: string
  yourEmploymentStatusOptions?: LabelValuePair[]
  yourEmployersBusiness?: string
  yourEmployersBusinessCaption?: string
  yourEmployersBusinessToolTip?: string
  yourEmployersBusinessOptions?: LabelValuePair[]
  yourRelationship?: string
  yourRelationshipCaption?: string
  yourRelationshipToolTip?: string
  yourRelationshipOptions?: LabelValuePair[]
  homeOwner: string
  homeOwnerCaption?: string
  homeOwnerToolTip?: string
  homeOwnerOptions?: LabelValuePair[]
}

interface Props {
  content: IAboutYouContent
  additionalDriversPage?: boolean
  values?: any
  renewal?: boolean
  formRef?: any
  resetResidentDate?: () => void
}

const AboutYouSection = ({
  content,
  additionalDriversPage = false,
  values,
  renewal = false,
  formRef,
  resetResidentDate,
}: Props) => {
  const {
    title,
    yourTitle,
    yourTitleCaption,
    yourTitleToolTip,
    yourTitleOptions,
    yourFirstName,
    yourFirstNameCaption,
    yourFirstNameToolTip,
    yourSurname,
    yourSurnameCaption,
    yourSurnameToolTip,
    yourDateOfBirth,
    yourDateOfBirthCaption,
    yourDateOfBirthToolTip,
    ukResidentMoreThan5Years,
    ukResidentMoreThan5YearsCaption,
    ukResidentMoreThan5YearsToolTip,
    ukResidentMoreThan5YearsOptions,
    ukResidentMoreThan5YearsSince,
    ukResidentMoreThan5YearsSinceCaption,
    ukResidentMoreThan5YearsSinceToolTip,
    yourMaritalStatus,
    yourMaritalStatusCaption,
    yourMaritalStatusToolTip,
    yourMaritalStatusOptions,
    yourGender,
    yourGenderCaption,
    yourGenderToolTip,
    yourGenderOptions,
    yourOccupation,
    yourOccupationCaption,
    yourOccupationToolTip,
    yourOccupationOptions,
    yourEmploymentStatus,
    yourEmploymentStatusCaption,
    yourEmploymentStatusToolTip,
    yourEmploymentStatusOptions,
    yourEmployersBusiness,
    yourEmployersBusinessCaption,
    yourEmployersBusinessToolTip,
    yourEmployersBusinessOptions,
    yourRelationship,
    yourRelationshipCaption,
    yourRelationshipToolTip,
    yourRelationshipOptions,
    homeOwner,
    homeOwnerCaption,
    homeOwnerToolTip,
    homeOwnerOptions,
  } = content

  const [isUKResidenceMoreThan5Years, setUKResidenceMoreThan5Years] = useState(
    additionalDriversPage && getIn(values, 'ukResident5Years') === 'no',
  )

  const classes = useStyles()

  return (
    <>
      <Typography style={{ fontSize: 20, fontWeight: 800 }}>{title}</Typography>
      {!renewal && (
        <Box pt={4} pb={2}>
          <Divider />
        </Box>
      )}

      <Box pl={2.5} pr={2.5} pb={2}>
        <Grid container spacing={2} className={classes.row}>
          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithSelector}
              name="title"
              question={yourTitle}
              hint={yourTitleCaption}
              toolTip={yourTitleToolTip}
              options={yourTitleOptions}
              disabled={renewal}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              required
              component={QuestionWithTextField}
              name="firstName"
              question={yourFirstName}
              hint={yourFirstNameCaption}
              toolTip={yourFirstNameToolTip}
              inputStyle="capitalize"
              disabled={renewal}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              required
              component={QuestionWithTextField}
              name="lastName"
              question={yourSurname}
              hint={yourSurnameCaption}
              toolTip={yourSurnameToolTip}
              inputStyle="capitalize"
              disabled={renewal}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              required
              row
              component={QuestionWithRadioBtns}
              name="gender"
              question={yourGender}
              hint={yourGenderCaption}
              toolTip={yourGenderToolTip}
              options={yourGenderOptions}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              required
              component={QuestionWithDatePicker}
              name="dateOfBirth"
              disableFuture
              dob
              question={yourDateOfBirth}
              hint={yourDateOfBirthCaption}
              toolTip={yourDateOfBirthToolTip}
              disabled={renewal}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithAutoCompletion}
              name="occupation"
              question={yourOccupation}
              hint={yourOccupationCaption}
              toolTip={yourOccupationToolTip}
              options={yourOccupationOptions}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithSelector}
              name="occupationStatus"
              question={yourEmploymentStatus}
              hint={yourEmploymentStatusCaption}
              toolTip={yourEmploymentStatusToolTip}
              options={yourEmploymentStatusOptions}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithAutoCompletion}
              name="employersBusiness"
              question={yourEmployersBusiness}
              hint={yourEmployersBusinessCaption}
              toolTip={yourEmployersBusinessToolTip}
              options={yourEmployersBusinessOptions}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithSelector}
              name="maritalStatus"
              question={yourMaritalStatus}
              hint={yourMaritalStatusCaption}
              toolTip={yourMaritalStatusToolTip}
              options={yourMaritalStatusOptions}
            />
          </Grid>

          {!renewal && (
            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
              <Field
                required
                component={QuestionWithSelector}
                name="phRelationship"
                question={yourRelationship}
                hint={yourRelationshipCaption}
                toolTip={yourRelationshipToolTip}
                options={yourRelationshipOptions}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              required
              row
              component={QuestionWithRadioBtns}
              name="homeOwner"
              question={homeOwner}
              hint={homeOwnerCaption}
              toolTip={homeOwnerToolTip}
              options={homeOwnerOptions}
            />
          </Grid>

          {/* Necessary for the correct layout on screens larger than mobile */}
          <Grid item xs={4} className={classes.hideOnMobile} />
          {renewal && <Grid item xs={4} className={classes.hideOnMobile} />}

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              required
              row
              component={QuestionWithRadioBtns}
              name="ukResident5Years"
              question={ukResidentMoreThan5Years}
              hint={ukResidentMoreThan5YearsCaption}
              toolTip={ukResidentMoreThan5YearsToolTip}
              options={ukResidentMoreThan5YearsOptions}
              onSelect={(value: string) => {
                setUKResidenceMoreThan5Years(value === 'no')
                if (value === 'yes') resetResidentDate && resetResidentDate()
              }}
              disabled={renewal}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Grow in={isUKResidenceMoreThan5Years} timeout={300} unmountOnExit>
              <Field
                required
                component={QuestionWithDatePicker}
                name="ukResidentSinceDate"
                format="MM / yyyy"
                question={ukResidentMoreThan5YearsSince}
                hint={ukResidentMoreThan5YearsSinceCaption}
                toolTip={ukResidentMoreThan5YearsSinceToolTip}
                disableFuture
                disabled={renewal}
                onDateChanged={(name: string, date: string) => {
                  if (moment(date).isValid()) {
                    formRef?.current?.setFieldValue('ukResidentSinceDate', date, true)
                  }
                }}
              />
            </Grow>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AboutYouSection
