import { all, call, put, takeLatest } from 'redux-saga/effects'
import { setIsLoadingAction } from 'redux/loading/actions'
import { isTokenValid } from 'redux/login/client'
import { onLogout } from 'redux/login/saga'
import { updateQuoteAction } from 'redux/renewal/actions'
import { updateCoverages } from 'redux/renewal/client'
import { updateCoveragesAction } from './actions'

export function* onUpdateCoveragesAction(action: any) {
  const {
    payload: { requestObject, quoteId },
  } = action

  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())
  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    try {
      const { data, status } = yield call(() => updateCoverages(requestObject, quoteId, access_token))

      if (status === 200) {
        yield put(updateQuoteAction.success({ quote: data }))
        yield put(setIsLoadingAction.success({ isLoading: false }))
      } else {
        console.log(`Status: ${status}. There was an error`)
        yield put(setIsLoadingAction.success({ isLoading: false }))
      }
    } catch (error) {
      console.log(error)
      yield put(setIsLoadingAction.success({ isLoading: false }))
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export default function* rootSaga() {
  yield all([takeLatest(updateCoveragesAction.start, onUpdateCoveragesAction)])
}
