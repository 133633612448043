import { stat } from 'fs'
import { portalTools } from 'guidewire/portalTools'
import moment from 'moment'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { resetVehicleAction } from 'redux/car/actions'
import { setIsLoadingAction } from 'redux/loading/actions'
import { isTokenValid } from 'redux/login/client'
import { onLogout, onPageRefreshAction } from 'redux/login/saga'
import { EndPoint } from 'types/endpoint'
import { SelectedPolicy } from 'types/policy'
import { AddressById } from 'types/responses'
import { history } from 'utils'

import {
  closeMTAModalAction,
  showMTAModalAction,
  continueAddressChangeAction,
  cancelMTAChangesAction,
  confirmMTAChangesAction,
  continueCarChangeAction,
  continueAddDriverAction,
  executeMTAChangesAction,
  showExecuteMTAModalAction,
  deleteAddedDriverAction,
  multiMTAAction,
  bindPaymentAction,
  resetAction,
  setShowExecuteModalAction,
} from './actions'

import {
  changeCar,
  changeOvernightAddress,
  addNewDriver,
  addBillingDetailsAndGetSagepayUrl,
  bindPayment,
  validateCard,
  multiMTAChange,
  createMultiChange,
  removeDriver,
  getRemainingInstallments,
} from './client'

export function* onShowMTAModal(action: {
  payload: {
    redirect: string
  }
}) {
  const {
    payload: { redirect },
  } = action

  yield put(
    showMTAModalAction.success({
      showMTAModal: true,
      redirect: redirect,
    }),
  )
}

export function* onShowExecuteMTAModal() {
  yield put(showExecuteMTAModalAction.success())
}

export function* onSetShowExecuteModal(action: { payload: boolean }) {
  const { payload } = action
  yield put(setShowExecuteModalAction.success(payload))
}

export function* onCloseMTAModal() {
  yield put(closeMTAModalAction.success())
}

export function* onCancelMTAChanges() {
  yield put(cancelMTAChangesAction.success())
  yield call(() => history.push(EndPoint.MY_POLICY))
}

export function* onContinueAddressChange(action: {
  payload: {
    startDate: string
    addressSelect: any
    furtherChanges: boolean
    homeOwner: string
    dayTimeParking: string
    nightTimeParking: string
    current: SelectedPolicy
    jobId: string
  }
}) {
  const {
    payload: { startDate, addressSelect, furtherChanges, homeOwner, dayTimeParking, nightTimeParking, current, jobId },
  } = action

  yield put(
    continueAddressChangeAction.success({
      startDate,
      addressSelect,
      homeOwner,
      dayTimeParking,
      nightTimeParking,
      showMTAConfirmModal: !furtherChanges,
    }),
  )

  if (furtherChanges) {
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string

      const currentPolicy = portalTools(current)
      const policyNumber = currentPolicy.policyId().replace('TYA/', '')

      //change changed to true
      addressSelect.changed = true

      const changedAddress = {
        dayTimeParking: dayTimeParking,
        nightTimeParking: nightTimeParking,
        homeOwner: homeOwner === 'yes',
        overnightParkingAddress: addressSelect,
        ...(jobId.length > 0 && { jobId: jobId, status: 'Draft' }),
      }

      const { data, status } = yield call(() => multiMTAChange(policyNumber, changedAddress, startDate, access_token))

      if (status == 200) {
        yield put(
          multiMTAAction.success({
            jobId: data.jobId,
          }),
        )
        yield call(() => history.push(EndPoint.MTA_MULTI_CHANGE))
      } else {
        yield call(() => history.push(EndPoint.ERROR_MESSAGE))
        yield put(continueAddressChangeAction.error({}))
      }
    }
  }
}

export function* onContinueCarChange(action: {
  payload: {
    startDate: string
    furtherChanges: boolean
    selectedVehicle: any
    carPurchaseDate: any
    marketValue: any
    hasBeenModified: any
    generalAfterMarketMods: any
    bodyWorkMods: any
    engineMods: any
    wheelsOrTyresMods: any
    conversionMods: any
    otherMods: any
    securityDevices: any
    nightTimeParking: any
    dayTimeParking: any
    classOfUse: any
    registeredKeeper: any
    securityTracker: any
    imported: any
    rightHandDrive: any
    lessThanEightSeats: any
    current: SelectedPolicy
    jobId: string
    registrationNumber: string
    vehicleIsLeased: any
    vehicleLeasingCompany: any
    vehicleOwner: any
  }
}) {
  const {
    payload: {
      startDate,
      furtherChanges,
      selectedVehicle,
      carPurchaseDate,
      marketValue,
      hasBeenModified,
      generalAfterMarketMods,
      bodyWorkMods,
      engineMods,
      wheelsOrTyresMods,
      conversionMods,
      otherMods,
      securityDevices,
      nightTimeParking,
      dayTimeParking,
      classOfUse,
      registeredKeeper,
      securityTracker,
      imported,
      rightHandDrive,
      lessThanEightSeats,
      current,
      jobId,
      registrationNumber,
      vehicleIsLeased,
      vehicleLeasingCompany,
      vehicleOwner,
    },
  } = action

  yield put(
    continueCarChangeAction.success({
      startDate,
      selectedVehicle,
      carPurchaseDate,
      marketValue,
      hasBeenModified,
      generalAfterMarketMods,
      bodyWorkMods,
      engineMods,
      wheelsOrTyresMods,
      conversionMods,
      otherMods,
      securityDevices,
      nightTimeParking,
      dayTimeParking,
      classOfUse,
      registeredKeeper,
      securityTracker,
      imported,
      rightHandDrive,
      lessThanEightSeats,
      showMTAConfirmModal: !furtherChanges,
      vehicleIsLeased,
      vehicleLeasingCompany,
      vehicleOwner,
    }),
  )

  //reset vehicle lookup
  yield put(resetVehicleAction.success({}))

  if (furtherChanges) {
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string

      const currentPolicy = portalTools(current)
      const policyNumber = currentPolicy.policyId().replace('TYA/', '')

      const changedCar = {
        vehicles: [
          {
            ...selectedVehicle,
            costNew: {},
            purchaseDate: carPurchaseDate,
            marketValue: {
              amount: parseInt(marketValue),
            },
            registeredKeeper: registeredKeeper === 'yes',
            securityTracker: securityTracker === 'yes',
            imported: imported === 'yes',
            rightHandDrive: rightHandDrive === 'yes',
            lessThanEightSeats: lessThanEightSeats === 'yes',
            voluntaryExcessOptions: [0, 0],
            hasBeenModified: hasBeenModified === 'yes',
            generalAfterMarketMods: generalAfterMarketMods,
            bodyWorkMods: bodyWorkMods,
            engineMods: engineMods,
            wheelsOrTyresMods: wheelsOrTyresMods,
            conversionMods: conversionMods,
            otherMods: otherMods,
            dayTimeParking,
            nightTimeParking,
            classOfUse,
            securityDevices,
            purchasedMonth: moment(carPurchaseDate).format('MMMM'),
            purchasedYear: moment(carPurchaseDate).year(),
            registrationNumber,
            vehicleIsLeased:
            registeredKeeper === 'yes' ? undefined : vehicleIsLeased === 'yes',
            vehicleLeasingCompany:
            registeredKeeper === 'yes' || vehicleIsLeased === 'no'
              ? undefined
              : vehicleLeasingCompany,
            vehicleOwner:
              registeredKeeper === 'yes' ||
              vehicleIsLeased !== 'yes' ||
              vehicleLeasingCompany === 'none' ||
              vehicleLeasingCompany === 'other'
                ? undefined
                : vehicleOwner,
          },
        ],
        ...(jobId.length > 0 && { jobId: jobId, status: 'Draft' }),
      }

      const { data, status } = yield call(() => multiMTAChange(policyNumber, changedCar, startDate, access_token))

      if (status == 200) {
        yield put(
          multiMTAAction.success({
            jobId: data.jobId,
          }),
        )
        yield call(() => history.push(EndPoint.MTA_MULTI_CHANGE))
      } else {
        yield call(() => history.push(EndPoint.ERROR_MESSAGE))
        yield put(continueCarChangeAction.error({}))
      }
    }
  }
}

export function* onContinueAddDriver(action: {
  payload: {
    startDate: string
    addedDrivers: any[]
    furtherChanges: boolean
    current: SelectedPolicy
    jobId: string
  }
}) {
  const {
    payload: { startDate, addedDrivers, furtherChanges, current, jobId },
  } = action

  yield put(
    continueAddDriverAction.success({
      startDate,
      addedDrivers,
      showMTAConfirmModal: !furtherChanges,
    }),
  )

  if (furtherChanges) {
    const { response: isValid } = yield call(() => isTokenValid())

    if (isValid) {
      const access_token = sessionStorage.getItem('access_token') as string
      const currentPolicy = portalTools(current)
      const policyNumber = currentPolicy.policyId().replace('TYA/', '')

      // check driver object
      const addedDriver = {
        drivers: [
          {
            ...addedDrivers[addedDrivers.length - 1],
          },
        ],
        ...(jobId && jobId?.length > 0 && { jobId: jobId, status: 'Draft' }),
      }

      const { data, status } = yield call(() => multiMTAChange(policyNumber, addedDriver, startDate, access_token))

      if (status == 200) {
        yield put(
          multiMTAAction.success({
            jobId: data.jobId,
          }),
        )
        yield call(() => history.push(EndPoint.MTA_MULTI_CHANGE))
      } else {
        yield call(() => history.push(EndPoint.ERROR_MESSAGE))
        yield put(continueAddDriverAction.error({}))
      }
    }
  }
}

export function* onDeleteAddedDriver(action: {
  payload: {
    startDate: string
    index: number
    current: SelectedPolicy
    jobId: string
  }
}) {
  const {
    payload: { startDate, index, current, jobId },
  } = action

  //removeDriver
  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const currentPolicy = portalTools(current)
    const policyNumber = currentPolicy.policyId().replace('TYA/', '')

    //(policyId: string, publicId: string, date: string, token: string)
    const { data, status } = yield call(() => removeDriver(policyNumber, jobId, startDate, access_token))

    if (status == 200) {
      yield put(
        multiMTAAction.success({
          jobId: data.jobId,
        }),
      )
      yield call(() => history.push(EndPoint.MTA_MULTI_CHANGE))
    } else {
      yield call(() => history.push(EndPoint.ERROR_MESSAGE))
      yield put(deleteAddedDriverAction.error({}))
    }
  }

  yield put(
    deleteAddedDriverAction.success({
      index,
    }),
  )
}

export function* onConfirmMTAChanges(action: {
  payload: {
    startDate: string
    changedAddress: AddressById
    homeOwner: string
    dayTimeParking: string
    nightTimeParking: string
    addedDrivers: any[]
    changedCar: any
    carPurchaseDate: string
    marketValue: string
    hasBeenModified: string
    generalAfterMarketMods: any[]
    bodyWorkMods: any[]
    engineMods: any[]
    wheelsOrTyresMods: any[]
    conversionMods: any[]
    otherMods: any[]
    securityDevices: string
    classOfUse: string
    registeredKeeper: string
    securityTracker: string
    imported: string
    rightHandDrive: string
    lessThanEightSeats: string
    current: SelectedPolicy
    jobId: string
    registrationNumber: string
    vehicleIsLeased: string
    vehicleLeasingCompany: string
    vehicleOwner: string
  }
}) {
  const {
    payload: {
      startDate,
      changedAddress,
      homeOwner,
      dayTimeParking,
      nightTimeParking,
      addedDrivers,
      changedCar,
      carPurchaseDate,
      marketValue,
      hasBeenModified,
      generalAfterMarketMods,
      bodyWorkMods,
      engineMods,
      wheelsOrTyresMods,
      conversionMods,
      otherMods,
      securityDevices,
      classOfUse,
      registeredKeeper,
      securityTracker,
      imported,
      rightHandDrive,
      lessThanEightSeats,
      current,
      jobId,
      registrationNumber,
      vehicleIsLeased,
      vehicleLeasingCompany,
      vehicleOwner,
    },
  } = action

  const { response: isValid } = yield call(() => isTokenValid())
  let error = false

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const currentPolicy = portalTools(current)
    const policyNumber = currentPolicy.policyId().replace('TYA/', '')

    yield put(setIsLoadingAction.success({ isLoading: true }))

    let responseData: { paymentPlanId: string; jobId: any; remainingInstallments: any; [Key: string]: any }

    //check if only one change
    if (changedAddress !== undefined && addedDrivers.length === 0 && changedCar === undefined) {
      const params = `&homeOwner=${homeOwner ===
        'yes'}&dayTimeParking=${dayTimeParking}&nightTimeParking=${nightTimeParking}`
      //change address
      const { status, data } = yield call(() =>
        changeOvernightAddress(policyNumber, changedAddress, startDate, params, access_token),
      )
      responseData = data

      if (status !== 200) {
        error = true
      }
    } else if (changedAddress == undefined && addedDrivers.length === 1 && changedCar == undefined) {
      //add one driver
      const { status, data } = yield call(() => addNewDriver(policyNumber, addedDrivers[0], startDate, access_token))
      responseData = data

      if (status !== 200) {
        error = true
      }
    } else if (changedAddress == undefined && addedDrivers.length === 0 && changedCar != undefined) {
      //change car
      const { status, data } = yield call(() =>
        changeCar(
          policyNumber,
          {
            ...changedCar,
            costNew: {},
            purchaseDate: carPurchaseDate,
            marketValue: {
              amount: parseInt(marketValue),
            },
            registeredKeeper: registeredKeeper === 'yes',
            securityTracker: securityTracker === 'yes',
            imported: imported === 'yes',
            rightHandDrive: rightHandDrive === 'yes',
            lessThanEightSeats: lessThanEightSeats === 'yes',
            voluntaryExcessOptions: [0, 0],
            hasBeenModified: hasBeenModified === 'yes',
            generalAfterMarketMods: generalAfterMarketMods,
            bodyWorkMods: bodyWorkMods,
            engineMods: engineMods,
            wheelsOrTyresMods: wheelsOrTyresMods,
            conversionMods: conversionMods,
            otherMods: otherMods,
            dayTimeParking,
            nightTimeParking,
            classOfUse,
            securityDevices,
            purchasedMonth: moment(carPurchaseDate).format('MMMM'),
            purchasedYear: moment(carPurchaseDate).year(),
            registrationNumber,
            vehicleIsLeased:
            registeredKeeper === 'yes' ? undefined : vehicleIsLeased === 'yes',
            vehicleLeasingCompany:
            registeredKeeper === 'yes' || vehicleIsLeased !== 'yes'
              ? undefined
              : vehicleLeasingCompany,
            vehicleOwner:
              registeredKeeper === 'yes' ||
              vehicleIsLeased !== 'yes' ||
              vehicleLeasingCompany === 'none' ||
              vehicleLeasingCompany === 'other'
                ? undefined
                : vehicleOwner,

          },
          startDate,
          access_token,
        ),
      )
      responseData = data

      if (status !== 200) {
        error = true
      }
    } else {
      //multi changes
      const { status, data } = yield call(() => createMultiChange(policyNumber, jobId, startDate, access_token))
      responseData = data

      if (status !== 200) {
        error = true
      }
    }

    if (responseData.paymentPlanId === 'bc:6' && responseData?.jobId) {
      const { status: remainingInstallmentsStatus, data: remainingInstallments } = yield call(() =>
        getRemainingInstallments(responseData?.jobId || '', access_token),
      )
      responseData.remainingInstallments = remainingInstallments
    }

    yield put(setIsLoadingAction.success({ isLoading: false }))
    if (error) {
      yield call(() => history.push(EndPoint.ERROR_MESSAGE))
      yield put(confirmMTAChangesAction.error({}))
    } else {
      yield put(confirmMTAChangesAction.success(responseData))
      yield call(() => history.push(EndPoint.MTA_SUMMARY))
    }
  } else {
    yield call(() => onLogout())
  }
}

export function* onExecuteMTAChanges(action: {
  payload: {
    mtaResponse: any
    current: SelectedPolicy
    existingCard: boolean
  }
}) {
  const {
    payload: { mtaResponse, current, existingCard },
  } = action

  const { response: isValid } = yield call(() => isTokenValid())
  let error = false

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    let paymentURL = null

    yield put(setIsLoadingAction.success({ isLoading: true }))

    //TODO add logic to use other then existing card
    if (mtaResponse.totalCost.amount > 0 && !existingCard) {
      const currentPolicy = portalTools(current)
      const policyAddress = mtaResponse.billingAddress
        ? mtaResponse.billingAddress
        : currentPolicy.overnightParking().details()

      const paymentDetails = mtaResponse.paymentDetails
        ? mtaResponse.paymentDetails
        : {
            creditCardData: {
              isCardRegisteredToYou: true,
            },
            reuseExistingCard_itb: false,
          }

      //payment card
      //anual payer
      //1.addbillingdetailsandgetsagepayurl
      const { status, data } = yield call(() =>
        addBillingDetailsAndGetSagepayUrl(policyAddress, paymentDetails, mtaResponse.jobId, access_token),
      )

      if (status === 200) {
        //bind payment
        paymentURL = data
      } else {
        error = true
      }
    }

    if (mtaResponse.totalCost.amount < 0 || existingCard) {
      // Annual payment type
      if (mtaResponse.paymentPlanId === 'bc:5') {
        const { status: validateStatus } = yield call(() => validateCard(mtaResponse.jobId, access_token))

        if (validateStatus === 200) {
          // bind payment
          const { status: bindPaymentStatus } = yield call(() => bindPayment(mtaResponse.jobId, access_token, true))
          error = bindPaymentStatus !== 200
        } else {
          error = true
        }
      }

      // Monthly payment type
      if (mtaResponse.paymentPlanId === 'bc:6') {
        const { status: remainingInstallmentsStatus, data: remainingInstallments } = yield call(() =>
          getRemainingInstallments(mtaResponse.jobId, access_token),
        )

        if (remainingInstallmentsStatus === 200 && remainingInstallments > 0) {
          //bind payment
          const { status: bindPaymentStatus } = yield call(() => bindPayment(mtaResponse.jobId, access_token, true))
          error = bindPaymentStatus !== 200
        }

        if (remainingInstallments === 0) {
          error = true
        }
      }
    }

    yield put(setIsLoadingAction.success({ isLoading: false }))
    if (error) {
      yield call(() => history.push(EndPoint.DECLINED + '?error=purchase_failed'))
      yield put(executeMTAChangesAction.error({}))
    } else {
      if (paymentURL) {
        yield put(executeMTAChangesAction.success(paymentURL ? { paymentURL } : {}))
        yield call(() => history.push(EndPoint.PAYMENT_PAGE))
      } else {
        yield call(() => onPageRefreshAction())
        yield call(() => history.push(EndPoint.MTA_SUCCESS))
        yield put(executeMTAChangesAction.success({}))
      }
    }
  } else {
    yield call(() => onLogout())
  }
}

export function* onBindPayment(action: {
  payload: {
    jobId: string
    reuseExistingCard: boolean
  }
}) {
  const {
    payload: { jobId, reuseExistingCard },
  } = action

  const { response: isValid } = yield call(() => isTokenValid())
  let error = false

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    yield put(setIsLoadingAction.success({ isLoading: true }))
    const { status: bindPaymentStatus } = yield call(() => bindPayment(jobId, access_token, reuseExistingCard))
    error = bindPaymentStatus !== 200

    if (error) {
      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield call(() => history.push(EndPoint.DECLINED + '?error=purchase_failed'))
      yield put(executeMTAChangesAction.error({}))
    } else {
      yield call(() => onPageRefreshAction())
      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield call(() => history.push(EndPoint.MTA_SUCCESS))
      yield put(executeMTAChangesAction.success({}))
    }
  } else {
    yield call(() => onLogout())
  }
}

export function* onReset() {
  yield put(resetAction.success({}))
}

export default function* rootSaga() {
  yield all([
    takeLatest(showMTAModalAction.start, onShowMTAModal),
    takeLatest(showExecuteMTAModalAction.start, onShowExecuteMTAModal),
    takeLatest(setShowExecuteModalAction.start, onSetShowExecuteModal),
    takeLatest(closeMTAModalAction.start, onCloseMTAModal),
    takeLatest(continueAddDriverAction.start, onContinueAddDriver),
    takeLatest(continueAddressChangeAction.start, onContinueAddressChange),
    takeLatest(continueCarChangeAction.start, onContinueCarChange),
    takeLatest(cancelMTAChangesAction.start, onCancelMTAChanges),
    takeLatest(confirmMTAChangesAction.start, onConfirmMTAChanges),
    takeLatest(executeMTAChangesAction.start, onExecuteMTAChanges),
    takeLatest(deleteAddedDriverAction.start, onDeleteAddedDriver),
    takeLatest(bindPaymentAction.start, onBindPayment),
    takeLatest(resetAction.start, onReset),
  ])
}
