import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'

import PhoneIcon from '@material-ui/icons/Phone'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import Title from 'components/ui/Title'
import { LabelValuePair } from 'types/generalTypes'

const useStyles = makeStyles(theme => ({
  noTextMargin: {
    '& p, h2': {
      marginTop: 0,
    },
  },
}))

export interface Props {
  index: number
  policyNumber: LabelValuePair
  policySelectAction: ({ index, policyId }: { index: number; policyId: string }) => void
}

const RenewalPaymentCompletePage = ({ policyNumber, index, policySelectAction }: Props) => {
  const classes = useStyles()

  React.useEffect(() => {
    // Refreshes policies on page load
    policySelectAction({ index, policyId: policyNumber.value })
  }, [policySelectAction])

  return (
    <Layout background="white">
      <Section>
        <Grid container spacing={4}>
          <Grid item>
            <Box padding={2} border={'1px solid #d8d8d8'} borderRadius={4} style={{ backgroundColor: '#eff0f0' }}>
              <Typography component="div" className={classes.noTextMargin}>
                <ReactMarkdown>{`## Renewal complete \n\n Thank you for renewing with Toyota Insurance Services. We look forward to protecting you and your car for another year. \n\n You will shortly receive a confirmation email detailing your new policy information. You can also view your policy documentation any time here in Your Portal. Please refer to your documents for full information about your cover, and let us know if something is missing or incorrect.
            `}</ReactMarkdown>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Title
              title="Self-manage your policy in Your Portal"
              text={`If you need to make a change to your policy, you can use Your Portal to benefit from the reduced administration charges.`}
            />

            <div>
              <strong>Here is how Your Portal can help:</strong>
              <Divider style={{ margin: '16px 0' }} />

              <strong>Change of car</strong>
              <ReactMarkdown>{`__£5:__ if you change your car \n\n *£25 via our service centre*`}</ReactMarkdown>
              <Divider style={{ margin: '16px 0' }} />
              <strong>Change of overnight parking address</strong>
              <ReactMarkdown>{`__£5:__ if you change your car \n\n *£25 via our service centre*`}</ReactMarkdown>
              <Divider style={{ margin: '16px 0' }} />
              <strong>Change / add driver</strong>
              <ReactMarkdown>{`__£5:__ if you change your car \n\n *£25 via our service centre*`}</ReactMarkdown>
              <Divider style={{ margin: '16px 0' }} />

              <Typography>These charges are in addition to any premium charge levied by your insurer</Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Title
              title="Making a claim"
              text={`If you need to make a claim, we are here for you 24 hours a day, every day. What we ask from you is that you save our Claims number 0333 103 7790 on your phone so that you can call us immediately if you have an accident. \n\n Any calls to numbers starting 03 cost the same as calls to 01 or 02 numbers, and may be included in any inclusive calls package you have. Toyota Insurance Services does not earn any revenue from the use of 03 numbers.`}
            />

            <Box padding={2} border={'1px solid #d8d8d8'} borderRadius={4} style={{ backgroundColor: '#eff0f0' }}>
              <div style={{ display: 'flex', gap: 16 }}>
                <PhoneIcon color="secondary" style={{ marginTop: 5 }} />
                <div className={classes.noTextMargin}>
                  <ReactMarkdown>{`Call our claims team \n\n __0333 103 7790__`}</ReactMarkdown>
                </div>
              </div>
              <div style={{ display: 'flex', gap: 16 }}>
                <PhoneIcon color="secondary" style={{ marginTop: 5 }} />
                <div className={classes.noTextMargin}>
                  <ReactMarkdown>{`Call for repairs or replacement to glass in windscreens or windows \n\n __0345 125 4069__`}</ReactMarkdown>
                </div>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography>If you have a query about anything else, please contact our Customer Service team.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Box padding={2} border={'1px solid #d8d8d8'} borderRadius={4} style={{ backgroundColor: '#eff0f0' }}>
              <div style={{ display: 'flex', gap: 16 }}>
                <PhoneIcon color="secondary" style={{ marginTop: 5 }} />
                <div className={classes.noTextMargin}>
                  <ReactMarkdown>{`Call our Customer Service team or contact us on Live Chat. We're here: \n\n __0333 103 7791__ \n\n __Mon-Fri:__ 9am - 5pm \n\n __Sat:__ 9am - 4pm \n\n __Bank holidays:__ 8am - 4pm`}</ReactMarkdown>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Section>
    </Layout>
  )
}

export default RenewalPaymentCompletePage
