import React from 'react'

import { Button, Checkbox, makeStyles, Switch } from '@material-ui/core'

import { setCookies, setPreferences, getCookie } from 'utils/cookie'

const useStyles = makeStyles(theme => ({
  cookieButton: {
    margin: 5,
  },
  settingDiv: {
    textAlign: 'left',
    display: 'flex',
  },
  settingText: {
    textAlign: 'left',
  },
  settingAction: {
    marginLeft: 'auto',
  },
  disabledCheck: {
    color: '#ff7e8f !important',
  },
}))

const CookieSection = () => {
  const currentCookie = getCookie()

  const [functionCookies, setFunctionCookies] = React.useState(
    currentCookie.includes('function') || currentCookie === 'all',
  )
  const [analyticsCookies, setAnalyticsCookies] = React.useState(
    currentCookie.includes('analytics') || currentCookie === 'all',
  )
  const [advertisingCookies, setAdvertisingCookies] = React.useState(
    currentCookie.includes('advertising') || currentCookie === 'all',
  )
  const classes = useStyles()

  return (
    <>
      <div className={classes.settingDiv}>
        <p>
          <b>{`Strictly necessary`} </b>
        </p>
        <Checkbox checked={true} className={classes.settingAction + ' ' + classes.disabledCheck} disabled />
      </div>
      <p className={classes.settingText}>
        {`These are first party cookies that we use to help our site work and provide security, network management, and accessibility. We cannot turn these off, but you can set your browser to block or alert you about these cookies. If you do this, our site may not work properly.For more information please read our Cookie Policy.`}
      </p>
      <div className={classes.settingDiv}>
        <p>
          <b>{`Functional cookies`} </b>
        </p>
        <Switch
          checked={functionCookies}
          className={classes.settingAction}
          onChange={() => {
            setFunctionCookies(!functionCookies)
            setPreferences(!functionCookies, analyticsCookies, advertisingCookies)
          }}
        />
      </div>
      <p className={classes.settingText}>
        {`These are first party Cookies that we use to help us remember the choices you make and provide personalised features, such as 
            preferred language, your region or accessibility preferences. We ask consent to place these Cookies on your browser. If you do 
            not consent, some of our services might not work properly.`}
      </p>

      <div className={classes.settingDiv}>
        <p>
          <b>{`Analytics Cookies`} </b>
        </p>
        <Switch
          checked={analyticsCookies}
          className={classes.settingAction}
          onChange={() => {
            setAnalyticsCookies(!analyticsCookies)
            setPreferences(functionCookies, !analyticsCookies, advertisingCookies)
          }}
        />
      </div>
      <p className={classes.settingText}>
        {`These are first party cookies that we use to help us understand more about the performance of our site. They tell us things like how many people have visited and where they have come from. They also tell how people use our site, such as the pages people spend most time on. These cookies are aggregated and therefore anonymous. We ask for consent to place these cookies of your browser, if you do not consent to these cookies, we will know less about how our site has performed, which means we might not know where improvements need to be made.`}
      </p>

      <div className={classes.settingDiv}>
        <p>
          <b>{`Advertising cookies`} </b>
        </p>
        <Switch
          checked={advertisingCookies}
          className={classes.settingAction}
          onChange={() => {
            setAdvertisingCookies(!advertisingCookies)
            setPreferences(functionCookies, analyticsCookies, !advertisingCookies)
          }}
        />
      </div>
      <p className={classes.settingText}>
        {`These are third party cookies that may be used to build a profile about you, so you can be shown adverts which are tailored to your interests. They do not directly store personal information, but are based on uniquely identifying your browser and internet device. We ask for consent to place these cookies of your browser. If you do not consent, your adverts will not be personalised.`}
      </p>
    </>
  )
}

export default CookieSection
