import * as yup from 'yup'

const validationSchema = yup.object().shape({
  agreement: yup
    .boolean()
    .required()
    .test('value', 'Please confirm the above to proceed', value => value !== false),
  continuousAuthorityGranted_itb: yup.string().required('Please complete the field'),
  accountHolderName: yup
    .string()
    .required('Please complete the field')
    .test('value', 'Too long', value => value !== undefined && value.length <= 60)
    .test(
      'value',
      'Your name contains invalid characters. Please check and re-enter using letters, hyphens and spaces only',
      value => value !== undefined && /^[a-zA-Z]+(?:[-' \s][a-zA-Z]+)*$/.test(value),
    ),
  bankAccountNumber: yup
    .string()
    .required('Please complete the field')
    .min(7, 'Value entered is invalid')
    .max(8, 'Value entered is invalid'),
  sortCode: yup
    .string()
    .required('Please complete the field')
    .matches(/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/, 'Value entered is invalid'),
  isAuthorisedPayment: yup
    .string()
    .required('Please complete the field')
    .test(
      'value',
      `Unfortunately the direct debit must be set up using the bank details of the policyholder and should not require a joint signature.`,
      value => value === 'yes',
    ),
})

const initialValues = {
  agreement: false, // checkbox, boolean (must be true for user to proceed)
  continuousAuthorityGranted_itb: '',
  bankAccountNumber: '',
  bankAccountType: 'individual', // always this (for TYA / ITB at least)
  accountHolderName: '',
  sortCode: '',
  isAuthorisedPayment: '', // boolean (must be true)
}

const paymentMonthlyValidation = { validationSchema, initialValues }

export default paymentMonthlyValidation
