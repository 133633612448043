import { Driver } from 'guidewire/policy/types/driver'
import { getTypekeyValue } from 'guidewire/typekeys/getTypekeyValue'
import moment from 'moment'
import { capitalizeString } from './capitalizeString'

export const driverToDisplay = (driver: Driver) => {
  const driverDisplay = Object.fromEntries(
    Object.keys(driver).map((key: keyof Driver) => {
      if (typeof driver[key] === 'boolean') return [key, driver[key] === true ? 'Yes' : 'No']

      if (key === 'dateOfBirth') return [key, moment(driver[key]).format('DD/MM/YYYY')]

      if (key === 'occupation') {
        return [key, getTypekeyValue(driver[key], 'typekey.OccupationType_itb')]
      }
      if (key === 'employersBusiness') {
        return [key, getTypekeyValue(driver[key], 'typekey.EmployersBusinessType_itb')]
      }
      if (key === 'gender') {
        return [key, getTypekeyValue(driver[key], 'typekey.GenderType')]
      }
      if (key === 'maritalStatus') {
        return [key, getTypekeyValue(driver[key], 'typekey.MaritalStatus')]
      }
      if (key === 'licenseType') {
        return [key, getTypekeyValue(driver[key], 'typekey.DrivingLicenseType_itb')]
      }
      if (key === 'drivingQualifications') {
        return [key, getTypekeyValue(driver[key], 'typekey.DrivingQualifications_itb')]
      }

      if (key === 'title') return [key, capitalizeString(driver[key])]

      return [key, driver[key]]
    }),
  )

  driverDisplay.displayName = `${driverDisplay.firstName} ${driverDisplay.lastName}`

  return driverDisplay
}
