import ReactMarkdown from 'react-markdown'

import { Grid, makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  field: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: `${theme.palette.text.primary}80`,
    borderRadius: 4,
    padding: '14px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    minHeight: 50,

    '& p': {
      margin: 0,
    },
  },
}))

export interface Props {
  value: any
}

const ReadOnlyField = ({ value }: Props) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.field} elevation={0}>
          <ReactMarkdown linkTarget="_blank">{value}</ReactMarkdown>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ReadOnlyField
