import React from 'react'

import DDLogo from 'assets/directDebit.jpg'

import CloseIcon from '@material-ui/icons/Close'

import { Dialog, Grid, IconButton, makeStyles, Typography, Button } from '@material-ui/core'

import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import ReactToPrint from 'react-to-print'

import { ValidationResponseItb } from 'guidewire/policy/types/renewalQuote'

const useStyles = makeStyles(theme => ({
  redText: {
    color: theme.palette.secondary.main,
  },
  viewButton: {
    marginLeft: 'auto',
  },
  viewButtonContainer: {
    display: 'flex',
  },
  ddiContainer: {
    margin: theme.spacing(1),
    marginBottom: 0,
    padding: theme.spacing(1),
    border: '1px solid',
    width: 'fit-content',
  },
  ddi: {
    '& p': {
      margin: 0,
    },
  },
  borderedText: {
    border: '1px solid black',
    padding: '5px 10px',
    margin: 0,
  },
  borderedEmptyText: {
    border: '1px solid black',
    height: '32px',
    margin: 0,
  },
  borderedCell: {
    border: '1px solid black',
    height: '36px',
    width: '32px',
    margin: 0,
  },
  borderedContainer: {
    display: 'flex',
  },
}))

interface Props {
  open: boolean
  accountHolderName: string
  bankAccountNumber: string
  sortCode: string
  bankDetails: ValidationResponseItb | undefined
  onClose: VoidFunction
}

const DirectDebitModal = ({ open, accountHolderName, bankAccountNumber, sortCode, bankDetails, onClose }: Props) => {
  const classes = useStyles()
  const componentRef = React.useRef<HTMLDivElement>(null)

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <IconButton style={{ marginLeft: 'auto' }} aria-label="delete" onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Grid container spacing={2} className={classes.ddiContainer} ref={componentRef}>
        <Grid item container xs={6} sm={6} alignContent="flex-start" spacing={1}>
          <Grid item>
            <Typography component="div" align="left" className={classes.ddi}>
              <ReactMarkdown>{`Premium credit limited \n\n Ermyn house \n\n Ermyn Way \n\n Leatherhead  \n\n Surrey \n\n KT22 8UX `}</ReactMarkdown>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography align="left">{`Name(s) of account holder(s)`}</Typography>
            <Typography align="left" className={classes.borderedText}>
              {accountHolderName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left">{`Bank/Building Society account number`}</Typography>
            <Typography align="left" className={classes.borderedContainer}>
              {bankAccountNumber.split('').map((x, key) => (
                <p key={key} className={classes.borderedText}>
                  {x}
                </p>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left">{`Branch Sort Code`}</Typography>
            <Typography align="left" className={classes.borderedContainer}>
              {sortCode.split('').map((x, key) => (
                <p key={key} className={classes.borderedText}>
                  {x}
                </p>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left">{`Name and full postal address of your Bank or Building Society`}</Typography>
            <Typography align="left" component="div" className={classes.ddi}>
              <p className={classes.borderedText}>{bankDetails?.bank || ''}</p>
              <p className={classes.borderedText}>{bankDetails?.branch || ''}</p>
              <p className={classes.borderedText}>{bankDetails?.contactAddressLine1 || ''}</p>
              <p className={classes.borderedEmptyText} />
              <p className={classes.borderedText}>{bankDetails?.contactPostcode || ''}</p>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} sm={6} alignContent="flex-start" spacing={1}>
          <Grid item xs={7}>
            <b>Instruction to your Bank or Building Society to pay by Direct Debit</b>
          </Grid>
          <Grid item xs={5}>
            <img width="100%" src={DDLogo} />
          </Grid>
          <Grid item xs={12}>
            <Typography align="left">{`Service user number`}</Typography>
            <Typography align="left" className={classes.borderedContainer}>
              {'942461'.split('').map((x, key) => (
                <p key={key} className={classes.borderedText}>
                  {x}
                </p>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left">{`Reference`}</Typography>
            <Typography align="left" className={classes.borderedContainer}>
              <p className={classes.borderedCell} />
              <p className={classes.borderedCell} />
              <p className={classes.borderedCell} />
              <p className={classes.borderedCell} />
              <p className={classes.borderedCell} />
              <p className={classes.borderedCell} />
              <p className={classes.borderedCell} />
              <p className={classes.borderedCell} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" component="div" className={classes.ddi}>
              <ReactMarkdown>
                {`Instruction to your Bank or Building Society \n\n Please pay Premium Credit Limited Direct Debits from the account detailed in this Instruction subject to the safeguards assured by the Direct Debit Guarantee. I understand that this instruction may remain with Premium Card Limited and, if so, details will be passed electronically to my Bank/Building Society`}
              </ReactMarkdown>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" component="div" className={classes.ddi + ' ' + classes.borderedText}>
              <ReactMarkdown>{`Date \n\n ${moment(new Date()).format(
                'DD / MM / YYYY',
              )} \n\n Day Month Year`}</ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ margin: 8 }}>
        <Grid item>
          <Typography>
            The details of your Direct Debit Instruction will be sent to you within 3 working days or no later than 5
            working days before the collection
          </Typography>
          <ReactToPrint
            trigger={() => (
              <Button variant="outlined" color="primary" onClick={() => print()} style={{ marginTop: 10 }}>
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DirectDebitModal
