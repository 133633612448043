import { createPhasedAction } from 'redux/actionCreators'

export const { getCarPageContentAction } = createPhasedAction('GET_CAR_PAGE_CONTENT_ACTION')
export const { getAddressByPostalCodeAction } = createPhasedAction('GET_ADDRESS_BY_POSTAL_CODE_ACTION')
export const { getAddressByIdAction } = createPhasedAction('GET_ADDRESS_BY_ID_ACTION')
export const { getAllMakesAction } = createPhasedAction('GET_ALL_MAKES_ACTION')
export const { getModelsByMakeAction } = createPhasedAction('GET_MODELS_BY_MAKE_ACTION')
export const { getRegiYearsAction } = createPhasedAction('GET_REGI_YEARS_ACTION')
export const { setRegiYearsAction } = createPhasedAction('SET_REGI_YEARS_ACTION')
export const { setVehiclesAction } = createPhasedAction('SET_VEHICLES_ACTION')
export const { setRegistrationAction } = createPhasedAction('SET_REGISTRATION_ACTION')
export const { setSelectedVehicleAction } = createPhasedAction('SET_SELECTED_VEHICLE_ACTION')
export const { getVehiclesAction } = createPhasedAction('GET_VEHICLES_ACTION')
export const { getVehicleByRegistrationAction } = createPhasedAction('GET_VEHICLE_BY_REGISTRATION_ACTION')
export const { selectVehicleAction } = createPhasedAction('SELECT_VEHICLE_ACTION')
export const { setCarPageFormValuesAction } = createPhasedAction('SET_CAR_PAGE_FORM_VALUES_ACTION')
export const { setDriversAction } = createPhasedAction('SET_DRIVERS_ACTION')
export const { resetVehicleAction } = createPhasedAction('RESET_VEHICLE_ACTION')
