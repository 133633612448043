import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .max(60, 'Value must be between 1 and 60 characters')
      .required('Please enter a valid email address')
      .matches(
        /^(([a-zA-Z0-9])|([a-zA-Z0-9\%_\+-]{1,}[a-zA-Z0-9\%._\+-]{1,}[a-zA-Z0-9\%_\+-]{1,}))@(([a-zA-Z0-9\%_\+-])|([a-zA-Z0-9\%_\+-]{1,}[a-zA-Z0-9\%._\+-]{1,}[a-zA-Z0-9\%_\+-]{1,}))\.[a-zA-Z]{2,3}$/,
        'Invalid email',
      ),
    password: yup
      .string()
      .min(1, 'Please complete the field')
      .test('value', 'Please complete the field', value => value !== 'none'),
  })

export const initialValues = {
  email: '',
  password: '',
}

export default validationSchema
