import {
  Box,
  Button,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import { history } from 'utils'
import { DateObject, DriverDisplay, SelectedPolicy } from 'types/policy'
import { AddressById } from 'types/responses'
import { portalTools } from 'guidewire/portalTools'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSectionHardCoded'
import MyDrivers from 'components/myPolicy/MyDrivers'
import { ContentType } from 'types/contentTypes'
import { EndPoint } from 'types/endpoint'
import { formatDateObject } from 'guidewire/formatTools'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  bottomText: {
    marginLeft: 24,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
}))

export interface Props {
  current: SelectedPolicy
  mta: any
  registration: string | undefined
  cancelMTAChangesAction: VoidFunction
  deleteAddedDriverAction: (x: any) => void
  confirmMTAChangesAction: (x: any) => void
}

const MTAMultiChangePage = ({
  current,
  mta,
  registration,
  cancelMTAChangesAction,
  deleteAddedDriverAction,
  confirmMTAChangesAction,
}: Props) => {
  const currentPolicy = portalTools(current)
  const address = currentPolicy.overnightParking().details()
  const carDetails = currentPolicy.getSectionValues().myCarDetails()
  const additionalDrivers = currentPolicy.allDrivers().display()

  const {
    startDate,
    changedAddress,
    homeOwner,
    dayTimeParking,
    nightTimeParking,
    addedDrivers,
    changedCar,
    carPurchaseDate,
    marketValue,
    hasBeenModified,
    securityDevices,
    classOfUse,
    registeredKeeper,
    securityTracker,
    imported,
    rightHandDrive,
    lessThanEightSeats,
    jobId,
    vehicleIsLeased,
    vehicleLeasingCompany,
    vehicleOwner,
  } = mta

  return (
    <>
      <Layout>
        <Box pt={5}>
          <Grid item xs={12}>
            <Section title="Your drivers">
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Drivers</b>
                        </TableCell>
                        <TableCell>
                          <b>Date of birth</b>
                        </TableCell>
                        <TableCell>
                          <b>Driver status</b>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={additionalDrivers?.length + addedDrivers?.length == 5}
                            endIcon={<PersonAddIcon />}
                            onClick={() => {
                              history.push(EndPoint.ADD_DRIVER)
                            }}
                          >
                            {'Add a new driver'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {additionalDrivers.map((item, index) => (
                        <TableRow key={`${item.value} ${index}`}>
                          <TableCell>{item.firstName + ' ' + item.lastName}</TableCell>
                          <TableCell>{item.dateOfBirth}</TableCell>
                          <TableCell>{item.mainDriver === 'Yes' ? 'Main driver' : 'Additional driver'}</TableCell>
                          <TableCell />
                        </TableRow>
                      ))}
                      {addedDrivers?.map(
                        (item: { firstName: string; lastName: string; dateOfBirth: DateObject }, index: any) => (
                          <TableRow key={`added ${index}`}>
                            <TableCell>{item.firstName + ' ' + item.lastName}</TableCell>
                            <TableCell>{formatDateObject(item.dateOfBirth, 'DD / MM / YYYY')}</TableCell>
                            <TableCell>{'Additional driver'}</TableCell>
                            <TableCell align="right">
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                  deleteAddedDriverAction({
                                    index,
                                    startDate,
                                    current,
                                    jobId,
                                  })
                                }}
                              >
                                Remove driver
                              </Button>
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Section>
            <Section title="Your car">
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Make</b>
                        </TableCell>
                        <TableCell>
                          <b>Model</b>
                        </TableCell>
                        <TableCell>
                          <b>Market value</b>
                        </TableCell>
                        <TableCell>
                          <b>Registration</b>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{carDetails.make}</TableCell>
                        <TableCell>{carDetails.model}</TableCell>
                        <TableCell>{carDetails.marketValue}</TableCell>
                        <TableCell>{carDetails.registrationNumber}</TableCell>
                        <TableCell align="right">
                          {!changedCar && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(EndPoint.EDIT_CAR)
                              }}
                            >
                              {'Amend car details'}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                      {changedCar && (
                        <TableRow>
                          <TableCell>{changedCar.make}</TableCell>
                          <TableCell>{changedCar.model}</TableCell>
                          <TableCell>{'£' + marketValue + '.00'}</TableCell>
                          <TableCell>{registration}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(EndPoint.EDIT_CAR)
                              }}
                            >
                              {'Amend car details'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Section>
            <Section title="Your address">
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Address</b>
                        </TableCell>
                        <TableCell>
                          <b>Postcode</b>
                        </TableCell>
                        <TableCell>
                          <b>Purpose</b>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {address.addressLine1}
                          {address.addressLine2 && ' ' + address.addressLine2}
                          {address.addressLine3 && ' ' + address.addressLine3}
                          {' ' + address.city}
                        </TableCell>
                        <TableCell>{address.postalCode}</TableCell>
                        <TableCell>{'current parking address'}</TableCell>
                        <TableCell align="right">
                          {!changedAddress && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(EndPoint.EDIT_OVERNIGHT_ADDRESS)
                              }}
                            >
                              {'Amend address details'}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                      {changedAddress && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {changedAddress.addressLine1}
                            {changedAddress.addressLine2 && ' ' + changedAddress.addressLine2}
                            {changedAddress.addressLine3 && ' ' + changedAddress.addressLine3}
                            {' ' + changedAddress.city}
                          </TableCell>
                          <TableCell>{changedAddress.postalCode}</TableCell>
                          <TableCell>{'new overnight parking'}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                history.push(EndPoint.EDIT_OVERNIGHT_ADDRESS)
                              }}
                            >
                              {'Amend address details'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                <Grid container justify="center" spacing={2}>
                  <Grid item>
                    <Button variant="outlined" color="secondary" onClick={cancelMTAChangesAction}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        confirmMTAChangesAction({
                          startDate,
                          changedAddress,
                          homeOwner,
                          dayTimeParking,
                          nightTimeParking,
                          addedDrivers,
                          changedCar,
                          carPurchaseDate,
                          marketValue,
                          hasBeenModified,
                          securityDevices,
                          classOfUse,
                          registeredKeeper,
                          vehicleIsLeased,
                          vehicleLeasingCompany,
                          vehicleOwner,
                          securityTracker,
                          imported,
                          rightHandDrive,
                          lessThanEightSeats,
                          current,
                          jobId,
                        })
                      }
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Section>
          </Grid>
        </Box>

        <Box pt={5}>
          <Grid item xs={12}>
            <Section title="Helpful information">
              <HelpSection showTitle={false} />
            </Section>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default MTAMultiChangePage
