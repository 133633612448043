import axios from 'axios'

const POLICY_BASE_URL = `/api/policy-service`
const CONTENT_BASE_URL = `/api/contenful-service`

const QUOTE_AND_BUY_URL = `/api/quoteandbuy-service`

// CMS content
export const getLoginPageContent = () => axios.get(CONTENT_BASE_URL + '/login-page')

export const getDetailsPageContent = () => axios.get(CONTENT_BASE_URL + '/toyota/quoteandbuypages/your-details')

export const getPolicies = async (token: string) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/policies`,
    method: 'get',
    headers: {
      'x-authorization': token,
    },
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

  return response
}

export const getPolicyById = async (policyId: string, token: string) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/policies/${policyId}`,
    method: 'get',
    headers: {
      'x-authorization': token,
    },
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

  return response
}

export const getDocumentByIdFromPolicy = (
  id: string,
  sessionId: string,
  name: string,
  mimeType: string,
  policyNumber: string,
  token: string,
) => {
  return axios
    .get(
      `${POLICY_BASE_URL}/docs/${id}?token=${sessionId}&name=${name}&mimeType=${mimeType}&policyNumber=${policyNumber}&jobId=undefined`,
      {
        headers: { 'x-authorization': token },
        responseType: 'arraybuffer',
      },
    )
    .then(response => {
      return {
        status: 200,
        payload: response.data,
      }
    })
    .catch(error => ({
      status: error.response?.status,
      payload: undefined,
    }))
}

export const getTypekeys = async () => {
  const response = await axios({
    url: POLICY_BASE_URL + `/typekeys/all`,
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then(response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return response
}

export const getConvictionCodes = async () => {
  const response = await axios({
    url: QUOTE_AND_BUY_URL + '/convictionCodes',
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { error }
    })
  return response
}
