import { validateYupSchema } from 'formik'
import moment from 'moment'
import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    // Address lookup
    postCodeLookup: yup
      .string()
      .required('Please complete the field')
      .test(
        'value',
        `Please ensure you are providing a valid postcode`,
        value =>
          value !== undefined &&
          /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/.test(
            value.toUpperCase(),
          ),
      ),
    addressSelect: yup.string().required('Please complete the field'),
  })

export const initialValues = {
  // Address lookup
  postCodeLookup: '',
  addressSelect: '',
}

export default validationSchema
