import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { Field, getIn } from 'formik'
import moment from 'moment'

import { LabelValuePair } from 'types/contentTypes'
import QuestionWithSelector from 'components/ui/QuestionWithSelector'
import QuestionWithDatePicker from 'components/ui/QuestionWithDatePicker'
import QuestionWithRadioBtns from 'components/ui/QuestionWithRadioBtns'

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

export interface INewDriverContent {
  id?: string
  title: string
  licenceType: string
  licenceTypeCaption?: string
  licenceTypeToolTip?: string
  licenceTypeOptions: LabelValuePair[]
  firstDroveOnProvisionalLicence: string
  firstDroveOnProvisionalLicenceCaption?: string
  firstDroveOnProvisionalLicenceToolTip?: string
  passTest10YearsAgo: string
  passTest10YearsAgoCaption?: string
  passTest10YearsAgoToolTip?: string
  passTest10YearsAgoOptions: LabelValuePair[]
  whenPassTest: string
  whenPassTestCaption?: string
  whenPassTestToolTip?: string
  accessOtherCars?: string
  accessOtherCarsCaption?: string
  accessOtherCarsToolTip?: string
  accessOtherCarsOptions?: LabelValuePair[]
  drivingQualifications: string
  drivingQualificationsCaption?: string
  drivingQualificationsToolTip?: string
  drivingQualificationsOptions: LabelValuePair[]
  medicalConditions: string
  medicalConditionsCaption?: string
  medicalConditionsToolTip?: string
  medicalConditionsOptions: LabelValuePair[]
  offences: string
  offencesCaption?: string
  offencesToolTip?: string
  offencesOptions: LabelValuePair[]
  cancelledPolicy: string
  cancelledPolicyCaption?: string
  cancelledPolicyToolTip?: string
  cancelledPolicyOptions: LabelValuePair[]
}

interface Props {
  content: INewDriverContent
  values?: any
  renewal?: boolean
}

const NewDriverSection = ({ content, values, renewal = false }: Props) => {
  const {
    title,
    licenceType,
    licenceTypeCaption,
    licenceTypeToolTip,
    licenceTypeOptions,
    firstDroveOnProvisionalLicence,
    firstDroveOnProvisionalLicenceCaption,
    firstDroveOnProvisionalLicenceToolTip,
    passTest10YearsAgo,
    passTest10YearsAgoCaption,
    passTest10YearsAgoToolTip,
    passTest10YearsAgoOptions,
    whenPassTest,
    accessOtherCars,
    accessOtherCarsCaption,
    accessOtherCarsToolTip,
    accessOtherCarsOptions,
    drivingQualifications,
    drivingQualificationsCaption,
    drivingQualificationsToolTip,
    drivingQualificationsOptions,
    medicalConditions,
    medicalConditionsCaption,
    medicalConditionsToolTip,
    medicalConditionsOptions,
    offences,
    offencesCaption,
    offencesToolTip,
    offencesOptions,
    cancelledPolicy,
    cancelledPolicyCaption,
    cancelledPolicyToolTip,
    cancelledPolicyOptions,
  } = content

  const licenseType = getIn(values, 'licenseType')
  const testPassed10Years = getIn(values, 'testPassed10Years')
  const isProvisionalLicence = licenseType === 'ProvisionalEU' || licenseType === 'ProvisionalUK'
  const showWhenDidYouPass = isProvisionalLicence || testPassed10Years === 'no'
  const isFullLicence = licenseType === 'FullUK' || licenseType === 'FullEU' || licenseType === 'Other'

  const classes = useStyles()

  return (
    <>
      <Typography style={{ fontSize: 20, fontWeight: 800 }}>{title}</Typography>
      <Box pt={4} pb={2}>
        <Divider />
      </Box>

      <Box pl={2.5} pr={2.5} pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithSelector}
              name="licenseType"
              question={licenceType}
              hint={licenceTypeCaption}
              toolTip={licenceTypeToolTip}
              options={licenceTypeOptions}
              disabled={renewal}
            />
          </Grid>

          {isProvisionalLicence && (
            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
              <Field
                component={QuestionWithDatePicker}
                name="firstDroveOnProvisionalLicence"
                question={firstDroveOnProvisionalLicence}
                hint={firstDroveOnProvisionalLicenceCaption}
                toolTip={firstDroveOnProvisionalLicenceToolTip}
                format="MM / yyyy"
                disabled={renewal}
              />
            </Grid>
          )}

          {isFullLicence && (
            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
              <Grid item>
                <Field
                  component={QuestionWithRadioBtns}
                  row
                  name="testPassed10Years"
                  question={passTest10YearsAgo}
                  options={passTest10YearsAgoOptions}
                  hint={passTest10YearsAgoCaption}
                  toolTip={passTest10YearsAgoToolTip}
                  disabled={renewal}
                />
              </Grid>
            </Grid>
          )}

          {isFullLicence === true && showWhenDidYouPass === true && (
            <Grid item xs={12} sm={4} className={classes.fieldContainer}>
              <Field
                component={QuestionWithDatePicker}
                name="whenPassTest"
                question={whenPassTest}
                format="MM / yyyy"
                disableFuture={true}
                minDate={moment(new Date())
                  .subtract(10, 'year')
                  .toISOString()}
                disabled={renewal}
              />
            </Grid>
          )}
        </Grid>
        {/* End of first row */}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithRadioBtns}
              row
              name="accessToOtherVehicles"
              question={accessOtherCars}
              hint={accessOtherCarsCaption}
              toolTip={accessOtherCarsToolTip}
              options={accessOtherCarsOptions}
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.fieldContainer}>
            <Field
              component={QuestionWithSelector}
              name="drivingQualifications"
              question={drivingQualifications}
              hint={drivingQualificationsCaption}
              toolTip={drivingQualificationsToolTip}
              options={drivingQualificationsOptions}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8} className={classes.fieldContainer}>
            <Field
              component={QuestionWithSelector}
              name="medicalConditions"
              question={medicalConditions}
              hint={medicalConditionsCaption}
              toolTip={medicalConditionsToolTip}
              options={medicalConditionsOptions}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Field
              component={QuestionWithRadioBtns}
              row
              name="nonMotoringConvictions"
              question={offences}
              hint={offencesCaption}
              toolTip={offencesToolTip}
              options={offencesOptions}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Field
              component={QuestionWithRadioBtns}
              row
              name="cancelledPreviousInsurance"
              question={cancelledPolicy}
              hint={cancelledPolicyCaption}
              toolTip={cancelledPolicyToolTip}
              options={cancelledPolicyOptions}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default NewDriverSection
