import { createMuiTheme } from '@material-ui/core/styles'
import { brandPalette } from './brandPalette'
import { breakpoints } from './breakPoints'
import { buttonStyles, formFieldStyles, heroSection, topBarStyles, stepperStyles } from './overrides'

import toyotaTypeLightOTF from './fonts/ToyotaType-Light.otf'
import toyotaTypeRegularOTF from './fonts/ToyotaType-Regular.otf'
import toyotaTypeSemiBoldOTF from './fonts/ToyotaType-Semibold.otf'

const toyotaTypeLight: any = {
  fontFamily: 'ToyotaType',
  fontStyle: 'lighter',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('ToyotaType'),
    local('ToyotaType-Light'),
    url(${toyotaTypeLightOTF}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const toyotaTypeRegular: any = {
  fontFamily: 'ToyotaType',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('ToyotaType'),
    local('ToyotaType-Regular'),
    url(${toyotaTypeRegularOTF}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const toyotaTypeSemiBold: any = {
  fontFamily: 'ToyotaType',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    local('ToyotaType'),
    local('ToyotaType-SemiBold'),
    url(${toyotaTypeSemiBoldOTF}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const theme = createMuiTheme({
  breakpoints,
  palette: {
    primary: {
      main: brandPalette.brand.primary.main,
      light: brandPalette.brand.primary.light,
    },
    secondary: {
      main: brandPalette.brand.secondary.main,
      light: brandPalette.brand.secondary.light,
    },
    error: {
      light: brandPalette.error.light,
      main: brandPalette.error.main,
      dark: brandPalette.error.main,
    },
    text: {
      primary: brandPalette.bodyText,
      secondary: brandPalette.lightText,
      disabled: `${brandPalette.bodyText}80`,
    },
    background: {
      paper: brandPalette.surface,
      default: brandPalette.background,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'ToyotaType',
    fontSize: 16,
    body1: {
      fontSize: 16,
    },
    h1: {
      color: brandPalette.headers.main,
      fontWeight: 'normal',
      fontSize: 30,
      [breakpoints.up(breakpoints.values.sm)]: {
        fontSize: 40,
      },
    },
    h3: {
      color: brandPalette.headers.main,
      fontWeight: 'normal',
      fontSize: 20,
      [breakpoints.up(breakpoints.values.sm)]: {
        fontSize: 30,
      },
    },
    caption: {
      fontStyle: 'italic',
      fontSize: 12,
    },
  },
  // !! IMPORTANT !!
  // MuiCssBaseline
  // These are branding overides so things like the Topbar, Hero, emphasis can be changed quickly by a content creator
  // Specific styling to components are still apllied in the component directly, not here
  //
  // Material UI global branding options also added here (color, radius, shadow etc)
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [toyotaTypeLight, toyotaTypeRegular, toyotaTypeSemiBold],
        '::-webkit-scrollbar ': {
          display: 'none',
        },
        '*': {
          '-ms-overflow-style': 'none' /* IE and Edge */,
          'scrollbar-width': 'none',
        },
        body: {
          backgroundColor: 'white',
        },
        a: {
          color: brandPalette.brand.secondary.main,
        },
        // #Customer Name - dynamic value in headings, change color
        '#emphasis': {
          color: brandPalette.headers.emphasis,
          textTransform: 'capitalize',
        },
        // TopBar at the top of each page
        ...topBarStyles,
        // Brand options for the Hero section
        ...heroSection,
      },
    },
    // branding for buttons
    MuiButton: buttonStyles,
    // Styling for the form fields
    ...formFieldStyles,
    // stepper
    ...stepperStyles,
  },
})
