import { connect } from 'react-redux'
import { resetAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'
import MTASuccessPage from './MTASuccessPage'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  startDate: state.mta.startDate,
})

const mapDispatchToProps = {
  resetAction: resetAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MTASuccessPage)
