import React from 'react'
import ReactMarkdown from 'react-markdown'
import { v4 as uuidv4 } from 'uuid'

import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { Detail } from 'types/contentTypes'

export interface Props {
  details: Detail[]
}

const useStyles = makeStyles(theme => ({
  space: {
    marginBottom: 20,
  },
  infoContainer: {
    display: 'flex',

    '& p': {
      marginTop: 0,
      marginLeft: 12,
    },
    '& em': {
      fontWeight: 'normal',
      color: theme.palette.secondary.main,
      fontStyle: 'normal',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  textWrap: {
    overflowWrap: 'anywhere',
  },
  infoIcon: {
    width: 18,
  },
  subheading: {
    marginBottom: 20,
    marginLeft: 30,
    fontSize: 20,
    lineHeight: 1.5,
  },
  normal: {
    marginLeft: 30,
    marginBottom: 20,
  },
}))

export const HelpSectionDetails = ({ details }: Props) => {
  const classes = useStyles()

  const getComponent = (detail: Detail) => {
    switch (detail.type) {
      case 'text-italic':
        return (
          <React.Fragment key={uuidv4()}>
            <Grid container spacing={4} className={classes.space}>
              <Grid item xs={12}>
                <Typography component="i">{detail.body}</Typography>
              </Grid>
            </Grid>

            {detail.divider && <Divider className={classes.space} />}
          </React.Fragment>
        )

      case 'text-icon':
        return (
          <React.Fragment key={uuidv4()}>
            <Grid item xs={12}>
              <div className={classes.infoContainer}>
                <InfoOutlinedIcon className={classes.infoIcon} color="secondary" />
                <Typography component="div" className={classes.textWrap}>
                  <ReactMarkdown linkTarget="__blank">{detail.body}</ReactMarkdown>
                </Typography>
              </div>
            </Grid>

            {detail.divider && <Divider className={classes.space} />}
          </React.Fragment>
        )

      case 'text-subheading':
        return (
          <React.Fragment key={uuidv4()}>
            <Grid item>
              <Typography className={classes.subheading}>{detail.body}</Typography>
            </Grid>

            {detail.divider && <Divider className={classes.space} />}
          </React.Fragment>
        )

      case 'text-normal':
      default:
        return (
          <Grid key={uuidv4()} item xs={12}>
            <Typography className={classes.normal}>{`Other useful numbers`}</Typography>
          </Grid>
        )
    }
  }

  return <>{details.map(detail => getComponent(detail))}</>
}
