import axios from 'axios'

const POLICY_BASE_URL = `/api/policy-service`

export const getQuoteSagePayURL = async (requestObject: any, quoteID: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/renewals/${quoteID}/addBillingDetailsAndGetSagePayUrl`,
    method: 'POST',
    data: JSON.stringify(requestObject),
    headers: headers,
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const getValidateDDI = async (requestObject: any, quoteID: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const response = await axios({
    url: `${POLICY_BASE_URL}/renewals/${quoteID}/updateQuotedAndValidateDDI`,
    method: 'post',
    data: requestObject,
    headers: headers,
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return response
}

export const bindPayment = async (requestObject: any, quoteID: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const response = await axios({
    url: `${POLICY_BASE_URL}/renewals/${quoteID}/bindRenewalPayment`,
    method: 'post',
    data: requestObject,
    headers: headers,
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return response
}
