import ReactMarkdown from 'react-markdown'

import RadioYesNo from 'components/formUI/RadioYesNo'
import { RadioYesNoProps } from 'components/formUI/RadioYesNo/RadioYesNo'
import Title from 'components/ui/Title'

interface Props {
  title?: string
  text?: string
  radioProps?: RadioYesNoProps
}

const RegisteredToYou = ({ title = '', text = '', radioProps }: Props) => {
  return (
    <div>
      {title !== '' && <Title title={title} />}

      <RadioYesNo {...radioProps} />

      {text !== '' && <ReactMarkdown>{text}</ReactMarkdown>}
    </div>
  )
}

export default RegisteredToYou
