import { add } from 'date-fns'
import eachDayOfInterval from 'date-fns/fp/eachDayOfInterval/index.js'
import { IncludedAddOn, Quote, AddonRequest, OfferedQuote } from 'types/policyTypes'

import { convertFormValues, createAccountHolderObject, createVehicleObject } from './formatToGuidewire'
import { SelectedAddress } from './types/selectedAddress'
import { SelectedVehicle } from './types/selectedVehicle'

export const guidewireRequestTools = () => {
  const firstQuoteObject = (
    carFormValues: any,
    yourDetailsFormValues: any,
    selectedVehicle: SelectedVehicle,
    selectedAddress: SelectedAddress,
  ) => {
    const formValues = convertFormValues({ ...carFormValues, ...yourDetailsFormValues })

    const accountHolder = createAccountHolderObject(formValues)

    const vehicle = createVehicleObject(selectedVehicle, formValues)

    const requestData = {
      accountHolder,
      marketingAnswer: formValues['marketingAnswer'],
      periodStartDate: formValues['periodStartDate'],
      productCode: 'TraditionalMotor',
      policyAddress: selectedAddress,
      vehicle,
    }

    return requestData
  }

  // Object for the direct journey Quote triggers
  // Should not be used for additional driver
  const policyHolderDriver = (values: any, phIsMainDriver: boolean) => {
    const formValues = convertFormValues(values)

    const requestData = {
      tempID: 1,
      policyHolder: true,
      mainDriver: phIsMainDriver,
      testPassed10Years: formValues['testPassed10Years'],
      licenseType: formValues['licenseType'],
      ...(formValues['passedTestMonth'] && { passedTestMonth: formValues['passedTestMonth'] }),
      ...(formValues['passedTestYear'] && { passedTestYear: formValues['passedTestYear'] }),
      ...(formValues['provisionalLicenseMonth'] && { provisionalLicenseMonth: formValues['provisionalLicenseMonth'] }),
      ...(formValues['provisionalLicenseYear'] && { provisionalLicenseYear: formValues['provisionalLicenseYear'] }),
      accessToOtherVehicles: formValues['accessToOtherVehicles'],
      drivingQualifications: formValues['drivingQualifications'],
      medicalConditions: formValues['medicalConditions'],
      nonMotoringConvictions: formValues['nonMotoringConvictions'],
      cancelledPreviousInsurance: formValues['cancelledPreviousInsurance'],
      hasMotorConvictions: formValues['hasMotorConvictions'],
      ...(formValues['pmMotorConvictions']?.length > 0 && { pmMotorConvictions: formValues['pmMotorConvictions'] }),
      hasMotorClaims: formValues['hasMotorClaims'],
      ...(formValues['pmClaims']?.length > 0 && { pmClaims: formValues['pmClaims'] }),
      occupationStatus: formValues['occupationStatus'],
      employersBusiness: formValues['employersBusiness'],
    }

    return requestData
  }

  // Object for the direct journey Additional Driver
  // mainDriver defaults to false for the initial adding of the driver and can be changed later
  const additionalDriver = (values: any, newlyAdded: boolean) => {
    const formValues = convertFormValues(values)

    const createIDNumber = () => {
      return new Date().getMinutes() + new Date().getMilliseconds()
    }

    const requestData = {
      tempID: formValues['tempID'] ? formValues['tempID'] : createIDNumber().toString(),
      policyHolder: false,
      mainDriver: false,
      testPassed10Years: formValues['testPassed10Years'],
      licenseType: formValues['licenseType'],
      ...(formValues['passedTestMonth'] && { passedTestMonth: formValues['passedTestMonth'] }),
      ...(formValues['passedTestYear'] && { passedTestYear: formValues['passedTestYear'] }),
      ...(formValues['provisionalLicenseMonth'] && { provisionalLicenseMonth: formValues['provisionalLicenseMonth'] }),
      ...(formValues['provisionalLicenseYear'] && { provisionalLicenseYear: formValues['provisionalLicenseYear'] }),
      accessToOtherVehicles: formValues['accessToOtherVehicles'],
      drivingQualifications: formValues['drivingQualifications'],
      medicalConditions: formValues['medicalConditions'],
      nonMotoringConvictions: formValues['nonMotoringConvictions'],
      cancelledPreviousInsurance: formValues['cancelledPreviousInsurance'],
      hasMotorConvictions: formValues['hasMotorConvictions'],
      ...(formValues['pmMotorConvictions']?.length > 0
        ? { pmMotorConvictions: formValues['pmMotorConvictions'] }
        : { pmMotorConvictions: [] }),
      hasMotorClaims: formValues['hasMotorClaims'],
      ...(formValues['pmClaims']?.length > 0 ? { pmClaims: formValues['pmClaims'] } : { pmClaims: [] }),
      occupationStatus: formValues['occupationStatus'],
      employersBusiness: formValues['employersBusiness'],
      // different fields to policyHolder object
      title: formValues['title'],
      firstName: formValues['firstName'],
      lastName: formValues['lastName'],
      dateOfBirth: formValues['dateOfBirth'],
      ukResident5Years: formValues['ukResident5Years'],
      ...(formValues['ukResidencyYear'] && { ukResidencyYear: formValues['ukResidencyYear'] }),
      ...(formValues['ukResidencyMonth'] && { ukResidencyMonth: formValues['ukResidencyMonth'] }),
      homeOwner: formValues['homeOwner'],
      maritalStatus: formValues['maritalStatus'],
      gender: formValues['gender'],
      occupation: formValues['occupation'],
      phRelationship: formValues['phRelationship'],
      ...(formValues['publicID'] && { publicID: formValues['publicID'] }),
      accountHolder: false,
      newlyAdded: newlyAdded,
    }

    return requestData
  }

  const addonSelected = (includedAddOns: IncludedAddOn[], id: string, name?: string, code?: string) => {
    const addon = includedAddOns.find(item => item.codeIdentifier === id)

    if (name && code && addon) {
      addon.terms[0] = { chosenTerm: code, chosenTermValue: name, ...addon.terms[0] }
      addon.terms[0].updated = true
      addon.terms[0].required = true
    }

    return [{ ...addon, selected: true }] as IncludedAddOn[]
  }

  const drivingAbroadSelected = (includedAddOns: IncludedAddOn[], id: string) => {
    const addon = includedAddOns.find(item => item.codeIdentifier === id)

    if (addon) {
      //switch options
      const newChosenTermValue = addon.terms[0].chosenTermValue === '3' ? '90' : '3'

      const termOption = addon.terms[0].options.find(option => option.name === newChosenTermValue)
      if (termOption) {
        addon.terms[0].chosenTerm = termOption.code
        addon.terms[0].chosenTermValue = newChosenTermValue
      }

      addon.terms[0].updated = true
      addon.terms[0].required = true
    }

    return [{ ...addon, selected: true }] as IncludedAddOn[]
  }

  const addonRemoved = (includedAddOns: IncludedAddOn[], id: string) => {
    const addon = includedAddOns.find(item => item.codeIdentifier === id)

    return [{ ...addon, selected: false }] as IncludedAddOn[]
  }

  const addonRequestObject = (quote: Quote, id: string, currNcd: boolean, name?: string, code?: string) => {
    const requestObject: AddonRequest = {
      sessionUUID: quote.sessionUUID,
      quoteID: quote.quoteID,
      quote: quote.quoteData?.offeredQuotes[0] as OfferedQuote,
    }

    if (
      id === 'PMLegalExpensesCov' ||
      id === 'PMTrailerCov' ||
      id === 'TMEnhancedCourtesyCarCov' ||
      id === 'TMBreakdownCov' ||
      id === 'TMKeyProtectCov'
    ) {
      return {
        ...requestObject,
        coverages: addonSelected(quote.includedAddOns, id, name, code),
        ncdProtection: currNcd,
      } as AddonRequest
    }

    if (id === 'PMDrivingAbroadCov') {
      return {
        ...requestObject,
        coverages: drivingAbroadSelected(quote.includedAddOns, id),
        ncdProtection: currNcd,
      } as AddonRequest
    }

    if (id === 'ncdProtection')
      return {
        ...requestObject,
        ncdProtection: true,
      } as AddonRequest

    return requestObject
  }

  const addonRemoveRequestObject = (quote: Quote, id: string, currNcd: boolean) => {
    const requestObject: AddonRequest = {
      sessionUUID: quote.sessionUUID,
      quoteID: quote.quoteID,
      quote: quote.quoteData?.offeredQuotes[0] as OfferedQuote,
    }
    if (
      id === 'PMLegalExpensesCov' ||
      id === 'PMTrailerCov' ||
      id === 'TMEnhancedCourtesyCarCov' ||
      id === 'TMBreakdownCov' ||
      id === 'TMKeyProtectCov'
    ) {
      return {
        ...requestObject,
        coverages: addonRemoved(quote.includedAddOns, id),
        ncdProtection: currNcd,
      } as AddonRequest
    }

    if (id === 'ncdProtection')
      return {
        ...requestObject,
        ncdProtection: false,
        coverages: quote.includedAddOns,
      } as AddonRequest

    return requestObject
  }

  const isSelected = (quote: Quote, id: string) => {
    const addonObject = quote.includedAddOns.find(x => x.codeIdentifier == id)

    return addonObject?.selected
  }

  const editCarPageRequestObject = (
    values: any,
    quote: Quote,
    selectedVehicle: any,
    selectedAddress: any,
    isCarChanged: boolean,
  ) => {
    const formValues = convertFormValues(values)

    const vehicle = createVehicleObject(selectedVehicle, formValues)

    return {
      quoteID: quote.quoteID,
      periodStatus: 'draft',
      periodStartDate: formValues.periodStartDate,
      policyAddress: selectedAddress,
      ...((isCarChanged || selectedVehicle.ncdYears != formValues.ncdYears) && { vehicle: vehicle }),
    }
  }

  const editDetailsPageRequestObject = (
    carFormValues: any,
    yourDetailsFormValues: any,
    quote: Quote,
    drivers: any[],
    selectedVehicle: SelectedVehicle,
    selectedAddress: SelectedAddress,
    isEditedAccountHolder: boolean,
  ) => {
    const formValues = convertFormValues({ ...carFormValues, ...yourDetailsFormValues })

    const accountHolder = createAccountHolderObject(formValues)

    const requestDrivers: any[] = []

    for (let i = 0; i < drivers.length; i++) {
      const driver = drivers[i]
      if (driver.isNew) {
        if (driver.updateOrRemove !== 'remove') {
          requestDrivers.push(driver)
        } else {
          //skip if new and remove
          continue
        }
      }

      if (driver.updateOrRemove) {
        //const driverValues = convertFormValues(driver)
        /* requestDrivers.push({
          ...driverValues,
          dateOfBirth: formatDate(new Date(driver.dateOfBirth).toString()),
        })*/

        requestDrivers.push(driver)
      }

      if ((driver.accountHolder === 'yes' || driver.accountHolder == true) && isEditedAccountHolder) {
        const phDriver = policyHolderDriver({ ...yourDetailsFormValues, ...carFormValues }, driver.mainDriver === 'yes')
        const { tempID, ...phWithoutTemp } = phDriver
        requestDrivers.push({
          updateOrRemove: 'update',
          publicID: driver.publicID,
          accountHolder: true,
          ...phWithoutTemp,
        })
      }
    }

    const { tempId, ...accountHolderWithoutTemp } = accountHolder

    return {
      quoteId: quote.quoteID,
      periodStatus: 'draft',
      accountHolder: accountHolderWithoutTemp,
      drivers: requestDrivers,
      policyAddress: selectedAddress,
    }
  }

  const updateDrivers = (drivers: any[], driver: any, edit: boolean) => {
    //replace driver with new data
    const index = drivers.findIndex(
      d => d.publicID === (driver.publicID || d.tempID === driver.tempID) && d.accountHolder === 'no',
    )

    //convert values
    const requestObject = guidewireRequestTools().additionalDriver(driver, false)

    if (edit) {
      const editRequestObject = {
        ...requestObject,
        isNew: false,
        updateOrRemove: 'update',
      }

      drivers[index] = editRequestObject
      return drivers
    }

    drivers[index] = requestObject
    return drivers
  }

  const setAsMain = (drivers: any[], driverId: any, edit: boolean) => {
    //replace driver with new data
    return drivers.map(driver => {
      driver.mainDriver = driver.tempID?.toString() === driverId || driver.publicID?.toString() === driverId
      if (driver.tempID?.toString() === driverId || driver.publicID?.toString() === driverId) {
        if (edit) {
          // Not the best solution but checks if dob is a string. If yes converts the data. If not, it needs converting
          // Done when the user is clicking change driver multiple times, the conversion was trying to convert something already converted
          const requestObject =
            typeof driver.dateOfBirth === 'string' ? guidewireRequestTools().additionalDriver(driver, false) : driver

          const editRequestObject = {
            ...requestObject,
            isNew: driver.isNew ? true : false,
            mainDriver: true,
          }
          if (driver.isNew !== true) {
            editRequestObject.updateOrRemove = 'update'
          }
          if (driver.accountHolder == true || driver.accountHolder == 'yes') {
            editRequestObject.accountHolder = true
            editRequestObject.policyHolder = true
          }
          return editRequestObject
        }
      }

      return driver
    })
  }

  const setDriversMainDriverToFalse = (drivers: any[]) => {
    return drivers.map(driver => {
      driver.mainDriver = false
      return driver
    })
  }

  const deleteDriver = (drivers: any[], driverId: any, edit: boolean) => {
    console.log(edit)
    if (edit) {
      //set driver to remove
      const deleteIndex = drivers.findIndex(driver => driver.tempID === driverId || driver.publicID === driverId)
      drivers[deleteIndex].updateOrRemove = 'remove'

      if (typeof drivers[deleteIndex].dateOfBirth === 'string') {
        const convert = guidewireRequestTools().additionalDriver(drivers[deleteIndex], false)

        if (drivers[deleteIndex].mainDriver === 'yes') {
          convert.mainDriver = true
        }
        convert.updateOrRemove = 'remove'
        drivers[deleteIndex] = convert
      }

      return drivers
    } else {
      return drivers.filter(function(driver) {
        return driver.tempID !== driverId && driver.publicID !== driverId
      })
    }
  }

  const createQuote = (
    carFormValues: any,
    yourDetailsFormValues: any,
    drivers: any[],
    selectedVehicle: SelectedVehicle,
    selectedAddress: SelectedAddress,
    phIsMainDriver: boolean,
  ) => {
    const formValues = convertFormValues({ ...carFormValues, ...yourDetailsFormValues })

    const accountHolder = createAccountHolderObject(formValues)

    const vehicle = createVehicleObject(selectedVehicle, formValues)

    const phDriver = policyHolderDriver({ ...yourDetailsFormValues, ...carFormValues }, phIsMainDriver)

    const requestData = {
      accountHolder,
      drivers: [phDriver],
      vehicle,
      periodStartDate: formValues['periodStartDate'],
      productCode: 'TraditionalMotor',
      policyAddress: selectedAddress,
      ...(drivers && { additionalDrivers: drivers }),
    }

    return requestData
  }

  return {
    firstQuoteObject,
    policyHolderDriver,
    additionalDriver,
    addonRequestObject,
    addonRemoveRequestObject,
    isSelected,
    editCarPageRequestObject,
    editDetailsPageRequestObject,
    updateDrivers,
    createQuote,
    setAsMain,
    deleteDriver,
    setDriversMainDriverToFalse,
  }
}
