import { Box, Button, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core'

import Layout from 'components/ui/Layout'

import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSectionHardCoded'
import { history } from 'utils'
import { EndPoint } from 'types/endpoint'
import ReactMarkdown from 'react-markdown'
import { dateStringToReadable } from 'guidewire/formatTools'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    textAlign: 'left',
    display: 'flex',
    '& a': {
      textDecoration: 'none',
    },
  },
  infoText: {
    marginTop: 0,
    paddingLeft: 12,
  },
  infoLink: {
    textDecoration: 'none',
  },
  bottomText: {
    marginLeft: 24,
  },
  label: {
    marginBottom: 13,
  },
  readOnlyLabel: {
    marginBottom: 13,
    color: theme.palette.text.disabled,
  },
}))

export interface Props {
  startDate: string
  current: any
  resetAction: VoidFunction
}

const MTASuccessPage = ({ current, startDate, resetAction }: Props) => {
  const classes = useStyles()

  useEffect(() => {
    // reset mta store when component unmounts
    return () => {
      resetAction()
    }
  }, [])

  return (
    <>
      <Layout>
        <Box pt={5}>
          <Grid item xs={12}>
            <Section title="Thank you.">
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }} className={classes.infoContainer}>
                <Typography>
                  <ReactMarkdown linkTarget="__blank">
                    {`The changes have now been applied to your policy. A confirmation email has been sent to ` +
                      current.policyHolder.emailAddress1 +
                      ` and we've updated your policy documents.  \n\nThe change will take effect from ` +
                      dateStringToReadable(startDate) +
                      `. \n\nIf you have any queries, please contact us on Live Chat. We're here 8am - 7:45pm Monday to Friday, 9am - 4pm Saturday and 8am - 4pm Bank Holidays. \n\n Alternatively, please call us on [0333 103 7791](tel:03331037791). Our lines are open 8:30am – 6pm Monday to Friday, 9am – 1pm Saturday and 8am – 4pm Bank Holidays.  \n\n_The cost of calls to 03 prefixed numbers are charged at national call rates (charges may vary dependent on your network provider) and are usually included in inclusive minute plans from landlines and mobiles._`}
                  </ReactMarkdown>
                </Typography>
              </Box>
              <Box pt={2.5} pr={2.5} pl={2.5} pb={4} style={{ display: 'flex' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: 'auto' }}
                  onClick={() => history.push(EndPoint.MY_POLICY)}
                >
                  Go back to my policy
                </Button>
              </Box>
            </Section>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default MTASuccessPage
