import React from 'react'
import ReactMarkdown from 'react-markdown'
import { FormikProps, Formik, Field } from 'formik'

import { Box, Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core'

import AboutYouSection from 'components/driver/AboutYouSection'
import ConvictionsAndClaimsSection from 'components/driver/ConvictionsAndClaimsSection'
import NewDriverSection from 'components/driver/NewDriverSection'
import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import Title from 'components/ui/Title'
import content from './addDriverContent.json'
import convictionCodes from './convictionCodes.json'
import CorrespondenceSection from 'components/account/CorrespondenceSection'
import { LookupAddressType, AddressById } from 'types/responses'
import QuestionWithDatePicker from 'components/ui/QuestionWithDatePicker'
import { EndPoint } from 'types/endpoint'
import { Driver } from 'types/policy'
import RenewalDrivers from 'components/renewal/RenewalDrivers'

import { formToClaims, formToConvictions, isEmpty, stringArrayToOptions } from 'guidewire/formatTools'

import validationSchema, { initialValues } from './validationSchema'

import { getContentById } from 'utils'
import { guidewireRequestTools } from 'guidewire/guidewireRequestTools'
import HowDoWeUseYourInfo from 'components/ui/HowDoWeUseYourInfo'

const useStyles = makeStyles(theme => ({
  screenPadding: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
  },
}))

const numberToWord = (number: number) => {
  switch (number) {
    case 1:
      return 'One Driver'
    case 2:
      return 'Two Drivers'
    case 3:
      return 'Three Drivers'
    case 4:
      return 'Four Drivers'
    default:
      return 'Drivers'
  }
}

export interface Props {
  formValues: any
  additionalDrivers: Driver[]
  lookupAddresses: LookupAddressType[]
  selectedAddress: AddressById
  addedDrivers: Driver[]
  getAddressByPostalCodeAction: (postalCode: string) => void
  getAddressByIdAction: (value: string) => void
  continueRenewalAction: (values: any) => void
}

const RenewalAboutYouPage = ({
  lookupAddresses,
  selectedAddress,
  formValues,
  additionalDrivers,
  addedDrivers,
  getAddressByPostalCodeAction,
  getAddressByIdAction,
  continueRenewalAction,
}: Props) => {
  const classes = useStyles()

  const formRef = React.useRef<FormikProps<any>>(null)

  const [submitType, setSubmitType] = React.useState(0)
  const [redirectParams, setRedirectParams] = React.useState('')
  const [newPrimaryAddress, setNewPrimaryAddress] = React.useState(undefined)
  const [newDrivers, setNewDrivers] = React.useState(addedDrivers)
  const [additionalDriversDisplay, setAdditionalDriversDisplay] = React.useState(additionalDrivers)

  const driversTitle = numberToWord(additionalDrivers.length + newDrivers.length + 1)

  const handleSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      //check if driver detials changed
      if (formValues !== values) {
        values.updateOrRemove = 'update'
      }

      if (newPrimaryAddress) {
        values.primaryAddress = newPrimaryAddress
        values.primaryAddress.changed = true
      }

      continueRenewalAction({
        formValues: values,
        additionalDrivers: additionalDriversDisplay,
        addedDrivers: newDrivers,
        redirect: getSubmitEndPoint(submitType, redirectParams),
      })
    }
  }

  const getSubmitEndPoint = (value: number, params: string) => {
    switch (value) {
      case 1:
        return EndPoint.RENEWAL_ADD_DRIVER

      case 2:
        return EndPoint.RENEWAL_ADD_DRIVER + params

      default:
        return EndPoint.RENEWAL_YOUR_CAR
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={isEmpty(formValues) === false ? formValues : initialValues}
      onSubmit={async values => handleSubmit(values)}
      validationSchema={validationSchema()}
      validateOnBlur={false}
      enableReinitialize
      isInitialValid={true}
    >
      {props => (
        <>
          <Layout background="white">
            <Box pt={8} />
            <Section>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Title title={'Your quote'} removeSubtitleMargin />

                  <HowDoWeUseYourInfo />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <AboutYouSection
                    values={props.values}
                    content={getContentById(content, 'additionalDriverAboutYouSection')}
                    renewal
                  />
                </Grid>

                <Grid item xs={12}>
                  <NewDriverSection
                    content={getContentById(content, 'additionalDriverDrivingExperienceSection')}
                    values={props.values}
                    renewal
                  />
                </Grid>

                <Grid item xs={12}>
                  <ConvictionsAndClaimsSection
                    content={getContentById(content, 'convictionsAndClaimsQuestions')}
                    values={props.values}
                    convictionCodes={stringArrayToOptions({
                      values: convictionCodes.sort().filter(element => isNaN(parseInt(element))),
                    })}
                    convictionsContent={getContentById(content, 'addConvictionSection')}
                    claimsContent={getContentById(content, 'addClaimSection')}
                    confirmConvictions={e => props.setFieldValue('pmMotorConvictions', formToConvictions(e))}
                    confirmClaims={e => props.setFieldValue('pmClaims', formToClaims(e))}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <RenewalDrivers
                    content={{}}
                    title={driversTitle}
                    drivers={additionalDriversDisplay}
                    newDrivers={newDrivers}
                    addDriver={() => {
                      setSubmitType(1)
                      const submit = async () => await props.submitForm()
                      submit()
                    }}
                    disableButton={false}
                    onRemoveAddedDriver={(index: number) => {
                      const removed = newDrivers.filter((value: Driver, listIndex: number) => listIndex !== index)
                      setNewDrivers(removed)
                    }}
                    onRemoveExistingDriver={(index: number) => {
                      const drivers = additionalDriversDisplay
                      const driverToRemove = additionalDriversDisplay[index]
                      driverToRemove.updateOrRemove = 'remove'

                      drivers[index] = driverToRemove
                      setAdditionalDriversDisplay(() => [...drivers])
                    }}
                    cancelRemoveDriver={(index: number) => {
                      const drivers = additionalDriversDisplay
                      const cancelRemoval = additionalDriversDisplay[index]
                      delete cancelRemoval.updateOrRemove

                      drivers[index] = cancelRemoval
                      setAdditionalDriversDisplay(() => [...drivers])
                    }}
                    onSetAdditionalDriverToMain={(index: number) => {
                      const drivers = additionalDriversDisplay
                      const driverToMain = additionalDriversDisplay[index]
                      driverToMain.mainDriver = true

                      drivers[index] = driverToMain

                      setAdditionalDriversDisplay(() => [...drivers])
                    }}
                    onCancelAdditionalDriverToMain={(index: number) => {
                      const drivers = additionalDriversDisplay
                      const driverToMain = additionalDriversDisplay[index]
                      driverToMain.mainDriver = false

                      drivers[index] = driverToMain

                      setAdditionalDriversDisplay(() => [...drivers])
                    }}
                    onEditAddedDriver={(value: string) => {
                      //onEditAddedDriver
                      setSubmitType(2)
                      setRedirectParams('?edit_id=' + value + '&new=true')
                      const submit = async () => await props.submitForm()
                      submit()
                    }}
                    onEditExistingDriver={(value: string) => {
                      //onEditExistingDriver
                      setSubmitType(2)
                      setRedirectParams('?edit_id=' + value + '&new=false')
                      const submit = async () => await props.submitForm()
                      submit()
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Section title="Overnight parking address">
                    <CorrespondenceSection
                      screenPadding={classes.screenPadding}
                      correspondenceAddress={newPrimaryAddress ? newPrimaryAddress : formValues.primaryAddress}
                      lookupAddresses={lookupAddresses}
                      selectedAddress={selectedAddress}
                      getAddressByPostalCodeAction={getAddressByPostalCodeAction}
                      getAddressByIdAction={getAddressByIdAction}
                      submitCorrespondenceAddress={selectedAddress => {
                        setNewPrimaryAddress(selectedAddress)
                      }}
                    />
                  </Section>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Section title="Cover start date">
                    <Box pl={2.5} pr={2.5} pb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <Field
                            required
                            component={QuestionWithDatePicker}
                            name="coverStartDate"
                            disableFuture
                            dob
                            question={'When do you want your insurance to start?'}
                            hint={''}
                            toolTip={''}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Section>
                </Grid>
              </Grid>
            </Section>
          </Layout>

          <Layout background="#eff0f0">
            <Box pt={8} pr={2.5} pl={2.5} pb={4}>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setSubmitType(0)
                      const submit = async () => await props.submitForm()
                      submit()
                    }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Layout>
        </>
      )}
    </Formik>
  )
}

export default RenewalAboutYouPage
