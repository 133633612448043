import { connect } from 'react-redux'
import MTAConfirmPage from './MTAConfirmPage'

import { executeMTAChangesAction, showExecuteMTAModalAction } from 'redux/mta/actions'
import { getAccountInformationAction } from 'redux/account/actions'
import { IReduxState } from 'types/stateTypes'

const mapStateToProps = (state: IReduxState) => ({
  current: state.policy.current,
  mtaResponse: state.mta.mtaResponse,
  correspondenceAddress: state.account.correspondenceAddress,
  autoRenew: state.policy.autoRenew,
})

const mapDispatchToProps = {
  showExecuteMTAModalAction: showExecuteMTAModalAction.start,
  getAccountInformationAction: getAccountInformationAction.start,
  executeMTAChangesAction: executeMTAChangesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MTAConfirmPage)
