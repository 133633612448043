import MuiAlert from '@material-ui/lab/Alert'
import { Snackbar } from '@material-ui/core'

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export interface Props {
  message: string
  isOpen: boolean
  error: boolean
  isSnackbarOpenAction: (action: { isOpen: boolean; message: string; error: boolean }) => void
}

const SnackbarMessage = ({ message, isOpen, error, isSnackbarOpenAction }: Props) => {
  const handleClose = () => {
    isSnackbarOpenAction({ isOpen: false, message: '', error: false })
  }

  return (
    <div>
      {error === false && (
        <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {message}
          </Alert>
        </Snackbar>
      )}
      {error === true && (
        <Snackbar open={isOpen} autoHideDuration={12000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {message}
          </Alert>
        </Snackbar>
      )}
    </div>
  )
}

export default SnackbarMessage
