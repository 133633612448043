export enum PhaseType {
  START = 'START',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface ISuccessMeta {
  successMessage: string
}

export interface IErrorMeta {
  errorMessage: string
  errorCode: number
}

export interface Action<T> {
  meta: {
    id: string
    phase: string
    actionName: string
  }
  payload: T
  type: string
}
