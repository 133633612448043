import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      padding: '0px 15px',
    },
    [theme.breakpoints.between(theme.breakpoints.values.sm, theme.breakpoints.values.md)]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.between(theme.breakpoints.values.md, theme.breakpoints.values.lg)]: {
      padding: '0px 60px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      padding: '0px 190px',
      maxWidth: 1440,
    },
  },
}))

export interface Props {
  children: any
  background?: string
}

const Layout = ({ children, background }: Props) => {
  const classes = useStyles()
  return (
    <Container disableGutters className={classes.root} style={{ backgroundColor: background }} component="div">
      {children}
    </Container>
  )
}

export default Layout
