import React from 'react'
import {
  Grid,
  makeStyles,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  Tooltip,
} from '@material-ui/core'
import { FieldProps, getIn } from 'formik'
import { LabelValuePair } from 'types/generalTypes'

interface Props {
  name: string
  question: string
  hint?: string
  toolTip?: string
  options: LabelValuePair[]
  defaultValue?: string
  disabled?: boolean
  onSelect?: (value: string) => void
}

const useStyles = makeStyles(theme => ({
  inputArea: {
    width: theme.spacing(35),
    justifyContent: 'space-between',
  },
  question: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingRight: theme.spacing(10),
    },

    // First paragraph, remove margin so its level with other elements
    '& :nth-child(1)': {
      marginTop: 0,
    },
  },
  radioGroup: {
    justifyContent: 'space-between',
  },
  radioInRow: {
    justifyContent: 'space-between',
    margin: theme.spacing(0),
    padding: '0 5px',
  },
  radioInColumn: {
    width: '100%',
    margin: `${theme.spacing(2)}px 0 0 0`,
    '&:first-child': {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingLeft: 9,
    },
  },
  radioButton: {
    borderRadius: 4,
    paddingLeft: 15,
    paddingRight: 20,
    margin: '0.5 rem',
    },
  toolTip: {
    marginLeft: 5,
  },
}))

const MarketingPreferenceRadioBtns = ({
  form,
  field,
  name,
  disabled = false,
  onSelect,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const submitCount = form.submitCount
  const errorText = error && touched ? error : null
  return (
    <Grid item xs={12} sm={6}>
      <FormControl>
        <RadioGroup
          name={field.name || undefined}
          value={field.value || ''}
          onChange={event => {
            const { value } = event.target
            onSelect && onSelect(value)
            form.setFieldValue(field.name, value, submitCount > 0)
          }}
        >
          <FormControlLabel
            className={classes.radioButton}
            value={'Yes, I Agree'}
            checked={'Yes, I Agree' === field.value}
            control={<Radio color='secondary' />}
            label={'Yes, I Agree'}
            disabled={disabled}
          />

          <FormControlLabel
          className={classes.radioButton}
            value={'No, I do not Agree'}
            checked={'No, I do not Agree' === field.value}
            control={<Radio color='secondary' />}
            label={'No, I do not Agree'}
            disabled={disabled}
          />
        </RadioGroup>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

export default MarketingPreferenceRadioBtns
