import { createPhasedAction } from 'redux/actionCreators'

export const { getMyPolicyPageContentAction } = createPhasedAction('GET_MY_POLICY_PAGE_CONTENT_ACTION')

export const { getPoliciesAction } = createPhasedAction('GET_POLICIES_ACTION')

export const { policySelectAction } = createPhasedAction('POLICY_SELECT_ACTION')

export const { getPolicyByIdAction } = createPhasedAction('GET_POLICY_BY_ID_ACTION')

export const { setPolicyIdsAction } = createPhasedAction('SET_POLICY_IDS_ACTION')

export const { getTypekeysAction } = createPhasedAction('GET_TYPEKEYS_ACTION')

export const { getDetailsPageContentAction } = createPhasedAction('GET_DETAILS_PAGE_CONTENT_ACTION')

export const { getConvictionCodesAction } = createPhasedAction('GET_CONVICTION_CODES_ACTION')
