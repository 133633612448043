import { useState } from 'react'
import moment from 'moment'

import { makeStyles, Box, Backdrop, CircularProgress } from '@material-ui/core'

import CookieModal from '../CookieModal'
import { LiveChat } from 'components/LiveChat'
import TopBar from 'components/navigation/TopBar'
import Footer from 'components/navigation/Footer'
import LoginModal from 'components/login/LoginModal'
import PolicyNavigationBar from '../PolicyNavBar'

import PolicyHeader from '../PolicyHeader'

import { portalTools } from 'guidewire/portalTools'

import { SelectedPolicy } from 'types/policy'
import { LabelValuePair } from 'types/generalTypes'
import { LoginRequest, PolicySelectRequest } from 'types/requests'
import { EndPoint } from 'types/endpoint'
import { ContentType } from 'types/contentTypes'

import { history } from 'utils'
import { getDateXdaysBefore } from 'guidewire/formatTools'
import RenewalStepper from '../RenewalStepper'

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: theme.palette.secondary.main,
    zIndex: 90,
  },
  root: {
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 3px 6px 0 rgb(0 0 0 / 16%)',
    backgroundColor: theme.palette.background.default,
  },
  topBarOffset: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      marginBottom: 90,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      marginBottom: 90,
    },
  },
  renewalOffset: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      marginBottom: 204,
    },
  },
  topBarOffsetNav: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      // topbarOffset (90) + navigation Appbar height (48)
      marginBottom: 150,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      marginBottom: 150,
    },
  },
  footer: {
    marginTop: 'auto',
  },
}))

export interface Props {
  footerContent: ContentType
  headerContent: ContentType[]
  cookieContent: ContentType[]
  children: React.ReactNode
  showFooter?: boolean
  current?: SelectedPolicy
  renewed?: SelectedPolicy
  isLoading: boolean
  policyIds?: LabelValuePair[]
  currentPolicyIndex?: number
  isPolicyCancelled?: boolean
  showLoginModal: boolean
  contactNumbersMessage: string
  emailOrPasswordMessage: string
  loginRedirect?: string
  isOpen: boolean
  setLiveChatAction: (values: any) => void
  activeStep: number
  policySelectAction: (action: PolicySelectRequest) => void
  logout: () => void
  signIn: (requestObject: { requestObject: LoginRequest }) => void
  closeLoginModalAction: () => void
}

export const PageContainer = ({
  children,
  footerContent,
  headerContent,
  cookieContent,
  current,
  renewed,
  showFooter = true,
  isLoading,
  policyIds,
  currentPolicyIndex,
  isPolicyCancelled, // Added for TOA-2675 fix
  showLoginModal,
  contactNumbersMessage,
  emailOrPasswordMessage,
  loginRedirect,
  isOpen,
  setLiveChatAction,
  activeStep,
  policySelectAction,
  logout,
  signIn,
  closeLoginModalAction,
}: Props) => {
  const classes = useStyles()

  const url = history.location.pathname

  const showRenewalBanner = (expirationDate: Date, renewedStatus: string) => {
    const todaysDate = moment(new Date())
      .utc(false)
      .toISOString()

    const warnDate = getDateXdaysBefore(30, expirationDate)
    const renewDate = getDateXdaysBefore(21, expirationDate)

    const daysUntilRenewalAllowed = moment(warnDate).diff(Date.now(), 'days')
    const daysUntilExpire = moment(expirationDate).diff(Date.now(), 'days')

    const banner = {
      showRenewWarning: moment(todaysDate).isAfter(warnDate) && moment(todaysDate).isBefore(renewDate),
      showRenewButton: moment(todaysDate).isAfter(renewDate) && moment(todaysDate).isBefore(expirationDate),
      expirationDate: moment(expirationDate).format('DD / MM / YYYY'),
      daysUntilRenewalAllowed,
      daysUntilExpire,
    }

    if (renewedStatus === 'Accepted') return undefined

    if (banner.showRenewWarning || banner.showRenewButton) {
      return banner
    }

    return undefined
  }

  const getPolicyHeaderContent = () => {
    // Empty values if the page has no header content or a value is not found
    // Prevents the page from throwing an error
    const error = { title: '', subTitle: '' }

    switch (url) {
      case '/my-policy':
        return {
          title: portalTools(current as SelectedPolicy)
            .vehicle()
            .vehicleDescription(),
        }

      case '/my-account':
        return headerContent.find(item => item.id === 'headerContentMyAccount') || error

      case '/my-messages':
        return headerContent.find(item => item.id === 'headerContentMyMessages') || error

      case '/my-documents':
        return headerContent.find(item => item.id === 'headerContentMyDocuments') || error

      case EndPoint.EDIT_OVERNIGHT_ADDRESS:
        return headerContent.find(item => item.id === 'headerContentEditOvernightAddress') || error

      case EndPoint.MTA_SUMMARY:
        return headerContent.find(item => item.id === 'headerContentMTASummary') || error

      case EndPoint.MTA_MULTI_CHANGE:
        return headerContent.find(item => item.id === 'headerContentMTAMultiChange') || error

      case EndPoint.MTA_CONFIRM:
        return headerContent.find(item => item.id === 'headerContentMTAConfirm') || error

      case EndPoint.MTA_SUCCESS:
        return headerContent.find(item => item.id === 'headerContentMTASuccess') || error

      case EndPoint.ADD_DRIVER:
        return headerContent.find(item => item.id === 'headerContentAdditionalDriverDetails') || error

      case EndPoint.EDIT_CAR:
        return headerContent.find(item => item.id === 'headerContentChangeVehicle') || error

      case '/':
      default:
        return error
    }
  }

  return (
    <>
      {/* Global loading spinner */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Box className={classes.root}>
        <TopBar
          showLogoutButton={showFooter}
          showMyAccountButton={current ? current.status.toLowerCase() !== ('cancelled' || 'lapsed') : false}
          onBrandLogo={() => history.push('/my-policy')}
          onLiveChat={() => setLiveChatAction(!isOpen)}
          onLogout={() => logout()}
          isPolicyCancelled={isPolicyCancelled}
        />

        {isOpen && <LiveChat closeLiveChat={() => setLiveChatAction(!isOpen)} />}

        {(url === EndPoint.MY_POLICY ||
          url == EndPoint.MY_ACCOUNT ||
          url == EndPoint.MY_DOCUMENTS ||
          url == EndPoint.MY_RENEWAL ||
          url == EndPoint.ERROR_MESSAGE) && (
          <PolicyNavigationBar
            url={url}
            // Disabled for MTA only release
            showMyRenewal={renewed !== undefined && (renewed.status === 'Renewing' || renewed.status === 'Accepted')}
            //showMyRenewal={false}
          />
        )}

        {url.startsWith('/renewal') && <RenewalStepper activeStep={activeStep} />}

        {current && currentPolicyIndex !== undefined && url !== '/' && url !== EndPoint.MTA_SUMMARY && (
          <>
            <Box
              className={
                url === EndPoint.MY_POLICY ||
                url == EndPoint.MY_ACCOUNT ||
                url == EndPoint.MY_DOCUMENTS ||
                url == EndPoint.ERROR_MESSAGE ||
                url === EndPoint.MY_RENEWAL
                  ? classes.topBarOffsetNav
                  : url.startsWith('/renewal')
                  ? classes.renewalOffset
                  : classes.topBarOffset
              }
            />
            {!url.startsWith('/renewal') && (
              <PolicyHeader
                content={getPolicyHeaderContent()}
                policyIds={policyIds}
                currentPolicyIndex={currentPolicyIndex}
                // Disabled for MTA only release
                renewalBanner={showRenewalBanner(current.expiration, renewed?.status || '')}
                //renewalBanner={undefined}
                onPolicySelect={({ index, policyId }) => policySelectAction({ index, policyId })}
              />
            )}
          </>
        )}

        {children}

        {showFooter && (
          <div className={classes.footer}>
            <Footer content={footerContent} />
          </div>
        )}

        {/* MODALS */}
        <LoginModal
          showLoginModal={showLoginModal}
          contactNumbersMessage={contactNumbersMessage}
          emailOrPasswordMessage={emailOrPasswordMessage}
          redirect={loginRedirect}
          onLogout={logout}
          signIn={signIn}
          closeLoginModalAction={closeLoginModalAction}
        />

        <CookieModal content={cookieContent} />
      </Box>
    </>
  )
}
