import { Button } from '@material-ui/core'

import ReactMarkdown from 'react-markdown'

export interface Props {
  onBackToSignIn: VoidFunction
}

const PasswordChanged = ({ onBackToSignIn }: Props) => {
  return (
    <>
      <h2>{'Password successfully changed'}</h2>
      <ReactMarkdown>{`You can now sign in using your new password.`}</ReactMarkdown>
      <Button variant="outlined" color="secondary" onClick={() => onBackToSignIn()}>
        {'Back to Sign in'}
      </Button>
    </>
  )
}

export default PasswordChanged
