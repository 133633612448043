import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  Switch,
} from '@material-ui/core'
import React from 'react'
import { Field } from 'formik'
import MarketingPreferenceRadioBtns from './MarketingPreferenceRadioBtns'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: '#6a6b70',
    paddingTop: 20,
    paddingBottom: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  gridContainer: {
    display: 'grid',
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: '19px',
    marginTop: '14px',
  },
}))

export interface Props {
  onSaveChanges: () => void
}

const MarketingPreferencesSection = ({ onSaveChanges }: Props) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            <span>
              {
                'We would like to send you marketing emails and SMS messages about our products and service that we think may be of interest to you. For details, please see our '
              }
              <a
                href='https://assets.ctfassets.net/numinvtctmda/2dKZgMPSSWk880lgwyOuxf/8b59481024af093989cb11d56fbdfe79/TYA_Privacy_Notice_v2.0.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Notice.
              </a>
              {' Please select your preference below:'}
              <span style={{ color: 'red' }}>*</span>
            </span>
          </Typography>
        </Grid>

        <Grid container spacing={4}>
          <Field column component={MarketingPreferenceRadioBtns} name='marketingPreference' />
          <Grid container justify='flex-end'>
            <Grid item xs={12} sm={6}>
              <Button className={classes.button} color='primary' variant='outlined' onClick={() => onSaveChanges()}>
                {'Make changes'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default MarketingPreferencesSection
