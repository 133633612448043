import { FieldProps, getIn } from 'formik'
import { debounce } from 'lodash'

import { makeStyles, TextField, Tooltip, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'
import InfoIcon from '@material-ui/icons/Info'
import Question from '../Question/Question'
import ReactMarkdown from 'react-markdown'
import CaptionText from '../CaptionText'

interface Props {
  name: string
  question: string
  required?: boolean
  hint?: string
  toolTip?: string
  options: string[]
  placeholderText?: string
  onChange?: (name: string, value: string) => void
  onSearch?: (query: string) => void
  onSelect?: (value: string) => void
}

const useStyles = makeStyles(theme => ({
  inputLabel: {
    margin: 0,
  },
  icon: {
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)',
    },
  },
  toolTip: {
    marginLeft: 5,
  },
}))

const QuestionWithAutoCompletion = ({
  field,
  form,
  name,
  question,
  required = false,
  hint,
  toolTip,
  options,
  placeholderText = 'Please select an option',
  onSearch,
  onChange,
  onSelect,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const submitCount = form.submitCount
  const errorText = error && touched ? error : null

  return (
    <>
      {' '}
      <Typography component="div" style={{ display: 'flex' }}>
        <ReactMarkdown linkTarget="_blank">{question}</ReactMarkdown>

        {toolTip && (
          <Tooltip title={toolTip} className={classes.toolTip}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        )}
      </Typography>
      {hint && <CaptionText hint={hint} />}
      <Autocomplete
        {...props}
        {...field}
        // Stops warnings appearing in console when material ui does its default comparison
        getOptionSelected={(option, value) => option.label === value.label}
        disableClearable
        value={field.value || null}
        className={classes.icon}
        popupIcon={<SearchIcon />}
        options={options}
        getOptionLabel={option => option.label || ''}
        onChange={(_, value) => {
          form.setFieldValue(field.name, value, submitCount > 0)
          onSelect && onSelect(value)
        }}
        renderInput={params => (
          <TextField
            {...params}
            {...props}
            className={classes.inputLabel}
            placeholder={placeholderText}
            onChange={evt => {
              const { value } = evt.target
              if (value.length > 2 && onSearch) {
                debounce(onSearch, 500)
              }
            }}
            variant="outlined"
            required
            error={!!errorText}
            helperText={errorText}
          />
        )}
      />
    </>
  )
}

export default QuestionWithAutoCompletion
