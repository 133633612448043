import { validateYupSchema } from 'formik'
import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .max(60, 'Please enter a valid email address')
      .required('Please complete the field')
      .matches(
        /^(([a-zA-Z0-9])|([a-zA-Z0-9\%_\+-]{1,}[a-zA-Z0-9\%._\+-][a-z0-9\%_\+-]{1,}))+@(([a-zA-Z0-9\%_\+-])|([a-zA-Z0-9\%_\+-]{1,}[a-zA-Z0-9\%._\+-]{1,}[a-z0-9\%_\+-]{1,}))+\.[a-z]{2,3}$/,
        'Please enter a valid email address',
      ),
  })

export const initialValues = {
  email: '',
}

export default validationSchema
