import axios from 'axios'
const base64 = require('base-64')

const AUTHORIZATION_BASE_URL = `/api/login-service`
const POLICY_BASE_URL = `/api/policy-service`


export const sendChangePassword = async (
  password: string,
  oldPassword: string,
  access_token: string,
  userId: string,
) => {
  const response = await axios({
    url: `${AUTHORIZATION_BASE_URL}/reset-password?brand=tya`,
    method: 'put',
    headers: {
      'x-authorization': access_token,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ oldPassword: oldPassword, password: password, userId: userId }),
  })
    .then(response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return response
}

export const updateMarketingPreferences = async (
  marketingPrefEmail: string,
  marketingPrefPost: string,
  marketingPrefMobileApp: string,
  marketingPrefLandline: string,
  marketingPrefVoiceMob: string,
  marketingPrefSMS: string,
  marketingOptOut: string,
  access_token: string,
) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/profilesettings/updateMarketingPreferences`,
    method: 'put',
    headers: {
      'x-authorization': access_token,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      marketingPrefEmail,
      marketingPrefPost,
      marketingPrefMobileApp,
      marketingPrefLandline,
      marketingPrefVoiceMob,
      marketingPrefSMS,
      marketingOptOut,
    }),
  })
    .then(response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return response
}

export const getUserinformation = async (token: string) => {
  const address = await axios({
    url: `${AUTHORIZATION_BASE_URL}/userinfo?brand=tya`,
    method: 'GET',
    headers: {
      'x-authorization': token,
      'Content-Type': 'application/json',
    },
  })
    .then(async response => {
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return address
}

export const updateContactNumbers = async (access_token: string, data: any) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/profilesettings/updatePhoneNumbers`,
    method: 'PUT',
    headers: {
      'x-authorization': access_token,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(data),
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

  return response
}

export const updateEmailAddress = async (access_token: string, data: any) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/profilesettings/updateEmailAddress`,
    method: 'PUT',
    headers: {
      'x-authorization': access_token,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(data),
  })
    .then(response => {
      if (response.status === 200) return { response }
    })
    .catch(error => {
      return { response: error.response }
    })

  return response
}

export const getAddressByPostalCode = (postalCode: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  return axios.get(POLICY_BASE_URL + '/addresses', {
    headers: headers,
    params: {
      postalCode,
    },
  })
}

export const getAddressById = (id: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  return axios.get(POLICY_BASE_URL + `/addresses/${id}`, {
    headers: headers,
  })
}

export const updateCorrespondenceAddress = async (token: string, data: any) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const address = await axios({
    url: `${POLICY_BASE_URL}/profilesettings/updateCorrespondenceAddress`,
    method: 'POST',
    headers: headers,
    data: JSON.stringify(data),
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return address
}

export const getAccountInfos = async (access_token: string) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/profilesettings/accountInfo`,
    method: 'GET',
    headers: {
      'x-authorization': access_token,
      'Content-Type': 'application/json',
    },
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return response
}

export const updateCommunicationPreference = async (access_token: string, data: any) => {
  const response = await axios({
    url: `${POLICY_BASE_URL}/profilesettings/updateDocsPreference`,
    method: 'PUT',
    headers: {
      'x-authorization': access_token,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ documentationPreference: data.communicationPreference }),
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: ' Update Comm Preference call failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return response
}

export const updateMyRenewalPreference = async (token: string, data: boolean, policyId: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const prefs = await axios({
    url: `${POLICY_BASE_URL}/policies/${policyId}`,
    method: 'PATCH',
    headers: headers,
    data: JSON.stringify([
      {
        operation: 'SET_AUTO_RENEW_CHOICE',
        path: 'autoRenew',
        value: data,
      },
    ]),
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error.response.status,
        data: error.response,
      }
    })

  return prefs
}
