import { connect } from 'react-redux'

import { cancelMTAChangesAction, executeMTAChangesAction, showExecuteMTAModalAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'
import MTASummaryPage from './MTASummaryPage'

const mapStateToProps = (state: IReduxState) => ({
  mtaResponse: state.mta.mtaResponse,
  startDate: state.mta.startDate,
  current: state.policy.current,
  correspondenceAddress: state.account.correspondenceAddress,
  addedDrivers: state.mta.addedDrivers,
  autoRenew: state.policy.autoRenew,
})

const mapDispatchToProps = {
  cancelMTAChangesAction: cancelMTAChangesAction.start,
  showExecuteMTAModalAction: showExecuteMTAModalAction.start,
  executeMTAChangesAction: executeMTAChangesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MTASummaryPage)
