import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Box, FormControl, FormHelperText, makeStyles, Typography } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

export interface RadioYesNoProps {
  name?: string
  label?: string
  pt?: number
  pb?: number
  formik?: FormikProps<any>
}

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: 10,
    display: 'flex',
  },
  field: {
    padding: '8px 20px 8px 4px',
    height: '50px',
    width: 'fit-content',
    borderRadius: '4px',
    marginLeft: 0,
  },
  fieldBorder: {
    border: 'solid 1px #d8d8d8',
  },
  fieldBorderError: {
    border: `solid 1px ${theme.palette.error.main}`,
  },
}))

const RadioYesNo = ({ name = '', label = '', pt = 2, pb = 8, formik }: RadioYesNoProps) => {
  const classes = useStyles()

  return (
    <Box pt={pt} pb={pb}>
      <FormControl error={formik?.errors[name] && formik.submitCount > 0 ? true : false}>
        {label !== '' && <Typography className={classes.label}>{label}</Typography>}
        <RadioGroup row name={name} value={formik?.values[name] || ''} onChange={formik?.handleChange}>
          <FormControlLabel
            className={`${classes.field} ${
              formik?.errors[name] && formik.submitCount > 0 ? classes.fieldBorderError : classes.fieldBorder
            }`}
            value="yes"
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            className={`${classes.field} ${
              formik?.errors[name] && formik.submitCount > 0 ? classes.fieldBorderError : classes.fieldBorder
            }`}
            value="no"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
        {formik?.errors[name] && formik.submitCount > 0 && <FormHelperText>{formik?.errors[name]}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

export default RadioYesNo
