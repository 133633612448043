import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { LabelValuePair } from 'types'

const useStyles = makeStyles(theme => ({
  root: {
    border: '0',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  label: {
    fontWeight: 'normal',
  },
  container: {
    width: '100%',
  },
}))

export interface Props {
  driver: any
  driverDetails: LabelValuePair[]
  driverOtherDetails: LabelValuePair[]
  showRemoveButton: boolean
  driverIndex: number
  chosenAsMain?: boolean
  status?: string
  onRemoveDriver: (values: any) => void
  onEditAddedDriver: (values: any) => void
  cancelRemoveDriver?: (values: any) => void
  setDriverToMain?: (values: any) => void
  cancelAdditionalDriverToMain?: (values: any) => void
}

export const RenewalDriversDetails = ({
  driver,
  driverDetails,
  driverOtherDetails,
  showRemoveButton = false,
  driverIndex,
  onRemoveDriver,
  onEditAddedDriver,
  status,
  cancelRemoveDriver,
  setDriverToMain,
  chosenAsMain = false,
  cancelAdditionalDriverToMain,
}: Props) => {
  const classes = useStyles()

  return (
    <React.Fragment key={driver.displayName}>
      <Box pb={1} />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* Border only needs to show for first driver or it looks false */}
          {driverIndex < 1 && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Accordion className={classes.root}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
              <Grid container spacing={1}>
                {driverDetails.map(item => (
                  <Grid item xs={4} key={`${item.value} ${driver.displayName}`}>
                    <Typography className={classes.label}>{driver[item.value]}</Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {driverOtherDetails.map(item => (
                          <TableRow key={`${item.value} ${driver.displayName}`}>
                            <TableCell>{item.label}</TableCell>
                            <TableCell align="right">{driver[item.value]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box pb={4} />
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Grid container justify="flex-end">
                        {status !== 'remove' && (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              onRemoveDriver(driverIndex)
                            }}
                          >
                            Remove
                          </Button>
                        )}
                        {cancelRemoveDriver && status === 'remove' && (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              cancelRemoveDriver(driverIndex)
                            }}
                          >
                            Cancel removal
                          </Button>
                        )}
                        {setDriverToMain && !chosenAsMain && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              setDriverToMain(driverIndex)
                            }}
                          >
                            Set as main driver
                          </Button>
                        )}
                        {cancelAdditionalDriverToMain && chosenAsMain && (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              cancelAdditionalDriverToMain(driverIndex)
                            }}
                          >
                            Cancel main driver
                          </Button>
                        )}

                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            onEditAddedDriver(driverIndex)
                          }}
                        >
                          Amend driver details
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
