import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tab: {
    padding: 16,
    borderBottom: '1px solid #282830',
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    textAlign: 'center',
    cursor: 'pointer',
    transition: '.5s',
    backgroundColor: 'rgba(209, 209, 209, 0.28)',
  },
  selected: {
    backgroundColor: '#282830',
    color: 'white',
  },
  mobile: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      display: 'flex',
      justifyContent: ' center',
    },
  },
}))

export interface Props {
  label: string
  selected: boolean
}

const Tab = ({ label, selected = false }: Props) => {
  const classes = useStyles()

  return (
    <div className={`${classes.tab} ${selected ? `${classes.selected}` : ''} ${classes.mobile}`}>
      <div>{label}</div>
    </div>
  )
}

export default Tab
