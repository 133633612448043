import { connect } from 'react-redux'
import { getAddressByPostalCodeAction, getAddressByIdAction } from 'redux/account/actions'
import { continueRenewalAction } from 'redux/renewal/actions'

import { IReduxState } from 'types/stateTypes'
import RenewalAboutYouPage from './RenewalAboutYouPage'

const mapStateToProps = (state: IReduxState) => ({
  lookupAddresses: state.account.lookupAddresses,
  selectedAddress: state.account.selectedAddress,
  additionalDrivers: state.renewal.additionalDrivers,
  formValues: state.renewal.formValues,
  addedDrivers: state.renewal.addedDrivers,
})

const mapDispatchToProps = {
  getAddressByPostalCodeAction: getAddressByPostalCodeAction.start,
  getAddressByIdAction: getAddressByIdAction.start,
  continueRenewalAction: continueRenewalAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalAboutYouPage)
