import { FieldProps, getIn } from 'formik'

import { FormControl, FormHelperText, Select, MenuItem, makeStyles, Typography, Tooltip } from '@material-ui/core'

import InfoIcon from '@material-ui/icons/Info'

import { LabelValuePair } from 'types/generalTypes'
import ReactMarkdown from 'react-markdown'
import CaptionText from '../CaptionText'

interface Props {
  question: string
  name?: string
  hint?: string
  toolTip?: string
  options: LabelValuePair[]
  disabled?: boolean
  placeholderText?: string
  onSelect?: (value: any) => void
}

const useStyles = makeStyles(theme => ({
  inputLabel: {
    width: '100%',
    margin: 0,
    '& .MuiInputLabel-formControl': {
      left: `-${theme.spacing(2)}px`,
    },

    '&.MuiFormControl-root': {
      flexDirection: 'unset',
      display: 'block',
      minHeight: '50px',
    },
  },
  placeholderColor: {
    color: theme.palette.text.disabled,
  },
  toolTip: {
    marginLeft: 5,
  },
  option: {
    whiteSpace: 'break-spaces',
  },
}))

const QuestionWithSelector = ({
  form,
  field,
  question,
  hint,
  toolTip,
  options,
  onSelect,
  placeholderText = 'Please select an option',
  disabled = false,
  ...props
}: Props & FieldProps) => {
  const classes = useStyles()
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const errorText = error && touched ? error : null
  const submitCount = form.submitCount

  return (
    <>
      <Typography component="div" style={{ display: 'flex' }}>
        <ReactMarkdown linkTarget="_blank">{question}</ReactMarkdown>

        {toolTip && (
          <Tooltip title={toolTip} className={classes.toolTip}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        )}
      </Typography>
      {hint && <CaptionText hint={hint} noTopMargin />}
      <FormControl variant="outlined" className={classes.inputLabel} error={!!errorText} {...props} fullWidth>
        <Select
          disabled={disabled}
          className={field.value === 'none' ? classes.placeholderColor : undefined}
          fullWidth
          id={field.name}
          name={field.name}
          value={field.value || 'none'}
          MenuProps={{ disableScrollLock: true }}
          onChange={event => {
            const { value } = event.target
            onSelect && onSelect(value)
            form.setFieldValue(field.name, value, submitCount > 0)
          }}
        >
          <MenuItem value="none" disabled>
            {placeholderText}
          </MenuItem>
          {options.map(option => (
            <MenuItem key={option.value} value={option.value} className={classes.option}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </>
  )
}

export default QuestionWithSelector
