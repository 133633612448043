import { Box, Grid } from '@material-ui/core'
import { Field } from 'formik'
import { addressSuggestionsOptions, isEmpty } from 'guidewire/formatTools'
import React from 'react'
import { LookupAddressType } from 'types/responses'
import Layout from '../Layout'
import QuestionRowButton from '../QuestionRowButton'
import QuestionWithSelector from '../QuestionWithSelector'
import QuestionWithTextField from '../QuestionWithTextField'

export interface Props {
  lookupAddresses: LookupAddressType[]
  onFindMyAddress: (value: string) => void
  onAddressSelect: (value: string) => void
  errorMessage: string
}

const AddressLookup = ({ lookupAddresses, errorMessage, onFindMyAddress, onAddressSelect }: Props) => {
  const [postCode, setPostCode] = React.useState('')
  const addressSuggestions = addressSuggestionsOptions(lookupAddresses)

  return (
    <Layout>
      <Box pt={5} pb={5} pl={2} pr={2}>
        <Grid container direction="column" spacing={4}>
          {isEmpty(lookupAddresses) && (
            <>
              <Field
                required
                component={QuestionWithTextField}
                name="postalCodeLookup"
                question="Enter your postcode"
                inputStyle="uppercase"
                onChange={(value: string) => setPostCode(value.toUpperCase())}
                lookupError={errorMessage}
              />

              <QuestionRowButton
                btnText="Lookup address"
                variant="outlined"
                color="secondary"
                disabled={postCode === ''}
                onClick={() => onFindMyAddress(postCode)}
              />
            </>
          )}

          {isEmpty(lookupAddresses) === false && (
            <Field
              component={QuestionWithSelector}
              name="addressSelect"
              question="Select your address"
              options={addressSuggestions}
              onSelect={(value: string) => onAddressSelect(value)}
            />
          )}
        </Grid>
      </Box>
    </Layout>
  )
}

export default AddressLookup
