import React from 'react'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'

import { Box, Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import DirectDebitModal from 'components/renewal/DirectDebitModal'
import CustomerName from 'components/renewal/CustomerName'
import QuestionReadOnlyField from 'components/renewal/QuestionReadOnlyField'
import Title from 'components/ui/Title'

import { RenewalQuote } from 'guidewire/policy/types/renewalQuote'

import { history } from 'utils'

import { renewalQuote } from 'guidewire/policy/quote/renewalQuote'

import { Display } from 'guidewire/policy/enums/display'
import { LabelValuePair } from 'types'

const useStyles = makeStyles(theme => ({
  redText: {
    color: theme.palette.secondary.main,
  },
  viewButton: {
    marginLeft: 'auto',
  },
  viewButtonContainer: {
    display: 'flex',
  },
  ddiContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid',
    width: 'fit-content',
  },
  ddi: {
    '& p': {
      margin: 0,
    },
  },
  guaranteeHeader: {
    marginBottom: '22px',
  },
}))

export interface Props {
  quote: RenewalQuote
  policyNumber: LabelValuePair
  renewalBindPaymentAction: ({
    requestObject,
    quoteID,
    redirectUrl,
  }: {
    requestObject: { selectedQuote: string; selectedPaymentPlan: string; autoRenew: boolean }
    quoteID: string
    redirectUrl: string
  }) => void
}

const RenewalMonthlyMandatePage = ({ quote, policyNumber, renewalBindPaymentAction }: Props) => {
  // IMPORTANT: This is entirely wrapped in an if statement because of how the quote object updates once a payment is successfully made
  // Once this happens bindData object loses many of its values, values this page expects so it crashes. this happens because quote updates before the redirect has a chance to trigger
  // This bindData object is not used again after this page so it makes no sense to change the BindData type, then have to specify values as their correct type and not undefined on this page
  if (quote.bindData.selectedPaymentPlan) {
    const classes = useStyles()

    const [showMandate, setshowMandate] = React.useState(false)

    const gw = renewalQuote(quote)

    const paymentDetails = gw.getBindData.getDirectDebitDetails()?.bankAccountData
    const bankDetails = paymentDetails?.validationResponse_itb

    const accountHolderName = gw.getAccountHolder.getDisplayName()

    const handleSubmit = () => {
      renewalBindPaymentAction({
        redirectUrl: '/renewal-payment-complete',
        quoteID: policyNumber.value,
        requestObject: {
          selectedQuote: gw.getBindData.getSelectedPaymentPlan().periodID as string,
          selectedPaymentPlan: gw.getBindData.getSelectedBillingId() as string,
          autoRenew: gw.getBindData.details().autoRenew as boolean,
        },
      })
    }

    return (
      <>
        <Layout background="white">
          <Section>
            <DirectDebitModal
              open={showMandate}
              onClose={() => setshowMandate(false)}
              accountHolderName={accountHolderName}
              bankAccountNumber={paymentDetails?.bankAccountNumber || ''}
              sortCode={paymentDetails?.sortCode || ''}
              bankDetails={bankDetails}
            />

            <Grid container spacing={4}>
              <CustomerName
                content={{
                  title: 'Here is your Direct Debit mandate',
                  subtitle:
                    'Please check the following details are correct. If any of the information is incorrect, please go back to the previous screen to amend your payment details.',
                }}
                customerName={accountHolderName}
              />

              <Grid item className={classes.viewButton}>
                <Button variant="outlined" color="secondary" onClick={() => setshowMandate(true)}>
                  {'View  completed mandate'}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* BANK DETAILS */}
              <QuestionReadOnlyField
                question={'Name of the Company Collecting Payments'}
                value={`Premium Credit Limited  \n\n Ermyn house, Ermyn Way  \n\nLeatherhead, Surrey \n\nKT22 8UX`}
              />

              <QuestionReadOnlyField
                question={'Name of Account Holder'}
                value={paymentDetails?.accountHolderName || ''}
              />

              <QuestionReadOnlyField
                question={'Bank/Building Society account number'}
                value={paymentDetails?.bankAccountNumber || ''}
              />

              <QuestionReadOnlyField question={'Branch Sort Code'} value={paymentDetails?.sortCode || ''} />

              <QuestionReadOnlyField
                question={'Name and full postal address of your Bank or Building Society'}
                value={`${bankDetails?.bank || ''} \n\n ${bankDetails?.branch || ''} \n\n ${
                  bankDetails?.contactAddressLine1
                } \n\n ${bankDetails?.contactPostcode}`}
              />

              {/* DD instruction */}
              <Grid item xs={12}>
                <Title title={'Instruction to your Bank or Building Society to pay by Direct Debit'} />
              </Grid>

              <QuestionReadOnlyField question={'Service User Number'} value={`9 4 2 4 6 1`} />

              <QuestionReadOnlyField question={'Reference'} value={' '} />

              <Grid item xs={12}>
                <Title
                  title={'Instruction to your Bank or Building Society'}
                  text={`Please pay Premium Credit Limited Direct Debits from the account detailed in this Instruction subject to
                the safeguards assured by the Direct Debit Guarantee. I understand that this instruction may remain with
                Premium Credit Limited and, if so, details will be passed electronically to my Bank/Building Society`}
                />
              </Grid>

              <QuestionReadOnlyField question={'Date'} value={moment(new Date()).format('DD / MM / YYYY')} />

              <Grid item xs={12}>
                <Typography color="secondary">
                  {'Banks and Building Societies may not accept Direct Debit Instructions for some types of account'}
                </Typography>
              </Grid>

              {/* DD guarantee */}
              <Grid item xs={12}>
                <Title
                  title={'The Direct Debit Guarantee'}
                  text={
                    'This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits. \n\n' +
                    'If there are any changes to the amount, date or frequency of your Direct Debit Premium Credit Limited will notify you five working days in advance of your account being debited or as otherwise agreed. If you request Premium Credit Limited to collect a payment, confirmation of the amount and date will be given to you at the time of the request.\n\n' +
                    'If an error is made in the payment of your Direct Debit by Premium Credit Limited or your bank or building society you are entitled to a full and immediate refund of the amount paid from your bank or building society - If you receive a refund you are not entitled to, you must pay it back when Premium Credit Limited asks you to.\n\n' +
                    'You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify Premium Credit Limited.'
                  }
                />
              </Grid>

              {/* DD instruction */}
              <Grid item xs={12}>
                <Title title={'Below are the details you have entered to set up the Direct Debit Instruction:'} />
              </Grid>

              <QuestionReadOnlyField
                question={'Name of the Company Collecting Payments'}
                value={`Premium Credit Limited  \n\n Ermyn house, Ermyn Way  \n\nLeatherhead, Surrey \n\nKT22 8UX`}
              />

              <QuestionReadOnlyField
                question={'Name of Account Holder'}
                value={paymentDetails?.accountHolderName || ''}
              />

              <QuestionReadOnlyField
                question={'Bank/Building Society account number'}
                value={paymentDetails?.bankAccountNumber || ''}
              />

              <QuestionReadOnlyField question={'Branch Sort Code'} value={paymentDetails?.sortCode || ''} />

              <QuestionReadOnlyField
                question={'Amount to be debited from bank account'}
                value={(gw.getBindData.getSelectedPaymentPlan(Display.DISPLAY).installment as string) || ''}
              />

              <Grid item xs={12}>
                <Typography variant="h3">{'Please note:'}</Typography>

                <Typography component="div">
                  <ReactMarkdown>
                    {`The company name which will appear on your bank statement against the Direct Debit will be PC/AIOI INSURANCE. \n\n Your Direct Debit Instruction will be confirmed to you by email within 3 working days. Alternatively the confirmation letter incorporating your advance notice will be received by you no later than 5 working days prior to the first collection date. If you are to receive this by post it will be sent to the address you have provided for your insurance policy.\n\n You'll be charged £5 by Premium Credit Limited if you change your payment date in the future. If you have any questions about your instalments, contact Premium Credit Limited on 0344 736 9836.`}
                  </ReactMarkdown>
                </Typography>
              </Grid>
            </Grid>
          </Section>
        </Layout>

        <Layout background="#eff0f0">
          <Box pt={8} pr={2.5} pl={2.5} pb={4}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <Button variant="outlined" color="secondary" onClick={() => history.goBack()}>
                  {'Back'}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={() => handleSubmit()}>
                  {'Complete purchase'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Layout>
      </>
    )
  } else {
    return <></>
  }
}

export default RenewalMonthlyMandatePage
