import { Button, Grid, makeStyles } from '@material-ui/core'
import moment from 'moment'

import Modal from 'components/ui/Modal'

import { getDateXdaysBefore } from 'guidewire/formatTools'
import { SelectedPolicy } from 'types/policy'
import { history } from 'utils'
import { EndPoint } from 'types/endpoint'
import { useState } from 'react'

export interface Props {
  current: SelectedPolicy
  mtaResponse: any
  showMTAExecuteModal: boolean
  autoRenew: boolean
  executeMTAChangesAction: (x: any) => void
  setShowExecuteModalAction: (x: any) => void
  cancelMTAChangesAction: VoidFunction
}

const useStyles = makeStyles(theme => ({
  firstButton: {
    marginLeft: 'auto',
    marginRight: 10,
  },
  modalText: {
    paddingTop: 0,
    marginTop: 0,
  },
}))

const MTAExecuteModal = ({
  current,
  mtaResponse,
  showMTAExecuteModal,
  autoRenew,
  executeMTAChangesAction,
  cancelMTAChangesAction,
  setShowExecuteModalAction,
}: Props) => {
  const classes = useStyles()
  const [isButtonClicked, setIsButtonClicked] = useState(false)

  //is installemnt
  const isInstallment = mtaResponse?.paymentPlanId === 'bc:6'
  //is less then 2 installemnts left
  const installmentsLeft = current?.installmentDetails?.remainingInstallments || -1

  //is renewing
  const todaysDate = moment(new Date())
    .utc(false)
    .toISOString()
  const renewDate = getDateXdaysBefore(21, current?.expiration)
  const isRenewing = moment(todaysDate).isAfter(renewDate) && moment(todaysDate).isBefore(current?.expiration)

  //6 day payment cycle before my 2nd from last payment
  const is6DaysBefore2ndFromLastPayment =
    current?.installmentDetails?.remainingInstallments &&
    current?.installmentDetails?.nextPaymentDueDate &&
    current?.installmentDetails?.remainingInstallments === 2 &&
    moment(current?.installmentDetails?.nextPaymentDueDate)
      .subtract(6, 'd')
      .isAfter(moment())

  //implementation fo TOA-2851
  //AC03 AC07 AC11 are handled before in directly executing the MTA without showing the modal

  const showAddNewCard = () => {
    return (
      !isInstallment ||
      installmentsLeft < 2 ||
      (isInstallment &&
        is6DaysBefore2ndFromLastPayment &&
        !isRenewing &&
        !autoRenew &&
        mtaResponse?.totalCost.amount > 0)
    ) //AC02 AC06 AC10 => same AC for different MTAs
  }

  const showContactCallCenter = () => {
    return (
      isInstallment && is6DaysBefore2ndFromLastPayment && !isRenewing && !autoRenew && mtaResponse?.totalCost.amount < 0 //AC04 AC08 AC12 => same AC for different MTAs
    )
  }

  const handleUseSavedCard = () => {
    if (!isButtonClicked){
      setIsButtonClicked(true)
      executeMTAChangesAction({
        current: current,
        mtaResponse: mtaResponse,
        existingCard: true,
      })
    }
  }

  return (
    <Modal open={showMTAExecuteModal} onClose={cancelMTAChangesAction}>
      <Grid item xs={12}>
        <h1>{'How to pay'}</h1>

        {showContactCallCenter() ? (
          <p className={classes.modalText}>
            {'We were unable to complete this transaction. Please call us on 0333 103 7791'}
          </p>
        ) : (
          <>
            <p className={classes.modalText}>
              {mtaResponse?.paymentPlanId === 'bc:6'
                ? installmentsLeft < 2 ||
                  (isInstallment &&
                    is6DaysBefore2ndFromLastPayment &&
                    !isRenewing &&
                    !autoRenew &&
                    mtaResponse?.totalCost.amount > 0)
                  ? "We are unable to add this to your monthly premium, so we will require a payment using the same card details as the original payment, unless you'd like to add a new card"
                  : 'The additional cost will be added to your monthly premuim using the current card'
                : "We'll use the same card details as the original payment, unless you'd like to add a new card."}
            </p>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.firstButton}
              onClick={() => {
                cancelMTAChangesAction()
              }}
            >
              Cancel
            </Button>

            {!isInstallment || //AC02 AC06 AC10 => same AC for different MTAs
            installmentsLeft < 2 ||
            (isInstallment && is6DaysBefore2ndFromLastPayment && !isRenewing && !autoRenew) ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.firstButton}
                onClick={() => {
                  setShowExecuteModalAction(false)
                  history.push(EndPoint.MTA_NEW_CARD)
                }}
              >
                Add a new card
              </Button>
            ) : (
              <></>
            )}
            {!isInstallment || //AC01 AC05 AC09 => same AC for different MTAs
            installmentsLeft > 2 ||
            (isInstallment && is6DaysBefore2ndFromLastPayment && !isRenewing && autoRenew) ? (
              <Button
                variant="outlined"
                color="primary"
                className={classes.firstButton}
                onClick={handleUseSavedCard}
                disabled={isButtonClicked}
              >
                Use saved card
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </Modal>
  )
}

export default MTAExecuteModal
