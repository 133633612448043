import { connect } from 'react-redux'
import { setLiveChatAction } from 'redux/livechat/actions'
import { IReduxState } from 'types/stateTypes'
import ErrorPage from './ErrorPage'

const mapStateToProps = (state: IReduxState) => ({})

const mapDispatchToProps = {
  setLiveChatAction: setLiveChatAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage)
