import React from 'react'
import { Dialog } from '@material-ui/core'

export interface Props {
  alertType?: any
  isClosePopup: boolean
}

const AlertMessage = (props: any) => {
  const [closePopup, setClosePopup] = React.useState(props.isClosePopup)
  return (
    <Dialog
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={!closePopup}
      disableBackdropClick={props.alertType == 'mandate' ? true : false}
      disableEscapeKeyDown={props.alertType == 'mandate' ? true : false}
      onClose={() => {
        setClosePopup(true)
      }}
      maxWidth="xl"
    >
      {props.children}
    </Dialog>
  )
}

export default AlertMessage
