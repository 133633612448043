import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSection'
import DocumentsTab from 'components/document/DocumentsTab'
import DocumentListItem from 'components/document/DocumentListItem'

import { openDocumentForPolicy } from './utils'

import { PolicyDocument } from 'types/policy'

export interface Props {
  content: any
  policyDocs: { documents: PolicyDocument[] }[]
}

const MyDocumentsPage = ({ policyDocs, content }: Props) => {
  const getDocs = (id?: 'current' | 'renewal') => {
    if (id === 'renewal') return policyDocs[1].documents
    return policyDocs[0].documents
  }

  const [docs, setDocs] = React.useState(getDocs())

  // This is for a really specific scenario
  // 1. user is checking out docs for a policy in renewals with the renewals tab selected
  // 2. user selects a policy not in renewals
  // 3. user once again selects a policy in renewals
  // Problem: The docs showing will be for renewal but the selected tab will be for current
  // This makes sure there is a mechanism to set the correct tab, when switching between policies
  React.useEffect(() => {
    if (policyDocs.length === 1) {
      setDocs(getDocs('current'))
    }
  }, [policyDocs])

  return (
    <>
      <Layout>
        <Box pt={8} pb={4}>
          <Section>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {policyDocs.length > 1 && (
                  <DocumentsTab
                    tabs={[
                      { label: 'Current policy', id: 'current' },
                      { label: 'Renewal policy', id: 'renewal' },
                    ]}
                    onTabSelect={(item: any) => setDocs(getDocs(item.id))}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {docs.map(item => (
                  <DocumentListItem
                    key={item.docUID}
                    label={item.description}
                    onClick={() => openDocumentForPolicy(item)}
                  />
                ))}
              </Grid>

              {/* If no docs are in the response */}
              <Grid item xs={12}>
                {docs.length === 0 && <Typography style={{ marginTop: 32 }}>The are currently no documents</Typography>}
              </Grid>
            </Grid>
          </Section>
        </Box>
      </Layout>

      {content !== undefined && (
        <Layout>
          <Section>
            <HelpSection content={content} />
          </Section>
        </Layout>
      )}
    </>
  )
}

export default MyDocumentsPage
