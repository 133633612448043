import { handleActions } from 'redux-actions'
import { setIsLoadingAction } from './actions'

const defaultState = {
  isLoading: false,
}

export default handleActions(
  {
    [setIsLoadingAction.success]: (state, { payload }: { payload: { isLoading: boolean } }) => {
      return {
        ...state,
        isLoading: payload.isLoading,
      }
    },
  },
  defaultState,
)
