import { connect } from 'react-redux'
import { bindPaymentAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'

import SagePayIframe from './SagePayIframe'

const mapStateToProps = (state: IReduxState) => ({
  paymentURL: state.mta.paymentURL,
  jobId: state.mta.jobId,
})

const mapDispatchToProps = {
  bindPaymentAction: bindPaymentAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(SagePayIframe)
