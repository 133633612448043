import { Grid, Button, makeStyles, Typography } from '@material-ui/core'
import Section from 'components/ui/Section'
import { LabelValuePair } from 'types'
import { Driver } from 'types/policy'
import { RenewalDriversDetails } from './RenewalDriversDetails'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { formatDriverToDisplay } from 'guidewire/formatToDisplay'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
  },
  label: {
    fontWeight: 'normal',
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
  },
}))

const driverDetails: LabelValuePair[] = [
  { label: 'First name', value: 'firstName' },
  { label: 'Surname', value: 'lastName' },
  { label: 'Date of birth', value: 'dateOfBirth' },
]

const driverOtherDetails: LabelValuePair[] = [
  { label: 'Primary occupation', value: 'occupation' },
  { label: 'Marital status', value: 'maritalStatus' },
  { label: 'UK residency', value: 'ukResident5Years' },
  { label: 'Licence type', value: 'licenseType' },
  { label: 'Access to other vehicles', value: 'accessToOtherVehicles' },
  { label: 'Additional driving qualifications', value: 'drivingQualifications' },
  { label: 'Medical conditions', value: 'medicalConditions' },
  { label: 'Unspent non-motoring offences', value: 'nonMotoringConvictions' },
  { label: 'Previously cancelled insurance by an insurer', value: 'cancelledPreviousInsurance' },
  { label: 'Convictions', value: 'hasMotorConvictions' },
  { label: 'Claims', value: 'hasMotorClaims' },
]

export interface Props {
  content: any
  title: string
  drivers: Driver[]
  newDrivers: Driver[]
  addDriver: VoidFunction
  disableButton: boolean
  onRemoveAddedDriver: (values: any) => void
  onEditAddedDriver: (values: any) => void
  onRemoveExistingDriver: (values: any) => void
  onEditExistingDriver: (values: any) => void
  cancelRemoveDriver: (values: any) => void
  onSetAdditionalDriverToMain: (values: any) => void
  onCancelAdditionalDriverToMain: (values: any) => void
}

const RenewalDrivers = ({
  content,
  title,
  drivers,
  newDrivers,
  addDriver,
  disableButton = false,
  onRemoveAddedDriver,
  onEditAddedDriver,
  onRemoveExistingDriver,
  onEditExistingDriver,
  cancelRemoveDriver,
  onSetAdditionalDriverToMain,
  onCancelAdditionalDriverToMain,
}: Props) => {
  const classes = useStyles()

  return (
    <Section title={title}>
      <Grid item xs={12}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={() => {
            addDriver()
          }}
          startIcon={<PersonAddIcon />}
        >
          {'Add a new driver'}
        </Button>
      </Grid>

      {drivers && drivers.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography className={classes.title}>All drivers</Typography>
          </Grid>

          <Grid container item xs={11}>
            {driverDetails.map(item => (
              <Grid item xs={4} key={item.label}>
                <Typography color="textSecondary" className={classes.label}>
                  {item.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {drivers
            .map(x => formatDriverToDisplay(x))
            .map((item: any, index: number) => {
              return (
                <RenewalDriversDetails
                  key={`${item.firstName} ${item.lastName}`}
                  driver={item}
                  driverDetails={driverDetails}
                  driverOtherDetails={driverOtherDetails}
                  showRemoveButton
                  driverIndex={index}
                  chosenAsMain={item.mainDriver === 'yes'}
                  status={item.updateOrRemove}
                  onRemoveDriver={onRemoveExistingDriver}
                  cancelRemoveDriver={cancelRemoveDriver}
                  onEditAddedDriver={onEditExistingDriver}
                  setDriverToMain={onSetAdditionalDriverToMain}
                  cancelAdditionalDriverToMain={onCancelAdditionalDriverToMain}
                />
              )
            })}
        </>
      )}

      {newDrivers && newDrivers.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography className={classes.title}>New</Typography>
          </Grid>
          {newDrivers
            .map(x => formatDriverToDisplay(x))
            .map((item: any, index: number) => {
              return (
                <RenewalDriversDetails
                  key={`${item.firstName} ${item.lastName}`}
                  driver={item}
                  driverDetails={driverDetails}
                  driverOtherDetails={driverOtherDetails}
                  showRemoveButton
                  driverIndex={index}
                  onRemoveDriver={onRemoveAddedDriver}
                  onEditAddedDriver={onEditAddedDriver}
                />
              )
            })}
        </>
      )}
    </Section>
  )
}

export default RenewalDrivers
