import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Box, Grid, makeStyles, Paper } from '@material-ui/core'

import HelpSection from 'components/login/HelpSection'
import IntroductionText from 'components/login/IntroductionText'
import { CheckEmail } from 'components/login/CheckEmail'
import { PasswordChanged } from 'components/login/PasswordChanged'
import { ForgotPassword } from 'components/login/ForgotPassword'
import { ConfirmPassword } from 'components/login/ConfirmPassword'
import { LoginForm } from 'components/login/LoginForm'

import { PAGE_STATUS } from 'types/loginPageStatus'
import { LoginRequest } from 'types/requests'
import { EndPoint } from 'types/endpoint'
import { ContentType } from 'types/contentTypes'
import { isEmpty } from 'guidewire/formatTools'
import LoginFooter from 'components/login/LoginFooter'
import moment from 'moment'

export interface Props {
  loginError: boolean
  loginErrorMessage: string
  content: ContentType[]
  getLoginPageContentAction: () => void
  signIn: (requestObject: { requestObject: LoginRequest }) => void
  sendResetPasswordToEmail: (email: { email: string }) => void
  sendResetPassword: (requestObject: { password: string; token: string }) => void
  showNewContent?: boolean
}

const useStyles = makeStyles(theme => ({
  left: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      padding: '30px 15px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: '100px 50px',
      minHeight: 'calc(100vh - 90px)',
      backgroundColor: 'white',
    },
  },
  right: {
    [theme.breakpoints.between(0, theme.breakpoints.values.sm)]: {
      padding: '30px 15px',
      backgroundColor: 'white',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: '100px 50px',
      minHeight: 'calc(100vh - 90px)',
    },
  },
  footer: {
    fontSize: 12,
  },
  loginContainer: {
    padding: '50px 60px 166.5px',
    maxWidth: '650px',
    background: 'white',
  },
}))

const LoginPage = ({
  loginError,
  loginErrorMessage,
  content,
  getLoginPageContentAction,
  signIn,
  sendResetPasswordToEmail,
  sendResetPassword,
}: Props) => {
  const [pageStatus, setPageStatus] = React.useState(PAGE_STATUS.LOGIN)
  const [loginIntroductionContent, setLoginIntroductionContent] = React.useState<ContentType | undefined>(undefined)
  const [helpSectionContent, setHelpSectionContent] = React.useState<ContentType | undefined>(undefined)
  const [loginFooterContent, setLoginFooterContent] = React.useState<ContentType | undefined>(undefined)

  const now = moment()
  let showNewContent = false
  if (now.isSameOrAfter('2023-11-01T00:00:00Z')) {
    showNewContent = true
  }
  const showNewPCPDdoc = moment('20231229', 'YYYYMMDD')
  const PCPD = 'https://assets.ctfassets.net/numinvtctmda/6qBToLDi5L0MPUtX40DaDG/a3703562a36caa7bbbe86f21a1a476bb/TYA_PCPD_v4.0.pdf'
  const showPCPDdoc = now.isAfter(showNewPCPDdoc)
  const params = new URLSearchParams(window.location.search)

  const classes = useStyles()

  const onBackToSignIn = () => setPageStatus(PAGE_STATUS.LOGIN)

  React.useEffect(() => {
    if (isEmpty(content) === true) {
      getLoginPageContentAction()
    }
    
    content.forEach(item => {
      if (item.id === 'loginHelpSection' && item.helpSection) {
        item.helpSection.forEach(section => {
          if (section.id === 'helpSectionComplaints') {
            section.details.forEach(detail => {
              if (detail.type === 'text-icon') {
                detail.body = `We aim to provide a high level of service but if we don’t meet your expectations, please contact us: \n\n *0333 103 7791* \n\n Email: *[complaints.car@toyotainsuranceservices.co.uk](mailto:complaints.car@toyotainsuranceservices.co.uk)* \n\n __Post: Complaints Officer, Toyota Insurance Services, PO Box 1308, Newcastle upon Tyne, NE12 2BF__ \n\n For more information on our complaints procedure, ${
                  !showPCPDdoc ? `please see our [Private Car Policy Document.](${PCPD})` : `please see your Private Car Policy Document which can be found within 'My Documents' once logged into your portal.`
                }`
              }
            })
          }
        })
      }
    })
    if (isEmpty(content) === false) {
      setLoginIntroductionContent(content.find(item => item.id === 'loginIntroductionContent'))
      setHelpSectionContent(content.find(item => item.id === 'loginHelpSection'))
      if(showNewContent === true) {
        setLoginFooterContent(content.find(item => item.id === 'loginFooterContentNew'))
      }else{
        setLoginFooterContent(content.find(item => item.id === 'loginFooterContent'))
      }
    }

    if (window.location.pathname === EndPoint.AUTH_RESET_PASSWORD) setPageStatus(PAGE_STATUS.CONFIRM_PASSWORD)
  }, [content])

  return (
    // CMS JSON content has been fully implemented for this page
    <>
      {isEmpty(content) === false && (
        <>
          {/* Desktop */}
          <Box display={{ xs: 'none', sm: 'block' }} mt={10}>
            <Grid container>
              {/* LEFT SIDE of screen - tablet / desktop */}
              <Grid item sm={6} className={classes.left}>
                {loginIntroductionContent && <IntroductionText content={loginIntroductionContent} />}

                {helpSectionContent && <HelpSection content={helpSectionContent} />}

                {loginFooterContent && <LoginFooter content={loginFooterContent} />}
              </Grid>

              {/* RIGHT SIDE of screen - tablet / desktop */}
              <Grid item sm={6} className={classes.right}>
                <Paper elevation={1} style={{ padding: 20 }}>
                  <div className={classes.loginContainer}>
                    {pageStatus === PAGE_STATUS.LOGIN && (
                      <LoginForm
                        onPasswordForgottenClick={() => setPageStatus(PAGE_STATUS.RESET_PASSWORD)}
                        signIn={(email: string, password: string) =>
                          signIn({ requestObject: { email: email, password: password } })
                        }
                        loginError={loginError}
                        loginErrorMessage={loginErrorMessage}
                      />
                    )}
                    {pageStatus === PAGE_STATUS.RESET_PASSWORD && (
                      <ForgotPassword
                        onBackToSignIn={onBackToSignIn}
                        onReset={email => {
                          setPageStatus(PAGE_STATUS.CHECK_EMAIL)
                          sendResetPasswordToEmail({ email })
                        }}
                      />
                    )}
                    {pageStatus === PAGE_STATUS.CONFIRM_PASSWORD && (
                      <ConfirmPassword
                        onBackToSignIn={onBackToSignIn}
                        onReset={password => {
                          const token: string = params.get('token') || ''
                          setPageStatus(PAGE_STATUS.PASSWORD_CHANGED)
                          sendResetPassword({ token, password })
                        }}
                      />
                    )}
                    {pageStatus === PAGE_STATUS.CHECK_EMAIL && <CheckEmail onBackToSignIn={onBackToSignIn} />}
                    {pageStatus === PAGE_STATUS.PASSWORD_CHANGED && <PasswordChanged onBackToSignIn={onBackToSignIn} />}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          {/* Mobile */}
          <Box display={{ xs: 'block', sm: 'none' }} mt={10}>
            <Grid container>
              {/* TOP of screen - Mobile */}
              <Grid item xs={12} className={classes.left}>
                <div className={classes.loginContainer}>
                  {pageStatus === PAGE_STATUS.LOGIN && (
                    <LoginForm
                      onPasswordForgottenClick={() => setPageStatus(PAGE_STATUS.RESET_PASSWORD)}
                      signIn={(email: any, password: any) =>
                        signIn({ requestObject: { email: email, password: password } })
                      }
                      loginError={loginError}
                      loginErrorMessage={loginErrorMessage}
                    />
                  )}
                  {pageStatus === PAGE_STATUS.RESET_PASSWORD && (
                    <ForgotPassword
                      onBackToSignIn={onBackToSignIn}
                      onReset={email => {
                        setPageStatus(PAGE_STATUS.CHECK_EMAIL)
                        sendResetPasswordToEmail({ email })
                      }}
                    />
                  )}
                  {pageStatus === PAGE_STATUS.CONFIRM_PASSWORD && (
                    <ConfirmPassword
                      onBackToSignIn={onBackToSignIn}
                      onReset={password => {
                        const token: string = params.get('token') || ''
                        setPageStatus(PAGE_STATUS.PASSWORD_CHANGED)
                        sendResetPassword({ token, password })
                      }}
                    />
                  )}
                  {pageStatus === PAGE_STATUS.CHECK_EMAIL && <CheckEmail onBackToSignIn={onBackToSignIn} />}
                  {pageStatus === PAGE_STATUS.PASSWORD_CHANGED && <PasswordChanged onBackToSignIn={onBackToSignIn} />}
                </div>
              </Grid>

              {/* Bottom of screen - Mobile */}
              <Grid item xs={12} className={classes.right}>
                {loginIntroductionContent && <IntroductionText content={loginIntroductionContent} />}

                {helpSectionContent && <HelpSection content={helpSectionContent} />}

                {loginFooterContent && <LoginFooter content={loginFooterContent} />}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}

export default LoginPage
