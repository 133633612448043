import { Grid, makeStyles, Typography } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'

interface Props {
  hint: string
  noTopMargin?: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  noTopMartin: {
    '& p': {
      marginTop: 0,
    },
  },
}))

const CaptionText = ({ hint, noTopMargin }: Props) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          <ReactMarkdown linkTarget="_blank" className={noTopMargin ? classes.noTopMartin : ''}>
            {hint}
          </ReactMarkdown>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CaptionText
