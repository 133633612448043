export enum EndPoint {
  LOGIN = '/',
  MY_POLICY = '/my-policy',
  MY_DOCUMENTS = '/my-documents',
  MY_MESSAGES = '/my-messages',
  MY_ACCOUNT = '/my-account',
  MY_RENEWAL = '/my-renewal',
  RENEWAL_ABOUT_YOU = '/renewal-about-you',
  RENEWAL_ADD_DRIVER = '/renewal-add-driver',
  RENEWAL_YOUR_CAR = '/renewal-your-car',
  RENEWAL_YOUR_QUOTE = '/renewal-your-quote',
  RENEWAL_PAYMENT_SUMMARY = '/renewal-payment-summary',
  RENEWAL_PAYMENT_COMPLETE = '/renewal-payment-complete',
  RENEWAL_MONTHLY_DETAILS = '/renewal-monthly-details',
  RENEWAL_MONTHLY_MANDATE = '/renewal-monthly-mandate',
  RENEWAL_ADDONS = '/renewal-addons',
  RENEWAL_DOCUMENTS = '/renewal-documents',
  RENEWAL_PAYMENT_ANNUAL = '/renewal-payment-annual',
  RENEWAL_PAYMENT_MONTHLY = '/renewal-payment-monthly',
  ERROR_MESSAGE = '/error-message',
  PASSWORD_RESET = '/auth/resetpassword',
  AUTH_RESET_PASSWORD = '/auth/resetpassword',
  EDIT_OVERNIGHT_ADDRESS = '/edit-overnight-address',
  EDIT_CAR = '/edit-car',
  MTA_SUMMARY = '/mta-summary',
  MTA_CONFIRM = '/mta-confirm',
  MTA_NEW_CARD = '/mta-new-card',
  MTA_SUCCESS = '/mta-success',
  MTA_MULTI_CHANGE = '/mta-multi-change',
  ADD_DRIVER = '/add-driver',
  PAYMENT_PAGE = '/payment',
  PAYMENT_FAILURE = '/payment-failure',
  PAYMENT_SUCCESS = '/payment-success',
  DECLINED = '/declined',
}
