// Note: this is a ts file rather than a json due to the use of local svg files.
// When this content comes from the cms, the svg files will have a url

import drivingAbroad from 'assets/drivingAbroad.svg'
import breakdownCover from 'assets/breakdownCover.svg'
import legalExpenses from 'assets/legalExpenses.svg'
import enhancedCourtesyCar from 'assets/enhancedCourtesyCar.svg'
import keyProtect from 'assets/keyProtect.svg'
import trailerCover from 'assets/trailerCover.svg'

import { LabelValuePair } from 'types/generalTypes'

// CMS Content
export const myCoverfirstRow: LabelValuePair = { label: 'Status', value: 'status' }

export const myCover: LabelValuePair[] = [
  { label: 'Start date', value: 'effective' },
  { label: 'End date', value: 'expiration' },
  { label: 'Cover type', value: 'coverType' },
  { label: 'No claim discount', value: 'ncdYears' },
  { label: 'No claim discount protected', value: 'ncdProtection' },
  {
    label: 'Use of car',
    value: 'classOfUse',
    infoIcon: 'Incorrect car usage may affect any claim you make or could result in your insurance being invalid.',
  },
  { label: 'Key Cover', value: 'keyCover'},
]

export const myAddons: LabelValuePair[] = [
  { label: 'Driving Abroad', value: '3 Days', icon: drivingAbroad, codeIdentifier: 'PMDrivingAbroadCov' },
  { label: 'Breakdown Cover', value: 'No', icon: breakdownCover, codeIdentifier: 'TMBreakdownCov' },
  { label: 'Legal Expenses', value: 'No', icon: legalExpenses, codeIdentifier: 'PMLegalExpensesCov' },
  {
    label: 'Enhanced Courtesy Car',
    value: 'No',
    icon: enhancedCourtesyCar,
    codeIdentifier: 'TMEnhancedCourtesyCarCov',
  },
  { label: 'Key Protect', value: 'No', icon: keyProtect, codeIdentifier: 'TMKeyProtectCov' },
  { label: 'Trailer Cover', value: 'No', icon: trailerCover, codeIdentifier: 'PMTrailerCov' },
]

export const myCarDetailsfirstRow: LabelValuePair = { label: 'Registration', value: 'registrationNumber' }

export const myCarDetails: LabelValuePair[] = [
  { label: 'Fuel type', value: 'fuelType' },
  { label: 'Purchased date', value: 'purchasedDate' },
  { label: 'Year of registration', value: 'firstRegisteredYear' },
  {
    label: 'Market value',
    value: 'marketValue',
    infoIcon:
      'This is the cost or replacement with one of the same make, model and specification, considering the age, mileage and condition.  ',
  },
  { label: 'Estimated annual mileage', value: 'annualMileage' },
  { label: 'Security devices', value: 'securityDevices' },
  { label: 'Security tracker', value: 'securityTracker' },
  {
    label: 'Car modified',
    value: 'hasBeenModified',
    infoIcon:
      'This does not include modifications by the manufacturer or for a disability. Contact us if you have questions about a specific modification.',
  },
  { label: 'Right hand drive', value: 'rightHandDrive' },
  { label: 'Less than 8 seats', value: 'lessThanEightSeats' },
  { label: 'Car imported', value: 'imported' },
  {
    label: 'Registered keeper and owner of the car',
    value: 'registeredKeeper',
    infoIcon:
      'The owner is the person who has legal title to the car. The registered keeper is the person or organisation recorded by the DVLA as the legal keeper of the car.',
  },
]

// Renewal Page

export const myCoverRenewal: LabelValuePair[] = [
  { label: 'Policy number', value: 'policyNumber' },
  { label: 'Cover type', value: 'coverType' },
  { label: 'No Claim Discount', value: 'ncdYears' },
  { label: 'Other drivers', value: 'otherDrivers' },
  { label: 'Start date', value: 'effective' },
  { label: 'End date', value: 'expiration' },
  { label: 'Excess', value: 'excess' },
  { label: 'Windscreen replacement', value: 'windscreenReplace' },
  { label: 'Windscreen repair', value: 'windscreenRepair' },
  { label: 'How do you use your car?', value: 'classOfUse' },
  { label: 'Key Cover', value: 'keyCover'},
]

export const myCarDetailsRenewal: LabelValuePair[] = [{ label: 'Car', value: 'displayName' }, ...myCarDetails]
