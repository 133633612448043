import {
  Accordion,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    border: '0',
    boxShadow: 'none',
    borderBottom: (props: any) => (props.useBorder ? `1px solid ${theme.palette.divider}` : 0),
    '&:last-child': {
      borderRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  label: {
    fontWeight: 'normal',
  },
  container: {
    width: '100%',
  },
}))

export interface Props {
  title: string
  children: React.ReactNode
  useBorder?: boolean
}

const BasicAccordion = ({ title, children, useBorder = true }: Props) => {
  const props = { useBorder }
  const classes = useStyles(props)

  return (
    <Accordion className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.label}>{title}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div>{children}</div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default BasicAccordion
