import { connect } from 'react-redux'
import { continueAddDriverAction } from 'redux/mta/actions'
import { addRenewalDriverAction } from 'redux/renewal/actions'
import { IReduxState } from 'types/stateTypes'
import RenewalAddDriver from './RenewalAddDriverPage'

const mapStateToProps = (state: IReduxState) => ({
  addedDrivers: state.renewal.addedDrivers,
  additionalDrivers: state.renewal.additionalDrivers,
})

const mapDispatchToProps = {
  continueAddDriverAction: continueAddDriverAction.start,
  addRenewalDriverAction: addRenewalDriverAction.start,
  // TODO: Make sure the convictions codes API is working
  // getConvictionCodesAction: getConvictionCodesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalAddDriver)
