import { accountHolderToDisplay } from 'guidewire/format/accountHolderToDisplay'
import { capitalizeString } from 'guidewire/format/capitalizeString'
import { AccountHolder } from '../types/accountHolder'

export const accountHolderUtils = (accountHolder: AccountHolder) => {
  const { displayName, title } = accountHolder

  const details = () => accountHolder

  const display = () => {
    return accountHolderToDisplay(accountHolder)
  }

  const getDisplayName = (withTitle?: 'withTitle') => {
    if (withTitle) return capitalizeString(`${title} ${displayName}`)
    return capitalizeString(displayName)
  }

  return {
    details,
    display,
    getDisplayName,
  }
}
