import { makeStyles, Typography } from '@material-ui/core'

import { HelpAccordian } from './HelpAccordian'
import { HelpSectionDetails } from './HelpSectionDetails'

import { ContentType } from 'types/contentTypes'

export interface Props {
  showTitle?: boolean
  content: ContentType
}

const useStyles = makeStyles(theme => ({
  title: {
    margin: '20px 0',
  },
}))

const HelpSection = ({ showTitle = true, content }: Props) => {
  const classes = useStyles()

  const { title, helpSection } = content

  return (
    <div style={{ paddingTop: 16 }}>
      {showTitle && (
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      )}

      {helpSection &&
        helpSection.map(item => (
          <HelpAccordian key={item.title} title={item.title}>
            <HelpSectionDetails details={item.details} />
          </HelpAccordian>
        ))}
    </div>
  )
}

export default HelpSection
