import { all, put, takeLatest } from 'redux-saga/effects'
import { setIsLoadingAction } from './actions'

export function* onIsLoadingAction(action: any) {
  const {
    payload: { isLoading },
  } = action

  yield put(setIsLoadingAction.success({ isLoading }))
}

export default function* rootSaga() {
  yield all([takeLatest(setIsLoadingAction.start, onIsLoadingAction)])
}
